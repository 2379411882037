import React from "react";
import { CreateButton, DateField, Filter, FunctionField, ReferenceField, TextField } from "react-admin";
import { List } from "@react-admin/ra-rbac";
import { CustomDatagrid } from "../../components/react-admin/CustomDatagrid";
import { ICollaborator } from "./ICollaborator";
import FormInput from "../../components/form/FormInput";
import { ListActions } from "../../components/react-admin/ListActions";
import PageTitle from "../../components/navigation/PageTitle";
import { Typography, useTheme } from "@mui/material";
import ListChip from "../../components/fields/ListChip";

const CollaboratorsList = ({ ...props }) => {
  const theme = useTheme();
  return (
    <>
      <PageTitle
        text="Collaborateurs"
        createButton={<CreateButton label="Nouveau collaborateur" resource="collaborators" />}
        type="list"
        breadcrumbListLabel="Liste des collaborateurs"
      />
      <List
        perPage={5}
        {...props}
        filters={
          <Filter {...props}>
            <FormInput name="multi_firstName_lastName" type="search" alwaysOn />
          </Filter>
        }
        sort={{ field: "firstName", order: "ASC" }}
        actions={<ListActions />}
      >
        <CustomDatagrid>
          <ReferenceField label="Nom complet" source="id" reference="collaborators">
            <FunctionField
              render={(record: ICollaborator) => {
                const formattedFirstName = record.firstName[0].toUpperCase() + record.firstName.slice(1);
                const formattedLastName = record.lastName.toUpperCase();
                return <b>{`${formattedFirstName} ${formattedLastName}`}</b>;
              }}
            />
          </ReferenceField>
          <TextField source="service" />
          <TextField source="role" />
          <FunctionField
            label="Statut"
            render={(record: ICollaborator) => {
              const isActive = record.accountStatus === "Actif";
              return <ListChip label={record.accountStatus} color={isActive ? "secondary" : "error"} />;
            }}
          />
          <DateField label="Modifié le" source="lastupdate" showTime={true} />
        </CustomDatagrid>
      </List>
    </>
  );
};

export default CollaboratorsList;
