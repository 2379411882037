import React from "react";
import { Typography, Box } from "@mui/material";
import { InfoIcon } from "lucide-react";

export default function Info({ type = "info", variant = "bold_sm", content = "", iconSize = 16, mb = 1 }: any) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "start",
        borderRadius: 2,
        backgroundColor: "button.neutral.contained.background",
        p: 1,
        gap: 1,
        mb,
      }}
    >
      <Box
        sx={{
          width: iconSize,
          height: iconSize,
        }}
      >
        <InfoIcon size={iconSize} />
      </Box>

      <Typography variant={variant} color="button.neutral.contained.content">
        {content}
      </Typography>
    </Box>
  );
}
