import {
  Box,
  Typography,
  useTheme,
  InputAdornment,
  Card,
  Divider,
  Button,
  Chip,
  IconButton,
  alpha,
  useMediaQuery,
} from "@mui/material";
import { ArrowLeftRight, CheckCircle, Coins, CreditCard, Minus, PenLine, Plus, XCircle } from "lucide-react";
import { useEffect, useState } from "react";
import React from "react";
import { useGetList, useGetOne, useNotify } from "react-admin";
import api from "../../services/apiWithAuth";
import FormInput from "../../components/form/FormInput";
import { useForm, FormProvider, useWatch, useFormContext } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";
import priceCalculator from "../../services/common-utils/priceCalculator";
import SubscriptionAction from "./SubscriptionActions";
import LicenseQuantityInput from "./LicenseQuantityInput";
import { collection, getDocs, query, where } from "firebase/firestore";
import Modal from "../../components/modals/Modal";
import SwitchablePaymentMethod from "./SwitchablePaymentMethod";
import Info from "../../components/info/Info";
import { useGetAutoDiscount } from "../../services/hooks/useGetAutoDiscount";
import { isEmpty } from "lodash";
import { useGetSubscriptions } from "../../services/hooks/useGetSubscriptions";
import { useGetPendingOrders } from "../../services/hooks/useGetPendingOrders";
import { fbFirestore } from "../../services/firebase/firebase.services";
import loadChat from "../../services/loadChat";
declare const window: any;

const SubscriptionDisplay = ({ subscription, ...props }: any) => {
  const { data: discount } = useGetOne("discounts", { id: subscription.discount });
  const theme = useTheme();
  return !subscription ? null : (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1, width: 1 }}>
      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: 1 }}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="medium_sm" color="common.title">
            {subscription.type === "Licence" ? "Licence utilisateur" : "Recharge automatique"}
          </Typography>
          <IconChip
            text={`Formule ${subscription.billingPeriod.toLowerCase()}`}
            icon={<CreditCard size={18} color={theme.palette.button.neutral.contained.content} />}
            typographyVariant="regular_xs"
            px={0.5}
            py={0}
          />
          <Typography variant="regular_xs" color="common.title">
            {subscription.type === "Licence"
              ? `${subscription.quantity - (subscription?.licensesToDelete?.length || 0)} utilisateur${
                  subscription.quantity - (subscription?.licensesToDelete?.length || 0) > 1 ? "s" : ""
                }`
              : `Recharge ${subscription.quantity} SMS`}
          </Typography>
        </Box>
        <Typography variant="medium_xs" color="common.title">
          {subscription.totals.withoutDiscount.totalHt.toFixed(2)}€
        </Typography>
      </Box>
      {subscription?.discount && subscription.totals.savings.totalHt > 0.1 && (
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: 1 }}>
          <Typography variant="regular_sm" color="common.title">
            {discount?.name}
          </Typography>
          <Typography variant="medium_xs" color="common.title">
            -{subscription.totals.savings.totalHt.toFixed(2)}€
          </Typography>
        </Box>
      )}
      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: 1 }}>
        <Typography variant="regular_sm" color="common.title">
          {subscription.totals.totalTtc - subscription.totals.totalHt > 0.01 ? "TVA 20%" : "TVA"}
        </Typography>
        <Typography variant="medium_xs" color="common.title">
          {subscription.totals.totalTtc - subscription.totals.totalHt > 0.01
            ? (subscription.totals.totalTtc - subscription.totals.totalHt).toFixed(2) + "€"
            : "Non applicable"}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: 1 }}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="bold_sm" color="common.title">
            Total
          </Typography>
          <Typography variant="regular_2xs" color="common.title">
            Facturé le{" "}
            {new Date(subscription.periodEnd * 1000).toLocaleDateString("fr", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </Typography>
        </Box>
        <Typography variant="bold_xs" color="common.title">
          {subscription.totals.totalTtc.toFixed(2)}€
        </Typography>
      </Box>
    </Box>
  );
};

const FeatureChip = ({ text, available = true }) => {
  const theme = useTheme();
  return (
    <Chip
      label={
        <Typography
          variant="medium_2xs"
          sx={{
            color: `button.neutral.contained.content`,
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          {available ? (
            <CheckCircle size={10} color={theme.palette.button.neutral.contained.content} />
          ) : (
            <XCircle size={10} color={theme.palette.button.neutral.contained.content} />
          )}
          {text}
        </Typography>
      }
      sx={{
        width: "fit-content",
        backgroundColor: `button.neutral.contained.background`,
        borderRadius: 2,
        height: "1rem",
        "& .MuiChip-label": {
          px: 0.5,
        },
      }}
    />
  );
};

const IconChip = ({ text, icon, typographyVariant = "medium_sm", px = 1, py = 0.5 }) => {
  return (
    <Chip
      label={
        <Typography
          variant={typographyVariant}
          sx={{
            color: `button.neutral.contained.content`,
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          {icon}
          {text}
        </Typography>
      }
      sx={{
        width: "fit-content",
        backgroundColor: `button.neutral.contained.background`,
        borderRadius: 2,
        height: "auto",
        "& .MuiChip-label": {
          px,
          py,
        },
      }}
    />
  );
};

const LicensesCard = ({ product, subscription, identity }: any) => {
  const licenseQuantity = subscription?.quantity || 1;
  const { licenceSubscriptionPending } = useGetPendingOrders();

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "start",
        p: 2,
        borderRadius: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "start",
          width: 1,
          position: "relative",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", width: 1 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "start",
              width: 1,
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="bold_md" color="common.title">
                Licences utilisateurs
              </Typography>
              {subscription && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 1,
                    position: ["relative", "absolute"],
                    right: 0,
                    top: 0,
                  }}
                >
                  {/* <SubscriptionAction identity={identity} action="pause" subscription={subscription} /> */}
                  {!subscription.cancelled ? (
                    <SubscriptionAction identity={identity} action="cancel" subscription={subscription} />
                  ) : (
                    <SubscriptionAction
                      identity={identity}
                      action={subscription.paymentStatus === "Litige" ? "solveDispute" : "uncancel"}
                      subscription={subscription}
                    />
                  )}
                </Box>
              )}
              <Typography variant="regular_xs" color="common.title" sx={{ mt: [2, 3] }}>
                S'abonner à cette offre vous permet d'utiliser notre solution SMS Meeting via l'obtention de licences
                affectables à des utilisateurs.
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: ["column", "row"],
              justifyContent: "space-between",
              width: 0.8,
              mt: 1,
              gap: [1, 0],
            }}
          >
            <Box sx={{ display: "flex", flexDirection: ["row", "column"], gap: 1, width: "fit-content" }}>
              <FeatureChip text="Téléchargement connecteurs" />
              <FeatureChip text="Modèles SMS avancés" />
            </Box>
            <Box sx={{ display: "flex", flexDirection: ["row", "column"], gap: 1 }}>
              <FeatureChip text="Contacts et listes" />
              <FeatureChip text="Accès multi-utilisateurs" />
            </Box>
            <Box sx={{ display: "flex", flexDirection: ["row", "column"], gap: 1 }}>
              <FeatureChip text="SMS transactionnels" />
              <FeatureChip text="Journal d'envoi" />
            </Box>
          </Box>
          <Divider sx={{ width: 1, my: 2 }} />
          {subscription && subscription.cancelled && (
            <Info
              content={`Cet abonnement sera résilié le ${new Date(subscription.periodEnd * 1000).toLocaleDateString(
                "fr",
                {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                }
              )}. Vous ne pourrez alors plus utiliser vos services.`}
            />
          )}
          {subscription && subscription?.licensesToDelete?.length > 0 && (
            <Info
              content={`Vous passerez à ${subscription.quantity - subscription?.licensesToDelete.length} ${
                subscription.quantity - subscription?.licensesToDelete.length > 1 ? "licences" : "licence"
              } le ${new Date(subscription.periodEnd * 1000).toLocaleDateString("fr", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })}.`}
            />
          )}
          {licenceSubscriptionPending ? (
            <Info
              content={`Abonnement en cours de création, vous pourrez profiter des licences utilisateurs achetées une fois le paiement accepté.`}
            />
          ) : product ? (
            <LicensesPurchase product={product} identity={identity} subscription={subscription} />
          ) : null}
        </Box>
      </Box>
    </Card>
  );
};

const CreditsCard = ({ product, subProduct, subscription, companyCredits, identity }: any) => {
  const { creditSubscriptionPending } = useGetPendingOrders();
  const theme = useTheme();
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "start",
        gap: 2,
        p: 2,
        borderRadius: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "start",
          width: 1,
          position: "relative",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", width: 1 }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Typography variant="bold_md" color="common.title">
              Crédits SMS
            </Typography>
            <IconChip
              text={(companyCredits || "") + " Crédits restants"}
              icon={<Coins size={18} color={theme.palette.button.neutral.contained.content} />}
            />
          </Box>
          <Typography variant="regular_xs" color="common.title" mt={2}>
            Acquérir des crédits vous permets de les utiliser pour envoyer des SMS lors de vos campagnes.
          </Typography>
          <Divider sx={{ width: 1, my: 2 }} />
          <Typography variant="medium_sm" color="common.title">
            Recharge unique
          </Typography>
          {product && <SingleCreditsPurchase product={product} identity={identity} />}
          <Divider sx={{ width: 1, my: 2 }} />

          {product && (
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2, position: "relative" }}>
              <Typography variant="medium_sm" color="common.title" mb={1}>
                Recharge automatique
              </Typography>
              {subscription && subscription.cancelled && (
                <Info
                  content={`Cet abonnement sera résilié le ${new Date(subscription.periodEnd * 1000).toLocaleDateString(
                    "fr",
                    {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    }
                  )}. Vous ne recevrez plus vos crédits SMS.`}
                />
              )}
              {creditSubscriptionPending ? (
                <Info
                  content={`Abonnement en cours de création, vous recevrez vos crédits SMS une fois le paiement accepté.`}
                />
              ) : (
                <SubCreditsPurchase product={subProduct} identity={identity} subscription={subscription} />
              )}

              {subscription && (
                <Box sx={{ display: "flex", flexDirection: "row", gap: 1, position: "absolute", right: 0, top: 0 }}>
                  {/* <SubscriptionAction identity={identity} action="pause" subscription={subscription} /> */}
                  {!subscription.cancelled ? (
                    <SubscriptionAction identity={identity} action="cancel" subscription={subscription} />
                  ) : (
                    <SubscriptionAction
                      identity={identity}
                      action={subscription.paymentStatus === "Litige" ? "solveDispute" : "uncancel"}
                      subscription={subscription}
                    />
                  )}
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Card>
  );
};

const ApiCard = ({ product, subscription, identity }: any) => {
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "start",
        p: 2,
        borderRadius: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "start",
          width: 1,
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", maxWidth: 0.7, gap: 1 }}>
          <Typography variant="bold_md" color="common.title">
            API SMS Meeting
          </Typography>
          <Typography variant="regular_xs" color="common.title">
            S'abonner a l'offre API SMS Meeting vous donne le droit d'utiliser l'API SMS Meetings pour un nombre de call
            limité.
          </Typography>
        </Box>
      </Box>
      <Divider sx={{ width: 1, my: 2 }} />
      {product && <ApiPurchase product={product} identity={identity} subscription={null} />}
    </Card>
  );
};

const TogglePeriod = ({ disabled = false }) => {
  const { setValue } = useFormContext();
  const billingPeriod = useWatch({ name: "billingPeriod" });

  return (
    <Box
      sx={{
        borderRadius: 2,
        px: 1,
        py: 0.5,
        display: "flex",
        justifyContent: "space-around",
        gap: 1,
        bgcolor: `switch.${disabled ? "disabledBackground" : "background"}`,
      }}
    >
      <Typography
        variant="medium_xs"
        sx={{
          borderRadius: 2,
          px: 1,
          py: 0.5,
          whiteSpace: "nowrap",
          cursor: disabled ? "default" : "pointer",
          color:
            billingPeriod === "Mensuelle"
              ? `button.${disabled ? "neutral" : "primary"}.contained.content`
              : `button.${disabled ? "neutral" : "primary"}.outlined.content`,
          bgcolor:
            billingPeriod === "Mensuelle" ? `button.${disabled ? "neutral" : "primary"}.contained.background` : "unset",
        }}
        onClick={() => {
          if (!disabled) {
            setValue("billingPeriod", "Mensuelle");
          }
        }}
      >
        Par mois
      </Typography>
      <Typography
        variant="medium_xs"
        sx={{
          borderRadius: 2,
          px: 1,
          py: 0.5,
          whiteSpace: "nowrap",
          cursor: disabled ? "default" : "pointer",
          color:
            billingPeriod === "Annuelle"
              ? `button.${disabled ? "neutral" : "primary"}.contained.content`
              : `button.${disabled ? "neutral" : "primary"}.outlined.content`,
          bgcolor:
            billingPeriod === "Annuelle" ? `button.${disabled ? "neutral" : "primary"}.contained.background` : "unset",
        }}
        onClick={() => {
          if (!disabled) {
            setValue("billingPeriod", "Annuelle");
          }
        }}
      >
        Par an
      </Typography>
    </Box>
  );
};

const SwitchBillingPeriod = ({ subscription, product, otherBillingPeriodTarif, identity, discount }: any) => {
  const theme = useTheme();
  const [openBillingPeriodModal, setOpenBillingPeriodModal] = useState(false);
  const [totals, setTotals] = useState({} as any);
  const notify = useNotify();
  const isMobile = !!useMediaQuery((theme: any) => theme.breakpoints.only("xs"));

  const handleConfirm = async (confirm: boolean) => {
    if (confirm) {
      try {
        await api.post("/utils/modifySubscription", {
          subscriptionId: subscription.id,
          tarifId: otherBillingPeriodTarif.id,
        });
        notify("Vos changements ont bien été pris en compte.", {
          type: "success",
          messageArgs: { smart_count: 1 },
        });
      } catch (err) {
        notify("Erreur lors de la prise en compte des changements.", {
          type: "error",
          messageArgs: { smart_count: 1 },
        });
      }
    }
    setOpenBillingPeriodModal(false);
  };

  useEffect(() => {
    if (subscription && otherBillingPeriodTarif) {
      setTotals(
        priceCalculator(
          [
            {
              product,
              tarifId: otherBillingPeriodTarif.id,
              quantity:
                subscription.quantity - (subscription?.licensesToDelete ? subscription?.licensesToDelete.length : 0),
            },
          ],
          discount,
          identity?.companyCountry
        )
      );
    }
  }, [
    discount,
    identity?.companyCountry,
    otherBillingPeriodTarif,
    otherBillingPeriodTarif?.id,
    product,
    subscription,
    subscription?.licensesToDelete,
    subscription?.quantity,
    subscription?.type,
  ]);

  return !subscription ? (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 2 }}>
      <TogglePeriod />
      {!subscription && (
        <Typography variant={isMobile ? "medium_2xs" : "medium_xs"} color="common.text">
          2 mois offerts en choisissant l'abonnement annuel.
        </Typography>
      )}
    </Box>
  ) : (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: 1,
        mb: 1,
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <IconChip
          text={`Formule ${subscription.billingPeriod.toLowerCase()}`}
          icon={<CreditCard size={18} color={theme.palette.button.neutral.contained.content} />}
          typographyVariant="medium_xs"
        />
        {subscription.billingPeriod === "Mensuelle" && (
          <Typography variant={isMobile ? "medium_2xs" : "medium_xs"} color="common.text">
            2 mois offerts en choisissant l'abonnement annuel
          </Typography>
        )}
      </Box>
      <Button
        sx={{
          color: "button.primary.outlined.content",
          ":hover": { bgcolor: alpha(theme.palette.button.primary.outlined.background, 0.7) },
          minWidth: ["unset"],
        }}
        onClick={() => {
          setOpenBillingPeriodModal(true);
        }}
        disabled={subscription?.cancelled}
      >
        <Typography variant="medium_sm" sx={{ display: ["none", "inline"] }}>
          Changer de formule &nbsp;
        </Typography>
        <PenLine size={14} />
      </Button>
      {Object.keys(totals).length > 0 && (
        <Modal
          title="Changement de formule"
          content={
            !totals
              ? ""
              : `Vous optez pour passer d'une formule ${subscription.billingPeriod.toLowerCase()} à ${
                  subscription.billingPeriod === "Mensuelle" ? "annuelle" : "mensuelle"
                }. Ce passage se fera à échéance de la période de facturation actuelle soit le : ${new Date(
                  subscription.periodEnd * 1000
                ).toLocaleDateString("fr", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}. Vous serez alors prélevés de ${totals?.totalHt.toFixed(2)}
        € HT par ${subscription.billingPeriod === "Mensuelle" ? "an" : "mois"}`
          }
          open={openBillingPeriodModal}
          handleConfirm={handleConfirm}
        />
      )}
    </Box>
  );
};

const LicensesPurchase = ({ product, identity, subscription }) => {
  const [cart, setCart] = useLocalStorage("cart", [] as any[]);
  const [checkoutStep, setCheckoutStep] = useLocalStorage("checkoutStep", 0);
  const discount = useGetAutoDiscount(
    [
      {
        product,
        //tarif and quantity not important here
        tarifId: product.tarifs[0].tarifId,
        quantity: 1,
      },
    ],
    subscription
  );
  const methods = useForm({
    defaultValues: {
      billingPeriod: "Mensuelle",
      quantity: 1,
    },
  });
  const navigate = useNavigate();
  const notify = useNotify();
  const theme = useTheme();
  const [tarif, setTarif] = useState({} as any);
  const [modifyOffer, setModifyOffer] = useState(false);
  const [quantity, billingPeriod, licensesToDelete, freeLicensesNumber] = useWatch({
    control: methods.control,
    name: ["quantity", "billingPeriod", "licensesToDelete", "freeLicensesNumber"],
  });
  const otherBillingPeriod = billingPeriod === "Mensuelle" ? "Annuelle" : "Mensuelle";
  const otherBillingPeriodTarif = !subscription
    ? null
    : product.tarifs.find((t: any) => t.billingPeriod === otherBillingPeriod);
  const [openLowerQuantityModal, setOpenLowerQuantityModal] = useState(false);

  const handleConfirm = async (confirm: boolean) => {
    if (confirm) {
      try {
        await api.post("/utils/removeLicensesOnSubscription", {
          companyId: subscription.company_id,
          subscriptionId: subscription.id,
          licensesToDelete: licensesToDelete || [],
          licensesToDeleteNumber: subscription.quantity - quantity,
        });
        notify("Baisse quantité utilisateurs programmée avec succès.", {
          type: "success",
          messageArgs: { smart_count: 1 },
          autoHideDuration: 4000,
        });
        setModifyOffer(false);
      } catch (err) {
        notify("Erreur lors du changement de la quantité de licences utilisateurs", {
          type: "error",
          messageArgs: { smart_count: 1 },
        });
      }
    }
    setOpenLowerQuantityModal(false);
  };

  useEffect(() => {
    if (subscription) {
      methods.setValue("billingPeriod", subscription.billingPeriod);
      methods.setValue("quantity", subscription.quantity);
    }
  }, [subscription, methods]);

  useEffect(() => {
    const selectedTarif = product.tarifs.find((tarif: any) => tarif.billingPeriod === billingPeriod);
    setTarif(selectedTarif);
  }, [product.tarifs, billingPeriod]);

  const onSubmit = async ({ quantity, quantityToAdd, prorationRate }: any) => {
    if (!subscription) {
      setCart([
        {
          product,
          tarifId: tarif.id,
          quantity,
        },
      ]);
      setCheckoutStep(0);
      navigate("/checkout");
    } else {
      if (quantityToAdd && quantity > subscription.quantity) {
        setCart([
          {
            product,
            tarifId: tarif.id,
            quantity: quantityToAdd,
            prorationRate,
            subscriptionId: subscription.id,
          },
        ]);
        setCheckoutStep(0);
        navigate("/checkout");
      } else if (quantity < subscription.quantity) {
        setOpenLowerQuantityModal(true);
      }
    }
  };
  return (
    <FormProvider {...methods}>
      <Box
        sx={{ display: "flex", flexDirection: "column", width: 1 }}
        component="form"
        autoComplete="off"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <SwitchBillingPeriod
          subscription={subscription}
          product={product}
          otherBillingPeriodTarif={otherBillingPeriodTarif}
          identity={identity}
          discount={discount}
        />
        {!tarif?.price ? null : (
          <Box
            sx={{
              display: "flex",
              flexDirection: ["column", "row"],
              alignItems: "start",
              justifyContent: "space-between",
              position: "relative",
            }}
          >
            {subscription && !modifyOffer ? (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: 1,
                  }}
                >
                  {subscription?.quantity && (
                    <Typography
                      variant="regular_sm"
                      color="common.title"
                      sx={{ display: "flex", alignItems: "center", gap: 1, mt: 1 }}
                    >
                      {subscription?.quantity || ""} utilisateur{subscription.quantity > 1 ? "s" : ""}
                    </Typography>
                  )}
                  <Button
                    sx={{
                      color: "button.primary.outlined.content",
                      ":hover": { bgcolor: alpha(theme.palette.button.primary.outlined.background, 0.7) },
                      minWidth: ["unset"],
                    }}
                    onClick={() => {
                      methods.setValue("quantity", subscription.quantity);
                      setModifyOffer(true);
                    }}
                    disabled={subscription?.cancelled}
                  >
                    <Typography variant="medium_sm" sx={{ display: ["none", "inline"] }}>
                      Modifier quantité &nbsp;
                    </Typography>
                    <PenLine size={14} />
                  </Button>
                </Box>
              </>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "start",
                  flexDirection: "column",
                  width: 1,
                  "& .MuiFormControl-root": {
                    width: ["auto", 150],
                    mt: 0,
                    "& .MuiInputBase-input": { textAlign: ["center !important", "left"] },
                  },
                  "& .Mui-disabled": {
                    color: theme.palette.common.title + " !important",
                    "-webkit-text-fill-color": theme.palette.common.title + " !important",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: 1,
                  }}
                >
                  <LicenseQuantityInput
                    subscription={subscription}
                    tarif={tarif}
                    product={product}
                    discount={discount}
                    identity={identity}
                  />
                </Box>
              </Box>
            )}
            {subscription ? (
              !modifyOffer ? null : (
                <Box
                  sx={{
                    display: "flex",
                    gap: 2,
                    width: [1, "auto"],
                    position: ["relative", "absolute"],
                    top: 4,
                    right: 0,
                  }}
                >
                  <Button
                    variant="contained"
                    color="neutral"
                    onClick={() => setModifyOffer(false)}
                    sx={{ width: [0.5, "auto"] }}
                  >
                    <Typography variant="medium_sm">Annuler</Typography>
                  </Button>
                  <LoadingButton
                    loading={methods.formState.isSubmitting}
                    variant="contained"
                    type="submit"
                    color="secondary"
                    disabled={
                      !quantity.toString().match(/^-?\d+$/) ||
                      (quantity < subscription.quantity &&
                        freeLicensesNumber &&
                        subscription.quantity - quantity > freeLicensesNumber &&
                        licensesToDelete?.length !== subscription.quantity - quantity - freeLicensesNumber)
                    }
                    sx={{ width: [0.5, "auto"], minWidth: "unset" }}
                  >
                    <Typography variant="medium_sm">Confirmer</Typography>
                  </LoadingButton>
                  <Modal
                    title="Baisse du nombre d'utilisateurs"
                    content={
                      <Typography variant="medium_sm" color="common.title">
                        En supprimant des licences utilisateurs de votre abonnement, ces dernières ne seront plus
                        utilisables à la fin de la période de facturation en cours.
                        <br /> <br /> Vous serez ensuite facturé{" "}
                        {priceCalculator(
                          [{ product, tarifId: tarif.id, quantity }],
                          discount,
                          identity?.companyCountry
                        ).totalHt.toFixed(2)}
                        € HT{subscription?.billingPeriod === "Mensuelle" ? "/mois" : "/an"} pour{" "}
                        {quantity > 1
                          ? `les ${quantity} licences utilisateurs restantes`
                          : "votre unique licence utilisateur restante"}
                        .
                      </Typography>
                    }
                    open={openLowerQuantityModal}
                    handleConfirm={handleConfirm}
                  />
                </Box>
              )
            ) : (
              <LoadingButton
                loading={methods.formState.isSubmitting}
                type="submit"
                variant="contained"
                color="secondary"
                sx={{ position: ["relative", "absolute"], width: [1, "auto"], top: 4, right: 0, minWidth: "unset" }}
              >
                <Typography variant="medium_sm">
                  S'abonner •{" "}
                  {priceCalculator(
                    [{ product, tarifId: tarif.id, quantity }],
                    discount,
                    identity?.companyCountry
                  ).totalHt.toFixed(2)}
                  €{billingPeriod === "Mensuelle" ? "/mois" : "/an"}
                </Typography>
              </LoadingButton>
            )}
          </Box>
        )}
      </Box>
      {subscription && (
        <>
          <Divider sx={{ width: 1, my: 2 }} />
          <SwitchablePaymentMethod subscription={subscription} />
        </>
      )}
    </FormProvider>
  );
};

const SingleCreditsPurchase = ({ product, identity }) => {
  const [cart, setCart] = useLocalStorage("cart", [] as any[]);
  const [checkoutStep, setCheckoutStep] = useLocalStorage("checkoutStep", 0);
  const methods = useForm();
  const theme = useTheme();
  const navigate = useNavigate();
  const discount = useGetAutoDiscount([
    {
      product,
      //tarif and quantity not important here
      tarifId: product.tarifs[0].tarifId,
      quantity: 1,
    },
  ]);
  const [tarif, setTarif] = useState({} as any);
  const tarifs = product.tarifs.sort((a: any, b: any) => a.packageSize - b.packageSize);
  const [quantity] = useWatch({ control: methods.control, name: ["quantity"] });

  useEffect(() => {
    const selectedTarif = !quantity ? tarifs[0] : tarifs.find((tarif: any) => tarif.packageSize === quantity);
    setTarif(selectedTarif);
  }, [quantity, tarifs]);

  const onSubmit = async ({ quantity }: any) => {
    setCart([
      {
        product,
        tarifId: tarif.id,
        quantity: 1,
      },
    ]);
    setCheckoutStep(0);
    navigate("/checkout");
  };
  return !tarif?.packageSize ? null : (
    <FormProvider {...methods}>
      <Box
        sx={{
          display: "flex",
          flexDirection: ["column", "row"],
          alignItems: "center",
          justifyContent: "space-between",
          width: 1,
        }}
        component="form"
        autoComplete="off"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: ["column", "row"],
            alignItems: ["auto", "center"],
            gap: [0, 1],
            mt: [1, 0],
            "& .MuiFormControl-root": { width: [1, 300] },
            width: 1,
          }}
        >
          <Typography variant="regular_sm" color="common.title">
            Pack
          </Typography>
          <FormInput
            type="select"
            name="quantity"
            defaultValue={tarif?.packageSize}
            values={tarifs.map((tarif: any) => ({
              id: tarif.packageSize,
              label: `${tarif.packageSize} crédits •
            ${(
              priceCalculator([{ product, tarifId: tarif.id, quantity: 1 }], discount, identity?.companyCountry)
                .totalHt / tarif.packageSize
            ).toFixed(2)}€ HT /unité`,
            }))}
            required
            disabled={false}
            filledSecondary
            hideSelectInsideLabel
          />
        </Box>
        <LoadingButton
          loading={methods.formState.isSubmitting}
          type="submit"
          variant="contained"
          color="secondary"
          sx={{ width: [1, "auto"], mt: [1, 0], minWidth: "unset" }}
        >
          <Typography variant="medium_sm">
            Payer •{" "}
            {priceCalculator(
              [{ product, tarifId: tarif.id, quantity: 1 }],
              discount,
              identity?.companyCountry
            ).totalHt.toFixed(2)}
            €
          </Typography>
        </LoadingButton>
      </Box>
    </FormProvider>
  );
};

const SubCreditsPurchase = ({ product, identity, subscription }) => {
  const methods = useForm({
    defaultValues: {
      billingPeriod: "Mensuelle",
    },
  });
  const [checkoutStep, setCheckoutStep] = useLocalStorage("checkoutStep", 0);
  const navigate = useNavigate();
  const [cart, setCart] = useLocalStorage("cart", [] as any[]);
  const discount = useGetAutoDiscount(
    [
      {
        product,
        //tarif and quantity not important here
        tarifId: product.tarifs[0].tarifId,
        quantity: 1,
      },
    ],
    subscription
  );
  const theme = useTheme();
  const [tarif, setTarif] = useState({} as any);
  const [modifyOffer, setModifyOffer] = useState(false);
  const notify = useNotify();
  const [openModifySubscription, setOpenModifySubscription] = useState(false);
  const tarifs = product.tarifs.sort((a: any, b: any) => a.packageSize - b.packageSize);
  const [quantity, billingPeriod] = useWatch({ control: methods.control, name: ["quantity", "billingPeriod"] });

  useEffect(() => {
    if (subscription) {
      methods.setValue("billingPeriod", subscription.billingPeriod);
    }
  }, [subscription, methods]);

  useEffect(() => {
    let selectedTarif = !quantity
      ? tarifs[0]
      : tarifs.find((tarif: any) => tarif.packageSize === quantity && tarif.billingPeriod === billingPeriod);
    if (!selectedTarif) {
      selectedTarif = tarifs[0];
    }
    setTarif(selectedTarif);
  }, [quantity, tarifs, billingPeriod]);

  const onSubmit = async ({ quantity }: any) => {
    if (!modifyOffer) {
      setCart([
        {
          product: product,
          tarifId: tarif.id,
          quantity: 1,
        },
      ]);
      setCheckoutStep(0);
      navigate("/checkout");
    } else {
      setOpenModifySubscription(true);
    }
  };

  const handleConfirm = async (confirm: boolean) => {
    if (confirm) {
      try {
        await api.post("/utils/modifySubscription", {
          subscriptionId: subscription.id,
          tarifId: tarif.id,
        });
        notify("Vos changements ont bien été pris en compte.", {
          type: "success",
          messageArgs: { smart_count: 1 },
        });
      } catch (err) {
        notify("Erreur lors de la prise en compte des changements.", {
          type: "error",
          messageArgs: { smart_count: 1 },
        });
      }
    }
    setOpenModifySubscription(false);
  };

  useEffect(() => {
    const selectedTarif = product.tarifs.find((tarif: any) => tarif.billingPeriod === billingPeriod);
    setTarif(selectedTarif);
  }, [product.tarifs, billingPeriod]);

  return (
    <FormProvider {...methods}>
      <Box
        sx={{ display: "flex", flexDirection: "column", width: 1 }}
        component="form"
        autoComplete="off"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        {subscription && !modifyOffer ? (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: 1,
              }}
            >
              {subscription?.quantity && (
                <Typography
                  variant="medium_xs"
                  color="common.title"
                  sx={{ display: "flex", alignItems: "center", gap: 1, mt: 1 }}
                >
                  Abonnement actuel de {subscription?.quantity || ""} crédits SMS par{" "}
                  {subscription?.billingPeriod === "Mensuelle" ? "mois" : "an"}
                </Typography>
              )}
              <Box sx={{ display: "flex", flexDirection: "row", gap: [0, 2] }}>
                <Button
                  sx={{
                    color: "button.primary.outlined.content",
                    ":hover": { bgcolor: alpha(theme.palette.button.primary.outlined.background, 0.7) },
                  }}
                  onClick={() => setModifyOffer(true)}
                  disabled={subscription?.cancelled}
                >
                  Modifier l'abonnement &nbsp;
                  <PenLine size={14} />
                </Button>
              </Box>
            </Box>
          </>
        ) : (
          <>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 1 }}>
              <TogglePeriod />
            </Box>
            {!tarif?.packageSize ? null : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: ["column", "row"],
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: ["column", "row"],
                    alignItems: ["auto", "center"],
                    gap: [0, 1],
                    mt: [1, 0],
                    "& .MuiFormControl-root": { width: [1, 300], flex: [1, "unset"] },
                    width: 1,
                  }}
                >
                  <Typography variant="regular_sm" color="common.title">
                    Abonnement
                  </Typography>
                  <FormInput
                    type="select"
                    name="quantity"
                    defaultValue={tarif?.packageSize}
                    values={tarifs
                      .filter((tarif) => tarif.billingPeriod === billingPeriod)
                      .map((tarif: any) => ({
                        id: tarif.packageSize,
                        label: `${(tarif.packageSize + " crédits").padEnd(12, " ")} •
                    ${(
                      priceCalculator([{ product, tarifId: tarif.id, quantity: 1 }], discount, identity?.companyCountry)
                        .totalHt / tarif.packageSize
                    ).toFixed(2)}€ HT /unité`,
                      }))}
                    required
                    disabled={false}
                    filledSecondary
                    hideSelectInsideLabel
                  />
                </Box>
                {!subscription ? (
                  <LoadingButton
                    loading={methods.formState.isSubmitting}
                    type="submit"
                    variant="contained"
                    color="secondary"
                    sx={{ width: [1, "auto"], mt: [1, 0], minWidth: "unset" }}
                  >
                    <Typography variant="medium_sm">
                      S'abonner •{" "}
                      {priceCalculator(
                        [{ product, tarifId: tarif.id, quantity: 1 }],
                        discount,
                        identity?.companyCountry
                      ).totalHt.toFixed(2)}
                      €{billingPeriod === "Mensuelle" ? "/mois" : "/an"}
                    </Typography>
                  </LoadingButton>
                ) : (
                  <Box>
                    <Button variant="contained" color="neutral" onClick={() => setModifyOffer(false)}>
                      <Typography variant="medium_sm">Annuler</Typography>
                    </Button>
                    <LoadingButton
                      loading={methods.formState.isSubmitting}
                      type="submit"
                      variant="contained"
                      color="secondary"
                      sx={{ ml: 2 }}
                    >
                      <Typography variant="medium_sm">Confirmer</Typography>
                    </LoadingButton>
                    <Modal
                      title="Modification de votre abonnement"
                      content={
                        <Typography variant="medium_sm" color="common.title">
                          Les changements relatifs à votre abonnement seront pris en compte le{" "}
                          {new Date(subscription.periodEnd * 1000).toLocaleDateString("fr", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          })}
                          .
                          <br /> <br /> Vous serez ensuite facturé{" "}
                          {priceCalculator(
                            [{ product, tarifId: tarif.id, quantity: 1 }],
                            discount,
                            identity?.companyCountry
                          ).totalHt.toFixed(2)}
                          € HT{billingPeriod === "Mensuelle" ? "/mois" : "/an"} pour {quantity} crédits SMS crédités
                          chaque {billingPeriod === "Mensuelle" ? "mois" : "année"}.
                        </Typography>
                      }
                      open={openModifySubscription}
                      handleConfirm={handleConfirm}
                    />
                  </Box>
                )}
              </Box>
            )}
          </>
        )}
      </Box>
      {subscription && (
        <>
          <Divider sx={{ width: 1, my: 2 }} />
          <SwitchablePaymentMethod subscription={subscription} />
        </>
      )}
    </FormProvider>
  );
};

const ApiPurchase = ({ product, identity, subscription }) => {
  const methods = useForm({
    defaultValues: {
      billingPeriod: "Mensuelle",
    },
  });
  const discount = useGetAutoDiscount(
    [
      {
        product,
        //tarif and quantity not important here
        tarifId: product.tarifs[0].tarifId,
        quantity: 1,
      },
    ],
    subscription
  );
  const theme = useTheme();
  const [tarif, setTarif] = useState({} as any);
  const tarifs = product.tarifs.sort((a: any, b: any) => a.packageSize - b.packageSize);
  const [quantity, billingPeriod] = useWatch({ control: methods.control, name: ["quantity", "billingPeriod"] });

  useEffect(() => {
    //handle billingPeriod change
    const selectedTarif = !quantity
      ? tarifs[0]
      : tarifs.find((tarif: any) => tarif.packageSize === quantity && tarif.billingPeriod === billingPeriod);
    setTarif(selectedTarif);
  }, [quantity, tarifs, billingPeriod]);

  const onSubmit = async ({ quantity }: any) => {
    try {
    } catch (err) {
      //notify something
    }
  };
  return (
    <FormProvider {...methods}>
      <Box
        sx={{ display: "flex", flexDirection: "column", width: 1 }}
        component="form"
        autoComplete="off"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 1 }}>
          <TogglePeriod />
          <Typography variant="medium_xs" color="common.text">
            2 mois offerts en choisissant l'abonnement annuel.
          </Typography>
        </Box>
        {!tarif?.packageSize ? null : (
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 1 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                "& .MuiFormControl-root": {
                  width: ["auto", 150],
                  "& .MuiInputBase-input": { textAlign: ["center !important", "left"] },
                },
              }}
            >
              <Typography variant="regular_sm" color="common.title">
                Abonnement
              </Typography>
              <FormInput
                type="select"
                name="quantity"
                defaultValue={tarif?.packageSize}
                values={tarifs
                  .filter((tarif) => tarif.billingPeriod === billingPeriod)
                  .map((tarif: any) => ({ id: tarif.packageSize, label: tarif.packageSize + " Call API" }))}
                required
                disabled={false}
                filledSecondary
                hideSelectInsideLabel
              />
              {tarif?.price && (
                <Typography variant="regular_sm" color="common.title">
                  {priceCalculator(
                    [{ product, tarifId: tarif.id, quantity: 1 }],
                    discount,
                    identity?.companyCountry
                  ).totalHt.toFixed(2)}
                  € HT /{tarif?.billingPeriod === "Mensuelle" ? "mois" : "an"}
                </Typography>
              )}
            </Box>
            <LoadingButton
              loading={methods.formState.isSubmitting}
              type="submit"
              variant="contained"
              color="secondary"
              sx={{ width: [1, "auto"], minWidth: "unset" }}
            >
              <Typography variant="medium_sm">
                S'abonner •{" "}
                {priceCalculator(
                  [{ product, tarifId: tarif.id, quantity: 1 }],
                  discount,
                  identity?.companyCountry
                ).totalHt.toFixed(2)}
                €
              </Typography>
            </LoadingButton>
          </Box>
        )}
      </Box>
      {subscription && (
        <>
          <Divider sx={{ width: 1, my: 2 }} />
          <SwitchablePaymentMethod subscription={subscription} />
        </>
      )}
    </FormProvider>
  );
};

const MySubscriptions = ({ identity }) => {
  const noBills = require("../../assets/facture.svg").default as string;
  const [companyCredits, setCompanyCredits] = useState(null);
  const theme = useTheme();
  const [helpMenu, setHelpMenu] = useState({} as any);
  const { creditSubscription, licenceSubscription } = useGetSubscriptions();
  let { data: productsData, isLoading: isProductsLoading } = useGetList("products", {
    pagination: { page: 1, perPage: 10 },
  });
  productsData = productsData
    ?.map((product: any) => {
      const availableTarifs = product.tarifs.filter((tarif: any) => {
        return (
          tarif.countries.includes(identity?.companyCountry) &&
          tarif.companyStatus.includes(identity?.status) &&
          tarif.companyTypes.includes(identity?.type) &&
          (!tarif?.companyIds?.length || tarif.companyIds.includes(identity?.company)) &&
          !(tarif?.blackListedCompanyIds || []).includes(identity?.company)
        );
      });
      return availableTarifs.length > 0
        ? {
            ...product,
            tarifs: availableTarifs,
          }
        : null;
    })
    .filter((product: any) => !!product);
  const licenceSubProduct = productsData?.find((product: any) => product.type === "Licence");
  const creditSubProduct = productsData?.find((product: any) => product.type === "Crédits SMS" && product.isRecurring);
  const creditProduct = productsData?.find((product: any) => product.type === "Crédits SMS" && !product.isRecurring);
  // const apiSubProduct = productsData?.find((product: any) => product.type === "API");

  useEffect(() => {
    if (!companyCredits) {
      const asyncCall = async () => {
        const getCreditsRes = await api.get("/utils/getCredits/" + identity?.company);
        setCompanyCredits(getCreditsRes.data.credits);
      };
      asyncCall();
    }
  }, [companyCredits, identity?.company]);

  useEffect(() => {
    const asyncCall = async () => {
      const q = query(collection(fbFirestore, "admin"));
      let adminSnap = await getDocs(q);
      let helpMenuData = adminSnap.docs[0].data().helpMenu;
      if (helpMenuData?.chatActivated) {
        window.BrevoConversationsSetup = {
          zIndex: 0,
          colors: {
            buttonText: theme.palette.primary /* chat button text/icon color */,
            buttonBg: theme.palette.primary /* chat button background color */,
            visitorBubbleBg: alpha(theme.palette.primary.main, 0.1) /* visitor’s message bubble color */,
            agentBubbleBg: alpha(theme.palette.primary.main, 0.1) /* agent’s message bubble color */,
          },
        };
        loadChat();
      }
      setHelpMenu(helpMenuData);
    };

    if (identity) asyncCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identity]);

  return (
    <Box sx={{ width: 1, display: "flex", gap: 2, flexDirection: ["column", "row"] }}>
      <Box sx={{ flexGrow: 1, maxWidth: 1000, display: "flex", flexDirection: "column", gap: 2 }}>
        <LicensesCard product={licenceSubProduct} subscription={licenceSubscription} identity={identity} />
        <CreditsCard
          product={creditProduct}
          subProduct={creditSubProduct}
          subscription={creditSubscription}
          companyCredits={companyCredits}
          identity={identity}
        />
        {/* //todo : implement API products */}
        {/* <ApiCard product={apiSubProduct} subscription={apiSubscription} identity={identity} /> */}
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          width: [1, "18rem"],
        }}
      >
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
            gap: 1,
            p: 2,
            borderRadius: 2,
            width: 1,
            height: "fit-content",
          }}
        >
          <Typography variant="bold_md" color="common.title" mb={2}>
            Factures à venir
          </Typography>
          {licenceSubscription && !licenceSubscription.cancelled && (
            <>
              <Divider sx={{ width: 1, mb: 1 }} />
              <SubscriptionDisplay subscription={licenceSubscription} />
            </>
          )}
          {creditSubscription && !creditSubscription.cancelled && (
            <>
              <Divider sx={{ width: 1, mb: 1 }} />
              <SubscriptionDisplay subscription={creditSubscription} />
            </>
          )}
          {!(licenceSubscription && !licenceSubscription.cancelled) &&
            !(creditSubscription && !creditSubscription.cancelled) && (
              <Box
                sx={{
                  width: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <img src={noBills} alt="no bills" style={{ width: "50%", height: "auto", margin: 0 }} />
                <Typography variant="medium_sm" color="common.title" textAlign="center">
                  Aucune facture en cours pour le moment
                </Typography>
              </Box>
            )}
        </Card>
        <Card
          sx={{
            gap: 1,
            p: 2,
            borderRadius: 2,
            width: 1,
            height: "fit-content",
          }}
          onClick={() => {
            if (helpMenu?.chatActivated) {
              window.BrevoConversations.setZIndex(9999);
              window.BrevoConversations.openChat(true);
              window.BrevoConversations.updateIntegrationData({
                email: identity?.email,
                firstName: identity?.firstName,
                lastName: identity?.lastName,
                ORGANISATION: identity?.companyName,
                status: identity?.status,
              });
              if (helpMenu?.scenarioId) {
                window.BrevoConversations.startBotScenario(helpMenu?.scenarioId);
              }
            }
          }}
        >
          <Typography variant="medium_xs" color="common.text" textAlign="center">
            Besoin d'aide pour trouver l'offre qui correspond à vos besoins ?
          </Typography>
          <Button
            sx={{
              color: "button.primary.outlined.content",
              borderRadius: 2,
              mt: 1,
              width: [1, "auto"],
              ":hover": { bgcolor: alpha(theme.palette.button.primary.outlined.background, 0.7) },
            }}
            variant="standard"
          >
            <Typography variant="medium_sm">Contactez notre service client</Typography>
          </Button>
        </Card>
      </Box>
    </Box>
  );
};

export default MySubscriptions;
