import * as React from "react";
import { Box, Breadcrumbs, Divider, Typography, useMediaQuery, useTheme } from "@mui/material";
import GoBackButton from "./GoBackButton";
import { useRecordContext } from "ra-core";
import { HomeIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";

const BreadcrumbElement = ({ url = "", type = "", key, children }: any) => {
  const navigate = useNavigate();
  return (
    <Typography
      key={key}
      color="common.text"
      variant="regular_sm"
      sx={url ? { ":hover": { textDecoration: "underline", cursor: "pointer" } } : {}}
      onClick={() => {
        if (url) {
          navigate(url);
        }
      }}
    >
      {children}
    </Typography>
  );
};

export default function PageTitle({
  text,
  type,
  divider = false,
  resource = "",
  breadcrumbListLabel = "",
  breadcrumbListUrl = "",
  breadcrumbCreateLabel = "",
  createButton = null,
}: {
  text: any;
  type: string;
  backButton?: boolean;
  backUrl?: string;
  divider?: boolean;
  resource?: string;
  breadcrumbListLabel?: string;
  breadcrumbListUrl?: string;
  breadcrumbCreateLabel?: string;
  createButton?: any;
}) {
  const record = useRecordContext();
  const theme = useTheme();
  const [title, setTitle] = React.useState("");
  const isMobile = !!useMediaQuery((theme: any) => theme.breakpoints.only("xs"));
  React.useEffect(() => {
    if (!title || text !== title) {
      if (typeof text === "string") {
        setTitle(text);
      }
      if (record && typeof text === "function") {
        setTitle(text(record));
      }
    }
  }, [record, text, title]);

  const breadcrumbs = [
    <BreadcrumbElement
      key="home"
      url="/"
      sx={{
        "& .MuiBreadcrumbs-li": {
          color: "blue !important",
          "& .MuiTypography-root": { display: "flex", flexDirection: "center", alignItems: "center", height: 1 },
        },
      }}
    >
      <HomeIcon size={16} />
    </BreadcrumbElement>,
    <BreadcrumbElement key="list" url={breadcrumbListUrl || (type !== "list" ? `/${resource}` : "")}>
      {breadcrumbListLabel}
    </BreadcrumbElement>,
  ];
  if (type === "edit") {
    breadcrumbs.push(<BreadcrumbElement key="edit">{title}</BreadcrumbElement>);
  }
  if (type === "create") {
    breadcrumbs.push(<BreadcrumbElement key="create">{breadcrumbCreateLabel}</BreadcrumbElement>);
  }

  return window.location.pathname.includes("companies") && resource !== "companies" ? null : (
    <Box sx={{ width: 1, pl: 1, mb: 2 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <Typography variant={isMobile ? "bold_md" : "bold_2xl"} color="common.title">
            {title}&nbsp;
          </Typography>
        </Box>
        {createButton}
      </Box>
      <Breadcrumbs
        separator="-"
        aria-label="breadcrumb"
        sx={{
          color: "common.text",
          "& .MuiBreadcrumbs-li": {
            "& .MuiTypography-root": { display: "flex", flexDirection: "center", alignItems: "center", height: 1 },
          },
        }}
      >
        {breadcrumbs}
      </Breadcrumbs>
      {divider && <Divider sx={{ mx: 2, mt: -1, mb: 2 }} />}
    </Box>
  );
}
