import React from "react";
import { TopToolbar } from "react-admin";
import PageTitle from "../navigation/PageTitle";
import { FilterButton } from "./FilterButton";

export const ListActions = ({ ...props }) => {
  return (
    <>
      {/* {pageTitle && <PageTitle text={pageTitle} backButton={false} />} */}
      <TopToolbar>
        {props.filters && <FilterButton filters={props.filters} disableSaveQuery />}
        {props.children}
      </TopToolbar>
    </>
  );
};
