import React, { useEffect, useRef, useState } from "react";
import Chart from "react-apexcharts";
import { Box, useMediaQuery, useTheme } from "@mui/material";

const monthToLabel: any = {
  "01": "Jan",
  "02": "Fev",
  "03": "Mar",
  "04": "Avr",
  "05": "Mai",
  "06": "Juin",
  "07": "Juil",
  "08": "Aout",
  "09": "Sep",
  "10": "Oct",
  "11": "Nov",
  "12": "Dec",
};

const Stats = ({ datesTotal, timeUnit, label, showLegend = true, randomColors = undefined }: any) => {
  const isMobile = !!useMediaQuery((theme: any) => theme.breakpoints.only("xs"));
  const theme = useTheme();
  const [series, setSeries] = useState<any>(null);
  const [options, setOptions] = useState<any>({
    chart: {
      id: "stats",
      toolbar: { show: false },
      zoom: { enabled: false },
    },
  });

  useEffect(() => {
    //if data not displayed by crmGroup then length > 10
    if (datesTotal) {
      let xAxis: any[];
      let series: any[];
      if (Object.keys(datesTotal)?.length && Object.keys(datesTotal)[0].length <= 10) {
        xAxis = Object.keys(datesTotal).map((date: any) => {
          const year = date.split("-")[0];
          const month = date.split("-")[1];
          if (timeUnit === "day") {
            const day = parseInt(date.split("-")[2]);
            return day + " " + monthToLabel[month];
          }
          return monthToLabel[month] + " " + year.slice(2, 4);
        });
        series = [
          {
            name: label,
            data: Object.values<any>(datesTotal),
          },
        ];
      } else {
        xAxis = Object.keys(Object.values<any>(datesTotal)[0]).map((date: any) => {
          const year = date.split("-")[0];
          const month = date.split("-")[1];
          if (timeUnit === "day") {
            const day = parseInt(date.split("-")[2]);
            return day + " " + monthToLabel[month];
          }
          return monthToLabel[month] + " " + year.slice(2, 4);
        });
        series = Object.keys(datesTotal).map((crmGroup: string) => {
          return {
            name: crmGroup.split("$")[1],
            data: Object.values<any>(datesTotal[crmGroup]),
          };
        });
      }
      setOptions({
        ...options,
        xaxis: { categories: xAxis },
        yaxis: { min: 0 },
        stroke: { curve: "smooth", width: 2 },
        colors:
          series?.length <= 1
            ? [theme.palette.primary.main]
            : randomColors
            ? randomColors.slice(0, series?.length)
            : undefined,
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        tooltip: {
          enabled: series?.length <= 1,
        },
        fill:
          series?.length <= 1
            ? {
                type: "gradient",
                gradient: {
                  type: "vertical",
                  shadeIntensity: 1,
                  inverseColors: false,
                  opacityFrom: 0.45,
                  opacityTo: 0.05,
                  stops: [20, 100, 100, 100],
                },
              }
            : {
                type: "solid",
              },
      });
      setSeries(series);
    }
  }, [datesTotal, timeUnit, label, showLegend]);

  return (
    series && <Chart options={options} series={series} type={series?.length <= 1 ? "area" : "line"} height="100%" />
  );
};

export default Stats;
