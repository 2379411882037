import { Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { FormTab, ArrayInput, SimpleFormIterator, FormDataConsumer, TabbedFormTabs } from "react-admin";
import { Edit, TabbedForm } from "@react-admin/ra-rbac";
import { COUNTRIES } from "../../services/common-utils/constants";
import CollaboratorsList from "../collaborators/CollaboratorsList";
import ProvidersList from "../providers/ProvidersList";
import PageTitle from "../../components/navigation/PageTitle";
import FormInput from "../../components/form/FormInput";
import { zodResolver } from "@hookform/resolvers/zod";
import { boolean, object, string } from "zod";

const formSchema = object({
  name: string().nullish(),
  siret: string().min(1, "Siret requis"),
  vat: string(),
  email: string().min(1, "Adresse mail requise").email("Adresse mail invalide"),
  phone: string().nullish(),
  address: object({
    address: string(),
    additionalAddress: string().nullish(),
    city: string(),
    zipCode: string(),
    country: string(),
  }),
  helpMenu: object({
    title: string().nullish(),
    subtitle: string().nullish(),
    helpCenterUrl: string(),
    chatActivated: boolean(),
    scenarioId: string(),
    meetingUrl: string(),
    phone: string(),
    disponibility: string(),
  }),
});

const DigisoftEdit = ({ ...props }) => {
  const onTransform = (data: any) => {
    return {
      ...data,
      country: data?.address?.country || null,
      accountManager: data?.accountManager || null,
    };
  };

  return (
    <Edit {...props} transform={onTransform} hasShow={false}>
      <PageTitle text="Administration" createButton={<></>} type="list" breadcrumbListLabel="Administration" />
      <TabbedForm tabs={<TabbedFormTabs indicatorColor="primary" />} resolver={zodResolver(formSchema)}>
        {/* Personnal infos form */}
        <FormTab label="Organisation">
          <Grid container direction={"column"}>
            <FormInput defaultValue={null} source="name" label="Nom" />
            <FormInput defaultValue={null} source="siret" label="SIRET" />
            <FormInput defaultValue={null} source="vat" label="Numéro de TVA Intracommunautaire" />
            <FormInput defaultValue={null} source="phone" label="Numéro de téléphone" type="phone" />
            <FormInput defaultValue={null} source="email" label="Adresse email" />
          </Grid>
        </FormTab>

        <FormTab label="Adresse">
          <Grid container direction={"column"}>
            <FormInput defaultValue={null} source="address.address" label="Rue et numéro" />
            <FormInput defaultValue={null} source="address.city" label="Ville" />
            <FormInput defaultValue={null} source="address.zipCode" label="Code postal" />
            <FormInput label="Pays" type="select" name="address.country" values={COUNTRIES} required />
          </Grid>
        </FormTab>

        <FormTab label="Collaborateurs">
          <CollaboratorsList resource="collaborators" sx={{ width: "100%" }} />
        </FormTab>

        <FormTab label="Configuration">
          <Grid container direction={"column"}>
            <Typography variant="medium_xl">Panneau d'aide</Typography>
            <Divider sx={{ mb: 1 }} />
            <FormInput defaultValue={null} source="helpMenu.title" label="Titre" />
            <FormInput defaultValue={null} source="helpMenu.subtitle" label="Sous-titre" />
            <FormInput defaultValue={null} source="helpMenu.helpCenterUrl" label="Url centre d'aide" />
            <FormInput
              defaultValue={true}
              source="helpMenu.chatActivated"
              checkboxLabel="Chat activé"
              type="checkbox"
            />
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.helpMenu.chatActivated && (
                  <FormInput
                    defaultValue={null}
                    source="helpMenu.scenarioId"
                    label="Id scénario chat"
                    helperText="laisser vide pour démarrer sans scénario"
                  />
                )
              }
            </FormDataConsumer>
            <FormInput defaultValue={null} source="helpMenu.meetingUrl" label="Url prise de rendez vous" />
            <FormInput defaultValue={null} source="helpMenu.phone" label="Numéro de téléphone" />
            <FormInput defaultValue={null} source="helpMenu.disponibility" label="Phrase disponibilité" />
            <Typography variant="medium_xl" mt={4}>
              Notifications
            </Typography>
            <Divider sx={{ mb: 1 }} />
            <ArrayInput
              required
              source="notifications.emails"
              label={
                <Typography
                  variant="bold_lg"
                  sx={{
                    height: 24,
                    position: "relative",
                    overflow: "visible",
                    transform: "none",
                  }}
                  color="common.title"
                >
                  Email(s) réception notifications
                </Typography>
              }
              sx={{ "& .RaSimpleFormIterator-list": { border: 1, borderColor: "rgba(0,0,0,0.2)", width: 450 } }}
            >
              <SimpleFormIterator>
                <FormInput sx={{ ml: 0.5 }} source="email" type="email" />
              </SimpleFormIterator>
            </ArrayInput>
            <Typography variant="medium_xl">Connecteurs</Typography>
            <Divider sx={{ mb: 1 }} />
            <Divider />
            <FormInput
              defaultValue={null}
              source="downloadDocSMSMeetingOutlook"
              label="Id doc exécutable SMS Meeting version Outlook"
            />
            <Divider />
          </Grid>
        </FormTab>

        <FormTab label="Providers">
          <ProvidersList resource="providers" sx={{ width: "100%" }} />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default DigisoftEdit;
