import { Box, Button, Checkbox, IconButton, Typography, InputAdornment, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  Labeled,
  NumberInput,
  ReferenceArrayInput,
  SelectArrayInput,
  ShowBase,
  TextField,
  required,
} from "react-admin";
import { useRecordContext } from "react-admin";
import { useWatch, useFormContext } from "react-hook-form";
import WarningIcon from "@mui/icons-material/Warning";
import api from "../../services/apiWithAuth";
import { AlertCircle, Info, InfoIcon, Minus, Plus, Search } from "lucide-react";
import FormInput from "../../components/form/FormInput";
import priceCalculator from "../../services/common-utils/priceCalculator";
import { AutocompleteInput } from "../../components/form/AutocompleteInput";

const optionRenderer = (choice) => (
  <Typography variant="regular_sm" color="common.title">
    {choice.firstName} {choice.lastName}
  </Typography>
);
const inputText = (choice) => `${choice.firstName} ${choice.lastName}`;

const LicenseQuantityInput = ({ subscription, tarif, product, discount, identity }: any) => {
  const [price, setPrice] = useState<any>(null);
  const [prorationAmount, setProrationAmount] = useState<any | null>(null);
  const quantityToAdd = useWatch({ name: "quantityToAdd" });
  const licensesToDelete = useWatch({ name: "licensesToDelete" });
  const freeLicensesNumber = useWatch({ name: "freeLicensesNumber" });
  const quantityAction = useWatch({ name: "quantityAction" });
  const quantity = useWatch({ name: "quantity" });
  const { setValue } = useFormContext();
  const theme = useTheme();

  //use to do not ask to user to select free licenses to suppress
  useEffect(() => {
    if (subscription && !freeLicensesNumber) {
      const asyncCall = async () => {
        const { data } = await api.post("/licenses/getFreeLicenses", {
          company: subscription.company_id,
        });
        setValue("freeLicensesNumber", data.freeLicensesNumber);
      };
      asyncCall();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscription]);

  useEffect(() => {
    setPrice(priceCalculator([{ product, tarifId: tarif.id, quantity }], discount, identity?.companyCountry));
    if (subscription) {
      if (quantity > subscription.quantity) {
        setValue("quantityAction", "add");
        setValue("quantityToAdd", quantity - subscription.quantity);
      } else if (quantity < subscription.quantity) {
        setValue("quantityAction", "delete");
      } else {
        setValue("quantityAction", "");
      }
    }
  }, [quantity, subscription?.quantity, setValue, subscription, product, tarif.id, discount, identity?.companyCountry]);

  useEffect(() => {
    if (quantityAction === "add" && quantityToAdd > 0) {
      const periodDuration = subscription.periodEnd - subscription.periodStart;
      const remainingDuration = subscription.periodEnd - Math.round(new Date().getTime() / 1000);
      const percentageRemaining = Math.floor((100 * remainingDuration) / periodDuration) / 100;
      const proration = priceCalculator(
        [{ product, tarifId: tarif.id, quantity: quantityToAdd, prorationRate: percentageRemaining }],
        discount,
        identity?.companyCountry
      );
      setValue("prorationRate", percentageRemaining);
      setProrationAmount(proration);
    }
  }, [
    discount,
    identity?.companyCountry,
    product,
    quantityAction,
    quantityToAdd,
    setValue,
    subscription?.periodEnd,
    subscription?.periodStart,
    tarif.id,
  ]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: subscription ? 1 : [1, "auto"] }}>
      <Box sx={{ display: "flex", flexDirection: "row", gap: 1, alignItems: "center", width: "fit-content" }}>
        <IconButton
          onClick={() => setValue("quantity", quantity - 1)}
          sx={{ width: 32, height: 32, p: 0 }}
          disabled={quantity <= 1}
        >
          <Minus size={14} color={theme.palette.common.title} />
        </IconButton>
        <FormInput
          name="quantity"
          required
          type="number"
          InputProps={{
            endAdornment: <InputAdornment position="start">utilisateur{quantity > 1 ? "s" : ""}</InputAdornment>,
          }}
          filledSecondary
          max={999}
        />
        <IconButton onClick={() => setValue("quantity", Math.min(quantity + 1, 999))} sx={{ width: 32, height: 32, p: 0 }}>
          <Plus size={14} color={theme.palette.common.title} />
        </IconButton>
      </Box>
      {subscription && quantity > subscription?.quantity && (
        <Typography variant="medium_xs" color="common.text" sx={{display: "flex", alignItems: "center", gap: 1, mt: 1}}>
          <InfoIcon size={18} /> Vous serez facturés {prorationAmount?.totalHt && quantityToAdd > 0 ? `${prorationAmount?.totalHt.toFixed(2)}€ HT` : ""} maintenant correspondant au
          prorata de l'ajout de {quantityToAdd}{" "}
          {quantityToAdd > 1 ? "utilisateurs supplémentaires" : "utilisateur supplémentaire"} sur la période en cours puis{" "}
          {price?.totalHt.toFixed(2)}€ HT{subscription?.billingPeriod === "Mensuelle" ? "/mois" : "/an"} pour {quantity} utilisateurs.
        </Typography>
      )}
      {subscription &&
        typeof freeLicensesNumber === "number" &&
        subscription.quantity - quantity > freeLicensesNumber &&
        (quantityAction === "delete" || subscription.licensesToDelete?.length > 0) && (
          <Box sx={{ "& .MuiFormControl-root": { width: 1 }, "& .MuiAutocomplete-root": { width: 1 } }}>
            <Typography
              variant="regular_sm"
              color="error"
              sx={{ display: "flex", alignItems: "center", gap: 0.5, my: 1 }}
            >
              <AlertCircle size={14} /> Vous devez sélectionner {subscription.quantity - quantity - freeLicensesNumber}
              &nbsp;
              {subscription.quantity - quantity - freeLicensesNumber > 1
                ? "licences utilisateurs"
                : "licence utilisateur"}{" "}
              à supprimer
            </Typography>
            <ReferenceArrayInput
              source="userLicensesToDelete"
              reference="users"
              defaultValue={[]}
              validate={required()}
              sort={{ field: "firstName", order: "ASC" }}
              perPage={1000}
              filter={{
                company: identity?.company,
              }}
              sx={{ "& .MuiFormControl-root": { width: 1 } }}
            >
              <AutocompleteInput
                optionText={optionRenderer}
                inputText={inputText}
                filterToQuery={(search: any) => ({
                  multi_firstName_lastName: search,
                })}
                onChange={(value, record) => {
                  setValue(
                    "licensesToDelete",
                    record.map((user: any) => user.licenseId)
                  );
                }}
                multiple
                defaultValue={[]}
                sx={{ "& .MuiInputBase-root": { mb: 0 } }}
                filledSecondary
                placeholder={licensesToDelete?.length >= 1 ? "" : "Rechercher une licence utilisateur"}
                myInputProps={
                  licensesToDelete?.length >= 1
                    ? {}
                    : {
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search size={16} />
                          </InputAdornment>
                        ),
                      }
                }
              />
            </ReferenceArrayInput>
          </Box>
        )}
    </Box>
  );
};

export default LicenseQuantityInput;
