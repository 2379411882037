import { arrayUnion, collection, doc, limit, onSnapshot, orderBy, query, updateDoc, where } from "firebase/firestore";
import React, { useState, useEffect } from "react";
import { fbFirestore } from "../../services/firebase/firebase.services";
import { Badge, Button, Collapse, Divider, IconButton, Tooltip, useMediaQuery } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { useRefresh, useGetIdentity } from "react-admin";
import { Bell, CircleCheck } from "lucide-react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import PageTitle from "../../components/navigation/PageTitle";
import lodash from "lodash";

const NotificationItem = ({ notification, identity }) => {
  const { displayTime, text, read, link, id, description } = notification;
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const isMobile = !!useMediaQuery((theme: any) => theme.breakpoints.only("xs"));

  const redirect = async (id, read, link: string) => {
    await markAsView(id);
    navigate(link);
  };

  const markAsView = async (id) => {
    if (!read) {
      await updateDoc(doc(fbFirestore, "notifications", id), {
        seenBy: arrayUnion(identity.id),
      });
    }
  };

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <MenuItem
      onClick={() => {
        if (link) {
          redirect(id, read, link);
        }
      }}
      sx={{
        whiteSpace: "unset",
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        width: 1,
        mt: [1],
        px: [1, 2],
        py: [0.5, 1],
        cursor: link ? "pointer" : "default",
        border: "1px solid",
        borderColor: "rgba(0, 0, 0, 0.1)",
        borderRadius: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: ["column", "row"],
          alignItems: ["start", "center"],
          color: read ? "grey" : "red",
          position: "relative",
          width: 1,
        }}
      >
        <Typography variant="bold_sm" color={read ? "common.title" : "error.main"}>
          {displayTime}
        </Typography>
        <Typography
          variant={isMobile ? "regular_xs" : "regular_sm"}
          color={read ? "common.text" : "error.main"}
          ml={isMobile ? 0 : 2}
        >
          {text}
        </Typography>
        <Box sx={{ position: "absolute", right: 0, display: "flex", alignItems: "center", gap: 0.5 }}>
          {!read && (
            <Tooltip title="Marquer comme vue">
              <IconButton
                onClick={(event) => {
                  markAsView(id);
                  event?.stopPropagation();
                }}
                sx={{ width: 24, height: 24, p: 0 }}
              >
                <CircleCheck size={20} />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title={open ? "voir moins" : "voir plus"}>
            {isMobile ? (
              <IconButton
                onClick={(event) => {
                  handleClick();
                  event?.stopPropagation();
                }}
                sx={{ width: 24, height: 24, p: 0 }}
              >
                {open ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            ) : (
              <Button
                onClick={(event) => {
                  handleClick();
                  event?.stopPropagation();
                }}
                sx={{ color: "common.text", mr: 2 }}
              >
                <Typography variant="regular_sm">{open ? "cacher détails" : "voir détails"}</Typography>
                {open ? <ExpandLess /> : <ExpandMore />}
              </Button>
            )}
          </Tooltip>
        </Box>
      </Box>
      {description && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Typography variant="regular_xs" color="common.title">
            {description}
          </Typography>
        </Collapse>
      )}
    </MenuItem>
  );
};

const Notifications = ({ identity, pageLimit = 20 }: any) => {
  const [notificationsByDate, setNotificationsByDate] = useState([] as any);
  const refresh = useRefresh();

  useEffect(() => {
    // if (identity?.showOrderNotifications) {
    const docRef = collection(fbFirestore, "notifications");
    let q: any;
    if (identity?.status !== "Collaborateur") {
      q = query(
        docRef,
        where("companyId", "==", identity?.company),
        where("recipient", "==", "customer"),
        where("methods", "array-contains", "dashboard"),
        orderBy("createdate", "desc"),
        limit(100)
      );
    } else {
      q = query(
        docRef,
        where("recipient", "==", "admin"),
        where("methods", "array-contains", "dashboard"),
        orderBy("createdate", "desc"),
        limit(100)
      );
    }
    const unsubscribeNotifications = onSnapshot(q, (snap: any) => {
      const newNotifications: any = [];
      snap.docs.forEach((doc: any) => {
        const notification = doc.data();
        newNotifications.push({
          id: doc.id,
          text: notification.text,
          description: notification.description,
          link: notification.link,
          read: (notification?.seenBy || []).includes(identity?.id),
          time: notification.createdate.seconds,
          date: new Date(notification.createdate.seconds * 1000).toLocaleDateString("fr", {
            year: "numeric",
            month: "long",
            day: "numeric",
          }),
          displayTime: new Date(notification.createdate.seconds * 1000).toTimeString().slice(0, 5).replace(":", "h"),
        });
      });
      const groupedNotifications = lodash(newNotifications)
        .groupBy((x) => x.date)
        .map((value, key) => ({ date: key, notifications: value }))
        .value();
      setNotificationsByDate(groupedNotifications);
    });

    return () => unsubscribeNotifications();
    // } else {
    //   return;
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageLimit]);

  return (
    <React.Fragment>
      <Box sx={{ mb: 2 }}>
        <PageTitle text="Notifications" backButton={false} />
      </Box>
      {notificationsByDate.length ? (
        notificationsByDate.map(({ date, notifications }: any, index: number) => {
          return (
            <Box sx={{ mt: 2 }}>
              <Typography variant="bold_sm">{date}</Typography>
              {notifications.map((notification: any, index: number) => {
                return <NotificationItem key={index} notification={notification} identity={identity} />;
              })}
            </Box>
          );
        })
      ) : (
        <Typography>Aucune notification</Typography>
      )}
    </React.Fragment>
  );
};

const NotificationsPage: FC = () => {
  const { isLoading, identity } = useGetIdentity();
  const [pageLimit, setPageLimit] = useState(20);

  return isLoading ? null : (
    <Box sx={{ width: 1 }}>
      <Notifications identity={identity} pageLimit={20} />
      {/* //!implement pageLimit better (see how to do it with firebase subscription) */}
      {/* <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: 1 }}>
        <Button
          variant="outlined"
          onClick={() => {
            setPageLimit(pageLimit + 20);
          }}
          sx={{ mt: 2, width: 1 }}
        >
          Charger plus
        </Button>
      </Box> */}
    </Box>
  );
};

export default NotificationsPage;
