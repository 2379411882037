import { LoadingButton } from "@mui/lab";
import {
  Box,
  Typography,
  Stack,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
  useTheme,
  Divider,
  alpha,
} from "@mui/material";
import React, { useState } from "react";
import PromoCode from "./PromoCode";
import { CheckCircle, RadioButtonUnchecked } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const getLabels = (cartItem: any) => {
  const product = cartItem.product;
  const tarif = product.tarifs.find((tarif: any) => tarif.id === cartItem.tarifId);
  let quantityLabel = "";
  let productLabel = "";
  if (product.type === "Crédits SMS") {
    quantityLabel = tarif.packageSize + " SMS";
    productLabel = "Recharge unique crédits SMS";
    if (product.isRecurring) {
      quantityLabel += tarif.billingPeriod === "Mensuelle" ? " par mois" : " par an";
      productLabel = "Recharge auto crédits";
    }
  }
  if (product.type === "Licence") {
    productLabel = "Licence SMS Meeting";
    quantityLabel = cartItem.quantity + " utilisateur" + (cartItem.quantity > 1 ? "s" : "");
  }
  if (product.type === "API") {
    quantityLabel = cartItem.quantity + "call API" + tarif.billingPeriod === "Mensuelle" ? " par mois" : " par an";
    productLabel = "Licence API SMS Meeting";
  }
  return { quantityLabel, productLabel };
};

const CheckoutResume = ({
  totals,
  discount,
  setDiscount,
  cart,
  setCart,
  checkoutStep,
  handleStep,
  identity,
  paymentFormSubmitting,
  paymentErrorMessage,
}: any) => {
  const [conditionsAccepted, setConditionsAccepted] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const cartItem = cart[0];
  const product = cartItem.product;
  const tarif = product.tarifs.find((tarif: any) => tarif.id === cartItem.tarifId);

  return (
    <Stack sx={{ display: "flex", alignItems: "start", MuiButtonBase: { width: 1 }, gap: 2 }}>
      <Typography variant="bold_lg" sx={{ mb: "0px" }}>
        Résumé des achats
      </Typography>

      <Box sx={{ display: "flex", alignItems: "start", justifyContent: "space-between", width: 1 }}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="medium_sm" color="common.title">
            {getLabels(cartItem).productLabel}
          </Typography>
          <Typography variant="regular_sm">{getLabels(cartItem).quantityLabel}</Typography>
        </Box>

        <Typography variant="regular_md" color="common.title">
          {totals.withoutDiscount.totalHt.toFixed(2)}€
          {cartItem?.prorationRate || !product.isRecurring ? "" : tarif.billingPeriod === "Mensuelle" ? "/mois" : "/an"}
        </Typography>
      </Box>

      {Object.keys(discount).length > 0 && totals.savings.totalHt > 0 && (
        <Box
          display="flex"
          flexDirection="row"
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{ width: 1 }}
        >
          <Typography variant="regular_sm" maxWidth={0.75}>
            {discount.applicationType === "Code promo" ? "Code " + discount.promoCode : discount.name}{" "}
            {discount.type === "Pourcentage" && ` (-${discount.rate}%)`}
          </Typography>

          <Typography variant="regular_md">-{totals.savings.totalHt.toFixed(2)}&euro;</Typography>
        </Box>
      )}

      {Object.keys(discount).length > 0 && (
        <Box
          display="flex"
          flexDirection="row"
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{ width: 1 }}
        >
          <Typography variant="regular_sm">Total HT après remise</Typography>

          <Typography variant="regular_md">{totals.totalHt.toFixed(2)}€</Typography>
        </Box>
      )}

      <Box display="flex" flexDirection="row" alignItems={"center"} justifyContent={"space-between"} sx={{ width: 1 }}>
        <Typography variant="regular_sm">Taxes 20%</Typography>

        <Typography variant="regular_md">
          {totals.taxes.total !== 0 ? totals.taxes.total.toFixed(2) + "€" : "Non applicable"}
        </Typography>
      </Box>
      <Divider sx={{ width: 1 }} />
      <Box
        display="flex"
        flexDirection="row"
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{
          width: 1,
        }}
      >
        <Typography variant="bold_md" color="common.title">
          Total TTC
        </Typography>

        <Typography variant="bold_md" color="common.title">
          <b>
            {totals.totalTtc.toFixed(2)}€
            {cartItem?.prorationRate || !product.isRecurring
              ? ""
              : tarif.billingPeriod === "Mensuelle"
              ? "/mois"
              : "/an"}
          </b>
        </Typography>
      </Box>
      {checkoutStep === 0 && (
        <PromoCode discount={discount} setDiscount={setDiscount} cart={cart} setCart={setCart} identity={identity} />
      )}
      {checkoutStep === 0 && (
        <Button
          sx={{ color: "white", mt: 1, width: 1 }}
          form="customer-infos-form"
          type="submit"
          color="primary"
          variant="contained"
          size="large"
          disabled={cart.length === 0}
        >
          Suivant
        </Button>
      )}
      {checkoutStep === 1 && (
        <>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={conditionsAccepted}
                  onClick={() => {
                    setConditionsAccepted(!conditionsAccepted);
                  }}
                  sx={{ height: "2.5rem" }}
                />
              }
              label={
                <Typography variant="bold_sm" color="common.title">
                  J'accepte les{" "}
                  <a
                    href="https://sms-meeting.com/cgs/"
                    rel="noreferrer"
                    target="_blank"
                    style={{ color: theme.palette.primary.main }}
                  >
                    conditions générales de services
                  </a>
                  .
                </Typography>
              }
            />
          </FormGroup>
          {paymentErrorMessage && (
            <Typography color="error" textAlign="center">
              {paymentErrorMessage}
            </Typography>
          )}
          <LoadingButton
            sx={{ color: "white", mt: 1, width: 1 }}
            form="payment-form"
            type="submit"
            color="primary"
            variant="contained"
            size="large"
            disabled={cart.length === 0 || !conditionsAccepted}
            loading={paymentFormSubmitting}
          >
            Payer maintenant
          </LoadingButton>
        </>
      )}
      <Button
        variant="outlined"
        sx={{
          width: 1,
          color: "button.primary.outlined.content",
          bgcolor: "button.primary.outlined.background",
          borderColor: "button.primary.outlined.stroke",
          ":hover": { bgcolor: alpha(theme.palette.button.primary.outlined.background, 0.7) },
        }}
        size="large"
        onClick={() => {
          if (checkoutStep > 0) {
            handleStep(-1);
          } else {
            setDiscount({});
            navigate("/myOffer");
          }
        }}
      >
        Retour
      </Button>
    </Stack>
  );
};

export default CheckoutResume;
