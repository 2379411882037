import { Grid } from "@mui/material";
import React from "react";
import { Create, useNotify, useRedirect } from "react-admin";
import { SimpleForm } from "@react-admin/ra-rbac";
import PageTitle from "../../components/navigation/PageTitle";
import FormInput from "../../components/form/FormInput";

const ProvidersCreate = ({ ...props }) => {
  const notify = useNotify();
  const redirect = useRedirect();

  // Success override
  const onSuccess = async (data: any) => {
    notify(`Provider créé avec succès`, { type: "success" });
    redirect("list", "providers", data.id, data);
  };

  return (
    <Create {...props} mutationOptions={{ onSuccess }}>
      <PageTitle
        text={"Création d'une nouveau provider"}
        type="create"
        resource="providers"
        breadcrumbListLabel={"Liste des providers"}
        breadcrumbListUrl="/admin/digisoft/4"
        breadcrumbCreateLabel={"Nouveau provider"}
      />
      <SimpleForm>
        <Grid container direction={"column"}>
          <FormInput required source="name" label="Nom" />
          <FormInput required source="entry_point" label="Url d'entrée" />
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export default ProvidersCreate;
