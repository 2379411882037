import { useStore } from 'ra-core';
import { useMediaQuery, Theme } from '@mui/material';

export const useAccountMenuState = (): useAccountMenuStateResult => {
    const isXSmall = useMediaQuery<Theme>(
        theme => theme?.breakpoints.down('sm'),
        { noSsr: true }
    );
    return useStore<boolean>('accountMenuOpen', isXSmall ? false : true);
};

export type useAccountMenuStateResult = [boolean, (open: boolean) => void];
