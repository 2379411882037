import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { Create, required, useNotify, useRedirect, useGetIdentity, FormDataConsumer, TextInput } from "react-admin";
import { SimpleForm } from "@react-admin/ra-rbac";
import { COMPANIES_STATUSES, COUNTRIES, TYPES } from "../../services/common-utils/constants";
import api from "../../services/apiWithAuth";
import PageTitle from "../../components/navigation/PageTitle";
import FormInput from "../../components/form/FormInput";
import { zodResolver } from "@hookform/resolvers/zod";
import { boolean, object, string } from "zod";

const formSchema = object({
  company: string().min(1, { message: "Veuillez entrer un nom d'organisation." }),
  type: string().min(1, { message: "Veuillez sélectionner un type d'organisation." }),
  address: object({
    country: string().min(1, { message: "Veuillez sélectionner un pays." }),
  }),
  status: string().nullish(),
  siret: string().nullish(),
  vat: string().nullish(),
});

const CompaniesCreate = ({ ...props }) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const { identity } = useGetIdentity();

  // Transform override
  const onTransform = async (data: any) => {
    let createGroupRes = await api.post("/utils/createGroup", { reference: data.siret || data.vat || data.company });
    const groupId = createGroupRes.data.groupId;
    data = {
      ...data,
      id: groupId,
      country: data?.address?.country || null,
      accountManager: identity?.company === "Collaborateur" ? identity?.email : null,
      parentCompany: identity?.status === "Revendeur" ? identity?.company : groupId,
      status: identity?.status === "Revendeur" ? "Indirect" : data.status,
      prospect: true,
      clientSince: new Date().toISOString().split("T")[0],
      freeTry: {
        available: true,
        licenseId: null,
        expirationDate: null,
        licenseConverted: false,
      },
    };
    return data;
  };

  // Success override
  const onSuccess = (data: any) => {
    notify(`Client créé avec succès`, { type: "success" });
    redirect("edit", "companies", data.id, data);
  };

  return (
    <Create {...props} transform={onTransform} mutationOptions={{ onSuccess }}>
      <PageTitle
        text={"Création d'un nouveau client"}
        type="create"
        resource="companies"
        breadcrumbListLabel={"Liste des clients"}
        breadcrumbCreateLabel={"Nouveau client"}
      />
      <SimpleForm resolver={zodResolver(formSchema)}>
        <Box sx={{ display: "grid", gridTemplateColumns: ["1fr", "1fr", "1fr 1fr"], gap: 2 }}>
          <FormInput required defaultValue={null} source="company" label="Nom de l'organisation" />
          <FormInput
            type="select"
            required
            defaultValue="Entreprise privée"
            source="type"
            label="Nature de l'organisation"
            values={TYPES.map((s) => ({ id: s, label: s }))}
          />
          <FormInput
            type="select"
            required
            defaultValue="France"
            source="address.country"
            label="Pays"
            values={COUNTRIES.map((s) => ({ id: s, label: s }))}
          />
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData?.address &&
              formData?.address?.country &&
              ["France", "Guadeloupe", "Saint-Barthelemy"].includes(formData.address.country) ? (
                <FormInput defaultValue={null} required source="siret" label="SIRET" />
              ) : null
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData?.address &&
              formData?.address?.country &&
              !["France", "Guadeloupe", "Saint-Barthelemy"].includes(formData.address.country) ? (
                <FormInput defaultValue={null} source="vat" label="Numéro de TVA Intracommunautaire" />
              ) : null
            }
          </FormDataConsumer>
          <FormInput
            type="select"
            required
            source="status"
            defaultValue="Client"
            label="Statut"
            values={COMPANIES_STATUSES.map((s) => ({ id: s, label: s }))}
          />
        </Box>
      </SimpleForm>
    </Create>
  );
};

export default CompaniesCreate;
