import EmailsList from "./EmailsList"
import EmailsCreate from "./EmailsCreate";
import EmailsEdit from "./EmailsEdit";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  list: EmailsList,
  create: EmailsCreate,
  edit: EmailsEdit
};
