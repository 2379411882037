import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Typography,
  Box,
  Card,
  FormGroup,
  ButtonBase,
  FormControlLabel,
  CircularProgress,
  useTheme,
} from "@mui/material";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import api from "../../../services/apiWithAuth";

import { CardElement, IbanElement, useElements, useStripe } from "@stripe/react-stripe-js";
import StripeCard from "./StripeCard";
import StripeSepa from "./StripeSepa";
import { CheckCircle, RadioButtonUnchecked } from "@mui/icons-material";
import FormInput from "../../../components/form/FormInput";
import { useInterval } from "usehooks-ts";
import { useGetIdentity, useGetList, useGetOne } from "react-admin";

const CheckoutPayment = ({
  handleCheckoutCompleted,
  cart,
  customerInfos,
  setPaymentFormSubmitting,
  setPaymentErrorMessage,
}: any) => {
  const { data: identity } = useGetIdentity();
  const { data: company } = useGetOne("companies", { id: identity?.company });
  const [paymentType, setPaymentType] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const elements = useElements();
  const methods = useForm();
  const {
    data: customerPaymentMethods,
    isLoading,
  } = useGetList("paymentMethods", {
    pagination: { page: 1, perPage: 20 },
    filter: {
      group_id: identity?.company || "",
    },
  });
  const [useExistingCard] = useWatch({ control: methods.control, name: ["useExistingCard"], defaultValue: false });
  const [useExistingIban] = useWatch({ control: methods.control, name: ["useExistingIban"], defaultValue: false });
  const [customerCards, setCustomerCards] = useState([] as any);
  const [customerIbans, setCustomerIbans] = useState([] as any);
  const [message, setMessage] = useState("");
  const stripe = useStripe();

  useEffect(() => {
    if (customerPaymentMethods?.length) {
      setCustomerCards(customerPaymentMethods.filter((paymentMethod: any) => paymentMethod.type === "card"));
      setCustomerIbans(customerPaymentMethods.filter((paymentMethod: any) => paymentMethod.type === "sepa_debit"));
    }
  }, [customerPaymentMethods]);

  useInterval(
    () => {
      const storedClientSecret = localStorage.getItem("clientSecret");
      if (storedClientSecret) {
        setClientSecret(storedClientSecret);
      }
    },
    !clientSecret ? 500 : null
  );

  useEffect(() => {
    setPaymentFormSubmitting(methods.formState.isSubmitting);
  }, [methods.formState.isSubmitting, setPaymentFormSubmitting]);

  const onSubmit = async (data: any) => {
    if (!stripe || !elements || !clientSecret || cart.length === 0) {
      return;
    }
    setPaymentErrorMessage("");
    const billingDetails = {
      address: {
        line1: customerInfos.address,
        postal_code: customerInfos.zipCode,
        city: customerInfos.city,
        //country: customerInfos.billingCountry,
      },
      email: customerInfos.email,
      name: customerInfos.firstName + " " + customerInfos.lastName,
      phone: customerInfos.contactPhone,
    };
    let res: any;
    try {
      if (paymentType === "card") {
        const card = elements.getElement(CardElement);
        if (((!customerCards.length || !useExistingCard) && !card) || (customerCards.length && useExistingCard && !data?.card)) {
          setPaymentErrorMessage("Veuiller choisir un mode de paiement");
          return;
        }
        if (data?.card && !useExistingCard) {
          data.card = "";
        }
        res = await stripe.confirmCardPayment(clientSecret, {
          receipt_email: customerInfos.email,
          payment_method: data?.card || {
            card,
            billing_details: billingDetails,
          },
        });
        console.log("stripe res", res);
      } else {
        const iban = elements.getElement(IbanElement);
        if (((!customerIbans.length || !useExistingIban) && !iban) || (customerIbans.length && useExistingIban && !data?.iban)) {
          setPaymentErrorMessage("Veuiller choisir un mode de paiement");
          return;
        }
        if (data?.iban && !useExistingIban) {
          data.iban = "";
        }
        res = await stripe.confirmSepaDebitPayment(clientSecret, {
          receipt_email: customerInfos.email,
          payment_method: data?.iban || {
            sepa_debit: iban,
            billing_details: billingDetails,
          },
        });
      }
    } catch (err) {
      console.log("error catched", err);
      setPaymentErrorMessage("Erreur lors du paiement, merci de réessayer avec un autre moyen de paiement.");
      return;
    }

    const error = res?.error || null;

    if (error) {
      console.log(error.message || "Erreur lors du paiement, merci de réessayer avec un autre moyen de paiement.");
      setPaymentErrorMessage(
        "Erreur lors du paiement, merci de réessayer avec un autre moyen de paiement ou contactez un administrateur."
      );
    } else {
      handleCheckoutCompleted(paymentType);
      setMessage("Paiement réussi! ");
    }
  };

  return (
    <Box
      sx={{
        mb: 2,
        mt: 3,
        width: 1,
        "& .MuiInputBase-root": { width: 1 },
        "& .MuiFormControl-root": { width: 1 },
      }}
    >
      <FormProvider {...methods}>
        <Box id="payment-form" component="form" autoComplete="off" onSubmit={methods.handleSubmit(onSubmit)}>
          <Typography variant="bold_md" color="common.title">
            Modes de paiement
          </Typography>
          <div style={{ color: "red", fontSize: "1.5rem" }}>{message}</div>
          {!clientSecret ? (
            <CircularProgress sx={{ m: 5, ml: 10 }} />
          ) : (
            <>
              {" "}
              <Box mt={2}>
                <Box
                  display="flex"
                  sx={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: 1,
                    ":hover": { cursor: "pointer" },
                  }}
                  alignItems="center"
                  onClick={() => setPaymentType("card")}
                >
                  <FormGroup sx={{ width: 1 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          icon={<RadioButtonUnchecked />}
                          checkedIcon={<CheckCircle />}
                          color="primary"
                          checked={paymentType === "card"}
                        />
                      }
                      label={
                        <Typography variant="bold_sm" color="common.title">
                          Carte bancaire
                        </Typography>
                      }
                    />
                  </FormGroup>
                </Box>
                {paymentType === "card" && (
                  <Box display="flex" sx={{ flexDirection: "flex-start" }} alignItems="center">
                    <Box sx={{ width: [1, 400], ml: 4 }}>
                      {customerCards.length > 0 && (
                        <FormInput checkboxLabel="Utiliser une carte existante" name="useExistingCard" type="checkbox" />
                      )}
                      {customerCards.length === 0 || !useExistingCard ? (
                        <>
                          <StripeCard />
                          <Typography fontSize="0.8rem">
                            Aucune information bancaire n'est stockée sur ce site, le paiement se fait directement sur
                            le serveur de votre banque.
                          </Typography>
                        </>
                      ) : (
                        <FormInput
                          type="select"
                          name="card"
                          values={customerCards.map((paymentMethod: any) => {
                            return {
                              id: paymentMethod.id,
                              label: "**** **** **** " + paymentMethod.card.last4,
                              cardType: paymentMethod.card.brand,
                              expMonth: paymentMethod.card.exp_month,
                              expYear: paymentMethod.card.exp_year,
                            };
                          })}
                          disabled={!useExistingCard}
                        />
                      )}
                    </Box>
                    {/* submit form with id */}
                  </Box>
                )}
              </Box>
              <Box>
                <Box
                  display="flex"
                  sx={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: 1,
                    ":hover": { cursor: "pointer" },
                  }}
                  alignItems="center"
                  onClick={() => setPaymentType("sepa")}
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          icon={<RadioButtonUnchecked />}
                          checkedIcon={<CheckCircle />}
                          color="primary"
                          checked={paymentType === "sepa"}
                        />
                      }
                      label={
                        <Typography variant="bold_sm" color="common.title">
                          SEPA
                        </Typography>
                      }
                    />
                  </FormGroup>
                </Box>
                {paymentType === "sepa" && (
                  <Box display="flex" sx={{ flexDirection: "flex-start" }} alignItems="center">
                    <Box sx={{ width: [1, 400], ml: 4 }}>
                      {customerIbans.length > 0 && (
                        <FormInput checkboxLabel="Utiliser un mandat SEPA existant" name="useExistingIban" type="checkbox" />
                      )}
                      {customerIbans.length === 0 || !useExistingIban ? (
                        <>
                          <StripeSepa />
                          <Typography variant="regular_xs" color="common.title">
                            En fournissant vos informations de paiement et en confirmant ce paiement, vous autorisez (A)
                            VENTUS et Stripe, notre prestataire de services de paiement et/ou PPRO, son prestataire de
                            services local, à envoyer des instructions à votre banque pour débiter votre compte et (B)
                            votre banque à débiter votre compte conformément à ces instructions. Vous avez, entre
                            autres, le droit de vous faire rembourser par votre banque selon les modalités et conditions
                            du contrat conclu avec votre banque. La demande de remboursement doit être soumise dans un
                            délai de 8 semaines à compter de la date à laquelle votre compte a été débité. Vos droits
                            sont expliqués dans une déclaration disponible auprès de votre banque. Vous acceptez de
                            recevoir des notifications des débits à venir dans les 2 jours précédant leur réalisation.
                          </Typography>
                        </>
                      ) : (
                        <FormInput
                          type="select"
                          name="iban"
                          label="Sélectionner un mandat de prélèvement"
                          values={customerIbans.map((paymentMethod: any) => {
                            return {
                              id: paymentMethod.id,
                              label: `${paymentMethod.sepa_debit.country}**${paymentMethod.sepa_debit.bank_code}${paymentMethod.sepa_debit.branch_code}*********${paymentMethod.sepa_debit.last4}`,
                            };
                          })}
                          disabled={!useExistingIban}
                        />
                      )}
                    </Box>
                    {/* submit form with id */}
                  </Box>
                )}
              </Box>
            </>
          )}
        </Box>
      </FormProvider>
    </Box>
  );
};

export default CheckoutPayment;
