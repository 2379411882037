import React from "react";
import {
  CreateButton,
  DateField,
  ReferenceField,
  Filter,
  TextField,
  useGetIdentity,
  ReferenceInput,
  FunctionField,
  CloneButton,
} from "react-admin";
import { List } from "@react-admin/ra-rbac";
import { CustomDatagrid } from "../../components/react-admin/CustomDatagrid";
import { Typography } from "@mui/material";
import PageTitle from "../../components/navigation/PageTitle";
import FormInput from "../../components/form/FormInput";
import { AutocompleteInput } from "../../components/form/AutocompleteInput";
import { ListActions } from "../../components/react-admin/ListActions";
import { CopyIcon } from "lucide-react";

const TemplatesList = ({ ...props }) => {
  const { isLoading, identity } = useGetIdentity();

  return isLoading ? (
    <></>
  ) : (
    <>
      <PageTitle
        text="Modèles"
        createButton={<CreateButton label="Nouveau modèle" resource="templates" />}
        type="list"
        breadcrumbListLabel="Liste des modèles"
      />
      <List
        perPage={5}
        {...props}
        filter={
          props.group_id
            ? { group_id: props.group_id }
            : identity?.status === "Client"
            ? { group_id: identity.company }
            : {}
        }
        filters={
          <Filter {...props}>
            <FormInput type="search" placeholder="Nom du modèle" name="name" alwaysOn />
            {!props.group_id && identity?.status === "Collaborateur" && (
              <ReferenceInput
                source="group_id"
                reference="companies"
                alwaysOn
                sort={{ field: "company", order: "ASC" }}
                perPage={1000}
              >
                <AutocompleteInput
                  placeholder="Organisation"
                  optionText="company"
                  filterToQuery={(search: any) => ({ company: search })}
                  filledSecondary
                />
              </ReferenceInput>
            )}
            <ReferenceInput
              source="crmGroupId"
              label="Groupe"
              reference="groups"
              filter={
                props.group_id
                  ? { company: props.group_id }
                  : identity?.status !== "Collaborateur"
                  ? { company: identity?.company }
                  : {}
              }
              sort={{ field: "name", order: "ASC" }}
              perPage={1000}
            >
              <AutocompleteInput
                placeholder="Groupe"
                optionText="name"
                filterToQuery={(search: any) => ({ name: search })}
                filledSecondary
              />
            </ReferenceInput>
            <FormInput
              placeholder="Visibilité"
              type="select"
              source="group_access"
              values={[
                { id: "true", label: "Tout le monde" },
                { id: "false", label: "Restreint par groupe" },
              ]}
              filledSecondary
              clearable
              label="Visibilité"
              hideLabel
            />
          </Filter>
        }
        actions={<ListActions />}
        sort={{ field: "name", order: "ASC" }}
        exporter={false}
        empty={false}
      >
        <CustomDatagrid>
          <TextField label="Nom du modèle" source="name" />
          {!props.group_id && (
            <ReferenceField label="Organisation" source="group_id" reference="companies" link={false} sortable={false}>
              <TextField source="company" />
            </ReferenceField>
          )}
          <FunctionField
            label="Accès"
            render={(record: any) => {
              if (record.group_access) {
                return "Tout le monde";
              } else {
                return !record.crmGroupId ? (
                  "Personne"
                ) : (
                  <ReferenceField source="crmGroupId" reference="groups" link={false}>
                    <FunctionField
                      render={(groupRecord: any) => {
                        return <Typography fontSize={"0.9rem"}>Groupe "{groupRecord.name}"</Typography>;
                      }}
                    />
                  </ReferenceField>
                );
              }
            }}
          />
          <DateField label="Mis à jour le" source="updatedAt" showTime />
          <CloneButton icon={<CopyIcon size={18} />} variant="contained" color="secondary" sx={{ px: 2, py: 0.5 }}/>
        </CustomDatagrid>
      </List>
    </>
  );
};

export default TemplatesList;
