import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const CheckoutSuccess = ({ paidWith, setCheckoutStep }: any) => {
  const navigate = useNavigate();
  const confirmImage = require("../../../assets/order-confirmed.svg").default as string;
  const orderReference = localStorage.getItem("orderReference");

  return (
    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" my={"1.5rem"} gap="2rem">
      <Typography variant="bold_2xl" color="common.title" sx={{ textAlign: ["center", "left"] }}>
        {paidWith === "sepa" ? "Votre paiement a bien été enregistré." : "Votre paiement a bien été effectué."}
      </Typography>
      <Box
        sx={{
          width: [1, 300, 300, 300, 380],
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={confirmImage} alt="confirmed" style={{ width: "100%", height: "auto", margin: 0 }} />
      </Box>
      <Typography variant="regular_lg" color="common.title" sx={{ textAlign: ["center", "left"] }}>
        {paidWith === "sepa"
          ? "Vous pourrez bénéficier des fonctionnalités auxquelles vous avez souscrites une fois que le prélèvement SEPA aura été autorisé par votre banque. "
          : ""}
        Vous pouvez consulter et modifier votre offre à tout moment.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={() => {
          setCheckoutStep(0);
          localStorage.removeItem("discount");
          navigate("/myOffer");
        }}
        sx={{ width: [1, "auto"] }}
      >
        <Typography variant="medium_lg">Voir mon offre</Typography>
      </Button>
    </Box>
  );
};

export default CheckoutSuccess;
