import React from "react";
import { Edit, Toolbar, SaveButton } from "react-admin";
import { SimpleForm } from "@react-admin/ra-rbac";
import PageTitle from "../../components/navigation/PageTitle";
import FormInput from "../../components/form/FormInput";

const ProvidersEdit = ({ ...props }) => {
  return (
    <Edit {...props}>
      <PageTitle
        text={(record: any) => `Provider ${record.name}`}
        type="edit"
        resource="providers"
        breadcrumbListLabel="Liste des providers"
        breadcrumbListUrl="/admin/digisoft/4"
      />
      <SimpleForm
        toolbar={
          <Toolbar {...props}>
            <SaveButton />
          </Toolbar>
        }
      >
        <FormInput source="id" disabled label="Identifiant" />
        <FormInput required source="name" label="Nom" />
        <FormInput required source="entry_point" label="Url d'entrée" />
      </SimpleForm>
    </Edit>
  );
};

export default ProvidersEdit;
