import { object, string, z, array, boolean, number } from "zod";

export const schema = object({
  name: string().min(1, "Nom de la remise requis"),
  startDate: string().min(1, "Date de début de remise requis"),
  endDate: string().nullish(),
  applicationType: string().min(1, "Date de début de remise requis"),
  promoCode: string().nullish(),
  scope: string().nullish(),
  expire: boolean(),
  onlyOnceByCompany: boolean(),
  onlyDuringFreetry: boolean(),
  type: string().min(1, "Type de remise requis"),
  rate: number().min(1).max(100).nullish(),
  rates: array(
    object({
      years: number(),
      rate: number().min(1).max(100),
    })
  ).nullish(),
  amount: number({
    required_error: "Montant de la remise requis",
  }).nullish(),
  products: array(string()).nullish(),
  scopedByCompanyType: boolean(),
  companyTypes: array(string()).nullish(),
  scopedByCompanyStatus: boolean(),
  companyStatus: array(string()).nullish(),
  scopedByCompanyIds: boolean(),
  companyIds: array(string()).nullish(),
});

export type Discount = z.infer<typeof schema>;
