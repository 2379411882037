import { Box, Typography, useMediaQuery } from "@mui/material";
import { DualListInput } from "@react-admin/ra-relationships";
import React, { useEffect } from "react";
import { useChoicesContext } from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";
import { AutocompleteInput } from "../form/AutocompleteInput";

const AutocompleteDualListInput = ({ ...props }: any) => {
  const { allChoices } = useChoicesContext();
  const itemToAdd = useWatch({ name: "itemToAdd" });
  const itemToRemove = useWatch({ name: "itemToRemove" });
  const items = useWatch({ name: props.source });
  const { setValue } = useFormContext();
  const isMobile = !!useMediaQuery((theme: any) => theme.breakpoints.only("xs"));

  useEffect(() => {
    if (itemToAdd) {
      setValue("itemToAdd", undefined);
      setValue(props.source, items.concat(itemToAdd));
    }
    if (itemToRemove) {
      setValue("itemToRemove", undefined);
      setValue(
        props.source,
        items.filter((item: any) => item !== itemToRemove)
      );
    }
  }, [itemToAdd, itemToRemove, setValue, items, props.source]);

  useEffect(() => {
    if (!items) {
      setValue(props.source, []);
    }
  }, [items, props.source, setValue]);

  return isMobile ? (
      <AutocompleteInput
        label={props.label}
        optionText={props.optionText}
        filterToQuery={(search: any) => ({ [props.queryField]: search })}
        multiple
        defaultValue={[]}
        sx={{ "& .MuiInputBase-root": { mb: 0 } }}
        debounce={500}
      />
  ) : (
    <Box sx={{ width: "fit-content" }}>
      <Typography variant="bold_sm" color="common.title" mb={0}>
        {props.label}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          "& .MuiFormControl-root": {
            mb: "0 !important",
            width: 350,
            "& .MuiInputBase-root": { borderBottomLeftRadius: 0, borderBottomRightRadius: 0 },
          },
          width: 1,
          "& .MuiBox-root": {
            width: "unset",
          },
        }}
      >
        <AutocompleteInput
          sx={{ mt: 0, pt: 0 }}
          choices={allChoices?.filter((choice) => (items ? !items.some((item: any) => item === choice.id) : [])) || []}
          optionText={props.optionText}
          label=""
          filterToQuery={(search: any) => ({ [props.queryField]: search })}
          debounce={500}
          helperText={false}
          source="itemToAdd"
          placeholder="Ajout rapide"
        />
        <AutocompleteInput
          sx={{ mt: 0, pt: 0 }}
          choices={allChoices?.filter((choice) => (items ? items.some((item: any) => item === choice.id) : [])) || []}
          optionText={props.optionText}
          label=""
          filterToQuery={(search: any) => ({ [props.queryField]: search })}
          debounce={500}
          helperText={false}
          source="itemToRemove"
          placeholder="Retrait rapide"
        />
      </Box>

      <Box
        sx={{
          "& .MuiFormControl-root": { width: "fit-content" },
        }}
      >
        <DualListInput
          sx={{
            mt: 0,
            pt: 0,
            "& .MuiListSubheader-root": { display: "none" },
            "& .MuiList-root": { width: 350 },
          }}
          {...props}
          label=""
        />
      </Box>
    </Box>
  );
};

export default AutocompleteDualListInput;
