import { object, string, z, any, boolean, number } from "zod";
const MAX_FILE_SIZE = 500000;
const ACCEPTED_IMAGE_TYPES = ["image/jpeg", "image/jpg", "image/png", "image/webp"];

export const schema = object({
  name: string().min(1, "Nom du fichier requis"),
  description: string().min(1, "Description requise"),
  version: string().min(1, "Version requise"),
  pictures: object({
    src: string().min(1, "Image requise"),
    title: string().nullish(),
    //todo : make it work correctly, maybe implement imageInput and file Input in formInput to add label and error
    rawFile: any()
      .refine((file) => {
        return !!file;
      }, "Image requise")
      .refine((file) => file.size <= MAX_FILE_SIZE, `Maximum 5MB par fichier.`)
      .refine(
        (file) => ACCEPTED_IMAGE_TYPES.includes(file.type),
        "seules des images de type .jpg, .jpeg, .png and .webp files sont acceptées."
      ),
  }),
  software: object({
    src: string().min(1, "Fichier requis"),
    title: string().nullish(),
    rawFile: any()
      .refine((file) => {
        return !!file;
      }, "Fichier requis")
  }),
});

export type Download = z.infer<typeof schema>;
