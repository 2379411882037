import { useEffect, useState } from "react";
import { useGetIdentity } from "react-admin";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { fbFirestore } from "../firebase/firebase.services";

export function useGetSubscriptions() {
  const [licenceSubscription, setLicenceSubscription] = useState<any>(null);
  const [creditSubscription, setCreditSubscription] = useState<any>(null);
  const { data: identity } = useGetIdentity();

  useEffect(() => {
    const docRef = collection(fbFirestore, "subscriptions");
    let q: any;
    if (identity?.status !== "Collaborateur") {
      q = query(docRef, where("company_id", "==", identity?.company));
    }
    const unsubscribeSubscriptions = onSnapshot(q, (snap: any) => {
      snap.docs.forEach((doc: any) => {
        const subscription = doc.data();
        subscription.id = doc.id;
        if (subscription.type === "Licence") {
          setLicenceSubscription(subscription);
        }
        if (subscription.type === "Crédits SMS") {
          setCreditSubscription(subscription);
        }
      });
    });

    return () => unsubscribeSubscriptions();
  }, [identity?.company, identity?.status]);

  // useEffect(() => {
  //   if (licenceSubscription) {
  //     console.log("license subscription changed", licenceSubscription);
  //   }
  // }, [licenceSubscription]);

  // useEffect(() => {
  //   if (creditSubscription) {
  //     console.log("credits subscription changed", creditSubscription);
  //   }
  // }, [creditSubscription]);

  return {
    licenceSubscription,
    creditSubscription,
  };
}
