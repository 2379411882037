export const translations = {
  uploadStep: {
    title: "Importez votre fichier",
    manifestTitle: "Données attendues",
    manifestDescription: "(Vous pourrez renommer ou supprimer des colonnes aux prochaines étapes)",
    maxRecordsExceeded: (maxRecords: string) => `Trop de lignes. Maximum ${maxRecords}`,
    dropzone: {
      title: "Téléchargez un fichier .xlsx, .xls ou .csv",
      errorToastDescription: "Erreur lors de l'import",
      activeDropzoneTitle: "Insérer votre fichier...",
      buttonTitle: "Sélectionner fichier",
      loadingTitle: "Traitement...",
    },
    selectSheet: {
      title: "Sélectionnez le fichier",
      nextButtonTitle: "Suivant",
      backButtonTitle: "Précédent",
    },
  },
  selectHeaderStep: {
    title: "Sélectionnez l'entête",
    nextButtonTitle: "Suivant",
    backButtonTitle: "Précédent",
  },
  matchColumnsStep: {
    title: "Mappez vos champs",
    nextButtonTitle: "Suivant",
    backButtonTitle: "Précédent",
    userTableTitle: "Votre table",
    templateTitle: "deviendra",
    selectPlaceholder: "Sélectionnez une colonne...",
    ignoredColumnText: "Colonne ignorée",
    subSelectPlaceholder: "Sélectionnez...",
    matchDropdownTitle: "Correspondance",
    unmatched: "valeur sans correspondance",
    duplicateColumnWarningTitle: "Une autre colonne est déselectionnée",
    duplicateColumnWarningDescription: "Les colonnes ne peuvent être dupliquées",
  },
  validationStep: {
    title: "Validez les données",
    nextButtonTitle: "Confirmer",
    backButtonTitle: "Précédent",
    noRowsMessage: "Aucune donnée trouvée",
    noRowsMessageWhenFiltered: "Aucune donnée contenant des erreurs",
    discardButtonTitle: "Supprimer les lignes sélectionnées",
    filterSwitchTitle: "Afficher uniquement les lignes avec erreurs",
  },
  alerts: {
    confirmClose: {
      headerTitle: "Quitter l'import",
      bodyText: "Êtes-vous sûr? Les informations entrées ne seront pas sauvegardées.",
      cancelButtonTitle: "Annuler",
      exitButtonTitle: "Quitter import",
    },
    submitIncomplete: {
      headerTitle: "Erreurs détectées",
      bodyText: "Il y a toujours des lignes contenant des erreurs. Celles-ci seront ignorées lors de la validation.",
      bodyTextSubmitForbidden: "Il y a toujours des lignes contenant des erreurs.",
      cancelButtonTitle: "Annuler",
      finishButtonTitle: "Valider",
    },
    submitError: {
      title: "Erreur",
      defaultMessage: "Une erreur est survenue lors de la validation",
    },
    unmatchedRequiredFields: {
      headerTitle: "Certaines colonnes ne correspondent pas",
      bodyText: "Il y a certaines colonnes qui ne correspondent pas ou sont ignorées. Voulez-vous continuer?",
      listTitle: "Colonnes ne correspondant pas:",
      cancelButtonTitle: "Annuler",
      continueButtonTitle: "Continuer",
    },
    toast: {
      error: "Erreur",
    },
  },
}