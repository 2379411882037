import React, { useEffect } from "react";
import { Layout as RALayout, useGetIdentity, Sidebar } from "react-admin";
import Menu from "./Menu";
import Topbar from "./Topbar";
import { useLocalStorage } from "usehooks-ts";
import api from "../../services/apiWithAuth";
import CustomSidebar from "./Sidebar";
import { useMediaQuery } from "@mui/material";

// React-Admin layout object
const Layout = (props: any) => {
  const [discount, setDiscount] = useLocalStorage("discount", ({} as any) || null);
  const { isLoading, identity } = useGetIdentity();
  const isMobile = !!useMediaQuery((theme: any) => theme.breakpoints.only("xs"));
  useEffect(() => {
    if (discount && !Object.keys(discount).length && !isLoading && identity?.status !== "Collaborateur") {
      const asyncCall = async () => {
        try {
          const { data } = await api.post("/utils/getDiscount", { promoCode: "auto" });
          if (data) {
            setDiscount(data);
          }
        } catch (err) {
          console.log("no auto discount found");
        }
      };
      asyncCall();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discount]);

  return isLoading ? null : (
    <RALayout
      {...props}
      menu={Menu}
      appBar={Topbar}
      sidebar={isMobile ? CustomSidebar : Sidebar}
      sx={{
        "& .RaLayout-contentWithSidebar": {
          paddingTop: "1.9rem",
        },
        ...(identity?.status !== "Collaborateur" && !identity?.firstStepsCompleted && {
          "& .RaSidebar-appBarCollapsed": {
            marginTop: "-8rem",
          },
          "& .RaLayout-contentWithSidebar": {
            paddingTop: ["4rem", "4.8rem"],
          },
        }),
      }}
    />
  );
};

export default Layout;
