import React, { useCallback, useState } from "react";
import {
  useListContext,
  useUnselectAll,
  useRefresh,
  useNotify,
  useCreate,
  FormDataConsumer,
  ReferenceInput,
  useUpdate,
  useDataProvider,
} from "react-admin";
import CreateInDialog from "../../components/react-admin/CreateInDialog";
import FormInput from "../../components/form/FormInput";
import { array, boolean, object, string } from "zod";
import { GroupIcon, PlusIcon, Users2Icon } from "lucide-react";
import { Typography, Box } from "@mui/material";
import { DialogIcon } from "../../components/react-admin/DialogIcon";
import { AutocompleteInput } from "../../components/form/AutocompleteInput";

const formSchema = object({
  name: string().nullish(),
  crmGroupId: string().nullish(),
  users: array(string()),
  company: string(),
  addInExistingGroup: boolean(),
})
  .refine((schema) => (schema.addInExistingGroup ? schema?.crmGroupId?.length > 0 : true), {
    message: "Merci de renseigner un groupe",
    path: ["crmGroupId"],
  })
  .refine((schema) => (!schema.addInExistingGroup ? schema?.name?.length > 0 : true), {
    message: "Nom du groupe à créer requis",
    path: ["name"],
  });

const GroupsUserButton = ({ company }: any) => {
  const notify = useNotify();
  const { selectedIds, data } = useListContext();
  const refresh = useRefresh();
  const unselectAll = useUnselectAll("users");
  //using key for the react-admin dialog component is a hack to close the dialog after submitting
  //without interfering with the isOpen property.
  const [key, setKey] = useState(0);

  const [create] = useCreate();
  const [update] = useUpdate();
  const dataProvider = useDataProvider();
  const save = useCallback(
    async (values: any) => {
      const selectedUsers = data.filter(({ id }) => values.users.includes(id));
      const oneUserAlreadyInGroup = selectedUsers.some((user) => user?.crmGroupId && user.crmGroupId !== "none");
      if (oneUserAlreadyInGroup) {
        notify("Au moins un utilisateur sélectionné appartient déjà à un groupe.", {
          type: "error",
          messageArgs: { smart_count: 1 },
        });
        setKey((key) => key + 1);
        refresh();
        return;
      }
      if (values.addInExistingGroup) {
        try {
          const groupRes = await dataProvider.getOne("groups", { id: values.crmGroupId });
          await update("groups", {
            id: values.crmGroupId,
            data: { users: (groupRes.data.users || []).concat(values.users) },
            previousData: groupRes.data,
          });
          notify(`Utilisateurs ajoutés dans le groupe ${groupRes.data.name}`, {
            type: "success",
            messageArgs: { smart_count: 1 },
          });
          unselectAll();
        } catch (error) {
          notify("Erreur lors de la création du groupe", {
            type: "error",
            messageArgs: { smart_count: 1 },
          });
        }
      } else {
        try {
          await create(
            "groups",
            { data: { name: values.name, users: values.users, company: values.company } },
            { returnPromise: true }
          );
          notify("Groupe créé avec succès", {
            type: "success",
            messageArgs: { smart_count: 1 },
          });
          unselectAll();
        } catch (error) {
          notify("Erreur lors de la création du groupe", {
            type: "error",
            messageArgs: { smart_count: 1 },
          });
        }
      }
      setKey((key) => key + 1);
      refresh();
    },
    [create, data, notify, refresh, unselectAll]
  );

  return (
    <CreateInDialog
      formSchema={formSchema}
      fullWidthFields
      title={
        <Typography variant="bold_xl" ml={0.5}>
          Ajout des utilisateurs sélectionnés dans un groupe
        </Typography>
      }
      label={
        <Typography variant="medium_sm" ml={0.5}>
          Ajouter dans groupe
        </Typography>
      }
      confirmLabel="Ajouter dans groupe"
      resource="groups"
      record={{ company, users: selectedIds }}
      key={key}
      formProps={{ onSubmit: save }}
      buttonType="bulkAction"
      icon={<DialogIcon icon={<Users2Icon size={18} />} text="Ajouter dans groupe" />}
    >
      <FormInput type="checkbox" source="addInExistingGroup" checkboxLabel="Ajouter dans groupe existant" />
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          !formData.addInExistingGroup ? (
            <FormInput label="Nom du nouveau groupe" source="name" required />
          ) : (
            <ReferenceInput
              required
              source="crmGroupId"
              reference="groups"
              sort={{ field: "name", order: "ASC" }}
              filter={{ company }}
              perPage={1000}
            >
              <AutocompleteInput
                label="Groupe"
                optionText="name"
                filterToQuery={(search: any) => ({ name: search })}
                isRequired
              />
            </ReferenceInput>
          )
        }
      </FormDataConsumer>
    </CreateInDialog>
  );
};

export default GroupsUserButton;
