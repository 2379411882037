import { Box, Typography, Divider, useTheme } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { FC, useState, useEffect } from "react";
import { useForm, SubmitHandler, FormProvider, useWatch, useFormContext } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { object, string, literal, union, TypeOf } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import FormInput from "../../components/form/FormInput";
import styled from "@emotion/styled";
import React from "react";
import { browserSessionPersistence, setPersistence } from "firebase/auth";
import api from "../../services/api";
import { COMPANIES_SECTORS, SERVICES, TITLES } from "../../services/common-utils/constants";
import { fbAuthProvider } from "../../services/firebase/firebase.providers";
import { CompanyInput } from "../../components/form/CompanyInput";
import SignContainer from "../../components/others/SignContainer";
import { Building2, Home } from "lucide-react";
import { fbAuth } from "../../services/firebase/firebase.services";

const PrimaryLinkItem = styled(Link)(({ theme }) => ({
  textDecoration: "underline",
  color: theme.palette.layout.link,
  fontWeight: "bold",
}));
const LinkItem = styled(Link)(({ theme }) => ({
  textDecoration: "underline",
  color: theme.palette.common.title,
  fontWeight: "bold",
}));

// Select values
// const COUNTRIES = ["France", "Belgique", "Suisse", "Canada"];
const COUNTRIES = ["France", "Belgique", "Suisse"];
const CountriesToIdLabel = {
  France: "Numéro SIRET",
  Belgique: "Numéro BCE",
  Suisse: "Numéro IDE",
};

const passwordValidation = new RegExp(/^(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&.])[A-Za-z\d@$!%*?&.]{8,}$/);

const signupSchema = object({
  // Company
  company: string().min(1, "Nom de l'entreprise requis"),
  siret: string().min(1, "Siret requis"),
  country: union(COUNTRIES.map((country) => literal(country)) as any),
  companyData: object({
    name: string(),
    address: string(),
    city: string(),
    zipCode: string(),
    siret: string(),
  }),
  vat: string(),
  address: string().min(1, "Adresse requise"),
  zipCode: string().min(1, "Code postal requis"),
  city: string().min(1, "Ville requise"),

  // Account
  firstName: string().min(1, "Prénom requis"),
  lastName: string().min(1, "Nom de famille requis"),
  service: string().min(1, "Service requis"),
  password: string().min(1, "Mot de passe requis").min(8, "Minimum 8 caractères").regex(passwordValidation, {
    message: "Mot de passe invalide",
  }),
  email: string().min(1, "Adresse mail requise").email("Adresse mail invalide"),
  proPhone: string().min(4, "Téléphone requis"),
})
  .refine((schema) => (["France", "Belgique", "Suisse"].includes(schema.country) ? schema.siret.length > 0 : true), {
    message: "SIRET requis",
    path: ["companyData"],
  })
  // .refine(
  //   (schema) =>
  //     ["France", "Guadeloupe", "Saint-Barthelemy"].includes(schema.country) ? schema.siret.length === 14 : true,
  //   {
  //     message: "Le SIRET doit contenir 14 caractères",
  //     path: ["companyData"],
  //   }
  // )
  .refine((schema) => (!["France", "Belgique", "Suisse"].includes(schema.country) ? schema.vat.length > 0 : true), {
    message: "Numéro de TVA intra-communautaire requis",
    path: ["vat"],
  });

// Infer the Schema to get the TS Type
type ISignUp = TypeOf<typeof signupSchema>;

const SignUpPage: FC = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [newProPhone, setNewProPhone] = useState("");
  const [finalCountry, setFinalCountry] = useState("");
  const theme = useTheme();

  //used if customer followed affiliation link
  const queryParameters = new URLSearchParams(window.location.search);
  const firstName = queryParameters.get("firstName");
  const lastName = queryParameters.get("lastName");
  const paramEmail = queryParameters.get("email");
  const companyCountry = queryParameters.get("country");
  const companyProPhone = queryParameters.get("proPhone");
  const referrerCompanyName = queryParameters.get("referrerCompanyName");
  const referrerCompanyId = queryParameters.get("referrerCompanyId");
  const opportunityId = queryParameters.get("opportunityId");
  const campaignId = queryParameters.get("c");

  // Default Values
  const defaultValues: ISignUp = {
    // Company
    company: "",
    country: companyCountry || "France",
    companyData: {
      name: "",
      address: "",
      city: "",
      zipCode: "",
      siret: "",
    },
    siret: "",
    vat: "",
    address: "",
    zipCode: "",
    city: "",

    // Account
    firstName: firstName || "",
    lastName: lastName || "",
    service: "",
    email: paramEmail || "",
    password: "",
    proPhone: companyProPhone || "",
  };

  // The object returned from useForm Hook
  const methods = useForm<ISignUp>({
    resolver: zodResolver(signupSchema),
    defaultValues,
  });

  const [country, siret, vat, companyData, proPhone, email] = useWatch({
    control: methods.control,
    name: ["country", "siret", "vat", "companyData", "proPhone", "email"],
  });

  useEffect(() => {
    // setError("");
    if ((siret === "test@@@" || vat === "test@@@") && !companyData?.siret) {
      const asyncCall = async () => {
        try {
          const {
            data: { fields },
          } = await api.get(`/utils/getSignUpFakeData/${country || "France"}`);
          Object.keys(fields).forEach((field: any) => {
            if (field === "proPhone") {
              setNewProPhone(fields[field]);
            }
            methods.setValue(field, fields[field] || "");
          });
          methods.setValue("siret", "");
        } catch (err) {
          console.log("error creating fake data", err);
        }
      };
      asyncCall();
    }
  }, [country, methods, siret, vat]);

  useEffect(() => {
    setError("");
  }, [email]);

  useEffect(() => {
    if (companyData?.siret && siret !== companyData?.siret) {
      methods.setValue("company", companyData?.name || "");
      methods.setValue("address", companyData?.address || "");
      methods.setValue("city", companyData?.city || "");
      methods.setValue("zipCode", companyData?.zipCode || "");
      methods.setValue("siret", companyData?.siret || "");
      if (country === "France") {
        if (companyData?.zipCode === "97133") {
          setFinalCountry("Saint-Barthelemy");
        } else if (companyData?.zipCode?.substring(0, 3) === "971") {
          setFinalCountry("Guadeloupe");
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyData, methods.setValue]);

  useEffect(() => {
    if (companyProPhone) {
      setNewProPhone(companyProPhone);
      methods.setValue("proPhone", companyProPhone);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (country) {
      setFinalCountry(country);
      methods.setValue("company", "");
      methods.setValue("address", "");
      methods.setValue("city", "");
      methods.setValue("zipCode", "");
      methods.setValue("siret", "");
      methods.setValue("companyData", {
        name: "",
        address: "",
        city: "",
        zipCode: "",
        siret: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country, methods.setValue]);

  // Submit Handler
  const onSubmitHandler: SubmitHandler<ISignUp> = async (values: ISignUp) => {
    setError("");

    // SignUp via firebase provider
    try {
      //Creates the company
      await api.post("/utils/createAccount", {
        company: {
          company: values.company,
          socialReason: values.company,
          country: finalCountry,
          siret: values.siret,
          vat: values.vat,
          type: "Entreprise privée",
          email: values.email.trim().toLowerCase(),
          address: {
            address: values.address,
            additionalAddress: "",
            zipCode: values.zipCode,
            city: values.city,
            country: finalCountry,
          },
          referrerCompanyId: referrerCompanyId || null,
          opportunityId: opportunityId || null,
          campaignId: campaignId || null,
        },
        user: {
          firstName: values.firstName,
          lastName: values.lastName,
          service: values.service,
          email: values.email.trim().toLowerCase(),
          password: values.password,
          proPhone: values.proPhone.trim().replace(/\s+/g, ""),
        },
      });
      await fbAuthProvider.login({ username: values.email, password: values.password });
      navigate("/");
      //! keep it here just in case we go back on it : old version when auth type was passwordless
      // const auth = getAuth();
      // await sendSignInLinkToEmail(auth, values.email, {
      //   url: window.location.href.split("sign-up")[0] + "login",
      //   handleCodeInApp: true,
      // });
      // setEmailForSignIn(values.email);
      // navigate("/login?fromRegister=true");
    } catch (err) {
      if (err.response && err.response.status === 409) {
        setError("Cet utilisateur existe déjà ou votre organisation a déjà été enregistrée");
      } else {
        setError("Enregistrement impossible");
      }
    }
  };

  return (
    <SignContainer
      cardWidth={[0.92, 560, 700]}
      type="signUp"
      BottomAction={() => (
        <Typography sx={{ fontSize: "0.9rem", mb: "1rem" }}>
          Vous avez déjà un compte ? <PrimaryLinkItem to="/login">Je me connecte.</PrimaryLinkItem>
        </Typography>
      )}
    >
      <FormProvider {...methods}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={async (evt) => {
            evt.preventDefault();
            methods.handleSubmit(onSubmitHandler)(evt);
          }}
          sx={{
            width: 1,
            "& .MuiFormControl-root": { width: 1, my: [0.5, 0.5, 0.5, 0.5, 1] },
            "& .MuiAutocomplete-root": { width: 1, "& .MuiFormControl-root": { mx: 0, my: 0 } },
            "& .MuiInputBase-root": { width: 1, my: 0 },
            "& #proPhone-inputLabelPhone": { mb: 0, mt: "auto" },
            "& .MuiButtonBase-root": { py: 0.5 },
            "& .MuiToggleButton-root": { borderRadius: 2 },
            "& .MuiFormControlLabel-root": { width: 1, mt: 1 },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "start",
              pb: 0,
              width: 1,
            }}
          >
            <Typography component="h1" variant="bold_xl" sx={{ textAlign: "left", width: 1 }}>
              Créez votre compte
            </Typography>
            <Typography
              component="h2"
              variant="regular_md"
              sx={{ fontSize: ["0.9rem", "1rem"], textAlign: "left", width: 1, color: "common.text" }}
            >
              Et essayez <b>gratuitement</b> SMS Meeting pendant <b>14 jours</b>, sans carte bancaire.
            </Typography>
            {referrerCompanyName && (
              <Typography
                component="h2"
                variant="regular_md"
                sx={{ fontSize: ["0.9rem", "1rem"], textAlign: "left", width: 1, color: "common.text", mt: 2 }}
              >
                Vous avez suivi un lien d'affiliation pour essayer notre solution SMS Meeting. Merci de vérifier les
                informations pré-remplies et compléter le formulaire.
              </Typography>
            )}
            <Divider sx={{ mb: 1, mt: 2, width: 1 }} />
          </Box>
          <Box display="flex" flexDirection="column" width={1}>
            {/* Enterprise registration */}
            <Typography component="h3" variant="bold_xl" sx={{ width: 1, textAlign: "left", mt: 1, mb: 2 }}>
              Votre organisation
            </Typography>
            <Box display="flex" width={1} sx={{ flexDirection: ["column", "row"], gap: [1, 2] }}>
              <FormInput
                label="Pays du siège social"
                type="select"
                name="country"
                values={COUNTRIES}
                required
                disabled={methods.formState.isSubmitting}
              />
              {["France", "Belgique", "Suisse"].includes(country) ? (
                <CompanyInput
                  name="companyData"
                  label={CountriesToIdLabel[country]}
                  disabled={methods.formState.isSubmitting}
                  required
                  country={country}
                />
              ) : (
                <>
                  <FormInput
                    label={"TVA intra-communautaire"}
                    name="vat"
                    required
                    disabled={methods.formState.isSubmitting}
                  />
                  <FormInput
                    label="Nom organisation"
                    name="company"
                    required
                    disabled={methods.formState.isSubmitting}
                  />
                </>
              )}

              {/* <FormInput
                      label="Activité"
                      type="input"
                      name="sector"
                      values={COMPANIES_SECTORS}
                      select
                      required
                      disabled={methods.formState.isSubmitting}
                    /> */}
            </Box>
            <Box display="flex" sx={{ flexDirection: ["column", "row"], gap: [1, 2] }} width={1}>
              {["France", "Belgique", "Suisse"].includes(country) && companyData?.siret && (
                <Box
                  sx={{
                    py: 1,
                    px: 2,
                    my: 2,
                    display: "flex",
                    flexDirection: ["column", "row"],
                    justifyContent: "flexStart",
                    width: 1,
                    borderLeft: "solid 4px",
                    borderLeftColor: "primary.main",
                    borderRadius: 2,
                    boxShadow: 1,
                    gap: [2, 4],
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flexStart",
                    }}
                  >
                    <Typography fontWeight={"bold"}>
                      <Building2 size={16} /> &nbsp;Raison sociale
                    </Typography>
                    <Typography fontSize={"0.9rem"}>{companyData?.name}</Typography>
                    <Typography fontSize={"0.9rem"}>{companyData?.siret}</Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flexStart",
                    }}
                  >
                    <Typography fontWeight={"bold"}>
                      <Home size={16} /> &nbsp;Adresse
                    </Typography>
                    <Typography fontSize={"0.9rem"}>{companyData?.address}</Typography>
                    <Typography fontSize={"0.9rem"}>
                      {companyData?.zipCode} {companyData?.city}
                    </Typography>
                  </Box>
                </Box>
              )}
              {!["France", "Belgique", "Suisse"].includes(country) && (
                <>
                  <FormInput label="Adresse" name="address" required disabled={methods.formState.isSubmitting} />
                  <FormInput label={"Code postal"} name="zipCode" required disabled={methods.formState.isSubmitting} />
                  <FormInput label="Ville" name="city" required disabled={methods.formState.isSubmitting} />
                </>
              )}
            </Box>
          </Box>
          <Divider sx={{ width: 1, mb: 1, mt: 2 }} />
          <Box display="flex" flexDirection="column" width={1}>
            <Typography component="h3" variant="bold_xl" sx={{ width: 1, textAlign: "left", mt: 1, mb: 2 }}>
              Vos Informations
            </Typography>
            <Box display="flex" sx={{ flexDirection: ["column", "row"], gap: [1, 2] }} width={1}>
              {/* Enterprise registration */}
              <FormInput label="Nom" name="lastName" required disabled={methods.formState.isSubmitting} />
              <FormInput label="Prénom" name="firstName" required disabled={methods.formState.isSubmitting} />
            </Box>
            <Box display="flex" sx={{ flexDirection: ["column", "row"], gap: [1, 2] }} width={1}>
              <FormInput
                label="Service"
                type="select"
                name="service"
                values={SERVICES}
                select
                autoComplete="off"
                required
                disabled={methods.formState.isSubmitting}
                placeholder="choisissez..."
              />
              <FormInput
                label="Ligne directe"
                name="proPhone"
                type="phone"
                autoComplete="off"
                phone
                country={finalCountry}
                required
                newPhoneValue={newProPhone}
                disabled={methods.formState.isSubmitting}
              />
            </Box>
            <Divider sx={{ my: 2 }} />
            <Box display="flex" sx={{ flexDirection: ["column", "row"], gap: [1, 2] }} width={1}>
              <FormInput
                label="Adresse email"
                name="email"
                type="email"
                autoComplete="off"
                required
                disabled={methods.formState.isSubmitting}
                helperText="Vous devez avoir accès à cette adresse email"
                placeholder="mail@domaine.com"
              />
              <FormInput
                label="Mot de passe"
                name="password"
                defaultValue=""
                autoComplete="new-password"
                type="password"
                required
                disabled={methods.formState.isSubmitting}
                helperText="Doit contenir au minimum 1 minuscule, 1 chiffre et un caractère spécial"
                placeholder="*************"
              />
            </Box>
          </Box>
          {error && (
            <Typography variant="bold_sm" color="error.main" sx={{ my: 2 }}>
              {error}
            </Typography>
          )}
          <Typography variant="regular_sm" color="common.text" sx={{ mt: 4, mb: 0.5, width: 1 }}>
            En cliquant sur m'inscrire vous acceptez les{" "}
            <LinkItem to="https://sms-meeting.com/cgs/" target="_blank" rel="noopener noreferrer">
              Conditions Générales de Service
            </LinkItem>
          </Typography>
          <LoadingButton
            loading={methods.formState.isSubmitting}
            type="submit"
            variant="contained"
            size="large"
            sx={{
              marginInline: "auto",
              width: 1,
            }}
          >
            <Typography variant="medium_lg">M'inscrire</Typography>
          </LoadingButton>
          {/* <Stack sx={{ mt: "1rem", textAlign: "center" }}>
            <Typography sx={{ fontSize: "0.9rem", mb: "1rem" }}>
              Vous avez déjà un compte ? <LinkItem to="/login">Connectez-vous</LinkItem>
            </Typography>
          </Stack> */}
        </Box>
      </FormProvider>
    </SignContainer>
    //     </Grid>
    //   </Box>
    // </Container>
  );
};

export default SignUpPage;
