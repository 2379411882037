import React, { useCallback, useEffect, useState } from "react";
import { Menu } from "@react-admin/ra-rbac";
import { useGetIdentity, useLogout, useTheme as useRaTheme } from "react-admin";
import {
  Typography,
  useMediaQuery,
  MenuItem,
  useTheme,
  ListItemText,
  ListItemIcon,
  Box,
  Button,
  Card,
} from "@mui/material";
import {
  Building2,
  CreditCard,
  Folder,
  Gift,
  LogOut,
  Moon,
  ShoppingBag,
  SlidersHorizontal,
  Sun,
  Unlock,
  User,
  Users,
} from "lucide-react";
import FormInput from "../form/FormInput";
import { useForm, FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import AffiliationButton from "../../pages/dashboard/AffiliationButton";

const AccountMenuItems = ({ closeMenu, isMobile = false }: any) => {
  const { data: identity } = useGetIdentity();
  const [raTheme, setRaTheme] = useRaTheme();
  const theme = useTheme();
  const logout = useLogout();
  const methods = useForm();
  const navigate = useNavigate();
  const [affiliationItemClicked, setAffiliationItemClicked] = useState(false);

  // useEffect(() => {
  //   if ((!open && !isMobile) || (open && isMobile)) {
  //     setOpen(!open);
  //   }
  // }, [open, isMobile, setOpen]);

  return (
    <>
      {isMobile && (
        <>
          <Box
            sx={{
              backgroundColor: "button.secondary.contained.background",
              color: "button.secondary.contained.content",
              display: "flex",
              alignItems: "center",
              px: 1,
              height: "35px",
              borderRadius: 2,
              width: "fit-content",
            }}
          >
            <Box sx={{ width: 18, mr: 1 }}>
              <Building2 size={18} />
            </Box>
            <Typography variant="medium_sm" sx={{ whiteSpace: "nowrap" }}>
              {identity?.status === "Collaborateur" ? "Metaventus" : identity?.companyName}
            </Typography>
          </Box>
          {identity?.freeTry?.daysTillFreeTryEnd > 0 && identity?.freeTry?.licenseConverted === false && (
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "start",
                borderLeft: "4px solid",
                borderLeftColor: "primary.main",
                px: 2,
                py: 1,
                width: "calc(100vw - 5rem)",
                borderRadius: "8px",
                gap: 1,
                mb: 2,
              }}
            >
              <Typography variant="medium_sm" color="common.title">
                {identity?.freeTry.daysTillFreeTryEnd === 1
                  ? "1 jour d'essai restant"
                  : `${identity?.freeTry.daysTillFreeTryEnd} jours d'essai restants`}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  width: 1,
                }}
                onClick={() => {
                  navigate("/myOffer");
                  closeMenu();
                }}
              >
                <Typography variant="medium_sm">Acheter maintenant</Typography>
              </Button>
            </Card>
          )}
        </>
      )}

      {/* waiting to have multilingual support */}
      {/* <FormProvider {...methods}>
        <Box
          component="form"
          sx={{
            "& .MuiFormControl-root": { mb: 2, ml: [0, 2] },
            "& .MuiInputBase-root": { width: [150, 250], height: "auto" },
          }}
        >
          <FormInput
            label="Langue"
            type="select"
            name="service"
            values={["Français"]}
            defaultValue={"Français"}
            select
            autoComplete="off"
            placeholder="choisissez..."
            hideSelectInsideLabel
            filledSecondary={isMobile}
          />
        </Box>
      </FormProvider> */}

      {/* To put in top left menu */}
      {identity?.status === "Collaborateur" ? (
        <Menu.Item
          action="read"
          resource="collaborators"
          to={"/collaborators/" + identity?.id}
          primaryText="Mon profil"
          leftIcon={<User color={theme.palette.common.text} size={16} />}
          onClick={closeMenu}
        />
      ) : (
        <Menu.Item
          action="read"
          resource="users"
          to={"/users/" + identity?.id}
          primaryText="Mon profil"
          leftIcon={<User color={theme.palette.common.text} size={16} />}
          onClick={closeMenu}
        />
        // <MenuItemLink
        //   to={"/users/" + identity?.id}
        //   state={{ _scrollToTop: true }}
        //   primaryText="Mon profil"
        //   leftIcon={<User />}
        //   onClick={() => {
        //     console.log("clicked");
        //     setOpen(false);
        //   }}
        // />
      )}

      {["Revendeur", "Client", "Indirect"].includes(identity?.status) && (
        <Menu.Item
          action="read"
          resource="myOffer"
          to="/myOffer"
          primaryText="Mon offre"
          leftIcon={<CreditCard color={theme.palette.common.text} size={16} />}
          onClick={closeMenu}
        />
      )}

      {["Revendeur", "Client", "Indirect"].includes(identity?.status) && (
        <MenuItem
          onClick={() => {
            setAffiliationItemClicked(true);
          }}
        >
          <ListItemIcon>
            <Gift color={theme.palette.common.text} size={16} />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="medium_sm" color="common.text">
              Parrainage
            </Typography>
          </ListItemText>
          <Box sx={{ visibility: "hidden" }}>
            <AffiliationButton
              clicked={affiliationItemClicked}
              closeMenu={closeMenu}
            />
          </Box>
        </MenuItem>
      )}

      {["Client", "Revendeur", "Indirect"].includes(identity?.status) && (
        <Menu.Item
          action="read"
          resource="companies"
          to={"/companies/" + identity?.company}
          primaryText="Paramètres"
          leftIcon={<SlidersHorizontal color={theme.palette.common.text} size={16} />}
          onClick={closeMenu}
        />
      )}
      {["Client", "Revendeur", "Indirect"].includes(identity?.status) && (
        <Menu.Item
          action="read"
          resource="users"
          to={"/users/" + identity?.id + "/1"}
          primaryText="Sécurité"
          leftIcon={<Unlock color={theme.palette.common.text} size={16} />}
          onClick={closeMenu}
        />
      )}
      <MenuItem
        onClick={() => {
          setRaTheme(raTheme === "dark" ? "light" : "dark");
          closeMenu();
        }}
      >
        <ListItemIcon>
          {raTheme === "dark" ? (
            <Sun color={theme.palette.common.text} size={16} />
          ) : (
            <Moon color={theme.palette.common.text} size={16} />
          )}
        </ListItemIcon>
        <ListItemText>
          <Typography variant="medium_sm">Mode {raTheme === "dark" ? "jour" : "nuit"}</Typography>
        </ListItemText>
      </MenuItem>
      <MenuItem onClick={() => logout()}>
        <ListItemIcon>
          <LogOut color={theme.palette.common.text} size={16} />
        </ListItemIcon>
        <ListItemText>
          <Typography variant="medium_sm" color="common.text">
            Déconnexion
          </Typography>
        </ListItemText>
      </MenuItem>
    </>
  );
};

export default AccountMenuItems;
