import { Box, Button, IconButton, Tooltip, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import {
  SaveButton,
  Toolbar,
  DeleteWithConfirmButton,
  ReferenceInput,
  FormDataConsumer,
  FunctionField,
  useRecordContext,
  useGetIdentity,
  TextField,
  TabbedFormTabs,
  SimpleForm,
  CreateButton,
} from "react-admin";
import { Edit, IfCanAccess, TabbedForm, FormTab } from "@react-admin/ra-rbac";
import {
  COUNTRIES,
  COMPANIES_STATUSES,
  COMPANIES_SOURCES,
  COMPANIES_ACCOUNT_STATUSES,
  TYPES,
  COMPANIES_SECTORS,
} from "../../services/common-utils/constants";
import UsersList from "../users/UsersList";
import GroupsList from "../groups/GroupsList";
import SubscriptionsList from "../subscriptions/SubscriptionsList";
import LicensesList from "../licenses/LicensesList";
import TemplatesList from "../templates/TemplatesList";
import SmsList from "../sms/SmsList";
import PaymentMethodsList from "../paymentMethods/PaymentMethodsList";
import ContactsOrContactLists from "../contactsOrContactLists/ContactsOrContactLists";
import PageTitle from "../../components/navigation/PageTitle";
import FormInput from "../../components/form/FormInput";
import { zodResolver } from "@hookform/resolvers/zod";
import { boolean, object, string } from "zod";
import { AutocompleteInput } from "../../components/form/AutocompleteInput";
import TabNav from "../../components/navigation/TabNav";
import { companyAccountSchema, companyDataSchema } from "./ICompany";
import { CreditCard, UserCircle, UserIcon } from "lucide-react";
import { fbAuthProvider } from "../../services/firebase/firebase.providers";
import api from "../../services/apiWithAuth";
import { useNavigate } from "react-router-dom";
import UsersCreate from "../users/UsersCreate";
import ContactsCreate from "../contacts/ContactsCreate";
import CreditsPage from "../credits/CreditsPage";
import CreateTransfer from "../credits/CreateTransfer";

const tabs = (identity, id) => {
  const isSelfCompany = !(
    identity?.status === "Collaborateur" ||
    (identity?.status === "Revendeur" && !window.location.pathname.includes(identity?.company))
  );
  return [
    {
      name: !isSelfCompany ? "Détails" : "Mon organisation",
      value: "1",
      display: true,
      component: (
        <SimpleForm resolver={zodResolver(companyDataSchema)} toolbar={<CompaniesToolBar identity={identity} />}>
          <Box sx={{ display: "grid", gridTemplateColumns: ["1fr", "1fr", "1fr 1fr"], gap: [0, "0 2rem"] }}>
            <FormDataConsumer>
              {({ formData, ...rest }) => (
                <FormInput
                  defaultValue={null}
                  source="company"
                  label="Nom de l'organisation"
                  disabled={
                    !(
                      identity?.status === "Collaborateur" ||
                      (identity?.status === "Revendeur" && formData.id !== identity?.company)
                    )
                  }
                />
              )}
            </FormDataConsumer>
            <FormDataConsumer>
              {({ formData, ...rest }) => (
                <FormInput
                  type="select"
                  defaultValue={null}
                  source="type"
                  label="Nature de l'organisation"
                  values={TYPES.map((s) => ({ id: s, label: s }))}
                  disabled={
                    !(
                      identity?.status === "Collaborateur" ||
                      (identity?.status === "Revendeur" && formData.id !== identity?.company)
                    )
                  }
                />
              )}
            </FormDataConsumer>
            <FormInput required defaultValue={null} source="address.address" label="Adresse" />
            <FormInput defaultValue={null} source="address.zipCode" label="Code postal" required />
            <FormInput defaultValue={null} source="address.city" label="Ville" required />
            <FormDataConsumer>
              {({ formData, ...rest }) => (
                <FormInput
                  type="select"
                  required
                  defaultValue="France"
                  source="address.country"
                  label="Pays"
                  values={COUNTRIES.map((s) => ({ id: s, label: s }))}
                  disabled={
                    !(
                      identity?.status === "Collaborateur" ||
                      (identity?.status === "Revendeur" && formData.id !== identity?.company)
                    )
                  }
                />
              )}
            </FormDataConsumer>
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.address &&
                formData.address.country &&
                formData.address.country === "France" && (
                  <FormInput
                    defaultValue={null}
                    required
                    source="siret"
                    label="SIRET"
                    disabled={
                      !(
                        identity?.status === "Collaborateur" ||
                        (identity?.status === "Revendeur" && formData.id !== identity?.company)
                      )
                    }
                  />
                )
              }
            </FormDataConsumer>
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.address &&
                formData.address.country &&
                formData.address.country !== "France" && (
                  <FormInput
                    defaultValue={null}
                    source="vat"
                    label="Numéro de TVA Intracommunautaire"
                    disabled={
                      !(
                        identity?.status === "Collaborateur" ||
                        (identity?.status === "Revendeur" && formData.id !== identity?.company)
                      )
                    }
                  />
                )
              }
            </FormDataConsumer>
            <FormInput
              type="select"
              defaultValue={null}
              source="sector"
              label="Activité"
              values={COMPANIES_SECTORS.map((s) => ({ id: s, label: s }))}
            />
            <FormInput type="phone" defaultValue={null} source="phone" label="Numéro de téléphone" />
            <FormInput defaultValue="" source="email" label="Adresse email" required />
          </Box>
        </SimpleForm>
      ),
    },
    {
      name: "Compte",
      value: "2",
      display: identity?.status === "Collaborateur",
      component: (
        <SimpleForm resolver={zodResolver(companyAccountSchema)}>
          <Typography variant="medium_xl" mb={1}>
            Statut du compte
          </Typography>
          <Box sx={{ display: "grid", gridTemplateColumns: ["1fr", "1fr", "1fr 1fr"], gap: 2 }}>
            <FormInput source="id" label="Identifiant" disabled />
            <FormInput
              type="select"
              defaultValue={null}
              source="status"
              label="Statut"
              values={COMPANIES_STATUSES.map((s) => ({ id: s, label: s }))}
            />
            <FormInput type="checkbox" source="prospect" label="Prospect" defaultValue={false} />
            <FormDataConsumer>
              {({ formData, ...rest }: any) =>
                formData.status &&
                formData.status === "Indirect" && (
                  <ReferenceInput
                    required
                    source="parentCompany"
                    reference="companies"
                    sort={{ field: "company", order: "ASC" }}
                    perPage={1000}
                  >
                    <AutocompleteInput
                      label="Propriétaire du compte"
                      optionText="company"
                      filterToQuery={(search: any) => ({ company: search })}
                    />
                  </ReferenceInput>
                )
              }
            </FormDataConsumer>
            <FormInput
              type="select"
              defaultValue={"Actif"}
              source="accountStatus"
              label="Statut du compte"
              values={COMPANIES_ACCOUNT_STATUSES.map((s) => ({ id: s, label: s }))}
            />
            <FormInput type="date" defaultValue={null} source="clientSince" label="Client depuis le" />
            <ReferenceInput required source="provider_id" reference="providers" perPage={1000}>
              {/* <FormInput
    type="select"
    label="Fournisseur SMS"
    optionText="name"
  /> */}
              <AutocompleteInput
                defaultValue={process.env.REACT_APP_DEFAULT_PROVIDER_ID}
                label="Fournisseur SMS"
                optionText="name"
                filterToQuery={(search: any) => ({ name: search })}
              />
            </ReferenceInput>
          </Box>
          <Typography variant="medium_xl" mb={1} mt={4}>
            Gestion Commerciale
          </Typography>
          <Box sx={{ display: "grid", gridTemplateColumns: ["1fr", "1fr", "1fr 1fr"], gap: 2 }}>
            <FormInput
              type="select"
              defaultValue={null}
              source="source"
              label="Source"
              values={COMPANIES_SOURCES.map((s) => ({ id: s, label: s }))}
            />
            <ReferenceInput label="Manager du compte" source="accountManager" reference="collaborators">
              <AutocompleteInput
                label="Manager du compte"
                optionText={(record) => {
                  return `${record.firstName} ${record.lastName} `;
                }}
                filterToQuery={(search: any) => ({ multi_firstName_lastName: search })}
              />
            </ReferenceInput>
          </Box>
        </SimpleForm>
      ),
    },
    {
      name: "Utilisateurs",
      value: "3",
      display: !isSelfCompany,
      component: <UsersList disableSyncWithLocation resource={"users"} company={id} group_id={id} />,
    },
    {
      name: "Groupes",
      value: "4",
      display: !isSelfCompany,
      component: <GroupsList disableSyncWithLocation resource={"groups"} company={id} group_id={id} />,
    },
    {
      name: "Abonnements",
      value: "5",
      display: identity?.status === "Collaborateur",
      component: <SubscriptionsList disableSyncWithLocation resource={"subscriptions"} company={id} group_id={id} />,
    },
    {
      name: "Licences",
      value: "6",
      display: !isSelfCompany,
      component: <LicensesList disableSyncWithLocation resource={"licenses"} company={id} group_id={id} />,
    },
    {
      name: "Transferts crédits",
      value: "11",
      display: !isSelfCompany,
      component: <CreditsPage disableSyncWithLocation company={id} />,
    },
    {
      name: "Modèles",
      value: "7",
      display: !isSelfCompany,
      component: <TemplatesList disableSyncWithLocation resource={"templates"} company={id} group_id={id} />,
    },
    // {
    //   name: "Contacts",
    //   value: "8",
    //   display: identity?.status === "Collaborateur",
    //   component: <ContactsOrContactLists resource={"ContactsOrContactLists"} company={id} group_id={id} />,
    // },
    {
      name: "Journal d'envoi",
      value: "9",
      display: identity?.status === "Collaborateur",
      component: <SmsList disableSyncWithLocation resource={"sms"} company={id} group_id={id} />,
    },
    // {
    //   name: "Moyens de paiement",
    //   value: "10",
    //   display: identity?.status === "Collaborateur",
    //   component: <PaymentMethodsList resource="paymentMethods" company={id} group_id={id} />,
    // },
  ].filter((tab) => tab.display);
};

// const resourceTabs = [
//   { resource: "users", label: "Utilisateurs", Component: ({ ...props }) => <UsersList {...props} /> },
//   { resource: "groups", label: "Groupes", Component: ({ ...props }) => <GroupsList {...props} /> },
//   { resource: "subscriptions", label: "Abonnements", Component: ({ ...props }) => <SubscriptionsList {...props} /> },
//   { resource: "licenses", label: "Licences", Component: ({ ...props }) => <LicensesList {...props} /> },
//   { resource: "templates", label: "Modèles", Component: ({ ...props }) => <TemplatesList {...props} /> },
//   {
//     resource: "contactsOrContactLists",
//     label: "Contacts",
//     Component: ({ ...props }) => <ContactsOrContactLists {...props} />,
//   },
//   { resource: "sms", label: "Statistiques SMS", Component: ({ ...props }) => <SmsList {...props} /> },
// ];

const CompaniesToolBar = ({ ...props }) => {
  const record = useRecordContext();
  // split length === 3 stuff is a hack to allow toolbar for main path (edit path), because if we put path on each formtab no default tab
  return !(
    /address|account/.test(window.location.pathname) || window.location.pathname.split("/").length === 3
  ) ? null : (
    <Toolbar sx={{ gap: 2 }}>
      <SaveButton label="Sauvegarder" />
      <IfCanAccess action="delete" record={record.id}>
        {window.location.pathname.includes("account") && (
          <DeleteWithConfirmButton
            disabled={props.identity?.role !== "Super Admin"}
            confirmTitle={`Suppression de l'organisation ${record.company.toUpperCase()}`}
            confirmContent="Êtes-vous sur de vouloir supprimer cette organisation? Toutes les données rattachées seront supprimées."
          />
        )}
      </IfCanAccess>
    </Toolbar>
  );
};

const Tabs = ({ identity, setCurrentTab, ...props }: any) => {
  const record = useRecordContext();
  return <TabNav tabs={tabs(identity, record.id)} setCurrentTab={setCurrentTab} {...props} />;
};

const CompanyTitle = ({ identity, currentTab, transfersInstanceKey, setTransfersInstanceKey }: any) => {
  const record = useRecordContext();
  const navigate = useNavigate();
  const theme = useTheme();
  const isSelfCompany = !(
    identity?.status === "Collaborateur" ||
    (identity?.status === "Revendeur" && !window.location.pathname.includes(identity?.company))
  );

  async function logAs() {
    const { data } = await api.post("utils/getAdminToken", { companyId: record.id });
    await fbAuthProvider.login({
      token: data.token,
    });
    navigate(0);
  }
  return isSelfCompany ? (
    <PageTitle text={"Paramètres"} type="list" resource="companies" breadcrumbListLabel="Paramètres" />
  ) : (
    <Box sx={{ display: "flex", alignItems: "center", width: 1 }}>
      <PageTitle
        text={record.company}
        type="edit"
        resource="companies"
        breadcrumbListLabel="Liste des clients"
        createButton={
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            {
              // identity?.status === "Collaborateur" || identity?.status === "Revendeur" ? ( //to put back when problem solved with logas
              identity?.status === "Collaborateur" ? (
                <Tooltip title={"Se connecter avec le compte du client"} arrow>
                  <Button variant="contained" color="neutral" onClick={logAs} sx={{ px: 2, py: 1 }}>
                    <Typography
                      variant="regular_sm"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <UserCircle size={18} />
                      Se connecter
                    </Typography>
                  </Button>
                </Tooltip>
              ) : null
            }
            {currentTab === "3" && <UsersCreate record={{ company: record.id }} />}
            {currentTab === "4" && <CreateButton label="Nouveau groupe" resource="groups" />}
            {currentTab === "5" && identity?.status === "Collaborateur" && (
              <CreateButton label="Nouvel abonnement" resource="subscriptions" />
            )}
            {currentTab === "11" && (
              <CreateTransfer
                instanceKey={transfersInstanceKey}
                setInstanceKey={setTransfersInstanceKey}
                company={record.id}
              />
            )}
            {currentTab === "6" && identity?.status !== "Collaborateur" && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  navigate("/myOffer");
                }}
                sx={{ px: 2, py: 1 }}
              >
                <Typography
                  variant="regular_sm"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <CreditCard size={18} />
                  Acheter
                </Typography>
              </Button>
            )}
            {currentTab === "7" && <CreateButton label="Nouveau modèle" resource="templates" />}
            {currentTab === "8" && <ContactsCreate record={{ group_id: record.id }} />}
            {currentTab === "10" && <CreateButton label="Nouveau mode de paiement" resource="paymentMethods" />}
          </Box>
        }
      />
    </Box>
  );
};

const CompaniesEdit = ({ ...props }) => {
  const { data: identity, isLoading } = useGetIdentity();
  const [currentTab, setCurrentTab] = useState("");
  const [transfersInstanceKey, setTransfersInstanceKey] = useState(0);

  const onTransform = (data: any) => {
    if (!data.accountManager) {
      delete data.accountManager;
    }
    return {
      ...data,
      country: data?.address?.country || data?.country || null,
    };
  };

  const isSelected = (tab: string) => window.location.pathname.includes(tab);

  return isLoading ? null : (
    <>
      <Edit {...props} transform={onTransform} hasShow={false} sx={{ "& .MuiTabPanel-root": { p: 0 } }}>
        <CompanyTitle
          identity={identity}
          currentTab={currentTab}
          transfersInstanceKey={transfersInstanceKey}
          setTransfersInstanceKey={setTransfersInstanceKey}
        />
        <Tabs
          identity={identity}
          setCurrentTab={setCurrentTab}
          transfersInstanceKey={transfersInstanceKey}
          setTransfersInstanceKey={setTransfersInstanceKey}
        />
        {/* <TabbedForm
          tabs={<TabbedFormTabs indicatorColor="primary" />}
          toolbar={<CompaniesToolBar identity={identity} />}
          sx={{ "& .MuiTabs-root": { "& .MuiTypography-root": { fontSize: "0.9rem" } } }}
          resolver={zodResolver(formSchema)}
        >
          {resourceTabs.map(({ resource, label, Component }: any) => {
            return (
              <FormTab
                key={label}
                path={resource}
                label={<Typography sx={[isSelected(resource) && { color: "primary.main" }]}>{label}</Typography>}
                name={resource}
              >
                {isSelected(resource) && (
                  <FunctionField
                    sx={{ width: "100%" }}
                    render={({ id }: any) => {
                      return <Component resource={resource} company={id} group_id={id} />;
                    }}
                  />
                )}
              </FormTab>
            );
          })}
          <FormTab
            path="paymentMethods"
            label={
              <Typography sx={[isSelected("paymentMethods") && { color: "primary.main" }]}>
                Moyens de paiement
              </Typography>
            }
            name="paymentMethods"
          >
            {isSelected("paymentMethods") && (
              <FunctionField
                sx={{ width: "100%" }}
                render={({ id }: any) => {
                  return <PaymentMethodsList resource="paymentMethods" {...props} company={id} />;
                }}
              />
            )}
          </FormTab> 
        </TabbedForm>*/}
      </Edit>
    </>
  );
};

export default CompaniesEdit;
