import * as React from "react";
import { Box, Drawer, IconButton, useTheme } from "@mui/material";
import { SidebarClasses, useSidebarState } from "react-admin";
import { X } from "lucide-react";
import { useEffect } from "react";

const Sidebar = ({ children }) => {
  const [open, setOpen] = useSidebarState();
  const closeMenu = () => setOpen(false);
  const theme = useTheme();
  const logo = require("../../assets/logo.svg").default as string;

  useEffect(() => {
    setOpen(false);
  }, []);

  return (
    <Drawer
      variant="temporary"
      open={open}
      onClose={closeMenu}
      classes={SidebarClasses}
      PaperProps={{
        sx: { width: 1, backgroundColor: "common.background" },
      }}
    >
      <Box sx={{ p: "1.5rem" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: 1 }}>
          <Box sx={{ height: 35 }}>
            <img
              src={logo}
              alt="digisoft"
              className="digisoft-logo"
              style={{ width: "auto", height: "100%", margin: 0 }}
            />
          </Box>
          <IconButton
            sx={{ p: 1, bgcolor: "button.neutral.contained.background", borderRadius: 2 }}
            onClick={closeMenu}
          >
            <X size={18} color={theme.palette.button.neutral.contained.content} />
          </IconButton>
        </Box>
        {children}
      </Box>
    </Drawer>
  );
};

export default Sidebar;
