import React, { useState, useEffect } from "react";
import { useListContext, useUnselectAll, useNotify, useRefresh } from "react-admin";
import api from "../../services/apiWithAuth";
import { MailIcon } from "lucide-react";
import { Button, Typography } from "@mui/material";

const LicensesBulkSendReminder = ({ company, identity }: any) => {
  const { selectedIds, data: licenses } = useListContext();
  const [isLoading, setIsLoading] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll("licenses");

  const sendReminderEmail = async () => {
    setIsLoading(true);
    const selectedItems = selectedIds
      .map((id: any) => {
        const license = licenses.find((license) => id === license.id);
        return license;
      })
      .filter((license) => license !== undefined);
    const notAssignedLicense = selectedItems.some((license: any) => !license.email);
    if (notAssignedLicense) {
      setIsLoading(false);
      notify("Une des licences sélectionnées n'est affectée à aucun utilisateur", { type: "error" });
      return;
    }
    const connectedLicense = selectedItems.some((license: any) => license.connections?.length > 0);
    if (connectedLicense) {
      setIsLoading(false);
      notify("Une des licences sélectionnées possède une connection active au complément.", { type: "error" });
      return;
    }
    try {
      const response = await api.post("/licenses/sendReminder", {
        licenseIds: selectedIds,
      });
      setIsLoading(false);
      refresh();
      notify("Envoi du mail de relance à " + response.data.length + " utilisateurs de licence effectué");
      unselectAll();
    } catch (err) {
      setIsLoading(false);
      unselectAll();
      notify("Erreur lors de l'envoi des emails de relance aux licences sélectionnées", { type: "error" });
    }
  };

  return (
    <Button disabled={isLoading} onClick={sendReminderEmail}>
      <MailIcon size={18} />
      <Typography variant="medium_sm" ml={0.5}>
        Envoyer relance utilisation licence
      </Typography>
    </Button>
  );
};

export default LicensesBulkSendReminder;
