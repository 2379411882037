import { createTheme, alpha, darken, lighten } from "@mui/material";
import { defaultTheme } from "react-admin";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/700.css";

const colors = {
  gray: {
    50: "#f8fafc",
    100: "#f1f5f9",
    200: "#e2e8f0",
    300: "#cbd5e1",
    400: "#94a3b8",
    500: "#64748b",
    600: "#475569",
    700: "#334155",
    800: "#1e293b",
    900: "#0f172a",
    950: "#020617",
  },
  orange: {
    50: "#fff7ed",
    100: "#ffedd5",
    200: "#fed7aa",
    300: "#fdba74",
    400: "#fb923c",
    500: "#f97316",
    600: "#ea580c",
    700: "#c2410c",
    800: "#9a3412",
    900: "#7c2d12",
  },
  red: {
    50: "#fef2f2",
    100: "#fee2e2",
    200: "#fecaca",
    300: "#fca5a5",
    400: "#f87171",
    500: "#ef4444",
    600: "#dc2626",
    700: "#b91c1c",
    800: "#991b1b",
    900: "#7f1d1d",
  },
  brand: {
    50: "#eafbf0",
    100: "#d9f7e1",
    200: "#aeefc1",
    300: "#88e7a3",
    400: "#5ddf82",
    500: "#36d764",
    600: "#24b34c",
    700: "#1b883a",
    800: "#125926",
    900: "#154523",
  },
  green: {
    50: "#ecfdf5",
    100: "#d1fae5",
    200: "#a7f3d0",
    300: "#6ee7b7",
    400: "#34d399",
    500: "#10b981",
    600: "#059669",
    700: "#047857",
    800: "#065f46",
    900: "#064e3b",
  },
  white: "#fff",
};

const { gray, orange, red, brand, green, white } = colors;

const MuiTheme = (mode = "light", isMobile = false) => {
  const light = mode === "light";
  return {
    ...defaultTheme,
    sidebar: {
      width: isMobile ? "100%" : 320,
    },
    palette: {
      ...defaultTheme.palette,
      mode: mode,
      ...colors,
      primary: {
        main: light ? brand[600] : brand[300],
        contrastText: light ? brand[50] : gray[900],
      },
      secondary: {
        main: light ? brand[300] : brand[300],
        contrastText: light ? brand[900] : brand[900],
      },
      neutral: {
        main: light ? gray[200] : gray[700],
        contrastText: light ? gray[900] : gray[50],
      },
      success: {
        main: light ? green[700] : green[300],
        contrastText: light ? green[50] : green[900],
      },
      error: {
        main: light ? red[700] : red[300],
        contrastText: light ? red[50] : red[900],
      },
      card: {
        background: light ? white : gray[800],
        stroke: light ? brand[600] : brand[300],
      },
      switch: {
        background: light ? brand[100] : brand[800],
        disabledBackground: light ? gray[300] : gray[100],
      },
      bankCard: {
        background: light ? gray[100] : gray[700],
      },
      common: {
        title: light ? gray[800] : gray[100],
        text: light ? gray[600] : gray[300],
        background: light ? gray[50] : gray[900],
      },
      input: {
        label: light ? gray[800] : gray[100],
        background: light ? white : gray[800],
        backgroundFilled: light ? gray[100] : gray[900],
        stroke: light ? gray[300] : gray[600],
        placeholder: light ? gray[400] : gray[500],
        required: light ? red[600] : red[300],
        helper: light ? gray[500] : gray[400],
        icon: light ? gray[400] : gray[500],
      },
      checkbox: {
        backgroundActive: light ? brand[600] : brand[500],
        content: light ? brand[50] : gray[900],
      },
      progressBar: {
        background: light ? gray[300] : gray[700],
        value: light ? brand[600] : brand[300],
      },
      layout: {
        topBarBackground: light ? white : gray[950],
        topBarDivider: light ? gray[200] : gray[800],
        subTopBarBackground: light ? brand[50] : gray[800],
        divider: light ? gray[300] : gray[600],
        link: light ? brand[600] : brand[300],
      },
      button: {
        primary: {
          contained: {
            background: light ? brand[600] : brand[500],
            content: light ? brand[50] : gray[900],
          },
          outlined: {
            background: light ? gray[50] : gray[900],
            content: light ? brand[700] : brand[400],
            stroke: light ? brand[700] : brand[400],
          },
        },
        secondary: {
          contained: {
            background: light ? brand[300] : brand[300],
            content: light ? brand[900] : brand[900],
          },
          outlined: {
            background: light ? gray[50] : gray[900],
            content: light ? brand[500] : brand[200],
            stroke: light ? brand[500] : brand[200],
          },
        },
        neutral: {
          contained: {
            background: light ? gray[200] : gray[700],
            content: light ? gray[900] : gray[50],
          },
          outlined: {
            background: light ? gray[50] : gray[900],
            content: light ? gray[500] : gray[100],
            stroke: light ? gray[500] : gray[100],
          },
        },
        disabled: {
          contained: {
            background: light ? gray[200] : gray[700],
            content: light ? gray[500] : gray[400],
          },
          outlined: {
            background: light ? gray[50] : gray[900],
            content: light ? gray[400] : gray[500],
            stroke: light ? gray[400] : gray[500],
          },
        },
        success: {
          contained: {
            background: light ? green[700] : green[300],
            content: light ? green[50] : green[900],
          },
          outlined: {
            background: light ? gray[50] : gray[900],
            content: light ? green[600] : green[300],
            stroke: light ? green[600] : green[300],
          },
        },
        error: {
          contained: {
            background: light ? red[700] : red[300],
            content: light ? red[50] : red[900],
          },
          outlined: {
            background: light ? gray[50] : gray[900],
            content: light ? red[700] : red[300],
            stroke: light ? red[700] : red[300],
          },
        },
        warning: {
          contained: {
            background: light ? orange[700] : orange[300],
            content: light ? orange[50] : orange[900],
          },
          outlined: {
            background: light ? gray[50] : gray[900],
            content: light ? orange[700] : orange[300],
            stroke: light ? orange[700] : orange[300],
          },
        },
      },
    },
    typography: {
      ...defaultTheme.typography,
      fontFamily: "'Poppins', sans-serif",
      h6: {
        fontWeight: 400,
      },
      body1: {
        fontWeight: 400,
      },
      body2: {
        fontWeight: 400,
      },
      regular_2xs: {
        fontWeight: 400,
        fontSize: 10,
        lineHeight: "14px",
      },
      medium_2xs: {
        fontWeight: 500,
        fontSize: 10,
        lineHeight: "14px",
      },
      bold_2xs: {
        fontWeight: 700,
        fontSize: 10,
        lineHeight: "14px",
      },
      regular_xs: {
        fontWeight: 400,
        fontSize: 12,
        lineHeight: "18px",
      },
      medium_xs: {
        fontWeight: 500,
        fontSize: 12,
        lineHeight: "18px",
      },
      bold_xs: {
        fontWeight: 700,
        fontSize: 12,
        lineHeight: "18px",
      },
      regular_sm: {
        fontWeight: 400,
        fontSize: 14,
        lineHeight: "21px",
      },
      medium_sm: {
        fontWeight: 500,
        fontSize: 14,
        lineHeight: "21px",
      },
      bold_sm: {
        fontWeight: 700,
        fontSize: 14,
        lineHeight: "21px",
      },
      regular_md: {
        fontWeight: 400,
        fontSize: 16,
        lineHeight: "24px",
      },
      medium_md: {
        fontWeight: 500,
        fontSize: 16,
        lineHeight: "24px",
      },
      bold_md: {
        fontWeight: 700,
        fontSize: 16,
        lineHeight: "24px",
      },
      regular_lg: {
        fontWeight: 400,
        fontSize: 18,
        lineHeight: "27px",
      },
      medium_lg: {
        fontWeight: 500,
        fontSize: 18,
        lineHeight: "27px",
      },
      bold_lg: {
        fontWeight: 700,
        fontSize: 18,
        lineHeight: "27px",
      },
      regular_xl: {
        fontWeight: 400,
        fontSize: 20,
        lineHeight: "30px",
      },
      medium_xl: {
        fontWeight: 500,
        fontSize: 20,
        lineHeight: "30px",
      },
      bold_xl: {
        fontWeight: 700,
        fontSize: 20,
        lineHeight: "30px",
      },
      regular_2xl: {
        fontWeight: 400,
        fontSize: 24,
        lineHeight: "36px",
      },
      medium_2xl: {
        fontWeight: 500,
        fontSize: 24,
        lineHeight: "36px",
      },
      bold_2xl: {
        fontWeight: 700,
        fontSize: 24,
        lineHeight: "36px",
      },
      regular_3xl: {
        fontWeight: 400,
        fontSize: 30,
        lineHeight: "45px",
      },
      medium_3xl: {
        fontWeight: 500,
        fontSize: 30,
        lineHeight: "45px",
      },
      bold_3xl: {
        fontWeight: 700,
        fontSize: 30,
        lineHeight: "45px",
      },
      regular_4xl: {
        fontWeight: 400,
        fontSize: 36,
        lineHeight: "54px",
      },
      medium_4xl: {
        fontWeight: 500,
        fontSize: 36,
        lineHeight: "54px",
      },
      bold_4xl: {
        fontWeight: 700,
        fontSize: 36,
        lineHeight: "54px",
      },
    },
  };
};

export const Theme = (mode = "light", isMobile = false) => {
  const theme = MuiTheme(mode, isMobile);
  return createTheme(theme, {
    components: {
      ...defaultTheme.components,
      // MuiTypography: {
      //   styleOverrides: {
      //     root: ({ ownerState }) => ({
      //       ...(["h1", "h2", "h3", "h4", "h5", "h6"].includes(ownerState.variant) || true
      //         ? {
      //             color: mode === "light" ? "#1E293B" : "#F1F5F9",
      //           }
      //         : { color: mode === "light" ? "#1E293B" : "#F1F5F9" }),
      //     }),
      //   },
      // },
      MuiPaper: {
        defaultProps: {
          sx: { backgroundImage: "unset" },
        },
      },
      MuiAutocomplete: {
        defaultProps: {
          sx: { width: isMobile ? "100%" : "350px" },
        },
      },
      MuiFormGroup: {
        defaultProps: {
          sx: { width: isMobile ? "100%" : "350px" },
        },
      },
      MuiFormControl: {
        defaultProps: {
          sx: { color: theme.palette.common.title, width: isMobile ? "100%" : "350px" },
        },
      },
      MuiInputBase: {
        defaultProps: {
          sx: {
            width: isMobile ? "100%" : "350px",
            "& .MuiInputBase-input": {
              borderRadius: 2,
            },
          },
        },
      },
      MuiSelect: {
        defaultProps: {
          sx: {
            "& .MuiSelect-select": {
              borderRadius: 2,
            },
            "& .MuiSelect-icon": {
              top: "50%",
            },
          },
        },
      },
      MuiTablePagination: {
        styleOverrides: {
          root: {
            "& .MuiSelect-icon": {
              top: "unset !important",
            },
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: { backgroundColor: theme.palette.card.background },
        },
      },
      MuiTab: {
        styleOverrides: {
          defaultProps: {
            sx: {
              "& .MuiTypography-root": { color: theme.palette.common.title },
            },
          },
          root: {
            textTransform: "none",
            opacity: 1,
            "& .MuiTypography-root": { ...theme.typography.medium_md },
          },
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: { whiteSpace: "nowrap" },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: ({ ownerState }) => {
            return {
              textTransform: "none",
              borderRadius: "0.5rem",
              paddingX: "1rem",
              ...(ownerState.color &&
                ["contained", "outlined"].includes(ownerState.variant) && {
                  color: theme.palette.button[ownerState.color][ownerState.variant].content,
                  backgroundColor: theme.palette.button[ownerState.color][ownerState.variant].background,
                  "&:hover": {
                    backgroundColor:
                      mode === "light"
                        ? darken(theme.palette.button[ownerState.color][ownerState.variant].background, 0.2)
                        : lighten(theme.palette.button[ownerState.color][ownerState.variant].background, 0.3),
                  },
                }),
            };
          },
        },
      },
      MuiButton: {
        defaultProps: {
          disableElevation: true,
        },
        styleOverrides: {
          root: ({ ownerState }) => {
            return {
              textTransform: "none",
              borderRadius: "0.5rem",
              paddingX: "1rem",
              ...(ownerState.color &&
                ["contained", "outlined"].includes(ownerState.variant) && {
                  color: theme.palette.button[ownerState.color][ownerState.variant].content,
                  backgroundColor: theme.palette.button[ownerState.color][ownerState.variant].background,
                  "&:hover": {
                    backgroundColor:
                      mode === "light"
                        ? darken(theme.palette.button[ownerState.color][ownerState.variant].background, 0.2)
                        : lighten(theme.palette.button[ownerState.color][ownerState.variant].background, 0.3),
                  },
                }),
            };
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: { textTransform: "none" },
        },
      },
      // fix react-admin-import-csv css error
      MuiDialogContent: {
        defaultProps: {
          sx: { "& p": { marginTop: "0 !important" } },
        },
      },
      RaDatagrid: {
        styleOverrides: {
          root: {
            "& .RaDatagrid-tableWrapper": {
              overflowX: "auto",
            },
            ".RaDatagrid-headerCell": {
              color: theme.palette.common.title,
              backgroundColor: "inherit",
              ...theme.typography.bold_md,
            },
          },
        },
      },
      RaImageInput: {
        styleOverrides: {
          root: {
            width: "350px",
          },
        },
      },
      RaDualListInput: {
        styleOverrides: {
          root: {
            "& .RaDualListInput-main": {
              width: "fit-content",
            },
          },
        },
      },
      RaFilterForm: {
        styleOverrides: {
          root: {
            minHeight: "unset !important",
            p: 0,
            width: "100%",
            "& .MuiInputBase-root": {
              width: "unset",
            },
          },
        },
      },
      RaNotification: {
        styleOverrides: {
          root: {
            "& .RaNotification-success": {
              backgroundColor: theme.palette.button.primary.contained.background,
              color: theme.palette.button.primary.contained.content,
            },
          },
        },
      },
      RaLayout: {
        styleOverrides: {
          root: {
            margin: 0,
            width: "100%",
            minWidth: "auto",
            overflowX: "hidden",
            color: theme.palette.common.text,
            backgroundColor: theme.palette.common.background,
            "& .RaLayout-content": {
              padding: isMobile ? "1rem" : "2rem",
              backgroundColor: theme.palette.common.background,
              display: "flex",
              alignItems: "start",
              width: "100%",
              overflowX: "hidden",
            },
            "& .RaLayout-contentWithSidebar": {
              overflowX: "auto",
              overflowY: "hidden",
            },
          },
        },
      },

      RaList: {
        styleOverrides: {
          root: {
            minWidth: "100%",
            width: "100%",
            marginTop: isMobile ? "1rem" : "0",
            backgroundColor: theme.palette.card.background,
            borderRadius: "8px",
            "& .RaList-content": {
              boxShadow: "none",
            },
            "& .RaList-main": {
              width: "100%",
            },
            "& .RaList-actions": {
              padding: "0.5rem 0.5rem",
              // flexDirection: "column-reverse",
              // width: "100%",
              "& .MuiToolbar-root": {
                minHeight: "0",
                // position: "absolute",
                // top: "1rem",
                // right: "1rem",
                display: "flex",
                alignItems: "center",
                "& .MuiButtonBase-root": {
                  color: `${theme.palette.common.text} !important`,
                  ":hover": {
                    backgroundColor: `${alpha(theme.palette.neutral.main, 0.5)} !important`,
                  },
                },
              },
              "& .MuiFormControl-root": {
                width: "250px",
              },
              "& .MuiAutocomplete-root": {
                width: "250px",
              },
              "& .MuiInputBase-root": {
                width: "250px",
              },
              "& .RaFilterForm-filterFormInput > .RaFilterFormInput-spacer:first-child": {
                display: "none",
              },
              "& .RaFilterForm-filterFormInput > .MuiBox-root": {
                justifyContent: "start",
              },
            },
          },
        },
      },
      RaToolbar: {
        styleOverrides: {
          root: {
            backgroundColor: "inherit",
          },
        },
      },
      RaBulkDeleteWithUndoButton: {
        styleOverrides: {
          root: {
            color: `${theme.palette.error.main} !important`,
          },
        },
      },
      RaBulkActionsToolbar: {
        styleOverrides: {
          root: {
            "& .RaBulkActionsToolbar-toolbar": !isMobile
              ? { height: "72px", transform: "translateY(-72px)" }
              : {
                  transform: "translateY(-8px)",
                  position: "relative",
                  height: "unset",
                  flexDirection: "column",
                  alignItems: "start",
                  "& .MuiIconButton-root ": {
                    color: "inherit",
                    backgroundColor: "unset",
                    fontFamily: "'Poppins', sans-serif",
                    width: "unset",
                    height: "unset",
                    margin: 0,
                  },
                },
            "& .RaBulkActionsToolbar-title ": !isMobile
              ? {}
              : {
                  marginTop: "0.5rem",
                  alignItems: "center",
                  "& .MuiTypography-root ": {
                    fontWeight: 700,
                    ...theme.typography.bold_md,
                  },
                },
            "& .RaBulkActionsToolbar-collapsed": {
              height: "0",
              flexDirection: isMobile ? "column" : "row",
            },
            "& .RaBulkActionsToolbar-topToolbar": {
              padding: "0.5rem 0",
              flexDirection: isMobile ? "column" : "row",
              alignItems: isMobile ? "flex-start" : "flex-end",
            },
            "& .MuiButtonBase-root": {
              padding: "4px 6px",
            },
          },
        },
      },
      RaTabbedForm: {
        styleOverrides: {
          root: {
            "& .MuiDivider-root": { display: "none" },
          },
        },
      },
      RaEdit: {
        styleOverrides: {
          root: {
            width: "100%",
            marginTop: isMobile ? "0" : "-1rem",
            "& .MuiCard-root": {
              backgroundColor: "transparent",
              boxShadow: "none",
              "& .MuiCardContent-root": {
                backgroundColor: theme.palette.layout.topBarBackground,
                marginTop: "1rem",
                borderTopLeftRadius: "2rem",
                borderTopRightRadius: "2rem",
              },
            },
            "& .MuiFormControl-root": {
              marginBottom: "0.8rem",
            },
            "& form .MuiToolbar-root": {
              backgroundColor: theme.palette.layout.topBarBackground,
              borderBottomLeftRadius: "2rem",
              borderBottomRightRadius: "2rem",
              marginBottom: "1rem",
              position: "relative !important",
              justifyContent: "flex-start",
              gap: "1rem",
              "& .RaToolbar-defaultToolbar": {
                justifyContent: "flex-start",
                gap: "1rem",
              },
              "& .ra-delete-button": {
                padding: "0.5rem 1rem",
                color: theme.palette.button.error.contained.content,
                backgroundColor: theme.palette.button.error.contained.background,
                "&:hover": {
                  backgroundColor:
                    mode === "light"
                      ? darken(theme.palette.button.error.contained.background, 0.2)
                      : lighten(theme.palette.button.error.contained.background, 0.3),
                },
              },
            },
          },
        },
      },
      RaCreate: {
        styleOverrides: {
          root: {
            width: "100%",
            marginTop: isMobile ? "0" : "-1rem",
            "& .MuiCard-root": {
              backgroundColor: "transparent",
              boxShadow: "none",
              "& .MuiCardContent-root": {
                backgroundColor: theme.palette.layout.topBarBackground,
                marginTop: "1rem",
                borderTopLeftRadius: "2rem",
                borderTopRightRadius: "2rem",
              },
            },
            "& .MuiFormControl-root": {
              marginBottom: "0.8rem",
            },
            "& .MuiToolbar-root": {
              backgroundColor: theme.palette.layout.topBarBackground,
              borderBottomLeftRadius: "2rem",
              borderBottomRightRadius: "2rem",
              marginBottom: "1rem",
              position: "relative !important",
            },
          },
        },
      },
      RaAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: theme.palette.layout.topBarBackground,
            color: mode === "light" ? "#020617" : "white",
          },
        },
      },
      RaFilterButton: {
        styleOverrides: {
          root: {
            "& .MuiIconButton-root ": {
              color: theme.palette.button.neutral.outlined.content,
            },
          },
        },
      },
      RaCreateButton: {
        styleOverrides: {
          root: {
            backgroundColor: theme.palette.button.primary.contained.background,
            color: theme.palette.button.primary.contained.content,
            padding: "0.5rem 1rem",
            "&:hover": {
              backgroundColor:
                mode === "light"
                  ? darken(theme.palette.button.primary.contained.background, 0.2)
                  : lighten(theme.palette.button.primary.contained.background, 0.3),
            },
          },
        },
      },
    },
  });
};
