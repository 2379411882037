import React, { useCallback, useContext, useEffect, useState } from "react";
import { HistoryContext } from "../../services/historyProvider";
import {
  Edit,
  FunctionField,
  TextField,
  Labeled,
  Toolbar,
  SaveButton,
  DeleteButton,
  useRecordContext,
  useNotify,
  useRedirect,
  FormDataConsumer,
  BooleanInput,
  ReferenceInput,
  DateField,
} from "react-admin";
import { SimpleForm } from "@react-admin/ra-rbac";
import { COUNTRIES, OPPORTUNITIES_STEPS, OPPORTUNITIES_STATUSES } from "../../services/common-utils/constants";
import api from "../../services/apiWithAuth";
import PaymentMethodInput from "../../components/fields/PaymentMethodInput";
import PageTitle from "../../components/navigation/PageTitle";

const PaymentMethodElement = ({ paymentMethod }: any) => {
  const visa = require("../../assets/visa.png") as string;
  const mastercard = require("../../assets/mastercard.png") as string;
  const amex = require("../../assets/amex.png") as string;
  const sepa = require("../../assets/sepa.png") as string;
  const paymentMethodToImage: any = {
    visa: visa,
    mastercard: mastercard,
    amex: amex,
    sepa: sepa,
  };
  const type = paymentMethod.type === "card" ? paymentMethod.card.brand : "sepa";
  return ["visa", "amex", "sepa", "mastercard"].includes(type) ? (
    <img src={paymentMethodToImage[type]} alt={type} width="40" height="25" />
  ) : (
    <div />
  );
};

const PaymentMethodField = ({ props }: any) => {
  return (
    <FunctionField
      render={(record: any) => {
        return <PaymentMethodElement paymentMethod={record} />;
      }}
    />
  );
};

const PaymentMethodToolBar = ({ ...props }) => {
  const record = useRecordContext();
  return (
    <Toolbar sx={{ gap: 2 }}>
      <SaveButton label="Sauvegarder" />
      <DeleteButton label="Supprimer définitivement" disabled={record.subscriptionsNumber} />
    </Toolbar>
  );
};

const Form = ({ ...props }) => {
  const redirect = useRedirect();
  const notify = useNotify();
  const record = useRecordContext();
  const history: string[] = useContext(HistoryContext);
  const [fromCompany, setFromCompany] = useState("");
  const save = useCallback(
    async (values: any) => {
      const errors = {} as any;
      if (values.id !== record.id) {
        try {
          await api.post("/paymentMethods/replace", {
            newPaymentMethodId: values.id,
            oldPaymentMethodId: record.id,
            company: fromCompany,
          });
        } catch (err) {
          errors.id = "Erreur lors du changement de mode de paiement.";
          return errors;
        }
        try {
          notify("Moyen de paiement remplacé avec succès", {
            type: "success",
            messageArgs: { smart_count: 1 },
          });
          redirect(history[1]);
        } catch (error) {}
      }
    },
    [notify, redirect, record, fromCompany, history]
  );

  useEffect(() => {
    //history from historyContext can be not already updated
    if (history.length && !fromCompany && history[0] === window.location.pathname) {
      const matches: any = history
        .map((path: string) => {
          const match = path.match(/(?:companies\/)(.*)/);
          return match ? match[1] : null;
        })
        .filter((match: string) => match !== null);
      setFromCompany(matches.length ? matches[0] : "none");
    }
  }, [history, fromCompany]);

  return (
    <SimpleForm toolbar={<PaymentMethodToolBar />} onSubmit={save}>
      <PageTitle text="Édition d'un moyen de paiement"/>
      <Labeled sx={{ mb: 2 }}>
        <FunctionField
          label="Créé le"
          render={(record: any) => {
            return (
              <DateField source="createdDate" record={{ id: record.id, createdDate: record.created * 1000 }} showTime />
            );
          }}
        />
      </Labeled>
      <Labeled sx={{ mb: 2 }}>
        <TextField label="Nom complet" source="billing_details.name" />
      </Labeled>
      <Labeled sx={{ mb: 2 }}>
        <PaymentMethodField label="Type" />
      </Labeled>
      <Labeled label="Numéro" sx={{ mb: 2 }}>
        <FunctionField
          render={(record: any) => {
            const identificator =
              record.type === "card"
                ? "**** **** **** " + record.card.last4
                : `${record.sepa_debit.country}**${record.sepa_debit.bank_code}${record.sepa_debit.branch_code}*********${record.sepa_debit.last4}`;
            return <div>{identificator}</div>;
          }}
        />
      </Labeled>

      <Labeled label="Expiration" sx={{ mb: 2 }}>
        <FunctionField
          render={(record: any) => {
            return record.type === "card" ? (
              <div>
                {record.card.exp_month}/{record.card.exp_year}
              </div>
            ) : (
              <div />
            );
          }}
        />
      </Labeled>
      <Labeled label="Nombre d'abonnements associés" sx={{ mb: 2 }}>
        <TextField source="subscriptionsNumber" />
      </Labeled>
      <BooleanInput
        defaultValue={false}
        source="replacePaymentMethod"
        label="Remplacer ce moyen de paiement"
        sx={{ mb: 2 }}
      />
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.replacePaymentMethod && (
            <ReferenceInput
              label="Mode de paiement"
              source="id"
              reference="paymentMethods"
              filter={{ group_id: fromCompany }}
              sx={{ mb: 2 }}
              perPage={1000}
            >
              <PaymentMethodInput component="edit" style={{ marginBottom: "20px" }} />
            </ReferenceInput>
          )
        }
      </FormDataConsumer>
    </SimpleForm>
  );
};

const PaymentMethodEdit = ({ ...props }) => {
  return (
    <Edit {...props}>
      <Form {...props} />
    </Edit>
  );
};

export default PaymentMethodEdit;
