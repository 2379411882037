import {
  Container,
  Grid,
  Box,
  Typography,
  Stack,
  IconButton,
  useTheme,
  Button,
  alpha,
  useMediaQuery,
} from "@mui/material";
import { useTheme as useRaTheme } from "react-admin";
import React from "react";
import { Globe, Moon, Sun } from "lucide-react";

const SignContainer = ({ children, cardWidth, BottomAction = null, type = "" }: any) => {
  const matches = useMediaQuery((theme: any) => theme.breakpoints.up("sm"));
  const logoPath = matches
    ? (require(`../../assets/logo-full-white.svg`).default as string)
    : require(`../../assets/logo-white.svg`).default;
  const theme = useTheme();
  const [raTheme, setRaTheme] = useRaTheme();
  const background =
    raTheme === "light" ? (require("../../assets/bg.png") as string) : (require("../../assets/bg-dark.png") as string);

  // JSX to be rendered
  return (
    <Box
      sx={{
        height: ["auto", "100vh"],
        width: "100%",
      }}
    >
      <Box
        sx={{
          backgroundImage: `url(${background})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: ["200%", "150%", "130%", "120%", "100%"],
          backgroundColor: "common.background",
          left: ["-50%", "-70%", "-30%", "-20%", 0],
          position: "fixed",
          overflow: "hidden",
          height: 1,
          width: ["150%", "170%", "130%", "120%", 1],
          zIndex: 0,
        }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          pt: [10, 2],
          alignItems: "center",
          flexDirection: "column",
          height: type === "signUp" ? ["auto", "auto", "auto", "auto", 1] : ["auto", 1, 1, 1, 1],
        }}
      >
        <Grid item sx={{ width: cardWidth, zIndex: 5 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: 1,
              zIndex: 5,
              mb: 2,
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              sx={{ height: "3rem" }}
            >
              <img
                src={logoPath}
                alt="digisoft"
                className="digisoft-logo-login"
                style={{ width: "auto", height: "100%", margin: 0 }}
              />
            </Box>
            <Box sx={{height: "2.75rem"}}>
              <IconButton
                sx={{
                  color: "button.primary.outlined.content",
                  bgcolor: "button.primary.outlined.background",
                  borderColor: "button.primary.outlined.stroke",
                  border: "1px solid",
                  borderRadius: 2,
                  height: "2.75rem",
                  width: "2.75rem",
                  ":hover": { bgcolor: alpha(theme.palette.button.primary.outlined.background, 0.7) },
                }}
                onClick={() => setRaTheme(raTheme === "dark" ? "light" : "dark")}
              >
                {raTheme === "light" ? (
                  <Moon />
                ) : (
                  <Sun />
                )}
              </IconButton>
              <Button
                sx={{
                  color: "button.primary.outlined.content",
                  bgcolor: "button.primary.outlined.background",
                  borderColor: "button.primary.outlined.stroke",
                  p: 2,
                  ml: 2,
                  borderRadius: 2,
                  height: 1,
                  ":hover": { bgcolor: alpha(theme.palette.button.primary.outlined.background, 0.7) },
                }}
                variant="outlined"
                startIcon={<Globe />}
              >
                <Typography variant="medium_lg" >
                  Français
                </Typography>
              </Button>
            </Box>
          </Box>
          <Grid
            container
            position="relative"
            sx={{
              py: 3,
              px: [3, 4],
              backgroundColor: "card.background",
              borderRadius: 4,
              boxShadow: 2,
            }}
          >
            {children}
          </Grid>
          {BottomAction && <Stack sx={{ mt: "1rem", textAlign: "center" }}>{<BottomAction />}</Stack>}
        </Grid>
      </Box>
    </Box>
  );
};

export default SignContainer;
