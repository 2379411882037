import { Box, Typography } from "@mui/material";
import React from "react";
import { Create, ReferenceInput, useGetIdentity } from "react-admin";
import { SimpleForm } from "@react-admin/ra-rbac";
import { COUNTRIES, OPPORTUNITIES_STATUSES } from "../../services/common-utils/constants";
import PageTitle from "../../components/navigation/PageTitle";
import FormInput from "../../components/form/FormInput";
import { AutocompleteInput } from "../../components/form/AutocompleteInput";
import { schema, Opportunity } from "./Opportunity";
import { zodResolver } from "@hookform/resolvers/zod";

const OpportunitiesCreate = ({ ...props }) => {
  const { data: identity } = useGetIdentity();

  const onTransform = (data: Opportunity) => {
    return {
      ...data,
      status: "En cours",
      origin: "admin",
      seenBy: identity?.id,
    };
  };

  return (
    <Create transform={onTransform} {...props}>
      <PageTitle
        text={"Création d'une opportunité"}
        type="create"
        resource="opportunities"
        breadcrumbListLabel={"Liste des opportunités"}
        breadcrumbCreateLabel={"Nouvelle opportunité"}
      />
      <SimpleForm resolver={zodResolver(schema)}>
        <Box
          sx={{
            display: ["flex", "flex", "grid"],
            flexDirection: ["column"],
            gridTemplateColumns: ["1fr", "1fr", "1fr 1fr"],
            gap: [0, "0 2rem"],
          }}
        >
          <FormInput defaultValue={null} source="company" label="Nom" required />
          <FormInput
            defaultValue={null}
            type="select"
            source="status"
            values={OPPORTUNITIES_STATUSES.map((s: any) => ({ id: s, label: s }))}
            label="État"
          />
          <FormInput defaultValue={null} source="mainContact" label="Contact principal" />
          <ReferenceInput
            label="Collaborateur responsable"
            source="collaborator_id"
            reference="collaborators"
            perPage={100}
          >
            <AutocompleteInput
              label="Collaborateur"
              placeholder="Rechercher..."
              optionText={(choice) => (
                <Typography variant="regular_sm" color="common.title">
                  {choice.firstName} {choice.lastName}
                </Typography>
              )}
              inputText={(choice) => `${choice.firstName} ${choice.lastName}`}
              filterToQuery={(search: any) => ({
                multi_firstName_lastName: search,
              })}
            />
          </ReferenceInput>
          <Box sx={{ display: "flex", flexDirection: ["column", "row"], width: 1, gridColumn: "span 2", gap: [0, 4] }}>
            <Box>
              <FormInput type="phone" defaultValue={null} source="phone" label="Téléphone" />
              <FormInput defaultValue={null} source="email" label="Email" />
            </Box>
            <FormInput defaultValue={null} source="note" label="Note" type="multiline" lineNumber={5} />
          </Box>
        </Box>
      </SimpleForm>
    </Create>
  );
};

export default OpportunitiesCreate;
