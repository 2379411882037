import React, { useState, useEffect } from "react";
import { useListContext, useUnselectAll, useNotify, useRefresh } from "react-admin";
import api from "../../services/apiWithAuth";
import { CheckIcon } from "lucide-react";
import { Button, Typography } from "@mui/material";

const UsersBulkAssignLicenses = ({ company, identity }: any) => {
  const { selectedIds, data: users } = useListContext();
  const [isLoading, setisLoading] = useState(false);
  const [freeLicensesNumber, setFreeLicensesNumber] = useState(0);
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll("users");

  const assignLicenses = async () => {
    setisLoading(true);
    if (selectedIds.length > freeLicensesNumber) {
      setisLoading(false);
      notify("Pas assez de licences disponibles.", { type: "error" });
      return;
    }
    const existingLicenseId = users.some((user: any) => selectedIds.includes(user.id) && user.licenseId);
    if (existingLicenseId) {
      setisLoading(false);
      notify("Un des utilisateurs sélectionnés possède déjà une licence", { type: "error" });
      return;
    }
    try {
      const response = await api.post("/licenses/assignLicenses", {
        userIds: selectedIds,
        company,
      });
      setisLoading(false);
      refresh();
      notify("Affectation des licences à " + response.data.length + " utilisateurs effectuée");
      unselectAll();
      const freeLicensesRes = await api.get("/licenses/getFreeLicenses/" + company);
      setFreeLicensesNumber(freeLicensesRes.data.freeLicensesNumber);
    } catch (err) {
      setisLoading(false);
      unselectAll();
      notify("Erreur lors de l'affectation des licences aux utilisateurs", { type: "error" });
    }
  };

  useEffect(() => {
    const asyncCall = async () => {
      const response = await api.get("/licenses/getFreeLicenses/" + company);
      setFreeLicensesNumber(response.data.freeLicensesNumber);
    };
    if (company) {
      asyncCall();
    }
  }, [company]);

  return (
    <Button disabled={isLoading} onClick={assignLicenses}>
      <CheckIcon size={18} /> <Typography variant="medium_sm" ml={0.5}>Affecter licences ({freeLicensesNumber > 50 ? `+${freeLicensesNumber}` : freeLicensesNumber} dispos)</Typography>
    </Button>
  );
};

export default UsersBulkAssignLicenses;
