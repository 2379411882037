import { alpha, Box, Typography, useTheme } from "@mui/material";
import { Datagrid } from "@react-admin/ra-rbac";
import React, { useEffect } from "react";
import { useListContext, useUnselectAll } from "react-admin";

const Empty = () => {
  const noDataImg = require("../../assets/ic-content.svg").default as string;
  return (
    <Box
      sx={{
        width: 1,
        m: 2,
        minHeight: "320px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        bgcolor: "common.background",
        borderRadius: 2
      }}
    >
      <Box sx={{ width: 200 }}>
        <img src={noDataImg} alt="noData" style={{ width: "100%", height: "auto", margin: 0 }} />
      </Box>
      <Typography color="common.text" variant="medium_md">
        Pas de donnée
      </Typography>
    </Box>
  );
};

export const CustomDatagrid = ({ ...props }) => {
  const { resource, data, isLoading } = useListContext();
  const unselectAll = useUnselectAll(resource);
  const theme = useTheme();

  useEffect(() => {
    unselectAll();
  }, [unselectAll]);

  return isLoading ? null : data?.length > 0 ? (
    <Datagrid
      {...props}
      sx={{
        "& .MuiTableRow-root > *": { p: 2, borderBottom: "1px solid", borderColor: "neutral.main" },
        "& .MuiTableRow-root > *:last-child": { pr: 4 },
        "& .MuiTableRow-root > *:first-child": { pl: 4 },
        "& .MuiTableHead-root": { bgcolor: alpha(theme.palette.neutral.main, 0.5) },
      }}
    />
  ) : (
    <Empty />
  );
};
