import React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";

//hack to be able to have full button with RA createInDialogButton
export const DialogIcon = ({ icon, text, variant = "medium_sm" }) => {
  const isMobile = !!useMediaQuery((theme: any) => theme.breakpoints.only("xs"));
  return !isMobile ? (
    icon
  ) : (
    <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
      {icon}
      {
        <Typography variant={variant} ml={0.5}>
          {text}
        </Typography>
      }
    </Box>
  );
};
