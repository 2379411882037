import { Box, Typography } from "@mui/material";
import React from "react";
import { FunctionField, useGetOne } from "react-admin";

const PaymentMethodElement = ({ paymentMethod, component }: any) => {
  const visa = require("../../assets/visa.png") as string;
  const mastercard = require("../../assets/mastercard.png") as string;
  const amex = require("../../assets/amex.png") as string;
  const sepa = require("../../assets/sepa.png") as string;
  const virement = require("../../assets/virement.png") as string;
  const mandat = require("../../assets/mandat-administratif.png") as string;
  if (typeof paymentMethod === "string") {
    paymentMethod = { type: paymentMethod };
  }
  const paymentMethodToImage: any = {
    visa: visa,
    mastercard: mastercard,
    amex: amex,
    sepa: sepa,
    Virement: virement,
    "Mandat administratif": mandat,
  };
  return ["visa", "amex", "sepa", "mastercard", "Virement", "Mandat administratif"].includes(paymentMethod?.type) ? (
    <Box display="flex" flexDirection="row" alignItems="center" sx={{ m: 1, ml: 0 }}>
      <img src={paymentMethodToImage[paymentMethod.type]} alt={paymentMethod.type} width="40" height="25" />
      {component === "edit" && ["visa", "amex", "sepa", "mastercard"].includes(paymentMethod?.type) && (
        <Typography sx={{ ml: 2 }}>{paymentMethod.identificator}</Typography>
      )}
    </Box>
  ) : (
    <div />
  );
};

const PaymentMethodField = ({ source, component }: any) => {
  return (
    <FunctionField
      render={(record: any) => {
        return <PaymentMethodElement paymentMethod={record[source]} component={component} />;
      }}
    />
  );
};

export default PaymentMethodField;
