import { object, string, z } from "zod";

export const schema = object({
  company: string().min(1, "Nom organisation requis"),
  country: string().nullish(),
  mainContact: string().nullish(),
  phone: string().nullish(),
  email: string().nullish(),
  collaborator_id: string().nullish(),
  status: string().nullish(),
  origin: string().nullish(),
  note: string().nullish(),
});

export type Opportunity = z.infer<typeof schema>;
