import React from "react";
import { CreateButton, useGetIdentity, DateField, Filter, ReferenceField, TextField, FunctionField } from "react-admin";
import { List } from "@react-admin/ra-rbac";
import { CustomDatagrid } from "../../components/react-admin/CustomDatagrid";
import SendSmsAction from "../contacts/SendSmsAction";
import FormInput from "../../components/form/FormInput";
import { ListActions } from "../../components/react-admin/ListActions";
import PageTitle from "../../components/navigation/PageTitle";

const ContactListsList = ({ ...props }) => {
  const { isLoading, data: identity } = useGetIdentity();
  return isLoading ? null : (
    <>
      {!props?.withoutTitle && (
        <PageTitle
          text="Liste de contacts"
          createButton={<CreateButton label="Nouvelle liste de contacts" />}
          type="list"
          breadcrumbListLabel="Liste de contacts"
        />
      )}
      <List
        perPage={5}
        sort={{ field: "name", order: "ASC" }}
        filter={{ group_id: props.company || identity?.company }}
        filters={
          <Filter {...props}>
            <FormInput placeholder="Nom liste" name="name" type="search" alwaysOn />
          </Filter>
        }
        {...props}
        exporter={false}
        empty={false}
        actions={<ListActions />}
      >
        <CustomDatagrid>
          <TextField source="name" label="Nom de la liste de contacts" />
          {!props.group_id && identity?.status === "Collaborateur" && (
            <ReferenceField label="Organisation" source="group_id" reference="companies" link={false} sortable={false}>
              <TextField source="company" />
            </ReferenceField>
          )}
          <DateField source="createdAt" label="Créé le" showTime />
          <DateField source="updatedAt" label="Mis à jour le" showTime />
          {identity?.licenseId && (
            <FunctionField
              label="Envoyer SMS"
              render={(record: any) => {
                // const formattedFirstName = record.firstname[0].toUpperCase() + record.firstname.slice(1);
                // const formattedLastName = record.lastname.toUpperCase();
                return (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <SendSmsAction record={record} identity={identity} />
                  </div>
                );
              }}
            />
          )}
        </CustomDatagrid>
      </List>
    </>
  );
};

export default ContactListsList;
