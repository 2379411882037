import { Grid, Typography, InputAdornment } from "@mui/material";
import React from "react";
import {
  Edit,
  SaveButton,
  Toolbar,
  FormDataConsumer,
  SimpleFormIterator,
  ArrayInput,
  ReferenceArrayInput,
} from "react-admin";
import { SimpleForm } from "@react-admin/ra-rbac";
import {
  PRODUCTS_TAXES,
  PRODUCTS_BILLING_PERIOD,
  COMPANIES_STATUSES,
  COUNTRIES,
  TYPES,
} from "../../services/common-utils/constants";
import PageTitle from "../../components/navigation/PageTitle";
import FormInput from "../../components/form/FormInput";
import { Product, Tarif, schema } from "./IProduct";
import { zodResolver } from "@hookform/resolvers/zod";
import { AutocompleteInput } from "../../components/form/AutocompleteInput";

const ProductsToolBar = ({ ...props }) => (
  <Toolbar>
    <SaveButton label="Sauvegarder" />
    <div style={{ flex: 1 }} />
  </Toolbar>
);

const ProductsEdit = ({ ...props }) => {
  const onTransform = (data: Product, { previousData }: any) => {
    data.tarifs.forEach((tarif: Tarif, index: number) => {
      if (!tarif.id) {
        tarif.id = Math.floor(Date.now() / 1000) + "-" + index;
      }
    });
    return data;
  };

  return (
    <Edit {...props} transform={onTransform} hasShow={false}>
      <PageTitle
        text={(record: any) => `${record.name}`}
        type="edit"
        resource="products"
        breadcrumbListLabel="Liste des produits"
      />
      <SimpleForm
        toolbar={<ProductsToolBar />}
        resolver={zodResolver(schema)}
        sx={{ "& .RaArrayInput-root": { width: [1, 900] } }}
      >
        <FormInput label="Nom du produit" name="name" required />
        <FormInput label="Description du produit" name="description" required type="multiline" />
        <FormInput
          label="TVA"
          name="taxRate"
          type="select"
          values={PRODUCTS_TAXES.map((s: any) => {
            return { label: s + " %", id: s };
          })}
          defaultValue={20}
          required
        />
        <FormInput label="Type de produit" name="type" disabled />
        <FormInput label="Modèle de tarification" name="paymentModel" disabled defaultValue={"Forfait"} />
        <FormInput defaultValue={false} type="checkbox" name="isRecurring" checkboxLabel="Paiment récurrent" disabled />
        <ArrayInput
          required
          source="tarifs"
          sx={{ "& .MuiFormLabel-asterisk": { color: "error.main" } }}
          label={
            <Typography
              variant="bold_lg"
              sx={{
                height: 24,
                position: "relative",
                overflow: "visible",
                transform: "none",
              }}
              color="common.title"
            >
              Tarifs
            </Typography>
          }
        >
          <SimpleFormIterator
            sx={{
              width: "100%",
              "& .RaSimpleFormIterator-form": {
                backgroundColor: "rgba(0,0,0,0.04)",
                padding: "10px",
                marginTop: "10px",
                borderRadius: "2px",
                border: "solid 1px",
                mb: 2,
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: 2,
                width: "100%",
              },
            }}
            disableClear
          >
            <FormDataConsumer>
              {({ formData, getSource, ...rest }) =>
                formData?.isRecurring && (
                  <FormInput
                    label="Cycle de facturation"
                    name={getSource?.("billingPeriod") || ""}
                    type="select"
                    values={PRODUCTS_BILLING_PERIOD.map((s) => {
                      return { label: s, id: s };
                    })}
                    required
                  />
                )
              }
            </FormDataConsumer>
            <FormDataConsumer>
              {({ formData, getSource, ...rest }) => (
                <FormInput
                  label="Tarif"
                  name={getSource?.("price") || ""}
                  required
                  type="number"
                  InputProps={{
                    endAdornment: <InputAdornment position="start">€ HT</InputAdornment>,
                  }}
                />
              )}
            </FormDataConsumer>

            {/* <NumberInput required source="price" label="Tarif" step={0.01} /> */}
            <FormDataConsumer>
              {({ formData, getSource, ...rest }) =>
                formData?.paymentModel === "Forfait" && (
                  <FormInput
                    required
                    name={getSource?.("packageSize") || ""}
                    label="Quantité pack"
                    type="number"
                    defaultValue={500}
                    {...rest}
                    InputProps={{
                      endAdornment: <InputAdornment position="start">unités</InputAdornment>,
                    }}
                  />
                )
              }
            </FormDataConsumer>
            <FormDataConsumer>
              {({ formData, getSource, ...rest }) => (
                <>
                  <FormInput
                    label="Pays du client"
                    name={getSource?.("countries") || ""}
                    helperText="Pays du client pouvant voir le produit"
                    type="multiselect"
                    defaultValue={COUNTRIES}
                    values={COUNTRIES.map((s) => {
                      return { label: s, id: s };
                    })}
                    required
                    hideSelectInsideLabel
                  />
                  <FormInput
                    label="Statut des clients"
                    name={getSource?.("companyStatus") || ""}
                    helperText="Statut des clients pouvant voir le produit"
                    type="multiselect"
                    defaultValue={["Client", "Revendeur"]}
                    values={COMPANIES_STATUSES.map((s) => {
                      return { label: s, id: s };
                    })}
                    required
                    hideSelectInsideLabel
                  />
                  <FormInput
                    label="Types de clients"
                    name={getSource?.("companyTypes") || ""}
                    helperText="Types de clients pouvant voir le produit"
                    type="multiselect"
                    defaultValue={TYPES}
                    values={TYPES.map((s) => {
                      return { label: s, id: s };
                    })}
                    required
                    hideSelectInsideLabel
                  />
                </>
              )}
            </FormDataConsumer>
            <ReferenceArrayInput
              source="companyIds"
              reference="companies"
              defaultValue={[]}
              sort={{ field: "company", order: "ASC" }}
              perPage={300}
            >
              <AutocompleteInput
                label="Whitelist clients"
                optionText="company"
                filterToQuery={(search: any) => ({
                  company: search,
                })}
                multiple
                defaultValue={[]}
                helperText="Clients pouvant voir le produit, si vide accessible à tout le monde"
                sx={{ "& .MuiInputBase-root": { mb: 0 } }}
              />
            </ReferenceArrayInput>
            <ReferenceArrayInput
              source="blackListedCompanyIds"
              reference="companies"
              defaultValue={[]}
              sort={{ field: "company", order: "ASC" }}
              perPage={300}
            >
              <AutocompleteInput
                label="Blacklist clients"
                optionText="company"
                filterToQuery={(search: any) => ({
                  company: search,
                })}
                multiple
                defaultValue={[]}
                helperText="Clients ne pouvant pas voir le produit"
                sx={{ "& .MuiInputBase-root": { mb: 0 } }}
              />
            </ReferenceArrayInput>
            {/* <FormDataConsumer>
              {({ formData, getSource, ...rest }) => (
                <FormInput
                  label="id"
                  name={getSource?.("id") || ""}
                  disabled
                />
              )}
            </FormDataConsumer> */}
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};

export default ProductsEdit;
