import React from "react";
import {
  CreateButton,
  TextField,
  Toolbar,
  ReferenceField,
  Filter,
  ReferenceInput,
  useGetIdentity,
  FunctionField,
  DateField,
  TextInput,
} from "react-admin";
import { List } from "@react-admin/ra-rbac";
import { CustomDatagrid } from "../../components/react-admin/CustomDatagrid";
import { Typography } from "@mui/material";
import PaymentMethodField from "../../components/fields/PaymentMethodField";
import PageTitle from "../../components/navigation/PageTitle";
import { query, where } from "firebase/firestore";
import { AutocompleteInput } from "../../components/form/AutocompleteInput";
import FormInput from "../../components/form/FormInput";
import { ListActions } from "../../components/react-admin/ListActions";
import ListChip from "../../components/fields/ListChip";

const statusToColor: any = {
  Complète: "secondary",
  "En cours de paiement": "warning",
  "En cours de traitement": "warning",
  Panier: "neutral",
  "Erreur de paiement": "error",
};

const paymentStatusToColor: any = {
  Succès: "secondary",
  "En cours": "warning",
  "En attente": "neutral",
  Erreur: "error",
  Litige: "error",
};

const OrdersList = ({ ...props }) => {
  const { isLoading, data: identity } = useGetIdentity();
  return isLoading ? null : (
    <>
      <PageTitle text="Commandes" createButton={<></>} type="list" breadcrumbListLabel="Liste des commandes" />
      <List
        perPage={5}
        {...props}
        filter={
          props.company
            ? { company_id: props.company }
            : identity?.status !== "Collaborateur"
            ? { company_id: identity?.company, collectionQuery: (c: any) => query(c, where("status", "!=", "Panier")) }
            : {}
        }
        filters={
          <Filter {...props}>
            <FormInput type="search" placeholder="Numéro de commande" source="id" alwaysOn />
            {identity?.status === "Collaborateur" && !props.company && (
              <ReferenceInput
                source="company_id"
                reference="companies"
                sort={{ field: "company", order: "ASC" }}
                perPage={1000}
                label="Organisation"
              >
                <AutocompleteInput
                  placeholder="Organisation"
                  optionText="company"
                  filterToQuery={(search: any) => ({ company: search })}
                  filledSecondary
                />
              </ReferenceInput>
            )}
          </Filter>
        }
        actions={<ListActions pageTitle="Commandes" />}
        exporter={false}
        empty={false}
        sort={{ field: "createdate", order: "DESC" }}
      >
        <CustomDatagrid bulkActionButtons={identity?.status === "Collaborateur"}>
          <DateField label="Créé le" source="createdate" showTime />
          <TextField label="Référence" source="id" />
          {identity?.status === "Collaborateur" && (
            <ReferenceField label="Organisation" source="company_id" reference="companies" link={false}>
              <TextField source="company" />
            </ReferenceField>
          )}
          <ReferenceField label="Utilisateur" source="user_id" reference="users" link={false}>
            <TextField source="email" />
          </ReferenceField>
          <FunctionField
            label="Montant HT"
            render={(record: any) => {
              return <div> {record.totals.totalHt.toFixed(2)} €</div>;
            }}
          />
          <FunctionField
            label="Montant TTC"
            render={(record: any) => {
              return <div> {record.totals.totalTtc.toFixed(2)} €</div>;
            }}
          />
          <PaymentMethodField source="paymentMethod" label="Mode de paiement" />
          <FunctionField
            label="Statut paiement"
            render={({ paymentStatus }: any) => {
              return <ListChip label={paymentStatus} color={paymentStatusToColor[paymentStatus]} />;
            }}
          />
          <FunctionField
            label="Statut commande"
            render={({ status }: any) => {
              return <ListChip label={status} color={statusToColor[status]} />;
            }}
          />
        </CustomDatagrid>
      </List>
    </>
  );
};

export default OrdersList;
