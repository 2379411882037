import React, { Fragment, useCallback } from "react";
import { CreateButton, useGetIdentity, DateField, Filter, TextField, FunctionField } from "react-admin";
import { List } from "@react-admin/ra-rbac";
import { CustomDatagrid } from "../../components/react-admin/CustomDatagrid";
import FormInput from "../../components/form/FormInput";
import { ListActions } from "../../components/react-admin/ListActions";
import PageTitle from "../../components/navigation/PageTitle";

const GroupsList = ({ ...props }) => {
  const { isLoading, data: identity } = useGetIdentity();
  return isLoading ? null : (
    <>
      <PageTitle
        text="Groupes"
        createButton={<CreateButton label="Nouveau groupe" resource="groups" />}
        type="list"
        breadcrumbListLabel="Liste des groupes"
      />
      <List
        perPage={5}
        debounce={750}
        sort={{ field: "name", order: "ASC" }}
        filter={{ company: props.company || identity?.company }}
        filters={
          <Filter {...props}>
            <FormInput placeholder="Nom groupe" name="name" type="search" alwaysOn />
          </Filter>
        }
        {...props}
        exporter={false}
        empty={false}
        actions={<ListActions />}
      >
        <CustomDatagrid>
          <TextField source="name" label="Nom du groupe" />
          <FunctionField
            label="Nombre d'utilisateurs"
            render={(record: any) => {
              return <div>{record?.users?.length || 0}</div>;
            }}
          />
          <DateField source="lastupdate" label="Mis à jour le" showTime />
        </CustomDatagrid>
      </List>
    </>
  );
};

export default GroupsList;
