import { Box, Typography, Divider, useTheme, Button } from "@mui/material";
import { FC, useState } from "react";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { object, string, TypeOf } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import FormInput from "../../components/form/FormInput";
import styled from "@emotion/styled";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import SignContainer from "../../components/others/SignContainer";
import React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { Mail } from "lucide-react";
import { fbAuth } from "../../services/firebase/firebase.services";

// Login Schema with Zod
const forgottenPasswordSchema = object({
  email: string().min(1, "Adresse mail requise").email("Adresse mail invalide"),
});

// Infer the Schema to get the TS Type
type IForgottenPassword = TypeOf<typeof forgottenPasswordSchema>;

const ForgottenPasswordPage: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();

  const defaultValues: IForgottenPassword = {
    email: "",
  };

  // The object returned from useForm Hook
  const methods = useForm<IForgottenPassword>({
    resolver: zodResolver(forgottenPasswordSchema),
    defaultValues,
  });

  const onSubmitHandler: SubmitHandler<IForgottenPassword> = async (values: IForgottenPassword) => {
    // Initialize progress bar styles
    setError("");

    // Send email
    try {
      const auth = fbAuth;
      await sendPasswordResetEmail(auth, values.email);
      setError("");
      setSuccess("Un mail vient de vous être envoyé.");
    } catch (err) {
      setError("Email introuvable");
    }
  };

  return (
    <SignContainer cardWidth={[0.92, 500]} type="forgottenPassword">
      <FormProvider {...methods}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={methods.handleSubmit(onSubmitHandler)}
          sx={{
            width: 1,
            "& .MuiFormControl-root": { width: 1 },
            "& .MuiInputBase-root": { width: "auto", m: 0 },
            "& .MuiButtonBase-root": { py: 0.5 },
            "& .MuiFormControlLabel-root": { width: 1, mt: 1 },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "start",
              pb: 0,
              width: 1,
            }}
          >
            <Typography component="h1" variant="bold_xl" sx={{ textAlign: "left", width: 1, mb: 2 }}>
              Mot de passe oublié
            </Typography>
            <Typography variant="regular_lg" sx={{ textAlign: "left", width: 1, color: "common.text" }}>
              Si vous avez oublié votre mot de passe, merci d'entrer l'adresse email lié à votre compte. Nous vous
              enverrons un email vous permettant de modifier votre mot de passe.
            </Typography>
            <Divider sx={{ mb: 2, mt: 2, width: 1 }} />
            <Box width={1}>
              <FormInput
                label="Adresse email"
                name="email"
                autoComplete="off"
                required
                disabled={methods.formState.isSubmitting}
              />
            </Box>
          </Box>
          <Typography
            variant="subtitle1"
            sx={{ width: 1, textAlign: "left", mt: 1, mb: 2, ml: 2, height: "2rem", color: error ? "error.main" : "success.main" }}
          >
            {error || success || ""}
          </Typography>
          <Box
            sx={{
              display: "flex",
              width: 1,
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              color="neutral"
              size="large"
              onClick={() => navigate("/login")}
            >
              <Typography variant="medium_lg">Retour</Typography>
            </Button>
            <LoadingButton
              loading={methods.formState.isSubmitting}
              type="submit"
              variant="contained"
              size="large"
            >
              <Typography variant="medium_lg" mr={1}>
                Envoyer
              </Typography>
              <Mail width={18} />
            </LoadingButton>
          </Box>
        </Box>
      </FormProvider>
    </SignContainer>
  );
};

export default ForgottenPasswordPage;
