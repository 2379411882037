import React from "react";
import {
  DateField,
  Filter,
  FunctionField,
  ReferenceField,
  TextField,
  ReferenceInput,
  TopToolbar,
  useGetIdentity,
  BulkDeleteWithConfirmButton,
  useNotify,
  useGetList,
} from "react-admin";
import { IfCanAccess, List } from "@react-admin/ra-rbac";
import { CustomDatagrid } from "../../components/react-admin/CustomDatagrid";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import { IUser } from "./IUser";
import UsersBulkGroup from "./UsersBulkGroup";
import { Box, Button, Typography } from "@mui/material";
import UsersBulkAssignLicenses from "./UsersBulkAssignLicenses";
import UsersBulkRemoveLicenses from "./UsersBulkRemoveLicenses";
import api from "../../services/apiWithAuth";
import { isEmailValid, sanitizePhoneNumber } from "../../services/common-utils/functions";
import PageTitle from "../../components/navigation/PageTitle";
import FormInput from "../../components/form/FormInput";
import { AutocompleteInput } from "../../components/form/AutocompleteInput";
import UsersCreate from "./UsersCreate";
import NewImportButtons from "../../components/importButtons/NewImportButtons";
import { ListActions } from "../../components/react-admin/ListActions";
import CustomExportButton from "../../components/react-admin/CustomExportButton";
import ListChip from "../../components/fields/ListChip";

const BulkActionButtons = ({ ...props }) => {
  return (
    <>
      <UsersBulkGroup company={props.company} />
      <UsersBulkAssignLicenses company={props.company} identity={props.identity} />
      <UsersBulkRemoveLicenses />
      <IfCanAccess action="delete">
        <BulkDeleteWithConfirmButton
          mutationMode="pessimistic"
          confirmColor="warning"
          confirmTitle="Suppression utilisateurs"
          confirmContent="Êtes vous sûr de vouloir supprimer ces utilisateurs? Si ces derniers possèdent des licences celles-ci ne seront plus utilisables."
        />
      </IfCanAccess>
    </>
  );
};

const getFields = (crmGroupOptions: any = null) => {
  const fields: any = [
    {
      label: "Email",
      key: "email",
      alternateMatches: ["Email", "Mail", "Adresse mail", "Adresse email"],
      fieldType: {
        type: "input",
      },
      example: "stephanie.dumas@gmail.com",
      validations: [
        {
          rule: "required",
          errorMessage: "Email requis",
          level: "error",
        },
        {
          rule: "unique",
          errorMessage: "Email déjà existant",
          level: "error",
        },
      ],
    },
    {
      // Visible in table header and when matching columns.
      label: "Prénom",
      // This is the key used for this field when we call onSubmit.
      key: "firstName",
      // Allows for better automatic column matching. Optional.
      alternateMatches: ["Prénom", "Prenom", "prenom", "prénom"],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "Stéphanie",
      // Can have multiple validations that are visible in Validation Step table.
      validations: [
        {
          // Can be "required" / "unique" / "regex"
          rule: "required",
          errorMessage: "Prénom requis",
          // There can be "info" / "warning" / "error" levels. Optional. Default "error".
          level: "error",
        },
      ],
    },
    {
      label: "Nom",
      key: "lastName",
      alternateMatches: ["Nom", "Nom de famille"],
      fieldType: {
        type: "input",
      },
      example: "Dumas",
      validations: [
        {
          rule: "required",
          errorMessage: "Nom requis",
          level: "error",
        },
      ],
    },
    {
      label: "Numéro de téléphone",
      key: "proPhone",
      alternateMatches: ["Numéro de téléphone", "Téléphone", "Telephone", "Téléphone pro", "telephone pro"],
      fieldType: {
        type: "input",
      },
      example: "+33601020304",
      validations: [
        {
          rule: "required",
          errorMessage: "Numéro de téléphone requis",
          level: "error",
        },
      ],
    },
  ];
  if (crmGroupOptions && crmGroupOptions?.length > 0) {
    fields.push({
      label: "Groupe",
      key: "crmGroupId",
      alternateMatches: ["Groupe"],
      fieldType: {
        type: "select",
        options: crmGroupOptions,
      },
      example: "Mon groupe",
    });
  }
  return fields;
};

// Having company prop means that list is called from companies edit
const UsersList = ({ ...props }) => {
  const { data: identity } = useGetIdentity();
  const { data: crmGroups } = useGetList("groups", {
    pagination: { page: 1, perPage: 100 },
    filter: { company: props?.company || identity?.company },
    sort: { field: "name", order: "ASC" },
  });

  const onSubmit = async (data: any) => {
    const {
      data: { usersCreated },
    } = await api.post("/utils/importUsers", {
      data,
      companyId: props.company || identity?.company,
    });
  };

  if (!identity) return null;
  return (
    <>
      <PageTitle
        text="Utilisateurs"
        backButton={false}
        createButton={<UsersCreate />}
        type="list"
        breadcrumbListLabel="Liste des utilisateurs"
      />
      <List
        perPage={5}
        {...props}
        filter={
          props.company
            ? { company: props.company }
            : identity?.status !== "Collaborateur"
            ? { company: identity?.company }
            : {}
        }
        sort={{ field: "name", order: "ASC" }}
        filters={
          <Filter {...props}>
            <FormInput placeholder="Rechercher" name="multi_firstName_lastName_email" type="search" alwaysOn />
            {!props.company && ["Collaborateur"].includes(identity?.status) && (
              <ReferenceInput
                source="company"
                reference="companies"
                alwaysOn
                filter={identity?.status !== "Collaborateur" ? { parentCompany: identity?.company } : {}}
                sort={{ field: "company", order: "ASC" }}
                perPage={1000}
              >
                <AutocompleteInput
                  placeholder="Organisation"
                  optionText="company"
                  filterToQuery={(search: any) => ({ company: search })}
                  filledSecondary
                />
              </ReferenceInput>
            )}
            <ReferenceInput
              label="Groupe"
              source="crmGroupId"
              reference="groups"
              filter={
                props.group_id
                  ? { company: props.group_id }
                  : identity?.status !== "Collaborateur"
                  ? { company: identity?.company }
                  : {}
              }
              sort={{ field: "name", order: "ASC" }}
              perPage={1000}
            >
              <AutocompleteInput
                placeholder="Groupe"
                optionText="name"
                filterToQuery={(search: any) => ({ name: search })}
                filledSecondary
              />
            </ReferenceInput>
          </Filter>
        }
        actions={
          <ListActions pageTitle="Utilisateurs">
            <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
              <NewImportButtons
                {...props}
                fields={getFields(
                  crmGroups?.map((crmGroup: any) => ({ label: crmGroup.name, value: crmGroup.id })) || null
                )}
                xlsxTemplate={process.env.REACT_APP_TEMPLATE_IMPORT_USERS_EXCEL}
                csvTemplate={process.env.REACT_APP_TEMPLATE_IMPORT_USERS}
                onSubmit={onSubmit}
                rowHook={async (data, addError) => {
                  if (data.email) {
                    if (!isEmailValid(data.email)) {
                      addError("email", {
                        message: data.email + " n'est pas une adresse email valide",
                        level: "error",
                      });
                    }
                    const {
                      data: { exists },
                    } = await api.post("/utils/checkExistingUser", {
                      email: data.email,
                    });
                    if (exists) {
                      addError("email", {
                        message: "Un utilisateur utilise déjà l'adresse email " + data.email,
                        level: "error",
                      });
                    }
                  }
                  if (data.proPhone) {
                    data.proPhone = sanitizePhoneNumber(data.proPhone);
                  }
                  return data;
                }}
              />
            </Box>
            <CustomExportButton
              fields={[
                { variable: "firstName", label: "Prénom" },
                { variable: "lastName", label: "Nom" },
                { variable: "email", label: "Email" },
                { variable: "service", label: "Service" },
                { variable: "position", label: "Fonction" },
                { variable: "proMobilePhone", label: "Numéro mobile PRO" },
                { variable: "proPhone", label: "Numéro de téléphone PRO" },
                {
                  variable: "licenseId",
                  label: "Possède une licence",
                  transform: (licenseId: any) => (licenseId ? "Oui" : "Non"),
                },
                { variable: "note", label: "Note" },
              ]}
              fileName="utilisateurs"
              maxResults={1000}
            />
          </ListActions>
        }
        empty={false}
      >
        <CustomDatagrid
          bulkActionButtons={
            <BulkActionButtons
              company={props.company || identity?.company}
              identity={identity}
              sx={{ zIndex: 1000000 }}
            />
          }
        >
          <ReferenceField label="Nom complet" source="id" reference="users" link={false}>
            <FunctionField
              render={(record: any) => {
                const formattedFirstName = !record.firstName
                  ? ""
                  : record.firstName[0].toUpperCase() + record.firstName.slice(1);
                const formattedLastName = !record.lastName ? "" : record.lastName.toUpperCase();
                return formattedFirstName && formattedLastName ? (
                  <div style={{ display: "flex", flexDirection: "row", gap: "12px", alignItems: "center" }}>
                    <b>{`${formattedFirstName} ${formattedLastName}`}</b>
                  </div>
                ) : (
                  <></>
                );
              }}
            />
          </ReferenceField>
          <TextField source="email" />

          <FunctionField
            label="Groupe"
            render={(record: any) => {
              return record?.crmGroupId ? (
                <ReferenceField source="crmGroupId" reference="groups" link={false}>
                  <TextField source="name" />
                </ReferenceField>
              ) : (
                <Typography sx={{ fontSize: "0.9rem" }}>aucun</Typography>
              );
            }}
          />

          <FunctionField
            label="Rôle"
            render={(record: IUser) => {
              const role = record.role;
              return (
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  {role === "Administrateur" && (
                    <StarRateRoundedIcon
                      style={{
                        marginRight: "4px",
                        marginTop: "-.235ex",
                        verticalAlign: "middle",
                        color: "#ffbf00",
                      }}
                    />
                  )}
                  <b>{role}</b>
                </div>
              );
            }}
          />

          <FunctionField
            label="Licence affectée"
            render={(record: IUser) => {
              const statusColor = record.licenseId ? "secondary" : "error";
              return <ListChip label={record.licenseId ? "Oui" : "Non"} color={statusColor} />;
            }}
          />
          <DateField label="Modifié le" source="lastupdate" showTime={true} />
        </CustomDatagrid>
      </List>
    </>
  );
};

export default UsersList;
