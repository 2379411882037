import { Box, IconButton, Typography, useMediaQuery, Button } from "@mui/material";
import { ArrowLeftRight } from "lucide-react";
import React, { useState } from "react";
import { ReferenceInput, useChoicesContext, useGetOne, useNotify } from "react-admin";
import Modal from "../../components/modals/Modal";
import FormInput from "../../components/form/FormInput";
import { useWatch, useFormContext } from "react-hook-form";
import api from "../../services/apiWithAuth";
import { useNavigate } from "react-router-dom";

const PaymentMethodData = ({ paymentMethod, short = false }: any) => {
  const visa = require("../../assets/visa.png") as string;
  const mastercard = require("../../assets/mastercard.png") as string;
  const amex = require("../../assets/amex.png") as string;
  const sepa = require("../../assets/sepa.png") as string;
  const isMobile = !!useMediaQuery((theme: any) => theme.breakpoints.only("xs"));
  const identificator =
    paymentMethod.type === "card"
      ? (isMobile || short ? "**** " : "**** **** **** ") + paymentMethod.card.last4
      : `${paymentMethod.sepa_debit.country}**${paymentMethod.sepa_debit.bank_code}${paymentMethod.sepa_debit.branch_code}*********${paymentMethod.sepa_debit.last4}`;
  const paymentMethodToImage: any = {
    visa: visa,
    mastercard: mastercard,
    amex: amex,
    sepa: sepa,
  };
  const type = paymentMethod.type === "card" ? paymentMethod.card.brand : "sepa";
  return !["visa", "amex", "sepa", "mastercard"].includes(type) ? null : (
    <Box sx={{ display: "flex", justifyContent: "space-between", width: "calc(100% - 50px)" }}>
      <Box display="flex" flexDirection="row" alignItems="center" sx={{ gap: isMobile || short ? 2 : 4 }}>
        <img src={paymentMethodToImage[type]} alt={type} width="40" height="25" />
        <Typography variant="regular_sm" color="common.title">
          {identificator}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center" sx={{ gap: isMobile || short ? 2 : 4 }}>
        {paymentMethod.type === "card" && (
          <Typography variant="regular_sm" color="common.title">
            {paymentMethod.card.exp_month.toString().padStart(2, "0")}/{paymentMethod.card.exp_year}
          </Typography>
        )}
        {paymentMethod.type !== "card" ||
        new Date(paymentMethod.card.exp_year, paymentMethod.card.exp_month, 1) > new Date() ? (
          <Typography
            sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 1 }}
            variant="regular_sm"
            color="common.title"
          >
            <Box sx={{ bgcolor: "primary.main", width: 6, height: 6, borderRadius: 100 }} />
            <Typography sx={{ display: isMobile || short ? "none" : "inline" }}>Valide</Typography>
          </Typography>
        ) : (
          <Typography
            sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 1 }}
            variant="regular_sm"
            color="common.title"
          >
            <Box sx={{ bgcolor: "error.main", width: 6, height: 6, borderRadius: 100 }} />
            <Typography sx={{ display: isMobile || short ? "none" : "inline" }}>Invalide</Typography>
          </Typography>
        )}
      </Box>
    </Box>
  );
};

const PaymentMethodElement = ({ paymentMethodId, setModifyPaymentOpen }: any) => {
  const { data: paymentMethod } = useGetOne("paymentMethods", { id: paymentMethodId });
  if (!paymentMethod) {
    return null;
  }
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ width: 1, m: 0.5, ml: 0, p: 1, bgcolor: "bankCard.background", borderRadius: 2 }}
    >
      <PaymentMethodData paymentMethod={paymentMethod} />
      <IconButton
        sx={{
          p: 1,
          border: "solid 1px",
          borderColor: "button.neutral.outlined.content",
          color: "button.neutral.outlined.content",
          borderRadius: 2,
          height: 32,
          width: 32,
          ml: 4,
        }}
        onClick={() => setModifyPaymentOpen(true)}
      >
        <ArrowLeftRight size={14} />
      </IconButton>
    </Box>
  );
};

export const PaymentMethodDisplay = ({ paymentMethodId = "", ...props }: any) => {
  const { data: paymentMethod } = useGetOne("paymentMethods", { id: paymentMethodId });

  return paymentMethod ? (
    <FormInput
      type="select"
      name="paymentMethodId"
      label="Moyen de paiement"
      defaultValue={paymentMethodId}
      values={[
        {
          label: (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: 2,
                width: 1,
              }}
            >
              <PaymentMethodData paymentMethod={paymentMethod} short />
            </Box>
          ),
          id: paymentMethodId,
        },
      ]}
      hideSelectInsideLabel
      {...props}
    />
  ) : (
    <FormInput
      label="Moyen de paiement"
      name="noPaymentMethod"
      type="display"
      defaultValue="Aucun moyen de paiement trouvé"
      disabled
      {...props}
    />
  );
};

const PaymentMethodInput = ({ currentPaymentMethodId = null }: any) => {
  const { allChoices } = useChoicesContext();
  const navigate = useNavigate();

  return allChoices?.length > 1 ? (
    <FormInput
      type="select"
      name="paymentMethodId"
      label="Moyen de paiement"
      values={allChoices
        .filter((paymentMethod: any) => paymentMethod.id !== currentPaymentMethodId)
        .map((paymentMethod: any) => ({
          label: (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: 2,
                width: 1,
              }}
            >
              <PaymentMethodData paymentMethod={paymentMethod} />
            </Box>
          ),
          id: paymentMethod.id,
        }))}
      hideSelectInsideLabel
    />
  ) : (
    <Button
      variant="contained"
      color="primary"
      onClick={() => {
        navigate("/paymentMethods/create");
      }}
      sx={{ width: [1, "fit-content"], mt: 2 }}
    >
      <Typography variant="medium_sm">Ajouter un moyen de paiement</Typography>
    </Button>
  );
};

const ChangePaymentMethodModal = ({ subscription }: any) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        width: 1,
        "& .MuiFormControl-root": {
          width: 1,
        },
      }}
    >
      <Typography variant="regular_sm" color="common.title">
        Merci de sélectionner votre nouveau moyen de paiement pour votre abonnement. Assurez vous que ce dernier soit
        valide pour éviter des frais de litige. <br /> <br />
        Si vous souhaitez utiliser un nouveau moyen de paiement, ajoutez celui-ci et revenez ici afin de pouvoir choisir
        ce dernier.
      </Typography>
      <ReferenceInput
        label="Moyen de paiement"
        source="paymentMethod"
        reference="paymentMethods"
        filter={{ group_id: subscription.company_id }}
        perPage={1000}
      >
        <PaymentMethodInput currentPaymentMethodId={subscription.paymentMethod.id} />
      </ReferenceInput>
    </Box>
  );
};

const SwitchablePaymentMethod = ({ subscription }) => {
  const [modifyPaymentOpen, setModifyPaymentOpen] = useState(false);
  const paymentMethodId = useWatch({ name: "paymentMethodId" });
  const { setValue } = useFormContext();
  const notify = useNotify();

  const handleConfirm = async (confirm) => {
    if (confirm) {
      try {
        await api.post("/paymentMethods/replace", {
          subscriptionId: subscription.stripe_id,
          newPaymentMethodId: paymentMethodId,
          company: subscription.company_id,
        });
        notify("Moyen de paiement mis à jour avec succès.", {
          type: "success",
          messageArgs: { smart_count: 1 },
        });
      } catch (err) {
        notify("Erreur lors du changement de moyen de paiement.", {
          type: "error",
          messageArgs: { smart_count: 1 },
        });
      }
    }
    setModifyPaymentOpen(false);
    setValue("paymentMethodId", "");
  };
  return (
    <Box sx={{ width: 1 }}>
      <Typography variant="medium_sm" color="common.title">
        Moyen de paiement utilisé
      </Typography>
      <PaymentMethodElement
        paymentMethodId={subscription.paymentMethod.id}
        setModifyPaymentOpen={setModifyPaymentOpen}
      />
      <Modal
        title="Changement de moyen de paiement"
        content={<ChangePaymentMethodModal subscription={subscription} />}
        open={modifyPaymentOpen}
        handleConfirm={handleConfirm}
      />
    </Box>
  );
};

export default SwitchablePaymentMethod;
