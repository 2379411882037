import { Box, Typography, Tab } from "@mui/material";
import React, { useEffect } from "react";
import PageTitle from "../../components/navigation/PageTitle";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ContactsList from "../contacts/ContactsList";
import ContactListsList from "../contactLists/ContactListsList";
import { useSearchParams } from "react-router-dom";
import ContactsCreate from "../contacts/ContactsCreate";
import { CreateButton } from "react-admin";

const ContactsOrContactLists = ({ ...props }) => {
  const [value, setValue] = React.useState("1");
  const [searchParams, setSearchParams] = useSearchParams();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    setSearchParams("");
  }, [setSearchParams, value]);

  return (
    <Box sx={{ width: 1, "& .MuiTabPanel-root": { p: 0 }, "& .MuiTab-root": { pb: 0 } }}>
      <PageTitle
        text={value === "1" ? "Contacts" : "Listes de contact"}
        backButton={false}
        createButton={value === "1" ? <ContactsCreate /> : <CreateButton label="Nouvelle liste de contacts" resource="contactLists"/>}
        type="list"
        breadcrumbListLabel={value === "1" ? "Liste de contacts" : "Listes de contacts"}
      />
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, mb: 2, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab
              label={
                <Typography variant="medium_md" color={value === "1" ? "primary" : "common.title"}>
                  Contacts
                </Typography>
              }
              value="1"
            />
            <Tab
              label={
                <Typography variant="medium_md" color={value === "2" ? "primary" : "common.title"}>
                  Listes de contact
                </Typography>
              }
              value="2"
            />
          </TabList>
        </Box>
        <TabPanel value="1">{value === "1" && <ContactsList resource="contacts" withoutTitle />}</TabPanel>
        <TabPanel value="2">{value === "2" && <ContactListsList resource="contactLists" withoutTitle />}</TabPanel>
      </TabContext>
    </Box>
  );
};

export default ContactsOrContactLists;
