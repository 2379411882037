import { Box, Typography } from "@mui/material";
import React from "react";
import {
  DateField,
  Edit,
  FormTab,
  ReferenceInput,
  TabbedForm,
  TabbedFormTabs,
  TextField,
  useGetIdentity,
} from "react-admin";
import { SimpleForm } from "@react-admin/ra-rbac";
import { COUNTRIES, OPPORTUNITIES_STATUSES } from "../../services/common-utils/constants";
import PageTitle from "../../components/navigation/PageTitle";
import FormInput from "../../components/form/FormInput";
import { AutocompleteInput } from "../../components/form/AutocompleteInput";
import { schema, Opportunity } from "./Opportunity";
import { zodResolver } from "@hookform/resolvers/zod";
import { useRecordContext } from "react-admin";
import EmailsList from "../emails/EmailsList";

const Emails = () => {
  const record = useRecordContext();
  return record?.email && <EmailsList email={record?.email} resource="emails" />;
};

const OpportunitiesEdit = ({ ...props }) => {
  const { data: identity } = useGetIdentity();
  const onTransform = (data: any, { previousData }: any) => {
    data.country = "France";
    if (data.status !== previousData.status) {
      data.seenBy = [identity?.id];
    }
    return {
      ...data,
    };
  };
  return (
    <Edit transform={onTransform} {...props}>
      <PageTitle
        text={(record: any) => record.company}
        type="edit"
        resource="opportunities"
        breadcrumbListLabel="Liste des opportunités"
      />
      <TabbedForm resolver={zodResolver(schema)} tabs={<TabbedFormTabs indicatorColor="primary" />}>
        <FormTab label="Détails">
          <Box
            sx={{
              display: ["flex", "flex", "grid"],
              flexDirection: ["column"],
              gridTemplateColumns: ["1fr", "1fr", "1fr 1fr"],
              gap: [0, "0 2rem"],
            }}
          >
            <FormInput defaultValue={null} source="company" label="Nom" required />
            <FormInput
              defaultValue={null}
              type="select"
              source="status"
              values={OPPORTUNITIES_STATUSES.map((s: any) => ({ id: s, label: s }))}
              label="État"
            />
            <FormInput defaultValue={null} source="mainContact" label="Contact principal" />
            <ReferenceInput
              label="Collaborateur responsable"
              source="collaborator_id"
              reference="collaborators"
              perPage={100}
            >
              <AutocompleteInput
                label="Collaborateur"
                placeholder="Rechercher..."
                optionText={(choice) => (
                  <Typography variant="regular_sm" color="common.title">
                    {choice.firstName} {choice.lastName}
                  </Typography>
                )}
                inputText={(choice) => `${choice.firstName} ${choice.lastName}`}
                filterToQuery={(search: any) => ({
                  multi_firstName_lastName: search,
                })}
              />
            </ReferenceInput>
            <Box
              sx={{ display: "flex", flexDirection: ["column", "row"], width: 1, gridColumn: "span 2", gap: [0, 4] }}
            >
              <Box>
                <FormInput type="phone" defaultValue={null} source="phone" label="Téléphone" callablePhone />
                <FormInput defaultValue={null} source="email" label="Email" />
              </Box>
              <FormInput defaultValue={null} source="note" label="Note" type="multiline" lineNumber={5} />
            </Box>
            <FormInput label="Pays" type="select" name="country" values={COUNTRIES} required defaultValue={"France"} />
            <FormInput defaultValue={null} source="origin" label="Source" disabled />
          </Box>
        </FormTab>
        <FormTab label="Historique">
          <Emails />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default OpportunitiesEdit;
