import { Grid, Typography, Button } from "@mui/material";
import React, { useState, useCallback } from "react";
import {
  Edit,
  useDelete,
  SaveButton,
  Toolbar,
  useRecordContext,
  TabbedForm,
  FormTab,
  BooleanInput,
  Confirm,
  Button as RaButton,
  useGetIdentity,
  useNotify,
  useRedirect,
  useUpdate,
  PasswordInput,
  FormDataConsumer,
  TabbedFormTabs,
} from "react-admin";
import { TITLES, SERVICES, COUNTRIES, CRM_ROLES, STATUSES } from "../../services/common-utils/constants";
import { getAuth, updatePassword } from "firebase/auth";
import PageTitle from "../../components/navigation/PageTitle";
import FormInput from "../../components/form/FormInput";
import { zodResolver } from "@hookform/resolvers/zod";
import { boolean, object, string } from "zod";

const formSchema = object({
  email: string().email({ message: "Veuillez saisir une adresse e-mail valide." }),
  title: string().min(1, { message: "Veuillez sélectionner une civilité." }),
  firstName: string().min(1, { message: "Veuillez saisir le prénom." }),
  lastName: string().min(1, { message: "Veuillez saisir le nom de famille." }),
  role: string().min(1, { message: "Veuillez spécifier le role du collaborateur." }),
  service: string().nullish(),
  proMobilePhone: string().nullish(),
  proPhone: string().nullish(),
  showOpportunityNotifications: boolean().nullish(),
  showOrderNotifications: boolean().nullish(),
  password: string().nullish(),
  accountStatus: string().nullish(),
  personalDetails: object({
    phone: string().nullish(),
    mobilePhone: string().nullish(),
    address: object({
      address: string().nullish(),
      additionalAddress: string().nullish(),
      city: string().nullish(),
      country: string().nullish(),
    }),
  }),
});

const CollaboratorsToolBar = ({ ...props }) => (
  <Toolbar>
    <SaveButton label="Sauvegarder" />
    <div style={{ flex: 1 }} />
    <CustomDelete />
  </Toolbar>
);

const CustomDelete = () => {
  const record = useRecordContext();
  const [open, setOpen] = useState(false);
  const redirect = useRedirect();
  const notify = useNotify();

  const [deleteOne, { isLoading, error }] = useDelete("collaborators", { id: record.id, previousData: record });
  if (error) {
    return <p>ERROR</p>;
  }

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = () => {
    deleteOne();
    setOpen(false);
    notify("Collaborateur supprimé avec succès", {
      type: "success",
      messageArgs: { smart_count: 1 },
    });
    redirect("list", "collaborators");
  };

  return (
    <>
      <RaButton
        label="Supprimer définitivement"
        onClick={handleClick}
        color="error"
        variant="contained"
        sx={{ py: 1, px: 2 }}
      />
      <Confirm
        isOpen={open}
        loading={isLoading}
        title="Suppression collaborateur"
        content="Etes-vous sur de vouloir supprimer votre compte collaborateur ? Toutes les données liées à celui-ci seront supprimées."
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};

const Form = ({ ...props }) => {
  const [changePassword, setChangePassword] = useState(false);
  const { data: identity } = useGetIdentity();
  const redirect = useRedirect();
  const notify = useNotify();
  const record = useRecordContext();

  const [update] = useUpdate();
  const save = useCallback(
    async (values: any) => {
      const errors = {} as any;
      if (values.password) {
        const auth = getAuth();
        const user = auth.currentUser;
        try {
          if (user) {
            await updatePassword(user, values.password);
          } else {
            errors.password = "...";
            return errors;
          }
        } catch (err) {
          errors.password =
            "Erreur lors de la mise à jour du mot de passe car session utilisateur trop longue. Déconnectez et reconnectez vous avant de refaire l'opération.";
          return errors;
        }
        delete values.password;
      }
      try {
        await update("collaborators", { id: record.id, data: values, previousData: record }, { returnPromise: true });
        notify("Utilisateur mis à jour avec succès", {
          type: "success",
          messageArgs: { smart_count: 1 },
        });
        redirect("list", "collaborators");
      } catch (error) {}
    },
    [update, notify, redirect, record]
  );

  return !identity ? null : (
    <TabbedForm
      tabs={<TabbedFormTabs indicatorColor="primary" />}
      toolbar={<CollaboratorsToolBar />}
      onSubmit={save}
      resolver={zodResolver(formSchema)}
    >
      <FormTab label="Géneral">
        <FormInput type="select" source="title" label="Civilité" values={TITLES.map((s) => ({ id: s, label: s }))} />
        <FormInput source="firstName" label="Prénom" />
        <FormInput source="lastName" label="Nom de famille" />
        <FormInput type="select" source="role" label="Role CRM" values={CRM_ROLES.map((s) => ({ id: s, label: s }))} />
        <FormInput source="email" label="Adresse email" helperText="Cet e-mail est utilisée pour se connecter au CRM" />
        <FormInput type="select" source="service" values={SERVICES.map((s) => ({ id: s, label: s }))} />
        <FormInput type="phone" defaultValue={null} source="proMobilePhone" label="Numéro mobile PRO" />
        <FormInput type="phone" defaultValue={null} source="proPhone" label="Numéro de téléphone PRO" />
        <FormInput
          type="checkbox"
          source="showOrderNotifications"
          checkboxLabel="Afficher les notifications de nouvelle commande"
        />
        <FormInput
          type="checkbox"
          source="showOpportunityNotifications"
          checkboxLabel="Afficher les notifications relatifves aux opportunités"
        />
      </FormTab>

      {/* Personnal infos form */}
      <FormTab label="Informations privées">
        <FormInput type="phone" defaultValue={null} source="personalDetails.mobilePhone" label="Numéro mobile PERSO" />
        <FormInput type="phone" defaultValue={null} source="personalDetails.phone" label="Numéro fixe PERSO" />
        <FormInput defaultValue={null} source="personalDetails.address.address" label="Rue et numéro" />
        <FormInput
          defaultValue={null}
          source="personalDetails.address.additionalAddress"
          label="Complément d'adresse"
        />
        <FormInput defaultValue={null} source="personalDetails.address.city" label="Ville" />
        <FormInput
          type="select"
          defaultValue="France"
          source="personalDetails.address.country"
          label="Pays"
          values={COUNTRIES.map((s) => ({ id: s, label: s }))}
        />
      </FormTab>

      {record.id === identity?.id && (
        <FormTab label="Sécurité">
          {["Super Admin", "Administrateur"].includes(identity?.role) && (
            <FormInput
              type="select"
              defaultValue="Actif"
              source="accountStatus"
              label="Statut"
              values={STATUSES.map((s) => ({ id: s, label: s }))}
            />
          )}
          <Button
            variant="outlined"
            onClick={() => {
              setChangePassword(!changePassword);
            }}
            sx={{ marginLeft: 0, maxWidth: "300px", mt: 2 }}
          >
            Créer/modifier mot de passe
          </Button>
          <Typography variant="regular_sm" maxWidth={"300px"} fontSize="12px">
            Pour pouvoir vous connecter avec un mot de passe en plus de la connexion par lien email ou pour modifier le
            mot de passe actuel
          </Typography>
          {changePassword && <FormInput type="password" defaultValue={null} source="password" label="Mot de passe" />}
        </FormTab>
      )}
    </TabbedForm>
  );
};

const CollaboratorsEdit = ({ ...props }) => {
  return (
    <Edit {...props} hasShow={false}>
      <PageTitle
        text={(record: any) => `${record.firstName} ${record.lastName}`}
        type="edit"
        resource="collaborators"
        breadcrumbListLabel="Liste des collaborateurs"
        breadcrumbListUrl={"/admin/digisoft/2"}
      />
      <Form {...props} />
    </Edit>
  );
};

export default CollaboratorsEdit;
