import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Typography } from "@mui/material";
import Info from "../info/Info";
import LoadingButton from "@mui/lab/LoadingButton";

export default function Modal({
  title,
  content,
  open,
  handleConfirm,
  confirmButtonText = "Confirmer",
  cancelButtonText = "Annuler",
}: any) {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={() => handleConfirm(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ "& .MuiPaper-root": { p: 2, gap: 2 } }}
      >
        <DialogTitle id="alert-dialog-title" sx={{ p: 0 }}>
          <Typography variant="bold_2xl" color="common.title">
            {title}
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ p: 0 }}>
          <DialogContentText id="alert-dialog-description">
            <Info content={content} variant="medium_sm"/>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: 0 }}>
          <Button
            variant="contained"
            color="neutral"
            onClick={async () => {
              await handleConfirm(false);
            }}
            sx={{ height: "2.4rem" }}
          >
            {cancelButtonText}
          </Button>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            onClick={async () => {
              setIsLoading(true);
              await handleConfirm(true);
              setIsLoading(false);
            }}
            size="large"
            sx={{
              height: "2.4rem",
            }}
          >
            {confirmButtonText}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
