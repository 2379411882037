import * as React from "react";
import FormInput from "../../components/form/FormInput";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { Container, Grid, Box, Typography, useTheme, Tabs, Tab, useMediaQuery } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";

export default function TabNav({ tabs, setCurrentTab = null, ...props }: any) {
  const [value, setValue] = React.useState("1");
  const isMobile = !!useMediaQuery((theme: any) => theme.breakpoints.only("xs"));
  const methods = useForm();
  const theme = useTheme();
  const [tabValue] = useWatch({
    control: methods.control,
    name: ["tabValue"],
  });
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {

    setValue(newValue);
    if (setCurrentTab) {
      setCurrentTab(newValue);
    }
  };
  return isMobile ? (
    <FormProvider {...methods}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: 1,
          "& .MuiFormControl-root": {
            width: 1,
            mb: 2,
            px: [1, 0],
            "& .MuiTypography-root": { color: `${theme.palette.primary.main} !important`, fontWeight: 500 },
          },
        }}
        component="form"
        autoComplete="off"
      >
        <FormInput
          type="select"
          name="tabValue"
          defaultValue={tabs[0].value}
          values={tabs.map((tab: any) => ({
            id: tab.value,
            label: tab.name,
          }))}
          required
          filledSecondary
          hideSelectInsideLabel
        />
      </Box>
      {tabValue
        ? tabs.filter((tab: any) => tab.value === tabValue).map((tab: any) => tab.component)
        : tabs[0].component}
    </FormProvider>
  ) : (
    <TabContext value={value}>
      <Box sx={{ borderBottom: 1, mb: 2, borderColor: "divider" }}>
        <TabList onChange={handleChange} aria-label="lab API tabs example" scrollButtons="auto" variant="scrollable">
          {tabs.map((tab: any) => (
            <Tab
              label={
                <Typography variant="medium_md" color={value === tab.value ? "primary" : "common.title"}>
                  {tab.name}
                </Typography>
              }
              value={tab.value}
            />
          ))}
        </TabList>
      </Box>
      {tabs.map((tab: any) => (
        <TabPanel value={tab.value}>{value === tab.value && tab.component}</TabPanel>
      ))}
    </TabContext>
  );
}
