import * as React from "react";
import Card from "@mui/material/Card";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { useGetIdentity, useGetList } from "react-admin";
import {
  Box,
  Typography,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Skeleton,
  Divider,
  IconButton,
  useMediaQuery,
  Stack,
} from "@mui/material";
import api from "../../services/apiWithAuth";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PaymentIcon from "@mui/icons-material/Payment";
import { ArrowRight, ChevronRight, Folder, X } from "lucide-react";
import { query, where } from "firebase/firestore";
import AffiliationButton from "./AffiliationButton";

// const TryItCard = ({ identity, refetchIdentity, daysTillFreeTryEnd, ...props }: any) => {
//   const [software, setSoftware] = useState({} as any);
//   const [error, setError] = useState("");
//   const [step, setStep] = useState(identity?.freeTry.licenseId ? 1 : 0);
//   const navigate = useNavigate();
//   const methods = useForm({
//     defaultValues: {
//       email: identity?.email,
//     },
//   });
//   const [email] = useWatch({ control: methods.control, name: ["email"] });

//   useEffect(() => {
//     const asyncCall = async () => {
//       const q = query(collection(fbFirestore, "downloads"), where("name", "==", "SMS Meeting"));
//       const downloads = await getDocs(q);
//       setSoftware(downloads.docs[0].data());
//     };

//     asyncCall();
//   }, []);

//   const downloadFile = (download: any) => {
//     axios({
//       url: download.software.src,
//       method: "GET",
//       responseType: "blob",
//     }).then((response) => {
//       const href = URL.createObjectURL(response.data);
//       const link = document.createElement("a");
//       link.href = href;
//       link.setAttribute("download", download.software.title);
//       document.body.appendChild(link);
//       link.click();
//       document.body.removeChild(link);
//       URL.revokeObjectURL(href);
//     });
//   };

//   // const onSubmit = async ({ email }: any) => {
//   //   try {
//   //     await api.post("/utils/beginTryPeriod", { email });
//   //     setStep(1);
//   //     refetchIdentity();
//   //   } catch (err) {
//   //     if (err.response && err.response.status === 409) {
//   //       setError("Un utilisateur existe déjà avec cette adresse e-mail. Merci d'en saisir une autre.");
//   //     } else {
//   //       setError("Création de la licence d'essai impossible, merci de contacter un administrateur");
//   //     }
//   //   }
//   // };

//   return (
//     <Box
//       display="flex"
//       flexDirection="column"
//       alignItems="center"
//       sx={{
//         width: 0.95,
//         bgcolor: "primary.main",
//         borderRadius: "10px",
//         padding: "14px",
//         "& .MuiInputBase-root": { backgroundColor: "white", color: "black", borderColor: "white", mb: "0" },
//         "& .MuiFormControl-root": { mb: "0", mt: "0" },
//       }}
//     >
//       {step === 0 ? (
//         <>
//           <Typography variant="h5" color="white" sx={{ mb: 3 }}>
//             Découvrer gratuitement et sans engagement notre solution pour une durée de 14 jours !
//           </Typography>
//           <FormProvider {...methods}>
//             <Box
//               display="flex"
//               flexDirection="column"
//               alignItems="center"
//               gap="32px"
//               mt="20px"
//               component="form"
//               autoComplete="off"
//               onSubmit={methods.handleSubmit(onSubmit)}
//             >
//               <FormInput required name="email" placeholder="Votre email" />
//               {!methods.formState.isSubmitting && error && (
//                 <Typography
//                   color="error"
//                   sx={{
//                     color: "white",
//                     bgcolor: "error.main",
//                     px: 1,
//                     py: 0.5,
//                     borderRadius: 2,
//                     width: "fit-content",
//                     fontSize: "0.9rem",
//                   }}
//                 >
//                   {error}
//                 </Typography>
//               )}
//               <LoadingButton
//                 loading={methods.formState.isSubmitting}
//                 type="submit"
//                 disabled={!email}
//                 variant="contained"
//                 sx={{
//                   backgroundColor: "secondary.main",
//                   height: "38px",
//                 }}
//               >
//                 Commencer l'essai gratuit
//               </LoadingButton>
//             </Box>
//           </FormProvider>
//         </>
//       ) : (
//         <Box sx={{ color: "white" }}>
//           <Typography variant={"h5"} sx={{ mb: 3 }} textAlign="center">
//             Félicitation, votre licence d'essai à bien été créée sur l'adresse e-mail renseignée.
//           </Typography>
//           <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
//             <Typography
//               textAlign="center"
//               sx={{
//                 color: "white",
//                 bgcolor: "warning.dark",
//                 px: 3,
//                 py: 0.6,
//                 mb: 2,
//                 borderRadius: 2,
//                 width: "fit-content",
//                 fontSize: "1.1rem",
//                 opacity: "0.95",
//               }}
//             >
//               Jours restants avant expiration :{" "}
//               <b>
//                 {Math.floor(
//                   (new Date(identity?.freeTry.expirationDate).valueOf() -
//                     new Date(new Date().toISOString().split("T")[0]).valueOf()) /
//                     86400000
//                 )}
//               </b>
//             </Typography>
//           </Box>
//           <Typography sx={{ mb: 2, fontSize: "1rem" }}>
//             Vous pouvez dès à présent tester les fonctionnalités de notre module SMS Meeting, pour cela veuillez suivre
//             les étapes décrites ci-dessous :
//           </Typography>
//           <Typography sx={{ mb: 1, fontSize: "1rem" }}>
//             1) Télécharger l’application SMS Meeting en cliquant sur{" "}
//             <span style={{ fontWeight: "bold", cursor: "pointer" }} onClick={() => downloadFile(software)}>
//               ce lien
//             </span>
//           </Typography>
//           <Typography sx={{ mb: 1, fontSize: "1rem" }}>
//             2) Fermer Outlook et Installer l’application SMS Meeting sur votre ordinateur
//           </Typography>
//           <Typography sx={{ mb: 1, fontSize: "1rem" }}>
//             3) Réouvrer Outlook, dans le ruban « section SMS Meeting » cliquer sur le bouton « Paramètres » puis sur
//             l’onglet « Sécurité »
//           </Typography>
//           <Typography sx={{ mb: 1, fontSize: "1rem" }}>
//             4) Cliquer sur le bouton « Envoyer code magique » puis fermer la fenêtre « Paramètres »
//           </Typography>
//           <Typography sx={{ mb: 1, fontSize: "1rem" }}>
//             5) Consulter votre boite email, copier le code magique, répéter l’étape 3 puis renseigner le code et activer
//             votre licence
//           </Typography>
//           <Typography sx={{ mb: 3, fontSize: "1rem" }}>
//             A l’issue de votre période d’essai gratuite, Si vous souhaitez continuer à utiliser les services d’envoi de
//             SMS depuis Outlook, vous devrez vous rendre dans la boutique pour acheter une licence SMS Meeting.
//           </Typography>
//           <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
//             <Button
//               sx={{ p: 1.3, mt: 2 }}
//               variant="contained"
//               color="secondary"
//               onClick={() => {
//                 navigate("/myOffer");
//               }}
//               startIcon={<LocalMallIcon />}
//             >
//               <Typography fontWeight="400">Boutique</Typography>
//             </Button>
//           </Box>
//         </Box>
//       )}
//     </Box>
//   );
// };

// const MyOfferCard = ({ identity, freeTryAvailable, ...props }: any) => {
//   const [companyCredits, setCompanyCredits] = useState("");
//   const navigate = useNavigate();

//   useEffect(() => {
//     if (!companyCredits) {
//       const asyncCall = async () => {
//         const getCreditsRes = await api.get("/utils/getCredits/" + identity?.company);
//         setCompanyCredits(getCreditsRes.data.credits);
//       };
//       asyncCall();
//     }
//   }, [companyCredits, identity?.company]);

//   return (
//     <Box
//       display="flex"
//       flexDirection="column"
//       alignItems="center"
//       sx={{
//         height: ["auto", 0.43],
//         bgcolor: "primary.main",
//         borderRadius: "10px",
//         padding: "14px",
//         textAlign: "center",
//       }}
//     >
//       <Typography variant="h5" color="white" sx={{ mb: 3 }}>
//         Solde SMS
//       </Typography>
//       <Typography color="white" sx={{ mb: 1, width: 1 }}>
//         Il vous reste <b>{companyCredits}</b> crédits SMS disponibles.
//       </Typography>
//       <Typography color="white" sx={{ mb: 1 }}>
//         Pour en racheter afin d'être sur de ne pas en manquer, n'hésitez pas à vous rendre dans la boutique.
//       </Typography>
//       <Button
//         sx={{ p: 1.3, mt: 2 }}
//         variant="contained"
//         color="secondary"
//         onClick={() => {
//           navigate("/myOffer");
//         }}
//         startIcon={<LocalMallIcon />}
//       >
//         <Typography fontWeight="400">Acheter SMS</Typography>
//       </Button>
//     </Box>
//   );
// };

const SubscriptionsCard = ({ identity, ...props }: any) => {
  const navigate = useNavigate();
  const { data, isLoading } = useGetList("subscriptions", {
    pagination: { page: 1, perPage: 4 },
    filter: {
      collectionQuery: {
        collectionQuery: (c: any) => query(c, where("company_id", "==", identity?.company)),
      },
    },
    sort: { field: "subscriptionDate", order: "DESC" },
  });
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      sx={{
        height: ["auto", 0.55],
        bgcolor: "primary.main",
        borderRadius: "10px",
        paddingTop: "14px",
      }}
    >
      <Typography variant="h5" color="white">
        Mes abonnements
      </Typography>
      {isLoading ? null : (
        <TableContainer component={Paper} sx={{ width: 0.9, mt: 2, "& .MuiTableCell-root": { py: 1, px: 1 } }}>
          <Table sx={{ width: 1 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Référence</TableCell>
                <TableCell align="right">Montant TTC</TableCell>
                <TableCell align="right">Type</TableCell>
                <TableCell align="right">Statut</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((subscription: any) => (
                <TableRow
                  key={subscription}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    ":hover": { cursor: "pointer", bgcolor: "secondary.main" },
                  }}
                  onClick={() => {
                    navigate("/subscriptions/" + subscription.id);
                  }}
                >
                  <TableCell component="th" scope="row">
                    {subscription.id}
                  </TableCell>
                  <TableCell align="right">{subscription.totals.totalTtc}€</TableCell>
                  <TableCell align="right">{subscription.type}</TableCell>
                  <TableCell align="right">{subscription.cancelled ? "Annulé" : "En cours"}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Button
        sx={{ py: 1, px: 2, my: 3 }}
        variant="contained"
        color="secondary"
        onClick={() => {
          navigate("/subscriptions");
        }}
        startIcon={<PaymentIcon />}
      >
        <Typography fontWeight="400">Tous mes abonnements</Typography>
      </Button>
    </Box>
  );
};

const QuickStats = ({ identity, refetchIdentity, ...props }: any) => {
  const [companyCredits, setCompanyCredits] = useState("");
  const [sentThisMonth, setSentThisMonth] = useState(-1);
  const [scheduledSmsNumber, setScheduledSmsNumber] = useState(-1);
  const [cards, setCards] = useState([null, null, null, null] as any);
  const navigate = useNavigate();
  const { data: subscriptions } = useGetList("subscriptions", {
    pagination: { page: 1, perPage: 100 },
    filter: {
      company_id: identity?.company,
      cancelled: false,
    },
    sort: { field: "subscriptionDate", order: "DESC" },
  });

  useEffect(() => {
    if (!companyCredits) {
      const asyncCall = async () => {
        const getCreditsRes = await api.get("/utils/getCredits/" + identity?.company);
        setCompanyCredits(getCreditsRes.data.credits);
      };
      asyncCall();
    }
  }, [companyCredits, identity?.company]);

  useEffect(() => {
    if (sentThisMonth === -1) {
      const asyncCall = async () => {
        const params = {
          group_id: identity?.company,
          period: "currentMonth",
          timeUnit: "month",
          countType: "credits",
        };
        try {
          const { data } = await api.get("/sms/statistics", { params });
          if (Object.keys(data).length) {
            const totals: { [key: string]: number } = data;
            setSentThisMonth(Object.values(totals).reduce((smsNumber: number, acc: number) => smsNumber + acc));
          }
          const scheduledSmsRes = await api.get("/sms/getScheduledSMS", { params });
          setScheduledSmsNumber(scheduledSmsRes.data?.scheduledSmsNumber);
        } catch (error) {
          console.log("error", error);
        }
      };
      asyncCall();
    }
  }, [identity?.company, sentThisMonth]);

  useEffect(() => {
    if (
      !cards.filter((card: any) => card !== null).length &&
      companyCredits &&
      subscriptions &&
      sentThisMonth >= 0 &&
      scheduledSmsNumber >= 0
    ) {
      const daysRemaining = identity?.freeTry?.daysTillFreeTryEnd;
      const licenceSubscription = subscriptions?.find(
        (subscription: any) => subscription.type === "Licence" && !subscription?.cancelled
      );
      setCards([
        identity?.freeTry?.licenseConverted === false && daysRemaining > 0
          ? {
              number: daysRemaining,
              text: `jour${daysRemaining > 1 ? "s" : ""} restants`,
              actionText: "Acheter",
              action: () => {
                navigate("/myOffer");
              },
            }
          : {
              number: licenceSubscription?.licenses?.length || 0,
              text: "Utilisateur" + (licenceSubscription?.licenses?.length > 0 ? "s" : ""),
              actionText: "Modifier",
              action: () => {
                navigate("/myOffer");
              },
            },
        {
          number: companyCredits,
          text: "Crédits SMS",
          actionText: "Recharger",
          action: () => {
            navigate("/myOffer");
          },
        },
        {
          number: sentThisMonth,
          text: "Envoyés ce mois",
        },
        {
          number: scheduledSmsNumber,
          text: "Sms programmés",
        },
      ]);
    }
  }, [
    cards,
    companyCredits,
    identity?.freeTry?.daysTillFreeTryEnd,
    identity?.freeTry?.licenseConverted,
    navigate,
    scheduledSmsNumber,
    sentThisMonth,
    subscriptions,
  ]);
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: ["repeat(2, 1fr)", "repeat(2, 1fr)", "repeat(2, 1fr)", "repeat(4, 1fr)"],
        gridAutoRows: "1fr",
        alignItems: "start",
        width: 1,
        gap: [2, 2, 2, 4],
      }}
    >
      {cards.map((card: any) =>
        card === null ? (
          <Skeleton variant="rectangular" sx={{ p: 2, height: 130 }} />
        ) : (
          <Card
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              p: 2,
              height: 1,
            }}
          >
            <Typography variant="bold_2xl" color="primary">
              {card.number}
            </Typography>
            <Typography variant="medium_sm" color="common.title" sx={{ textAlign: "center" }}>
              {card.text}
            </Typography>
            {card.actionText && (
              <Button
                variant="contained"
                color="secondary"
                sx={{
                  mt: 2,
                  py: [0, "auto"],
                  width: 1,
                }}
                onClick={card.action}
              >
                {card.actionText}
              </Button>
            )}
          </Card>
        )
      )}
    </Box>
  );
};

const ActionCards = ({ identity, refetchIdentity, ...props }: any) => {
  const navigate = useNavigate();
  const cards: any = [
    {
      title: "Modèles SMS",
      subtitle: "Gagner du temps en créant des modèles de SMS accessibles et réutilisables par vos utilisateurs.",
      actionText: "Créer un modèle",
      action: () => {
        navigate("/templates/create");
      },
    },
    {
      title: "Utilisateurs",
      subtitle:
        "Créez puis gérer des utilisateurs facilement grâce au formulaire de création ou via la commande d'import",
      actionText: "Gérer",
      action: () => {
        navigate("/users");
      },
    },
    {
      title: "Centre d'aide",
      subtitle: "Laissez-vous guider par nos tutoriels videos et FAQs pour comprendre comment utiliser SMS Meeting",
      actionText: "Consulter",
      action: () => {
        window.location.href = "https://sms-meeting.com/assistance-sms-meeting/";
      },
    },
  ];
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: ["repeat(1, 1fr)", "repeat(3, 1fr)", "repeat(3, 1fr)", "repeat(3, 1fr)"],
        gridAutoRows: "1fr",
        alignItems: "start",
        mt: 2,
        gap: [2, 2, 2, 4],
        width: 1,
      }}
    >
      {cards.map((card: any) => (
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "start",
            gap: 1,
            borderRadius: 1,
            height: 1,
            p: 2,
            flex: 1,
            width: 1,
            maxWidth: 500,
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 0.5, width: 1 }}>
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 1, width: 1 }}>
              <Box width={"18px"} height={"18px"}>
                <ArrowRight size={18} color="green" />
              </Box>
              <Typography variant="bold_md" color="common.title">
                {card.title}
              </Typography>
            </Box>
            <Typography variant="regular_sm" color="common.text" ml="26px" sx={{ width: 1 }}>
              {card.subtitle}
            </Typography>
          </Box>

          {card.customButton ? (
            card.customButton
          ) : (
            <Button
              variant="contained"
              color="primary"
              sx={{
                fontSize: "0.9rem",
                width: 1,
                mt: 2,
              }}
              onClick={card.action}
            >
              {card.actionText}
            </Button>
          )}
        </Card>
      ))}
    </Box>
  );
};

const DashboardPage = () => {
  const { isLoading, data: identity } = useGetIdentity();
  const isMobile = !!useMediaQuery((theme: any) => theme.breakpoints.only("xs"));
  const isMobileOrSmallTablet = !!useMediaQuery((theme: any) => theme.breakpoints.down("md"));
  const navigate = useNavigate();

  return isLoading ? null : (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "start",
        position: "relative",
        width: 1,
      }}
    >
      {identity?.status !== "Collaborateur" && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "center",
            height: ["auto", "auto", 1],
            width: [1, 1, 0.9, 0.9, 0.7],
          }}
        >
          {identity?.freeTry?.daysTillFreeTryEnd >= 1 && identity?.freeTry?.licenseConverted === false && (
            <Card
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                borderLeft: "4px solid",
                borderLeftColor: "primary.main",
                px: [2, 2, 2, 3],
                py: [2, 2, 2, 2],
                width: 1,
                borderRadius: "8px",
                overflow: "unset",
                mb: [2, 4],
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  width: 1,
                }}
              >
                <Typography variant={isMobileOrSmallTablet ? "bold_md" : "bold_xl"} color="common.title">
                  Souscrivez maintenant et économisez 10%
                </Typography>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <Typography variant={isMobileOrSmallTablet ? "regular_sm" : "regular_md"} color="common.text">
                    Offre conditionnée à la souscription d'un abonnement durant votre période d'essai. <br />{" "}
                    Profitez-en vite, cette remise automatique sera expirée dans {identity?.freeTry?.daysTillFreeTryEnd}{" "}
                    jour{identity?.freeTry?.daysTillFreeTryEnd > 1 ? "s" : ""}.
                  </Typography>{" "}
                  {isMobileOrSmallTablet && (
                    <IconButton
                      sx={{
                        backgroundColor: "button.primary.contained.background",
                        color: "button.primary.contained.content",
                        p: [1, 1.5],
                        width: "36px",
                        height: "36px",
                        ml: 1,
                        borderRadius: 2,
                        ":hover": { bgcolor: "secondary.main", color: "secondary.contrastText" },
                      }}
                      onClick={() => {
                        navigate("/myOffer");
                      }}
                    >
                      <ArrowRight />
                    </IconButton>
                  )}
                </Stack>
              </Box>
              {!isMobileOrSmallTablet && (
                <IconButton
                  sx={{
                    backgroundColor: "button.primary.contained.background",
                    color: "button.primary.contained.content",
                    p: [1, 1.5],
                    borderRadius: 2,
                    ":hover": { bgcolor: "secondary.main", color: "secondary.contrastText" },
                  }}
                  onClick={() => {
                    navigate("/myOffer");
                  }}
                >
                  <ChevronRight />
                </IconButton>
              )}
            </Card>
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "start",
              pb: 4,
              width: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: ["column", "column", "row"],
                gap: [0, 0, 2],
              }}
            >
              <Typography variant="bold_2xl" sx={{ color: "common.title" }}>
                Bonjour {identity?.firstName}
              </Typography>
              <Typography
                variant="medium_sm"
                sx={{
                  backgroundColor: "button.primary.contained.background",
                  color: "button.primary.contained.content",
                  display: "flex",
                  alignItems: "center",
                  px: 1,
                  borderRadius: 2,
                  gap: 0.5,
                  width: ["fit-content", "auto"],
                }}
              >
                <Folder size={16} />
                {identity?.companyName}
              </Typography>
            </Box>
          </Box>
          <QuickStats identity={identity} />

          <Typography variant="bold_xl" color="common.title" sx={{ mt: 4, width: 1 }}>
            Nos conseils pour bien démarrer
          </Typography>
          <ActionCards identity={identity} />
        </Box>
      )}
    </Box>
  );
};

export default DashboardPage;
