import React, { useState, useEffect } from "react";
import { Building2, ChevronDown, X } from "lucide-react";
import { useAccountMenuState } from "../../services/hooks/useAccountMenuState";
import { Menu, useGetIdentity } from "react-admin";
import AccountMenuItems from "./AccountMenuItems";
import {
  Box,
  Typography,
  useMediaQuery,
  IconButton,
  useTheme,
  Button,
  Drawer,
  PopoverOrigin,
  Menu as MuiMenu,
} from "@mui/material";

const MyAccount = () => {
  const { identity } = useGetIdentity();
  const [open, setOpen] = useAccountMenuState();
  const theme = useTheme();
  const isMobile = !!useMediaQuery((theme: any) => theme.breakpoints.only("xs"));
  const toggleUserMenu = () => {
    if (isMobile) {
      setOpen(!open);
    }
  };
  //desktop
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openDesktop = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const sxMenu: any = {
    "& .MuiMenuItem-root": {
      height: "2.5rem",
      ...theme.typography.medium_sm,
      color: theme.palette.common.text + " !important",
      bgcolor: "inherit !important",
      boxShadow: "none !important",
      minHeight: "unset",
      ...(isMobile ? { width: "80vw", height: "2.3rem", pl: 0 } : {}),
      "& .MuiListItemIcon-root": {
        minWidth: 16,
        mr: 2,
      },
    },
  };

  return !isMobile ? (
    <>
      <Button
        variant="contained"
        color="secondary"
        id="top-rigth-button"
        aria-controls={openDesktop ? "top-rigth-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={openDesktop ? "true" : undefined}
        onClick={handleClick}
        sx={{ py: 0, px: 1 }}
      >
        <Typography
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "35px",
            flexDirection: "row",
            gap: 1,
          }}
        >
          <Building2 size={18} />
          {!isMobile && (
            <Typography variant="bold_sm">
              {identity?.status === "Collaborateur" ? "Metaventus" : identity?.companyName}
            </Typography>
          )}
          <ChevronDown size={18} />
        </Typography>
      </Button>
      <MuiMenu
        id="top-rigth-menu"
        anchorEl={anchorEl}
        anchorOrigin={AnchorOrigin}
        transformOrigin={TransformOrigin}
        open={openDesktop}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "top-rigth-button",
        }}
        sx={{ ...sxMenu, mt: 1, width: "100%", "& .MuiList-root": { width: 280 } }}
      >
        <AccountMenuItems closeMenu={handleClose} />
      </MuiMenu>
    </>
  ) : (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "35px",
          flexDirection: "row",
          gap: 1,
          px: [1, 2],
          borderRadius: 2,
          backgroundColor: "button.secondary.contained.background",
          color: "button.secondary.contained.content",
          cursor: "pointer",
        }}
        onClick={toggleUserMenu}
      >
        <Building2 size={18} />
        {!isMobile && <Typography variant="bold_sm">{identity?.companyName}</Typography>}
        <ChevronDown size={18} />
      </Box>
      <Drawer
        variant="temporary"
        open={open}
        anchor={"right"}
        onClose={toggleUserMenu}
        PaperProps={{
          sx: { width: 1, bgcolor: theme.palette.common.background + " !important" },
        }}
      >
        <IconButton
          sx={{
            position: "absolute",
            p: 1,
            right: "1rem",
            top: "2rem",
            bgcolor: "button.neutral.contained.background",
            borderRadius: 2,
          }}
          onClick={toggleUserMenu}
        >
          <X size={18} color={theme.palette.button.neutral.contained.content} />
        </IconButton>
        <Menu
          sx={{
            width: "100%",
            paddingTop: 0,
            ...sxMenu,
            ml: "1.5rem !important",
            mt: "2rem !important",
            gap: 2,
          }}
          disableScrollLock={true}
        >
          <AccountMenuItems closeMenu={() => setOpen(false)} isMobile />
        </Menu>
      </Drawer>
    </>
  );
};

export default MyAccount;

const AnchorOrigin: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "right",
};

const TransformOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "right",
};
