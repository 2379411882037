import React, { useState } from "react";
import { useListContext, useUnselectAll, useNotify, useRefresh } from "react-admin";
import api from "../../services/apiWithAuth";
import { X } from "lucide-react";
import { Typography, Button } from "@mui/material";

const UsersBulkRemoveLicenses = ({ company }: any) => {
  const { selectedIds, data: users } = useListContext();
  const [isLoading, setisLoading] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll("users");

  const removeLicenses = async () => {
    setisLoading(true);
    const selectedLicenses = users
      .filter((user: any) => selectedIds.includes(user.id) && user.licenseId)
      .map((user: any) => {
        return { id: user.licenseId, email: user.email };
      });
    try {
      const response = await api.post("/licenses/removeLicenses", {
        licenses: selectedLicenses,
      });
      setisLoading(false);
      refresh();
      notify("Désaffectation de " + response.data.length + " licences effectuée");
      unselectAll();
    } catch (err) {
      setisLoading(false);
      refresh();
      notify("Erreur lors de la désaffectation des licences", { type: "error" });
    }
  };

  return (
    <Button disabled={isLoading} onClick={removeLicenses}>
      <X size={18} />{" "}
      <Typography variant="medium_sm" ml={0.5}>
        Désaffecter licences
      </Typography>
    </Button>
  );
};

export default UsersBulkRemoveLicenses;
