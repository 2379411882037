import React, { useState, useEffect, useCallback } from "react";
import { useNotify, useGetIdentity, useCreate, useRefresh } from "react-admin";
import FormInput from "../../components/form/FormInput";
import CreateInDialog from "../../components/react-admin/CreateInDialog";
import { Box } from "@mui/material";
import { boolean, object, optional, string } from "zod";

const formSchema = object({
  firstname: string({
    invalid_type_error: "Prénom requis",
  }).min(1, "Prénom requis"),
  lastname: string({
    invalid_type_error: "Nom requis",
  }).min(1, "Nom requis"),
  phonenumber: string({
    invalid_type_error: "Téléphone requis",
  }).min(1, "Téléphone requis"),
  email: string().min(1, "Adresse mail requise").email("Adresse mail invalide").nullish(),
  group_id: string().nullish(),
});

const ContactsCreate = ({ ...props }) => {
  const notify = useNotify();
  const { isLoading, data: identity } = useGetIdentity();
  // const [crmGroupId, setCrmGroupId] = useState("");
  const [key, setKey] = useState(0);
  const refresh = useRefresh();

  const [create] = useCreate();
  const save = useCallback(
    async (values: any) => {
      const errors = {} as any;
      delete values.customExpeditor;
      if (["Client", "Indirect"].includes(identity?.status)) {
        values.group_id = identity?.company;
      }
      if (["Revendeur"].includes(identity?.status)) {
        values.group_id = values?.group_id || identity?.company;
      }
      try {
        await create("contacts", { data: values }, { returnPromise: true });
        notify("Utilisateur créé avec succès", {
          type: "success",
          messageArgs: { smart_count: 1 },
        });
        setKey((key) => key + 1);
        refresh();
      } catch (error) {
        notify("Erreur lors de la création du contact", {
          type: "error",
          messageArgs: { smart_count: 1 },
        });
        setKey((key) => key + 1);
        refresh();
      }
    },
    [create, identity?.company, identity?.status, notify]
  );

  // Success override
  const onSuccess = async (data: any) => {
    // if (crmGroupId) {
    //   try {
    //     const groupRes = await dataProvider.getOne("groups", { id: crmGroupId });
    //     update("groups", {
    //       id: crmGroupId,
    //       data: { contacts: (groupRes.data.contacts || []).concat(data.id) },
    //       previousData: groupRes.data,
    //     });
    //   } catch (error) {}
    // }
    notify(`Contact créé avec succès`, { type: "success" });
  };

  return isLoading ? (
    <></>
  ) : (
    <CreateInDialog
      key={key}
      {...props}
      title="Ajouter un contact"
      label="Nouveau contact"
      resource="contacts"
      formSchema={formSchema}
      mutationOptions={{ onSuccess }}
      fullWidthFields
      formProps={{ onSubmit: save }}
    >
      <Box sx={{ display: "grid", gridTemplateColumns: ["1fr", "1fr", "1fr 1fr"], gap: 2 }}>
        <FormInput label="Prénom" name="firstname" required />
        <FormInput label="Nom" name="lastname" required />
        <FormInput label="Numéro de téléphone" name="phonenumber" type="phone" required />
        <FormInput label="Adresse email" name="email" type="email" defaultValue={null} />
        {/* <BooleanInput defaultValue={true} source="group_access" label="Visible par tout les utilisateurs" />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.group_access === false && (
              <ReferenceInput required source="crmGroupId" reference="groups" filter={{ company: formData.group_id }}>
                <AutocompleteInput
                  label="Groupe d'accès au contact"
                  optionText="name"
                  filterToQuery={(search: any) => ({ name: search })}
                  helperText="Seuls les utilisateurs du groupe pourront accéder au contact"
                />
              </ReferenceInput>
            )
          }
        </FormDataConsumer> */}
      </Box>
    </CreateInDialog>
  );
};

export default ContactsCreate;
