import { Box, Typography } from "@mui/material";
import React from "react";
import {
  Edit,
  Labeled,
  ReferenceField,
  TextField,
  useRecordContext,
  FunctionField,
  TabbedFormTabs,
  ReferenceInput,
} from "react-admin";
import { FormTab, TabbedForm } from "@react-admin/ra-rbac";
import PaymentMethodField from "../../components/fields/PaymentMethodField";
import PaymentsField from "../../components/fields/PaymentsField";
import PageTitle from "../../components/navigation/PageTitle";
import FormInput from "../../components/form/FormInput";
import { AutocompleteInput } from "../../components/form/AutocompleteInput";
import { PaymentMethodDisplay } from "../my-offer/SwitchablePaymentMethod";

const CartField = ({ props }: any) => {
  const { cart, totals } = useRecordContext();
  return (
    <Box>
      {cart.map((item: any) => {
        return (
          <Box display="flex" flexDirection="row" mb="1" key={item.product.name}>
            <Typography>&bull; &nbsp; {item.product.name}</Typography>
            <Typography>&nbsp; - {item.product?.type}</Typography>
            <Typography>&nbsp; - x{item.quantity}</Typography>
            <Typography>&nbsp; - {totals.totalByProduct[item.product.id].totalHt.toFixed(2)}€ HT</Typography>
          </Box>
        );
      })}
    </Box>
  );
};

const OrdersEdit = ({ ...props }) => {
  return (
    <Edit {...props}>
      <PageTitle
        text={(record: any) => `Commande ${record.id}`}
        type="edit"
        resource="orders"
        breadcrumbListLabel="Liste des commandes"
      />
      <TabbedForm tabs={<TabbedFormTabs indicatorColor="primary" />}>
        <FormTab label="Commande" name="order">
          <Box sx={{ display: "grid", gridTemplateColumns: ["1fr", "1fr", "1fr 1fr"], gap: 2 }}>
            <ReferenceInput source="company_id" reference="companies" perPage={1}>
              <AutocompleteInput
                label="Organisation"
                optionText="company"
                filterToQuery={(search: any) => ({ company: search })}
                disabled
              />
            </ReferenceInput>
            <FormInput source="id" label="Référence" disabled />
            <ReferenceInput source="user_id" reference="users" perPage={1}>
              <AutocompleteInput
                label="Utilisateur"
                optionText="email"
                filterToQuery={(search: any) => ({ company: search })}
                disabled
              />
            </ReferenceInput>
            <FunctionField
              render={(record: any) => (
                <FormInput
                  source="amount"
                  type="display"
                  label="Montant"
                  defaultValue={`${Number(record.totals.totalHt).toFixed(2)}€ HT (${Number(
                    record.totals.totalTtc
                  ).toFixed(2)}€ TTC)`}
                  disabled
                />
              )}
            />
            <ReferenceInput source="discount_id" reference="discounts" perPage={1}>
              <AutocompleteInput
                label="Remise"
                optionText="name"
                filterToQuery={(search: any) => ({ company: search })}
                disabled
              />
            </ReferenceInput>
            <FunctionField
              render={(record: any) => <PaymentMethodDisplay paymentMethodId={record.paymentMethod.id} disabled/>}
            />
            <FormInput source="status" label="Statut commande" disabled />
            <FormInput source="paymentStatus" label="Statut paiement" disabled />
          </Box>
          <Typography variant="bold_sm" color="common.title" mt={2}>
            Contenu commande
          </Typography>
          <CartField />
        </FormTab>
        <FormTab
          label="Paiements"
          name="payments"
          sx={{
            "& .RaDatagrid-root": { width: 1 },
            "& .RaFileInput-root": { maxWidth: "300px" },
            "& .MuiTextField-root": { maxWidth: "300px" },
            "& .MuiInputBase-root": { width: 1 },
          }}
        >
          <PaymentsField target="order_id" />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default OrdersEdit;
