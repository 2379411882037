import {
  Badge,
  Box,
  Button,
  Card,
  CircularProgress,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useListContext } from "react-admin";
import Stats from "../../components/charts/Stats";
import api from "../../services/apiWithAuth";
import StatsDrawer from "./StatsDrawer";
import randomcolor from "randomcolor";

const periodToLabel: { [key: string]: string } = {
  currentMonth: "sur le mois en cours",
  lastMonth: "le mois précédent",
  currentYear: "sur l'année en cours",
  lastYear: "l'année précédente",
};

const colorsArray = [...Array(100).keys()].map((i) => randomcolor());

const SmsStats = ({ widthComputer = 1, showLegend = true, groupId, displayDetails, setDisplayDetails }: any) => {
  const { filterValues } = useListContext();
  const period = filterValues?.period || "currentMonth";
  const startDate = filterValues?.startDate;
  const endDate = filterValues?.endDate;
  const group_id = filterValues?.group_id || groupId;
  const userId = filterValues?.userId;
  const crmGroupId = filterValues?.crmGroupId;
  const countType = filterValues?.countType || "SMS";
  const filterType = filterValues?.filterType;
  const viewType = filterValues?.viewType || "statistics";
  const [datesTotal, setDatesTotal] = useState<any>(null);
  const [timeUnit, setTimeUnit] = useState("day");
  const [totalOnPeriod, setTotalOnPeriod] = useState<any>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [totalByCrmGroupOnPeriod, setTotalByCrmGroupOnPeriod] = useState({} as any);
  const isMobile = !!useMediaQuery((theme: any) => theme.breakpoints.only("xs"));
  const theme = useTheme();

  useEffect(() => {
    if (viewType === "statistics" && period && (period !== "custom" || (period === "custom" && startDate && endDate))) {
      const asyncCall = async () => {
        const timeUnit =
          period === "custom"
            ? (new Date(endDate).getTime() - new Date(startDate).getTime()) / 86400000 > 31 // if more than 1 month between dates
              ? "month"
              : "day"
            : period.includes("Year")
            ? "month"
            : "day";
        setTimeUnit(timeUnit);
        const params = {
          ...(group_id && { group_id }),
          ...(period && { period }),
          ...(period && { timeUnit }),
          ...(period === "custom" && { startDate, endDate }),
          ...(countType && { countType }),
          ...(filterType === "user" && userId && { userId }),
          ...((crmGroupId || filterType === "allCrmGroups") && {
            crmGroupId: filterType === "allCrmGroups" ? "all" : crmGroupId,
          }),
        };
        try {
          setIsLoading(true);
          const { data } = await api.get("/sms/statistics", { params });
          setDatesTotal(data);
          if (Object.keys(data).length) {
            if (params.crmGroupId) {
              const totalsByGroup = {} as any;
              Object.keys(data).forEach((crmGroup: string) => {
                totalsByGroup[crmGroup] = Object.values(data[crmGroup]).reduce(
                  (acc: number, smsNumber: any) => acc + parseInt(smsNumber),
                  0
                );
              });
              setTotalByCrmGroupOnPeriod(totalsByGroup);
              setTotalOnPeriod(
                Object.values(totalsByGroup).reduce((acc: number, smsNumber: any) => acc + parseInt(smsNumber), 0)
              );
            } else {
              const totals: { [key: string]: number } = data;
              setTotalByCrmGroupOnPeriod({});
              setTotalOnPeriod(
                Object.values(totals).reduce((acc: number, smsNumber: any) => {
                  return acc + parseInt(smsNumber);
                }, 0)
              );
            }
          }
          setIsLoading(false);
        } catch (error) {
          console.log("error", error);
        }
      };
      asyncCall();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group_id, period, userId, crmGroupId, startDate, endDate, filterType, viewType, countType]);

  useEffect(() => {
    if (filterType !== "allCrmGroups") {
      setDisplayDetails(false);
    }
    if (filterType === "allCrmGroups" && !isMobile) {
      setDisplayDetails(true);
    }
  }, [filterType, isMobile]);

  return viewType !== "statistics" ? null : (
    <Box
      sx={{
        width: 1,
        my: 2,
        display: "flex",
        flexDirection: ["column", "column"],
        justifyContent: "space-around",
        alignItems: "start",
      }}
    >
      <Box
        sx={{
          width: [1, widthComputer],
          height: "55vh",
          mt: 2,
          position: "relative",
          display: "flex",
          overflow: "hidden",
          pb: 2,
        }}
      >
        <Box
          sx={{
            height: 1,
            position: "relative",
            width: displayDetails ? [0, "calc(100% - 360px)"] : 1,
          }}
        >
          <Typography variant="regular_md" sx={{ ml: 3 }}>
            <b>
              {totalOnPeriod} {countType === "SMS" ? "SMS" : "crédits SMS"}
            </b>{" "}
            {countType === "SMS" ? "envoyés" : "consommés"} {periodToLabel[period]}.{" "}
          </Typography>
          <Box sx={{ width: 1, height: 1, position: "relative" }}>
            <Stats
              datesTotal={datesTotal}
              showLegend={showLegend}
              timeUnit={timeUnit}
              label={countType === "SMS" ? "SMS envoyés" : "Crédits consommés"}
              randomColors={colorsArray}
            />
            {isLoading && (
              <Box
                sx={{
                  width: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  top: 150,
                  opacity: 0.8,
                  zIndex: 100000,
                }}
              >
                <CircularProgress sx={{ color: "neutral.dark" }} />
              </Box>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            width: displayDetails ? [1, 360] : 0,
            transition: "opacity 0.2s",
            transitionDelay: "0.3s",
            opacity: displayDetails ? 1 : 0,
            borderLeft: displayDetails ? `solid ${theme.palette.neutral.main} 1px` : "none",
            px: displayDetails ? 2 : 0,
          }}
        >
          {Object.keys(totalByCrmGroupOnPeriod).length > 0 && displayDetails && (
            <>
              <Typography variant="medium_sm" sx={{ width: 1, textAlign: "center", mb: 2, display: "inline-block" }}>
                {countType === "SMS" ? "SMS envoyés" : "Crédits SMS consommés"} {isMobile ? "" : periodToLabel[period]}
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
                <Typography variant="bold_sm">Groupe</Typography>
                <Typography variant="bold_sm" sx={{ mr: 2 }}>
                  {countType === "SMS" ? "SMS" : "Crédits"}
                </Typography>
              </Box>
              <Box sx={{ overflowY: "auto", overflowX: "hidden", height: 0.8, px: 1 }}>
                {Object.keys(totalByCrmGroupOnPeriod).map((crmGroup: string, index: number) => (
                  <Box
                    key={crmGroup}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mb: 1,
                    }}
                  >
                    <Typography variant="regular_sm" sx={{ display: "flex", alignItems: "center" }}>
                      <Typography variant="bold_xl" sx={{ color: colorsArray[index], mr: 1 }}>
                        &#x2022;
                      </Typography>
                      {crmGroup.split("$")[1]?.length > 25
                        ? crmGroup.split("$")[1].slice(0, 22) + "..."
                        : crmGroup.split("$")[1]}
                    </Typography>
                    <Typography variant="regular_sm">{totalByCrmGroupOnPeriod[crmGroup]}</Typography>
                  </Box>
                ))}
              </Box>
            </>
            // <TableContainer component={Paper} sx={{ width: [1, 0.8], my: 4 }}>
            //   <Table sx={{ minWidth: [300, 650] }} aria-label="simple table">
            //     <TableHead>
            //       <TableRow>
            //         <TableCell>Groupe</TableCell>
            //         <TableCell align="right">
            //           {countType === "SMS" ? "SMS envoyés" : "Crédits SMS consommés"}{" "}
            //           {isMobile ? "" : periodToLabel[period]}
            //         </TableCell>
            //       </TableRow>
            //     </TableHead>
            //     <TableBody>
            //       {Object.keys(totalByCrmGroupOnPeriod).map((crmGroup: string) => (
            //         <TableRow key={crmGroup} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            //           <TableCell component="th" scope="row">
            //             {crmGroup.split("$")[1]}
            //           </TableCell>
            //           <TableCell align="right">{totalByCrmGroupOnPeriod[crmGroup]}</TableCell>
            //         </TableRow>
            //       ))}
            //     </TableBody>
            //   </Table>
            // </TableContainer>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default SmsStats;
