import { Grid, Typography } from "@mui/material";
import React from "react";
import { Edit, ImageField, ImageInput, FileInput, FileField, required } from "react-admin";
import { SimpleForm } from "@react-admin/ra-rbac";
import PageTitle from "../../components/navigation/PageTitle";
import FormInput from "../../components/form/FormInput";
import { Download, schema } from "./IDownload";
import { zodResolver } from "@hookform/resolvers/zod";

const DownloadsEdit = ({ ...props }) => {
  const onTransform = (data: Download) => {
    return data;
  };

  return (
    <Edit {...props} transform={onTransform}>
      <PageTitle
        text={(record: any) => record.name}
        type="edit"
        resource="downloads"
        breadcrumbListLabel="Liste des connecteurs"
      />
      <SimpleForm resolver={zodResolver(schema)}>
        <Grid container direction={"column"}>
          <FormInput required source="name" label="Nom du logiciel" />
          <FormInput required source="description" label="Description" type="multiline" />
          <FormInput required source="version" label="Version" />
          <Typography variant="bold_sm" color="common.title">
            Image (max 200ko)
          </Typography>
          <ImageInput validate={required()} source="pictures" label={<></>} maxSize={200000} accept="image/*">
            <ImageField source="src" title="title" />
          </ImageInput>
          <Typography variant="bold_sm" color="common.title">
            Exécutable du logiciel
          </Typography>
          <FileInput isRequired source="software" label={<></>}>
            <FileField source="src" title="title" />
          </FileInput>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

export default DownloadsEdit;
