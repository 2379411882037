import React, { useEffect, useRef, useState } from "react";
import { count } from "sms-length";
import { useRecordContext } from "react-admin";
import { Box, Chip, Typography, useTheme } from "@mui/material";
import { useFormContext, useWatch } from "react-hook-form";
import FormInput from "../form/FormInput";

const getHelperFromSms = (Sms: string) => {
  const { length, messages, remaining } = count(Sms || "");
  let warning = "";
  if ((length + remaining) / messages <= 70) {
    warning =
      "Attention : votre texte contient au moins un caractère unicode, la longueur d'un SMS est donc réduite et la quantité de crédits utilisée peut être augmentée";
  }
  return (
    <>
      {length}/{length + remaining} = {messages} SMS <br />
      {warning ? <span style={{ color: "orange", fontWeight: "bold" }}>{warning}</span> : ""}
    </>
  );
};

const VariableChip = ({ text, variable, setVariable, available }) => {
  return (
    <Chip
      onClick={() => setVariable(`[${variable}]`)}
      label={
        <Typography
          variant="medium_xs"
          sx={{
            color: `button.${available ? "secondary" : "neutral"}.contained.content`,
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          {text}
        </Typography>
      }
      sx={{
        width: "fit-content",
        backgroundColor: `button.${available ? "secondary" : "neutral"}.contained.background`,
        borderRadius: 2,
        height: "auto",
        "& .MuiChip-label": {
          py: "2px",
          px: 1,
        },
      }}
    />
  );
};

const SmsTextInput = ({ source, label, validator, direction = "row", ...props }: any) => {
  const record = useRecordContext(props);
  const [helper, setHelper] = useState(getHelperFromSms(record ? record[source] : ""));
  const [newCursorPosition, setNewCursorPosition] = useState(0);
  const [selectedVariable, setSelectedVariable] = useState<null | string>(null);
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<any>(null);
  const containerRef = useRef<any>(null);
  const { setValue } = useFormContext();
  const smsInputValue = useWatch({ name: source });

  const variables = {
    DATE: "Date début RDV",
    DATE_END: "Date fin RDV",
    HOUR: "Heure début RDV",
    HOUR_END: "Heure fin RDV",
    LOCATION: "Lieu RDV",
    USER_CIVILITY: "Civilité destinataire",
    USER_FIRSTNAME: "Nom destinataire",
    USER_NAME: "Prénom destinataire",
    USER_PHONE: "Téléphone destinataire",
  };

  useEffect(() => {
    if (selectedVariable) {
      setNewCursorPosition(inputRef?.current?.selectionStart + selectedVariable.length);
      const sms = smsInputValue || "";
      setValue(
        source,
        sms.substring(0, inputRef?.current?.selectionStart) +
          selectedVariable +
          sms.substring(inputRef?.current?.selectionStart, sms.length)
      );
      inputRef.current.focus();
      setSelectedVariable(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVariable]);

  useEffect(() => {
    if (newCursorPosition) {
      inputRef.current.setSelectionRange(newCursorPosition, newCursorPosition);
      setNewCursorPosition(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newCursorPosition]);

  useEffect(() => {
    if (!inputRef.current && containerRef.current) {
      inputRef.current = containerRef.current.getElementsByTagName("textarea")[0];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef?.current]);

  useEffect(() => {
    if (inputRef?.current?.value) {
      setHelper(getHelperFromSms(inputRef?.current?.value));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputRef?.current?.value]);

  return (
    <Box
      sx={{ display: "flex", flexDirection: ["column", direction], gap: [0, 2], width: [1, "fit-content"], mb: 2 }}
      ref={containerRef}
    >
      <FormInput
        name={source}
        label={label}
        helperText={helper}
        type="multiline"
        setIsInputFocused={setIsFocused}
        tooltip="Vous pouvez insérer des variables personnalisées en fonction du rendez-vous et du destinataire en cliquant sur un des boutons à côté de ce champ"
      />
      <Box
        sx={{
          width: [1, 400],
          height: "fit-content",
          display: "flex",
          flexWrap: "wrap",
          gap: [1, 1.5],
          mt: [0, direction === "row" ? 4 : 0],
          alignItems: "center",
        }}
      >
        {Object.keys(variables).map((variable: any) => (
          <VariableChip
            key={variable}
            text={variables[variable]}
            variable={variable}
            setVariable={setSelectedVariable}
            available={isFocused}
          />
        ))}
      </Box>
    </Box>
  );
};

export default SmsTextInput;
