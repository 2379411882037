import React from "react";
import {
  Edit,
  FormDataConsumer,
  ReferenceArrayInput,
  SimpleForm,
  ReferenceField,
  useGetIdentity,
  useNotify,
  ReferenceInput,
} from "react-admin";
import useCustomListRedirect from "../../services/hooks/useCustomRedirect";
import AutocompleteDualListInput from "../../components/fields/AutocompleteDualListInput";
import PageTitle from "../../components/navigation/PageTitle";
import FormInput from "../../components/form/FormInput";
import { AutocompleteInput } from "../../components/form/AutocompleteInput";

const GroupsEdit = ({ ...props }) => {
  const { isLoading, data: identity } = useGetIdentity();
  const notify = useNotify();
  const redirect = useCustomListRedirect();

  // Transform override
  const onTransform = (data: any) => {
    delete data.itemToAdd;
    delete data.itemToRemove;
    return {
      ...data,
    };
  };

  const onSuccess = (data: any) => {
    notify(`Groupe modifié avec succès`, { type: "success" });
    redirect("groups");
  };

  return (
    <Edit {...props} transform={onTransform} mutationMode="optimistic" mutationOptions={{ onSuccess }} hasShow={false}>
      <PageTitle
        text={(record: any) => record.name}
        type="edit"
        resource="groups"
        breadcrumbListLabel="Liste des groupes"
      />
      <SimpleForm>
        <FormInput required label="Nom du groupe" source="name" />
        {["Collaborateur", "Revendeur"].includes(identity?.status) && (
          <ReferenceInput source="company" reference="companies" perPage={1000}>
            <AutocompleteInput label="Organisation" optionText="company" disabled />
          </ReferenceInput>
        )}
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <ReferenceArrayInput
              reference="users"
              source="users"
              filter={{
                company: ["Collaborateur", "Revendeur"].includes(identity?.status)
                  ? formData.company
                  : identity?.company,
                crmGroupId: "none",
              }}
              perPage={1000}
              sort={{ field: "firstName", order: "ASC" }}
            >
              <AutocompleteDualListInput
                label="Utilisateurs"
                optionText={(user: any) => `${user.firstName} ${user.lastName}`}
                queryField="multi_firstName_lastName_email"
                source="users"
              />
            </ReferenceArrayInput>
          )}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

export default GroupsEdit;
