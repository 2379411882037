import React from "react";
import { Create, SimpleForm, useNotify, useRedirect } from "react-admin";
import { CRM_ROLES, SERVICES, TITLES } from "../../services/common-utils/constants";
import PageTitle from "../../components/navigation/PageTitle";
import FormInput from "../../components/form/FormInput";
import { zodResolver } from "@hookform/resolvers/zod";
import { object, string } from "zod";

const formSchema = object({
  email: string().email({ message: "Veuillez saisir une adresse e-mail valide." }),
  title: string().min(1, { message: "Veuillez sélectionner une civilité." }),
  firstName: string().min(1, { message: "Veuillez saisir le prénom." }),
  lastName: string().min(1, { message: "Veuillez saisir le nom de famille." }),
  role: string().min(1, { message: "Veuillez spécifier le role du collaborateur." }),
  service: string().optional(),
});

const CollaboratorsCreate = ({ ...props }) => {
  const notify = useNotify();
  const redirect = useRedirect();

  // Used to first signup the account
  const onTansform = async (data: any) => {
    return {
      ...data,
      id: data.email,
      accountStatus: "Actif",
    };
  };

  // Success override
  const onSuccess = (data: any) => {
    notify(`Compte créé avec succès`, { type: "success" });
    redirect("edit", "collaborators", data.id, data);
  };

  return (
    <Create {...props} transform={onTansform} mutationOptions={{ onSuccess }}>
      <PageTitle
        text={"Création d'un collaborateur"}
        type="create"
        resource="collaborators"
        breadcrumbListLabel={"Liste des collaborateurs"}
        breadcrumbListUrl={"/admin/digisoft/2"}
        breadcrumbCreateLabel={"Nouveau collaborateur"}
      />
      <SimpleForm resolver={zodResolver(formSchema)}>
        <FormInput
          required
          source="email"
          label="Adresse email"
          helperText="Cet e-mail sera utilisée pour se connecter au CRM."
        />
        <FormInput
          type="select"
          required
          source="title"
          label="Civilité"
          values={TITLES.map((s) => ({ id: s, label: s }))}
        />
        <FormInput required source="firstName" label="Prénom" />
        <FormInput required source="lastName" label="Nom de famille" />
        <FormInput
          type="select"
          required
          source="role"
          label="Role CRM"
          values={CRM_ROLES.map((s) => ({ id: s, label: s }))}
        />
        <FormInput type="select" source="service" label="Service" values={SERVICES.map((s) => ({ id: s, label: s }))} />
      </SimpleForm>
    </Create>
  );
};

export default CollaboratorsCreate;
