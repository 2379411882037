import React, { Children, useCallback, useEffect, useState } from "react";
// import { Tabs, Tab, Divider, InputAdornment } from "@mui/material";
import {
  Filter,
  ReferenceInput,
  DateField,
  TextField,
  FunctionField,
  useListContext,
  Count,
  useGetIdentity,
  ReferenceField,
  FormDataConsumer,
} from "react-admin";
import { List } from "@react-admin/ra-rbac";
import { CustomDatagrid } from "../../components/react-admin/CustomDatagrid";
import SmsStats from "./SmsStats";
import FormInput from "../../components/form/FormInput";
import { AutocompleteInput } from "../../components/form/AutocompleteInput";
import { ListActions } from "../../components/react-admin/ListActions";
import { useSearchParams } from "react-router-dom";
import PageTitle from "../../components/navigation/PageTitle";
import { Button, useTheme, alpha, Box } from "@mui/material";
import ListChip from "../../components/fields/ListChip";
// import { useFormContext, useWatch } from "react-hook-form";

// const ViewTypeInput = () => {
//   const filterType = useWatch({ name: "filterType" });
//   const { setValue } = useFormContext();

//   useEffect(() => {
//     setValue("crmGroupId", null);
//     setValue("userId", null);
//   }, [filterType, setValue]);

//   return (
//     <FormInput
//       type="select"
//       name="viewType"
//       values={[
//         { id: "historic", label: "Historique" },
//         { id: "statistics", label: "Statistiques" },
//       ]}
//       alwaysOn
//       filledSecondary
//     />
//   );
// };

const getFilterTypes = (viewType: string) => {
  const filterTypes = [
    { id: "user", label: "Utilisateur" },
    { id: "crmGroup", label: "Groupe spécifique" },
  ];
  if (viewType === "historic") {
    filterTypes.push({ id: "phone_number", label: "Numéro téléphone" });
  } else {
    filterTypes.push({ id: "allCrmGroups", label: "Tous les groupes" });
  }
  return filterTypes;
};

const SmsList = ({ ...props }) => {
  const { isLoading, identity } = useGetIdentity();
  const viewType = window.location.pathname.includes("sms-statistics") ? "statistics" : "historic";
  const displayDetailsButton = window.location.href.includes("allCrmGroups");
  const [displayDetails, setDisplayDetails] = useState(false);
  const theme = useTheme();
  return isLoading || !viewType ? null : (
    <>
      <PageTitle
        text={viewType === "statistics" ? "Statistiques d'envoi" : "Journal d'envoi"}
        createButton={<></>}
        type="list"
        breadcrumbListLabel={viewType === "statistics" ? "Statistiques d'envoi des SMS" : "Journal d'envoi de SMS"}
      />
      <List
        perPage={5}
        {...props}
        key={viewType}
        exporter={false}
        empty={false}
        filter={
          props.group_id
            ? {
                group_id: props.group_id,
                viewType,
              }
            : ["Client", "Indirect"].includes(identity?.status)
            ? { group_id: identity?.company, viewType }
            : { viewType }
        }
        filters={
          <Filter {...props}>
            {!props.group_id && ["Collaborateur"].includes(identity?.status) && (
              <ReferenceInput
                source="group_id"
                reference="companies"
                alwaysOn
                filter={identity?.status === "Revendeur" ? { parentCompany: identity?.company } : {}}
                sort={{ field: "company", order: "ASC" }}
                perPage={1000}
              >
                <AutocompleteInput
                  placeholder="Organisation"
                  optionText="company"
                  filterToQuery={(search: any) => ({ company: search })}
                  filledSecondary
                />
              </ReferenceInput>
            )}
            <FormInput
              type="select"
              placeholder="Période"
              name="period"
              values={[
                { id: "currentMonth", label: "Mois en cours" },
                { id: "lastMonth", label: "Mois précédent" },
                { id: "currentYear", label: "Année en cours" },
                { id: "lastYear", label: "Année précédente" },
                { id: "custom", label: "Dates personnalisées" },
              ]}
              alwaysOn
              filledSecondary
            />

            <FormDataConsumer alwaysOn>
              {({ formData, ...rest }) => {
                return formData.period !== "custom" ? null : (
                  <FormInput type="date" placeholder="Date début" name="startDate" alwaysOn filledSecondary />
                );
              }}
            </FormDataConsumer>
            <FormDataConsumer alwaysOn>
              {({ formData, ...rest }) => {
                return formData.period !== "custom" ? null : (
                  <FormInput type="date" placeholder="Date fin" name="endDate" alwaysOn filledSecondary />
                );
              }}
            </FormDataConsumer>
            <FormDataConsumer alwaysOn>
              {({ formData, ...rest }) => {
                return formData.viewType !== "statistics" ? null : (
                  <FormInput
                    type="select"
                    name="countType"
                    values={[
                      { id: "SMS", label: "Par sms envoyés" },
                      { id: "credits", label: "Par crédits consommés" },
                    ]}
                    alwaysOn
                    filledSecondary
                  />
                );
              }}
            </FormDataConsumer>
            <FormDataConsumer alwaysOn>
              {({ formData, ...rest }) => {
                return formData.viewType !== "historic" ? null : (
                  <FormInput
                    type="select"
                    name="sent"
                    values={[
                      { id: "false", label: "Programmés" },
                      { id: "true", label: "Envoyés" },
                    ]}
                    alwaysOn
                    filledSecondary
                  />
                );
              }}
            </FormDataConsumer>
            <FormDataConsumer label="Filtrage" source="filterType">
              {({ formData, ...rest }) => {
                const group_id = props.group_id || formData.group_id || identity?.company || "";
                return !group_id ? null : (
                  <FormInput
                    type="select"
                    placeholder="Filtrage"
                    source="filterType"
                    values={getFilterTypes(viewType)}
                    filledSecondary
                    clearable
                  />
                );
              }}
            </FormDataConsumer>
            <FormDataConsumer alwaysOn>
              {({ formData, ...rest }) => {
                const group_id = props.group_id || formData.group_id || identity?.company || "";
                return !(formData.filterType === "crmGroup" && group_id) ? null : (
                  <ReferenceInput
                    source="crmGroupId"
                    reference="groups"
                    alwaysOn
                    filter={{ company: group_id }}
                    sort={{ field: "name", order: "ASC" }}
                    perPage={1000}
                  >
                    <AutocompleteInput
                      placeholder="Rechercher par groupe"
                      optionText="name"
                      filterToQuery={(search: any) => ({ name: search })}
                      filledSecondary
                    />
                  </ReferenceInput>
                );
              }}
            </FormDataConsumer>
            <FormDataConsumer alwaysOn>
              {({ formData, ...rest }) => {
                const group_id = props.group_id || formData.group_id || identity?.company || "";
                return !(formData.filterType === "user" && group_id) ? null : (
                  <ReferenceInput
                    source="userId"
                    reference="users"
                    alwaysOn
                    filter={{ company: group_id }}
                    sort={{ field: "email", order: "ASC" }}
                    perPage={20}
                  >
                    <AutocompleteInput
                      placeholder="Rechercher par utilisateur"
                      optionText={(user: any) => `${user.firstName} ${user.lastName}`}
                      filterToQuery={(search: any) => ({ multi_firstName_lastName: search })}
                      filledSecondary
                    />
                  </ReferenceInput>
                );
              }}
            </FormDataConsumer>
            <FormDataConsumer alwaysOn>
              {({ formData, ...rest }) => {
                return formData.filterType !== "phone_number" ? null : (
                  <FormInput placeholder="Numéro téléphone" name="phone_number" alwaysOn filledSecondary />
                );
              }}
            </FormDataConsumer>
          </Filter>
        }
        actions={
          <ListActions>
            {displayDetailsButton ? (
              <Button
                size="small"
                sx={{
                  color: `${theme.palette.common.text} !important`,
                  ":hover": {
                    backgroundColor: `${alpha(theme.palette.neutral.main, 0.5)} !important`,
                  },
                }}
                onClick={() => setDisplayDetails(!displayDetails)}
              >
                {displayDetails ? "Cacher le détail par groupe" : "Afficher le détail par groupe"}
              </Button>
            ) : null}
          </ListActions>
        }
        sort={{ field: "createdAt", order: "DESC" }}
        filterDefaultValues={{
          sent: "true",
          period: "currentMonth",
          countType: "credits",
          viewType,
        }}
      >
        <>
          {viewType === "statistics" ? (
            <SmsStats groupId={props.group_id} displayDetails={displayDetails} setDisplayDetails={setDisplayDetails} />
          ) : (
            <CustomDatagrid
              //todo : put CustomDatagrid in self component to access list context, check filters and set bulkactions according to "sent" filter
              bulkActionButtons={identity?.status === "Collaborateur"} // && window.location.href.includes("notSent")}
            >
              <DateField label="Date d'envoi" source="send_date" showTime />

              <ReferenceField label="Utilisateur" source="userId" reference="users" link={false} sortable={false}>
                <TextField source="email" />
              </ReferenceField>
              {["Collaborateur"].includes(identity?.status) && (
                <ReferenceField
                  label="Organisation"
                  source="group_id"
                  reference="companies"
                  link={false}
                  sortable={false}
                >
                  <TextField source="company" />
                </ReferenceField>
              )}
              <ReferenceField source="crmGroupId" label="Groupe" reference="groups" link={false} sortable={false}>
                <FunctionField
                  render={(groupRecord: any) => {
                    return <div>{groupRecord.name}</div>;
                  }}
                />
              </ReferenceField>
              <TextField label="Destinataire" source="phonenumber" sortable={false} />
              <FunctionField
                label="Type"
                render={(record: any) => {
                  return <div> {record.type === "reminder" ? "rappel" : "confirmation"}</div>;
                }}
              />
              <FunctionField
                label="Envoyé"
                render={(record: any) => {
                  const statusColor = record.sent ? "secondary" : "warning";
                  return <ListChip label={record.sent ? "oui" : "non"} color={statusColor} />;
                }}
              />
              <TextField label="Coût (crédits)" source="length" sortable={false} />
            </CustomDatagrid>
          )}
        </>
      </List>
    </>
  );
};

export default SmsList;
