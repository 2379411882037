import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useFormContext, Controller } from "react-hook-form";
import {
  InputBase,
  TextFieldProps,
  MenuItem,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  Typography,
  Select,
  useTheme,
  SvgIcon,
  Box,
  Chip,
  Divider,
  Tooltip,
} from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import React from "react";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import {
  CalendarIcon,
  ChevronDown,
  Eye,
  EyeOff,
  InfoIcon,
  Mail,
  MinusIcon,
  PhoneIcon,
  PlusIcon,
  Search,
  X,
} from "lucide-react";
import { ReactComponent as FranceFlag } from "../../assets/france.svg";
import { ReactComponent as BelgiumFlag } from "../../assets/belgium.svg";
import { ReactComponent as SwissFlag } from "../../assets/swiss.svg";
import { ReactComponent as CanadaFlag } from "../../assets/canada.svg";
import { ReactComponent as UnitedStatesFlag } from "../../assets/united-states.svg";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { format } from "date-fns/format";

const flags: any = {
  FR: FranceFlag,
  BE: BelgiumFlag,
  US: UnitedStatesFlag,
  CA: CanadaFlag,
  CH: SwissFlag,
  GP: FranceFlag,
  BL: FranceFlag,
};
const valueToFlag: any = {
  France: FranceFlag,
  Belgique: BelgiumFlag,
  Canada: CanadaFlag,
  Suisse: SwissFlag,
  Guadeloupe: FranceFlag,
  "Saint-Barthelemy": FranceFlag,
};
const countryToIso: any = {
  France: "FR",
  Belgique: "BE",
  Canada: "CA",
  Suisse: "CH",
  Guadeloupe: "GP",
  "Saint-Barthelemy": "BL",
};
const numberTypeToPattern = {
  positiveInteger: "[0-9]+([\\.,][0-9]+)?",
  integer: "",
  positiveDecimal: "",
  decimal: "",
};

// Styled Material UI TextField Component
export const CustomInput = styled(InputBase, {
  shouldForwardProp: (prop) => {
    return (
      prop !== "filledSecondary" && prop !== "fixedHeight" && prop !== "withoutPadding" && prop !== "withoutPadding"
    );
  },
})(({ theme, filledSecondary, fixedHeight, withoutPadding, withoutRightPadding }: any) => {
  return {
    position: "relative",
    border: "1px solid",
    borderColor: theme.palette.input.stroke,
    backgroundColor: !filledSecondary ? theme.palette.input.backgroundFilled : theme.palette.card.background,
    padding: withoutPadding ? "0" : "4px 12px",
    paddingRight: withoutRightPadding ? "0" : "12px",
    height: fixedHeight ? "2.5rem" : "auto",
    minHeight: "2.5rem",
    borderRadius: "8px",
    width: "100%",
    "& .MuiInputBase-input": {
      padding: 0,
    },
    "& .MuiInputBase-input:focus": {
      backgroundColor: "inherit",
    },
  };
});

const DateOrDateTimePicker = ({ type = "date", ...props }) => {
  return type === "date" ? <DatePicker {...props} /> : <DateTimePicker {...props} />;
};

export const CustomFormHelperText = ({ helperText, error = false, id = "", sx = {} }) => {
  return (
    <FormHelperText {...(id ? { id } : {})} sx={{ mt: "3px", ...sx }}>
      <Typography variant="regular_xs" color={error ? "error.main" : "common.text"}>
        {helperText}
      </Typography>
    </FormHelperText>
  );
};

// Type of Props the FormInput will receive
type FormInputProps = {
  name?: string;
  label?: string;
  tooltip?: string;
  checkboxLabel?: string;
  helperText?: string | React.JSX.Element;
  values?: any;
  type?:
    | "text"
    | "display"
    | "number"
    | "phone"
    | "select"
    | "multiselect"
    | "checkbox"
    | "password"
    | "email"
    | "multiline"
    | "search"
    | "dateTime"
    | "date";
  numberType?: string;
  min?: number;
  max?: number;
  password?: boolean;
  phone?: boolean;
  callablePhone?: boolean;
  required?: boolean;
  lineNumber?: number; //for multiline
  //allow to modfify externally the mui phone value
  newPhoneValue?: string;
  country?: string;
  checkbox?: boolean;
  hideSelectInsideLabel?: boolean;
  filledSecondary?: boolean;
  setIsInputFocused?: Function;
  //specific to react-admin for filtering
  hideLabel?: boolean;
  alwaysOn?: boolean;
  source?: string;
  //
  clearable?: boolean;
} & TextFieldProps;

const FormInput: FC<FormInputProps> = ({
  name,
  label,
  tooltip = "",
  helperText,
  values,
  defaultValue = null,
  type = "text",
  numberType = "positiveInteger",
  country = "France",
  hideSelectInsideLabel = false,
  filledSecondary = false,
  newPhoneValue = "",
  callablePhone = false,
  setIsInputFocused = null,
  required = false,
  lineNumber = 4,
  alwaysOn = null,
  source = null,
  hideLabel = false,
  checkboxLabel = null,
  clearable = false,
  ...otherProps
}: any) => {
  const [isFocused, setIsFocused] = useState(false);
  const [error, setError] = useState("");

  //password
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  //phone
  const [muiTelValue, setMuiTelValue] = useState(defaultValue);

  //date
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [datePickerValue, setDatePickerValue] = useState(defaultValue);
  const dateRef = useRef();

  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();
  if (!name) {
    name = source;
  }
  const inputValue = watch(name);
  const startDate = watch("startDate");
  const id = name + "-input" + Math.floor(Math.random() * 1000000);
  const theme = useTheme();
  if (hideLabel) {
    label = null;
  }
  let placeholder = otherProps.placeholder || "";
  if ((type === "select" || type === "multiselect") && !otherProps.placeholder) {
    placeholder = "choisissez...";
  }
  if (type === "search") {
    placeholder = "Rechercher";
  }
  if (type === "text") {
    defaultValue = "";
  }
  if (type === "number") {
    if (!otherProps.InputProps) {
      otherProps.InputProps = {};
    }
    otherProps.InputProps.inputProps = {
      ...(otherProps?.InputProps?.inputProps || {}),
      inputMode: ["positiveInteger", "Integer"].includes(numberType) ? "numeric" : "decimal",
    };
  }

  const visa = require("../../assets/visa.png") as string;
  const mastercard = require("../../assets/mastercard.png") as string;
  const amex = require("../../assets/amex.png") as string;
  const sepa = require("../../assets/sepa.png") as string;

  const incrementValue = useCallback(
    (step) => {
      setValue(
        name,
        convertStringToNumber(numberType, (inputValue + step).toString(), otherProps?.min, otherProps?.max)
      );
    },
    [inputValue, name, numberType, otherProps?.max, otherProps?.min, setValue]
  );

  if (values) {
    values = values?.map((value: any) => {
      if (typeof value === "object" && !Array.isArray(value)) {
        return value;
      } else {
        return {
          id: value,
          label: value,
          cardType: value?.cardType || "",
          expMonth: value?.expMonth || "",
          expYear: value?.expYear || "",
        };
      }
    });
  }

  //errors
  useEffect(() => {
    let error: any = "";
    // console.log("errors", errors);
    if (errors && name.includes(".")) {
      const path = name.split(".");
      if (!!errors?.[path[0]]?.[path[1]]) {
        error = errors?.[path[0]]?.[path[1]]?.message;
      }
    } else {
      if (!!errors[name]) {
        error = errors[name]?.message;
      }
    }
    if (error) {
      // console.log("error: ", errors);
      setError(error);
    } else {
      setError("");
    }
  }, [errors, name]);

  //date
  useEffect(() => {
    if ((type === "date" || type === "dateTime") && inputValue) {
      setDatePickerValue(format(new Date(inputValue), "dd/MM/yyyy " + (type === "dateTime" ? "'à' HH'h'mm" : "")));
    }
  }, [defaultValue, inputValue, otherProps.disabled, type]);

  useEffect(() => {
    if (type === "date" && name === "endDate" && startDate && inputValue) {
      if (inputValue < startDate) {
        setDatePickerValue(format(new Date(startDate), "dd/MM/yyyy"));
        setValue(name, startDate);
      } else if (inputValue > new Date().toISOString().split("T")[0]) {
        setDatePickerValue(format(new Date(), "dd/MM/yyyy"));
        setValue(name, new Date().toISOString().split("T")[0]);
      }
    }
  }, [inputValue, name, setValue, startDate, type]);

  useEffect(() => {
    if (type === "phone") {
      const label = document.getElementById(name + "-inputLabelPhone");
      if (label) {
        document.getElementById(id)?.parentNode?.parentNode?.prepend(label);
        label.style.setProperty("display", "block");
      }
      const helper = document.getElementById(name + "-inputHelperPhone");
      if (helper) {
        document.getElementById(id)?.parentNode?.parentNode?.append(helper);
        helper.style.setProperty("display", "block");
      }
    }
  }, [name, type]);

  useEffect(() => {
    if (type === "phone") {
      const error = document.getElementById(name + "-inputErrorPhone");
      if (error) {
        document.getElementById(id)?.parentNode?.parentNode?.append(error);
        error.style.setProperty("display", "block");
      }
    }
  }, [name, type, errors]);

  useEffect(() => {
    if (newPhoneValue) {
      setMuiTelValue(newPhoneValue);
      setValue(name, newPhoneValue);
    }
  }, [name, newPhoneValue, setValue]);

  useEffect(() => {
    if (inputValue && !muiTelValue) {
      setMuiTelValue(inputValue);
    }
  }, [inputValue, muiTelValue]);

  useEffect(() => {
    if (setIsInputFocused) {
      setIsInputFocused(isFocused);
    }
  }, [isFocused, setIsInputFocused]);

  useEffect(() => {
    if (type !== "checkbox" && type !== "number" && defaultValue && !inputValue) {
      setValue(name, defaultValue);
      if (type === "date" || type === "dateTime") {
        setDatePickerValue(format(new Date(defaultValue), "dd/MM/yyyy " + (type === "dateTime" ? "'à' HH'h'mm" : "")));
      }
    }
  }, [defaultValue, inputValue, name, setValue, type]);

  // useEffect(() => {
  //   if (type === "phone") {
  //     console.log(defaultValue, name, inputValue, typeof inputValue);
  //   }
  // }, [defaultValue, inputValue, name]);

  const rules = type === "phone" ? { validate: matchIsValidTel } : {};

  // return defaultValue && !inputValue && type !== "checkbox" ? null : ( //? why this line ? Maybe makes some input disappear (not in local)
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      {...rules}
      render={({ field: { ref, ...field }, fieldState }) => {
        return type === "phone" ? (
          <Box
            ref={dateRef}
            sx={{
              width: 1,
              display: "flex",
              justifyContent: "start",
              alignItems: "start",
              "& .MuiFormControl-root": {
                mt: "8px !important",
              },
            }}
          >
            <InputLabel
              shrink
              htmlFor={id}
              sx={{
                fontWeight: "bold",
                fontSize: theme.typography.bold_sm.fontSize,
                display: "none",
                position: "relative",
                height: 24,
                color: isFocused ? "primary.main" : "common.title",
                transform: "none",
                "& .MuiFormLabel-asterisk": { color: "error.main" },
                mt: "0 !important",
              }}
              id={name + "-inputLabelPhone"}
              required={required}
              {...otherProps}
              disabled={false}
            >
              {label}
            </InputLabel>
            {helperText && (
              <CustomFormHelperText
                sx={{ display: "none" }}
                id={name + "-inputHelperPhone"}
                helperText={helperText || ""}
              />
            )}
            {error && (
              <CustomFormHelperText sx={{ display: "none" }} id={name + "-inputErrorPhone"} helperText={error} error />
            )}
            <MuiTelInput
              langOfCountryName="fr"
              MenuProps={{ disableScrollLock: true }}
              forceCallingCode
              variant="outlined"
              id={id}
              sx={{
                margin: "0",
                "& .MuiIconButton-root": {
                  color: "black",
                  ml: -1,
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderWidth: "1px",
                  borderColor: theme.palette.input.stroke,
                },
                "& > .MuiOutlinedInput-root": {
                  position: "relative",
                  backgroundColor: !filledSecondary
                    ? theme.palette.input.backgroundFilled
                    : theme.palette.card.background,
                  ...(isFocused && {
                    boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
                    borderColor: theme.palette.primary.main,
                  }),
                  fontSize: 16,
                  height: "2.5rem",
                  padding: "4px 12px",
                  borderRadius: "8px",
                  width: 1,
                },
                "& .MuiInputBase-input": {
                  padding: "0",
                },
                "& .MuiInputBase-root .MuiTypography-root": {
                  color: "common.text",
                  borderColor: alpha(theme.palette.common.text, 0.5),
                },
              }}
              inputRef={ref}
              onlyCountries={["FR", "GP", "BL", "BE", "CH", "CA"]}
              defaultCountry={countryToIso[country]}
              value={muiTelValue}
              onChange={(value, info) => {
                setMuiTelValue(value);
                if (muiTelValue !== info.numberValue) {
                  field.onChange(info.numberValue);
                }
              }}
              {...otherProps}
              InputProps={{
                ...otherProps.InputProps,
                endAdornment: !callablePhone ? null : (
                  <Box sx={{ width: 20, height: 20, display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <a
                      href={`tel:${inputValue}`}
                      style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                    >
                      <PhoneIcon size={20} color={theme.palette.primary.main} />
                    </a>
                  </Box>
                ),
              }}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              getFlagElement={(isoCode, { imgProps, countryName, isSelected }) => {
                return <SvgIcon component={flags[isoCode]} inheritViewBox />;
              }}
            />
          </Box>
        ) : (
          <Box ref={dateRef} sx={{ width: 1, display: "flex", justifyContent: "start", alignItems: "start" }}>
            <FormControl variant="standard">
              {label && (
                <Box
                  sx={{
                    height: 24,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <InputLabel
                    shrink
                    htmlFor={id}
                    sx={{
                      height: 1,
                      position: "relative",
                      overflow: "visible",
                      color: isFocused ? "primary.main" : "common.title",
                      transform: "none",
                      "& .MuiFormLabel-asterisk": { color: "error.main" },
                    }}
                    focused={isFocused}
                    required={required}
                    {...otherProps}
                    disabled={false}
                  >
                    <Typography variant="bold_sm">{label}</Typography>
                  </InputLabel>
                  {tooltip && (
                    <Tooltip title={tooltip} arrow>
                      <IconButton sx={{ width: 24, height: 24, p: 0 }}>
                        <InfoIcon size={16} />
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
              )}
              {type === "select" || type === "multiselect" ? (
                <Select
                  labelId={`customized-select-label-${name}`}
                  id={`customized-select-${name}`}
                  MenuProps={{ disableScrollLock: true }}
                  IconComponent={() => (
                    <>
                      <Box
                        sx={{
                          height: 32,
                          width: 32,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "input.icon",
                          position: "absolute",
                          right: clearable && inputValue ? 32 : 6,
                          pointerEvents: "none",
                        }}
                      >
                        <ChevronDown size={16} />
                      </Box>
                      {clearable && inputValue && (
                        <IconButton
                          aria-label="reset value"
                          onClick={() => setValue(name, "")}
                          sx={{ ml: -1, color: "input.icon", position: "absolute", right: 6 }}
                        >
                          <X size={16} />
                        </IconButton>
                      )}
                    </>
                  )}
                  onClose={() => {
                    setTimeout(() => {
                      setIsFocused(false);
                    }, 0);
                  }}
                  {...field}
                  {...(type === "multiselect"
                    ? {
                        multiple: true,
                        renderValue: (selected) => (
                          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                            {selected.map((value) => (
                              <Chip key={value} label={value} sx={{ height: "auto", "& .MuiChip-label": { px: 1 } }} />
                            ))}
                          </Box>
                        ),
                      }
                    : {})}
                  value={field.value || defaultValue}
                  input={
                    <CustomInput
                      id={id}
                      // sx={{ mb: "1.5rem" }}
                      error={!!error}
                      inputRef={ref}
                      {...field}
                      {...otherProps}
                      onFocus={() => setIsFocused(true)}
                      onBlur={() => setIsFocused(false)}
                      filledSecondary={filledSecondary}
                      fixedHeight={!(type === "multiselect")}
                      withoutPadding={!(type === "multiselect")}
                    />
                  }
                  sx={{
                    ...(isFocused && {
                      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
                      borderColor: theme.palette.primary.main,
                    }),
                    "& .MuiSelect-select": {
                      position: type === "multiselect" ? "relative" : "absolute",
                      width: 1,
                      height: type === "multiselect" ? "auto !important" : "100% !important",
                      pr: "0 !important",
                      borderRadius: 2,
                    },
                    "& .MuiSelect-select .notranslate": {
                      display: "flex",
                      alignItems: "center",
                      height: 1,
                      ml: 2,
                    },
                    "& .MuiSelect-select .notranslate::after": placeholder
                      ? {
                          content: `"${placeholder}"`,
                          opacity: 0.42,
                        }
                      : {},
                  }}
                >
                  {!hideSelectInsideLabel && (
                    <MenuItem disabled value="">
                      <Typography variant="bold_sm" sx={{ color: "neutral.contastText" }}>
                        {label}
                      </Typography>
                    </MenuItem>
                  )}
                  {values &&
                    values.map((value: any) => (
                      <MenuItem
                        value={typeof value.id === "boolean" ? (value.id as any) : value.id}
                        key={value.id.toString()}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            width: "calc(100% - 1rem)",
                            height: "100%",
                            paddingLeft: "1rem",
                          }}
                        >
                          {name.toLowerCase().includes("country") && (
                            <SvgIcon component={valueToFlag[value.id]} inheritViewBox sx={{ mr: 1 }} />
                          )}
                          {name === "card" &&
                            (value.cardType === "visa" ? (
                              <img src={visa} alt={value.label} height="26" style={{ marginRight: "12px" }} />
                            ) : value.cardType === "mastercard" ? (
                              <img src={mastercard} alt={value.label} height="26" style={{ marginRight: "12px" }} />
                            ) : value.cardType === "amex" ? (
                              <img src={amex} alt={value.label} height="26" style={{ marginRight: "12px" }} />
                            ) : (
                              <img src={visa} alt={value.label} height="26" style={{ marginRight: "12px" }} />
                            ))}
                          {name === "iban" && (
                            <img src={sepa} alt={value.label} height="26" style={{ marginRight: "12px" }} />
                          )}
                          <Typography sx={{ width: 1 }}>{value.label}</Typography>

                          {name === "card" && value.id !== "none" && (
                            <div style={{ position: "absolute", right: "30px" }}>
                              Exp : {value.expMonth}/{value.expYear}
                            </div>
                          )}
                        </div>
                      </MenuItem>
                    ))}
                </Select>
              ) : type === "checkbox" ? (
                <FormControlLabel
                  label={<Typography variant="bold_sm">{checkboxLabel}</Typography>}
                  color="primary"
                  control={<Checkbox checked={field.value} onChange={field.onChange} id={id} />}
                  sx={{ height: "2.5rem" }}
                  disabled={otherProps?.disabled}
                  defaultChecked={defaultValue === true}
                />
              ) : type === "search" ? (
                <CustomInput
                  id={id}
                  sx={[
                    isFocused && {
                      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
                      borderColor: theme.palette.primary.main,
                    },
                  ]}
                  error={!!error}
                  type={"text"}
                  startAdornment={
                    <InputAdornment position="start">
                      <Search size={16} />
                    </InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      {inputValue && (
                        <IconButton
                          aria-label="reset value"
                          onClick={() => setValue(name, "")}
                          sx={{ ml: -1, color: "input.icon" }}
                        >
                          <X size={16} />
                        </IconButton>
                      )}
                    </InputAdornment>
                  }
                  inputRef={ref}
                  {...field}
                  onBlur={() => setIsFocused(false)}
                  onFocus={() => setIsFocused(true)}
                  {...otherProps}
                  {...otherProps.InputProps}
                  fixedHeight={true}
                  filledSecondary={true}
                />
              ) : type === "date" || type === "dateTime" ? (
                <DateOrDateTimePicker
                  type={type}
                  onClose={() => {
                    setIsDatePickerOpen(false);
                  }}
                  {...field}
                  {...otherProps}
                  onChange={(value) => {
                    setDatePickerValue(format(value, "dd/MM/yyyy " + (type === "dateTime" ? "'à' HH'h'mm" : "")));
                    field.onChange(format(value, "yyyy-MM-dd"));
                  }}
                  open={isDatePickerOpen}
                  slots={{ field: CustomInput }}
                  slotProps={{
                    popper: {
                      anchorEl: dateRef.current,
                    },
                    field: {
                      setOpen: setIsDatePickerOpen,
                      error: !!error,
                      sx: [
                        isFocused && {
                          boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
                          borderColor: theme.palette.primary.main,
                          caretColor: "transparent",
                        },
                        {
                          border: "1px solid",
                          borderColor: theme.palette.input.stroke,
                          backgroundColor: !filledSecondary
                            ? theme.palette.input.backgroundFilled
                            : theme.palette.card.background,
                        },
                      ],
                      inputRef: ref,
                      ...field,
                      value: datePickerValue,
                      onBlur: () => setIsFocused(false),
                      onFocus: () => {
                        setIsFocused(true);
                      },
                      onClick: () => {
                        if (!otherProps.disabled) {
                          setIsDatePickerOpen(true);
                        }
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          {datePickerValue && !otherProps.disabled && (
                            <IconButton
                              aria-label="reset value"
                              onClick={(event) => {
                                setValue(name, "");
                                setDatePickerValue("");
                                event?.stopPropagation();
                              }}
                              sx={{ ml: -1, color: "input.icon", mr: 0.5 }}
                            >
                              <X size={16} />
                            </IconButton>
                          )}
                          {!otherProps.disabled && (
                            <IconButton
                              aria-label="calendar"
                              onClick={() => setIsDatePickerOpen(true)}
                              sx={{ ml: -1, color: "input.icon" }}
                            >
                              <CalendarIcon size={16} />
                            </IconButton>
                          )}
                        </InputAdornment>
                      ),
                      ...otherProps,
                      ...otherProps.InputProps,
                    } as any,
                  }}
                />
              ) : (
                <CustomInput
                  id={id}
                  sx={[
                    // {
                    //   mb: "1.5rem",
                    // },
                    isFocused && {
                      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
                      borderColor: theme.palette.primary.main,
                    },
                  ]}
                  error={!!error}
                  type={type !== "password" || showPassword ? "text" : "password"}
                  startAdornment={
                    ["password", "email"].includes(type) ? (
                      <InputAdornment position="start">
                        {type === "password" ? (
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            sx={{ ml: -1, color: "input.icon" }}
                          >
                            {showPassword ? <Eye width={16} /> : <EyeOff width={16} />}
                          </IconButton>
                        ) : (
                          <Mail width={16} style={{ marginLeft: "-4px", color: theme.palette.input.icon }} />
                        )}
                      </InputAdornment>
                    ) : null
                  }
                  endAdornment={
                    type === "number" && numberType === "positiveInteger" ? (
                      <InputAdornment position="end">
                        <Box sx={{ display: "flex", "& .MuiIconButton-root": { borderRadius: 0 } }}>
                          <Divider orientation="vertical" flexItem />
                          <IconButton
                            aria-label="remove1"
                            onClick={() => incrementValue(-1)}
                            sx={{ color: "input.icon" }}
                          >
                            <MinusIcon width={16} />
                          </IconButton>
                          <Divider orientation="vertical" flexItem />
                          <IconButton aria-label="add1" onClick={() => incrementValue(1)} sx={{ color: "input.icon" }}>
                            <PlusIcon width={16} />
                          </IconButton>
                        </Box>
                      </InputAdornment>
                    ) : null
                  }
                  withoutRightPadding={type === "number" && numberType === "positiveInteger"}
                  inputRef={ref}
                  {...field}
                  onBlur={() => setIsFocused(false)}
                  onFocus={() => setIsFocused(true)}
                  {...otherProps}
                  {...otherProps.InputProps}
                  onChange={(event) =>
                    field.onChange(
                      type === "number"
                        ? convertStringToNumber(numberType, event.target.value, otherProps.min, otherProps.max)
                        : event.target.value
                    )
                  }
                  multiline={type === "multiline"}
                  fixedHeight={!(type === "multiline")}
                  minRows={type === "multiline" ? lineNumber : 1}
                  filledSecondary={filledSecondary}
                />
              )}
              {helperText && <CustomFormHelperText helperText={helperText || ""} />}
              {error && <CustomFormHelperText helperText={error} error />}
            </FormControl>
          </Box>
        );
      }}
    />
  );
};

export default FormInput;

function isPositiveInteger(n: string) {
  var floatN = parseFloat(n);
  return !isNaN(floatN) && isFinite(floatN) && floatN > 0 && floatN % 1 === 0;
}

const convertStringToNumber = (
  numberType: string,
  value: string,
  min: number | null = null,
  max: number | null = null
) => {
  if (numberType === "positiveInteger") {
    const number = isPositiveInteger(value) ? parseInt(value) : "";
    if (max && number && number > max) return max;
    if (min && number && number < min) return min;
    return number;
  }
};
