import { Container, Grid, Box, Typography, useTheme, Tabs, Tab, useMediaQuery } from "@mui/material";
import { FC, useEffect, useState } from "react";
import React from "react";
import { CreateButton, List, useGetIdentity, useGetList } from "react-admin";
import PageTitle from "../../components/navigation/PageTitle";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import PaymentMethodsList from "../paymentMethods/PaymentMethodsList";
import MySubscriptions from "./MySubscriptions";
import BillingDetails from "./BillingDetails";
import { PaymentDatagrid } from "../../components/fields/PaymentsField";

import TabNav from "../../components/navigation/TabNav";

const tabs = (identity) => [
  {
    name: "Abonnements",
    value: "1",
    component: <MySubscriptions identity={identity} />,
  },
  {
    name: "Modes de paiement",
    value: "2",
    component: <PaymentMethodsList resource="paymentMethods" company={identity?.company} hideTitle />,
  },
  {
    name: "Informations de facturation",
    value: "3",
    component: <BillingDetails identity={identity} />,
  },
  {
    name: "Historique de facturation",
    value: "4",
    component: (
      <List
        perPage={5}
        resource="payments"
        sort={{ field: "paymentDate", order: "DESC" }}
        filter={{ company_id: identity?.company }}
        exporter={false}
        empty={false}
      >
        <PaymentDatagrid />
      </List>
    ),
  },
];

const MyOfferPage: FC = () => {
  const { isLoading, identity } = useGetIdentity();
  const [currentTab, setCurrentTab] = useState(null);

  return isLoading ? null : (
    <Box sx={{ width: ["calc(100vw - 2rem)", 0.98], "& .MuiTabPanel-root": { p: 0 }, "& .MuiTab-root": { pb: 0 } }}>
      <PageTitle
        text="Mon offre"
        createButton={currentTab === "2" ? <CreateButton label="Nouveau mode de paiement" resource="paymentMethods"/> : <></>}
        type="list"
        breadcrumbListLabel="Mon offre"
      />
      <TabNav tabs={tabs(identity)} setCurrentTab={setCurrentTab} />
    </Box>
  );
};

export default MyOfferPage;
