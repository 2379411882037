import React, { useEffect, useState } from "react";
import { Menu } from "@react-admin/ra-rbac";
import { useGetIdentity, useSidebarState } from "react-admin";
import { Divider, Typography, useTheme, useMediaQuery } from "@mui/material";
import {
  BarChartBig,
  Boxes,
  Building,
  CirclePercent,
  CreditCard,
  Download,
  FileClockIcon,
  LayoutDashboard,
  MessageSquareCode,
  MessageSquareText,
  NotebookTabs,
  Scale,
  ScanBarcode,
  ShieldCheck,
  Store,
  Target,
  Users,
} from "lucide-react";
import { useTheme as useRaTheme } from "react-admin";

// Digisoft CRM Sidebar component
const Sidebar = () => {
  const { data: identity } = useGetIdentity();
  const theme = useTheme();
  const [raTheme, setRaTheme] = useRaTheme();
  const isMobile = !!useMediaQuery((theme: any) => theme.breakpoints.only("xs"));
  const [open, setOpen] = useSidebarState();
  useEffect(() => {
    if (!open && !isMobile) {
      setOpen(true);
    }
  }, [open, isMobile, setOpen]);
  // useEffect(() => {
  //   setOpen(false);
  // }, [isMobile, setOpen]);

  return (
    <Menu
      sx={{
        height: "120%",
        width: [1, "auto"],
        px: [0, "1.5rem"],
        py: [0, "2rem"],
        gap: 0.5,
        borderRight: "solid 1px",
        borderRightColor: `${theme.palette.neutral.main} !important`,
        "& .RaMenu-open": {
          width: [320, "auto"],
        },
        "& .MuiMenuItem-root": {
          color: theme.palette.common.text,
          ...theme.typography.bold_sm,
          marginLeft: "0",
          marginRight: "0",
          width: "100%",
          minHeight: "unset",
          borderRadius: "8px",
          paddingBottom: "0",
          paddingTop: "0",
          height: "2.3rem",
          "&.RaMenuItemLink-active": {
            backgroundColor: theme.palette.card.background,
            color: theme.palette.primary.main,
            boxShadow:
              "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
            "& .RaMenuItemLink-icon": {
              color: theme.palette.primary.main,
            },
          },
        },
      }}
    >
      <Typography variant="bold_xs" sx={{ ml: 3, mt: isMobile ? "2rem" : 0 }}>
        Administration
      </Typography>
      <Menu.DashboardItem leftIcon={<LayoutDashboard size={18} />} />
      {["Collaborateur"].includes(identity?.status) && (
        <>
          <Menu.Item
            action="read"
            resource="admin"
            to="/admin/digisoft"
            primaryText="Administration"
            leftIcon={<ShieldCheck size={18} />}
          />
          <Menu.Item
            action="read"
            resource="opportunities"
            to="/opportunities"
            primaryText="Opportunités"
            leftIcon={<Target size={18} />}
          />
        </>
      )}

      {["Collaborateur", "Revendeur"].includes(identity?.status) && (
        <Menu.Item
          action="read"
          resource="companies"
          to="/companies"
          primaryText="Clients"
          leftIcon={<Building size={18} />}
          // hack to prevent companies menu item to be displayed as active when user's company item is active
          sx={{
            ...(window.location.pathname.includes(identity?.company) && {
              "&.RaMenuItemLink-active": {
                backgroundColor: "inherit",
                color: "inherit",
                "& .RaMenuItemLink-icon": {
                  color: "inherit",
                },
              },
            }),
          }}
        />
      )}

      {["Revendeur", "Client", "Indirect"].includes(identity?.status) && (
        <Menu.Item
          action="read"
          resource="users"
          to="/users"
          primaryText="Utilisateurs"
          leftIcon={<Users size={18} />}
        />
      )}

      {["Client", "Indirect", "Revendeur"].includes(identity?.status) && (
        <Menu.Item action="read" resource="groups" to="/groups" primaryText="Groupes" leftIcon={<Boxes size={18} />} />
      )}

      {["Client", "Indirect", "Revendeur"].includes(identity?.status) && (
        <Menu.Item
          action="read"
          resource="contactsOrContactLists"
          to="/contactsOrContactLists"
          primaryText="Contacts"
          leftIcon={<NotebookTabs size={18} />}
        />
      )}

      {["Collaborateur"].includes(identity?.status) && (
        <>
          <Menu.Item
            action="read"
            resource="orders"
            to="/orders"
            primaryText="Commandes"
            leftIcon={<Store size={18} />}
          />
          <Menu.Item
            action="read"
            resource="subscriptions"
            to="/subscriptions"
            primaryText="Abonnements"
            leftIcon={<CreditCard size={18} />}
          />
          <Menu.Item
            action="read"
            resource="products"
            to="/products"
            primaryText="Produits"
            leftIcon={<ScanBarcode size={18} />}
          />

          <Menu.Item
            action="read"
            resource="discounts"
            to="/discounts"
            primaryText="Remises"
            leftIcon={<CirclePercent size={18} />}
          />
        </>
      )}

      <Typography variant="bold_xs" sx={{ ml: 3, mt: 2 }}>
        Gestion
      </Typography>
      <Menu.Item
        action="read"
        resource="licenses"
        to="/licenses"
        primaryText="Licences"
        leftIcon={<Scale size={18} />}
      />
      {["Client", "Indirect", "Revendeur"].includes(identity?.status) && (
        <Menu.Item
          action="read"
          resource="templates"
          to="/templates"
          primaryText="Modèles SMS"
          leftIcon={<MessageSquareCode size={18} />}
        />
      )}

      {["Collaborateur", "Revendeur"].includes(identity?.status) && (
        <Menu.Item
          action="read"
          resource="credits"
          to="/credits"
          primaryText="Transferts crédits"
          leftIcon={<MessageSquareText size={18} />}
        />
      )}

      <Menu.Item
        action="read"
        resource="sms"
        to="/sms"
        primaryText="Journal d'envoi"
        leftIcon={<FileClockIcon size={18} />}
      />
      <Menu.Item
        action="read"
        resource="sms"
        to="/sms-statistics"
        primaryText="Statistiques SMS"
        leftIcon={<BarChartBig size={18} />}
      />

      <Typography variant="bold_xs" sx={{ ml: 3, mt: 2 }}>
        Téléchargements
      </Typography>

      <Menu.Item
        action="read"
        resource="downloads"
        to="/downloads"
        primaryText="Connecteurs"
        leftIcon={<Download size={18} />}
      />

      {["Client", "Indirect", "Revendeur"].includes(identity?.status) && (
        <Menu.Item
          action="read"
          resource="download"
          to="/download"
          primaryText="Modules SMS"
          leftIcon={<Download size={18} />}
        />
      )}
    </Menu>
  );
};

export default Sidebar;
