import { Chip, Typography } from "@mui/material";
import React from "react";

const ListChip = ({
  label,
  color,
}: {
  label: any;
  color: "error" | "primary" | "secondary" | "info" | "success" | "warning" | "default";
}) => {
  return (
    <Chip
      label={
        <Typography variant="medium_sm" sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          {label}
        </Typography>
      }
      color={color}
    />
  );
};

export default ListChip;
