import * as React from "react";
import { Button, useMediaQuery, useTheme } from "@mui/material";
import { SaveButton, SimpleForm, Toolbar, useEvent } from "react-admin";
import { CreateInDialogButton, useFormDialogContext } from "@react-admin/ra-form-layout";
import { zodResolver } from "@hookform/resolvers/zod";
import ContentAdd from "@mui/icons-material/Add";

const ToolBar = ({ confirmLabel, onClose = () => {}, ...props }) => {
  const context = useFormDialogContext(props);
  return (
    <Toolbar>
      <div style={{ flex: 1 }} />
      <Button
        variant="contained"
        color="neutral"
        onClick={() => {
          context?.close();
          if (onClose) {
            onClose();
          }
        }}
        sx={{ height: "2.3rem" }}
      >
        Annuler
      </Button>
      <SaveButton
        label={confirmLabel || "Ajouter"}
        alwaysEnable
        mutationOptions={{
          onSuccess: () => {
            onClose();
          },
        }}
        icon={<></>}
        sx={{ ml: 2 }}
      />
    </Toolbar>
  );
};

const CreateInDialog = ({
  children,
  formProps,
  formSchema,
  confirmLabel = null,
  label = null,
  fullWidthFields = false,
  ButtonProps = {},
  buttonType = "create",
  ...props
}: any) => {
  const theme = useTheme();
  const isMobile = !!useMediaQuery((theme: any) => theme.breakpoints.only("xs"));
  return (
    <CreateInDialogButton
      inline={buttonType === "create" && isMobile}
      label={label === null ? "Ajouter" : label || ""}
      {...props}
      ButtonProps={
        buttonType === "custom"
          ? ButtonProps
          : {
              color: buttonType === "create" ? "primary" : "primary",
              variant: buttonType === "create" ? "contained" : "text",
              sx: !isMobile
                ? { px: 2, py: 1, ...(ButtonProps?.sx || {}) }
                : {
                    backgroundColor: "button.secondary.contained.background",
                    color: "button.secondary.contained.content",
                    m: "6px",
                    width: "36px",
                    height: "36px",
                    borderRadius: 2,
                    ":hover": { bgcolor: "secondary.main", color: "secondary.contrastText" },
                    ...(ButtonProps?.sx || {}),
                  },
            }
      }
      sx={{
        "& .MuiDialog-paper": {
          p: 2,
          m: [0, 2],
          height: [1, "auto"],
          maxHeight: ["unset", "inherit"],
        },
        "& .MuiDialogTitle-root": {
          ...theme.typography.bold_2xl,
          color: "common.title",
        },
        "& .MuiFormControl-root": {
          width: fullWidthFields ? 1 : "250px",
          mt: 0,
        },
        "& .MuiAutocomplete-root": {
          width: fullWidthFields ? 1 : "250px",
        },
        "& .MuiInputBase-root": {
          width: fullWidthFields ? 1 : "250px",
        },
        "& .RaFormDialogTitle-closeButton": {
          display: "none",
        },
      }}
    >
      <SimpleForm
        toolbar={<ToolBar confirmLabel={confirmLabel} onClose={props?.onClose} />}
        resolver={zodResolver(formSchema)}
        {...formProps}
        onKeyDown={(event) => {
          event.stopPropagation();
        }}
      >
        {children}
      </SimpleForm>
    </CreateInDialogButton>
  );
};

export default CreateInDialog;
