import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useRef, useState, useEffect } from "react";
import {
  Autocomplete,
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputBase,
  InputLabel,
  Popper,
  TextField,
  Typography,
  alpha,
  debounce,
  styled,
  useTheme,
} from "@mui/material";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import React from "react";
import api, { apiUrl } from "../../services/api";
import { CustomFormHelperText, CustomInput } from "./FormInput";
import { Building2 } from "lucide-react";

interface CompanyType {
  name: string;
  siret: string;
  zipCode: string;
  address: string;
  city: string;
}

const CountriesToIdLabel = {
  France: "numéro SIRET",
  Belgique: "numéro BCE",
  Suisse: "numéro IDE",
};

const CountriesToHelperText = {
  France: "Identifiant à 14 chiffres",
  Belgique: "Identifiant à 10 chiffres",
  Suisse: "Identifiant à 9 chiffres (sans le CHE)",
};

const hiddenPopper = () => <Popper open={false} />;

export const CompanyInput = ({ name, label, required = false, filledSecondary = false, country = "France" }: any) => {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();
  const [isFocused, setIsFocused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [customError, setCustomError] = useState("");
  const previousController = useRef<any>();
  const companyData = useWatch({ name });
  const theme = useTheme();

  const getData = (searchTerm: string) => {
    setIsLoading(true);
    if (!!previousController?.current && previousController.current) {
      previousController.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousController.current = controller;
    fetch(apiUrl + "/utils/getCompanyData", {
      method: "POST",
      body: JSON.stringify({
        country,
        searchTerm,
      }),
      signal,
      headers: {
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        console.log("myJson.companyData", myJson.companyData);
        if (myJson?.companyData?.siret) {
          setCustomError("");
          setValue(name, { ...myJson.companyData, siret: searchTerm });
        } else {
          setCustomError("Aucun résultat, veuillez vérifier votre identifiant");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        if (err.name === "AbortError") {
          console.log("AbortError: Fetch request aborted");
        } else {
          setIsLoading(false);
        }
      });
  };

  useEffect(() => {
    if (inputValue === "test@@@") {
      setValue("siret", inputValue);
    } else if (
      inputValue === "" ||
      (inputValue.length !== 14 && country === "France") ||
      (inputValue.length !== 10 && country === "Belgique") ||
      (inputValue.length !== 9 && country === "Suisse") ||
      !/^\d+$/.test(inputValue)
    ) {
      setCustomError("");
      setValue(name, null);
    } else {
      if (companyData?.siret !== inputValue) {
        getData(inputValue);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyData, inputValue]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, ...field } }) => {
        return (
          <FormControl variant="standard">
            <InputLabel
              shrink
              htmlFor={"siret-input"}
              sx={{
                transform: "none",
                color: "common.title",
                "& .MuiFormLabel-asterisk": { color: "error.main" },
                position: "relative",
                height: 24,
              }}
              required={required}
            >
              <Typography variant="bold_sm">{label}</Typography>
            </InputLabel>
            <CustomInput
              error={!!errors[name]}
              id="siret-input"
              inputProps={{ placeholder: `Tapez votre ${CountriesToIdLabel[country]}...` }}
              onChange={(e) => {
                setInputValue(e.target.value);
              }}
              // startAdornment={
              //   country === "Belgique" ? (
              //     <InputAdornment position="start">
              //       <Typography variant="regular_md" color="common.text">
              //         BE
              //       </Typography>
              //     </InputAdornment>
              //   ) : country === "Suisse" ? (
              //     <InputAdornment position="start">
              //       <Typography variant="regular_md" color="common.text">
              //         CHE
              //       </Typography>
              //     </InputAdornment>
              //   ) : null
              // }
              endAdornment={
                isLoading ? (
                  <InputAdornment position="end">
                    <CircularProgress size={18} />
                  </InputAdornment>
                ) : null
              }
              sx={[
                {
                  mb: "1.5rem",
                },
                isFocused && {
                  boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
                  borderColor: theme.palette.primary.main,
                },
              ]}
              onBlur={() => setIsFocused(false)}
              onFocus={() => setIsFocused(true)}
              filledSecondary={false}
              fixedHeight
            />
            {/* <Autocomplete
              {...field}
              fullWidth
              getOptionLabel={(option: any) => option?.siret || ""}
              filterOptions={(x) => x}
              options={options}
              PopperComponent={
                options?.length === 0 ||
                (options?.length === 1 && !options[0]?.siret) ||
                inputValue === "" ||
                (inputValue.length !== 14 && country === "France") ||
                (inputValue.length !== 9 && country === "Belgique") ||
                (inputValue.length !== 9 && country === "Suisse") ||
                !/^\d+$/.test(inputValue)
                  ? hiddenPopper
                  : Popper
              }
              autoComplete
              includeInputInList
              filterSelectedOptions
              noOptionsText={null}
              sx={{ m: 0 }}
              value={value || null}
              onChange={(event: any, newValue: CompanyType | null) => {
                onChange(newValue);
              }}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              isOptionEqualToValue={(option, value) => option.siret === value.siret}
              renderInput={({ id, ...params }) => {
                return (
                  <CustomInput
                    error={!!errors[name]}
                    id="siret-input"
                    inputProps={{ ...params.inputProps, placeholder: `Tapez votre ${CountriesToIdLabel[country]}...` }}
                    endAdornment={
                      isLoading ? (
                        <InputAdornment position="end">
                          <CircularProgress size={18} />
                        </InputAdornment>
                      ) : null
                    }
                    ref={params.InputProps.ref}
                    sx={[
                      {
                        mb: "1.5rem",
                      },
                      isFocused && {
                        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
                        borderColor: theme.palette.primary.main,
                      },
                    ]}
                    onBlur={() => setIsFocused(false)}
                    onFocus={() => setIsFocused(true)}
                    filledSecondary={filledSecondary}
                    fixedHeight
                  />
                );
              }}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.siret}>
                    <Grid container alignItems="center">
                      <Grid item sx={{ display: "flex", width: 44 }}>
                        <Building2 />
                      </Grid>
                      <Grid item sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}>
                        <Box component="span">{option?.name ? option.name : "Entreprise non reconnue"}</Box>
                        <Typography variant="body2" color="common.text" fontWeight={"bold"}>
                          {option?.siret}
                        </Typography>
                      </Grid>
                    </Grid>
                  </li>
                );
              }}
            /> */}
            {!customError && <CustomFormHelperText helperText={CountriesToHelperText[country]} id="helperText" />}
            {!!errors[name] && (
              <CustomFormHelperText
                helperText={errors[name] ? (errors[name]?.message as unknown as string) : ""}
                error
                id="formError"
              />
            )}
            {customError && <CustomFormHelperText helperText={customError} error id="customError" />}
          </FormControl>
        );
      }}
    />
  );
};
