import React from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "react-query";
import HistoryProvider from "./services/historyProvider";
import { AppLocationContext } from "@react-admin/ra-navigation";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { fr } from "date-fns/locale";
import App from "./App";

// for client side caching
const queryClient = new QueryClient({
  // defaultOptions: {
  //     queries: {
  //         staleTime: 10 * 60 * 1000,
  //     },
  // },
});

function Providers() {
  return (
    <BrowserRouter>
      <HistoryProvider>
        <QueryClientProvider client={queryClient}>
          <AppLocationContext>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
              <App />
            </LocalizationProvider>
          </AppLocationContext>
        </QueryClientProvider>
      </HistoryProvider>
    </BrowserRouter>
  );
}

export default Providers;
