import { useEffect, useState } from "react";
import { useGetIdentity } from "react-admin";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { fbFirestore } from "../firebase/firebase.services";

export function useGetPendingOrders() {
  const [licenceSubscriptionPending, setLicenceSubscriptionPending] = useState<any>(false);
  const [creditSubscriptionPending, setCreditSubscriptionPending] = useState<any>(false);
  const { data: identity } = useGetIdentity();

  useEffect(() => {
    const docRef = collection(fbFirestore, "orders");
    let q: any;
    if (identity?.status !== "Collaborateur") {
      q = query(docRef, where("company_id", "==", identity?.company));
    }

    const unsubscribePendingOrders = onSnapshot(q, (snap: any) => {
      snap.docs.forEach((doc: any) => {
        const order = doc.data();
        order.id = doc.id;
        order.cart.forEach((item: any, index: number) => {
          if (item.product.type === "Licence") {
            setLicenceSubscriptionPending(order.paymentStatus === "En cours" || order.status === "En cours de traitement");
          }
          if (item.product.type === "Crédits SMS") {
            setCreditSubscriptionPending(order.paymentStatus === "En cours" || order.status === "En cours de traitement");
          }
        });
      });
    });

    return () => unsubscribePendingOrders();
  }, [identity?.company, identity?.status]);

  // useEffect(() => {
  //   if (licenceSubscriptionPending) {
  //     console.log("license subscription order pending", licenceSubscriptionPending);
  //   }
  // }, [licenceSubscriptionPending]);

  // useEffect(() => {
  //   if (creditSubscriptionPending) {
  //     console.log("license subscription order pending", creditSubscriptionPending);
  //   }
  // }, [creditSubscriptionPending]);

  return {
    licenceSubscriptionPending,
    creditSubscriptionPending,
  };
}
