import React from "react";
import { styled } from "@mui/material/styles";
import { Stepper, Step, StepLabel, StepIconProps, Box, Typography, Divider } from "@mui/material";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";
import { LocalMall, ShareLocation, Payments } from "@mui/icons-material";
import { CheckCircle, CreditCard, File } from "lucide-react";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 24px)',
    right: 'calc(50% + 24px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.neutral.main,
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  color: theme.palette.button.neutral.contained.content,
  backgroundColor: theme.palette.button.neutral.contained.background,
  zIndex: 1,
  width: 24,
  height: 24,
  display: "flex",
  borderRadius: "0.5rem",
  justifyContent: "center",
  alignItems: "center",
  ...((ownerState.completed || ownerState.active) && {
    backgroundColor: theme.palette.button.primary.contained.background,
    color: theme.palette.button.primary.contained.content,
  }),
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <File size={12}/>,
    2: <CreditCard size={12} />,
    3: <CheckCircle size={12} />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

const CheckoutStepper = ({ checkoutStep }: { checkoutStep: number }) => {
  const steps = ["Facturation", "Paiement", "Paiement"];

  return (
    <Box sx={{ width: 1 }}>
      <Box sx={{ width: 1, display: "flex", flexDirection: ["column", "row"], justifyContent: "space-between", alignItems: ["center"], gap: [1, 0]}}>
      <Typography variant="bold_2xl" color="common.title">{steps[checkoutStep]}</Typography>
        <Stepper alternativeLabel activeStep={checkoutStep} connector={<ColorlibConnector />} sx={{width: [1, "25rem"]}}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={ColorlibStepIcon}></StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <Divider sx={{ width: 1, my: 2 }} />
    </Box>
  );
};

export default CheckoutStepper;
