import React, { useState } from "react";
import { useListContext, useUnselectAll, useNotify, useRefresh } from "react-admin";
import api from "../../services/apiWithAuth";
import { XIcon } from "lucide-react";
import { Button, Typography } from "@mui/material";

const LicensesBulkRemove = ({ company }: any) => {
  const { selectedIds, data: licenses } = useListContext();
  const [isLoading, setisLoading] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll("licenses");

  const removeLicenses = async () => {
    setisLoading(true);
    const selectedLicenses = licenses
      .filter((license: any) => selectedIds.includes(license.id) && license.email)
      .map((license: any) => {
        return { id: license.id, email: license.email };
      });
    try {
      const response = await api.post("/licenses/removeLicenses", {
        licenses: selectedLicenses,
      });
      setisLoading(false);
      refresh();
      notify("Désaffectation de " + response.data.length + " licences effectuée");
      unselectAll();
    } catch (err) {
      setisLoading(false);
      refresh();
      notify("Erreur lors de la désaffectation des licences", { type: "error" });
    }
  };

  return (
    <Button disabled={isLoading} onClick={removeLicenses}>
      <XIcon size={18} />
      <Typography variant="medium_sm" ml={0.5}>
        Désaffecter ces licences
      </Typography>
    </Button>
  );
};

export default LicensesBulkRemove;
