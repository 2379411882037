import { useEffect, useState } from "react";
import { useGetIdentity } from "react-admin";
import api from "../apiWithAuth";

export function useGetAutoDiscount(cart, subscription: any = null) {
  const [discount, setDiscount] = useState({});
  const { data: identity } = useGetIdentity();
  useEffect(() => {
    const asyncCall = async () => {
      if (identity && cart) {
        try {
          const params: any = {
            promoCode: "auto",
            date:
              subscription && subscription?.periodEnd > 0
                ? new Date(subscription.periodEnd * 1000).toISOString().split("T")[0]
                : new Date().toISOString().split("T")[0],
          };
          const { data } = await api.post("/utils/getDiscount", params);
          if (data) {
            if (data.scope === "Produits spécifiques") {
              if (!cart.filter((i: any) => data.products.includes(i.product.id)).length) {
                return;
              }
            }
            setDiscount(data);
          } else {
            setDiscount({});
          }
        } catch (err) {
          setDiscount({});
          // // console.log(err);
        }
      }
    };
    asyncCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identity, setDiscount]);

  return discount;
}
