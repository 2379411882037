import React from "react";
import { FormDataConsumer, useGetIdentity, ReferenceInput, useNotify, CloneButton } from "react-admin";
import { Edit, SimpleForm } from "@react-admin/ra-rbac";
import { DELAYS } from "../../services/common-utils/constants";
import SmsTextInput from "../../components/inputs/SmsInput";
import { count } from "sms-length";
import useCustomListRedirect from "../../services/hooks/useCustomRedirect";
import PageTitle from "../../components/navigation/PageTitle";
import FormInput from "../../components/form/FormInput";
import { object, string, literal, union, TypeOf, boolean, number, optional } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { AutocompleteInput } from "../../components/form/AutocompleteInput";
import { Box } from "@mui/material";
import { CopyIcon } from "lucide-react";

const formSchema = object({
  group_id: string(),
  name: string().min(1, "Nom du template requis"),
  group_access: boolean(),
  crmGroupId: string(),
  expeditor: string().nullish(),
  confirmation_message: string(),
  reminder_message: string(),
  reminder_delay: number(),
  showAlert: boolean(),
  alert_message: string().nullish(),
  alert_delay: number().nullish(),
});

const validateTemplateEdit = (values: any) => {
  let errors = {} as any;
  ["confirmation_message", "reminder_message", "alert_message"].forEach((input: string) => {
    if (values[input]) {
      const smsInfos = count(values[input]);
      if (smsInfos.length > 1377) errors[input] = "Maximum 9 SMS";
    }
  });
  if (values.customExpeditor) {
    if (values.expeditor.length > 11) {
      errors["expeditor"] = "Maximum 11 caractères";
    }
    if (values.expeditor.indexOf(" ") !== -1) {
      errors["expeditor"] = "Espaces interdits";
    }
  }
  return errors;
};

const TemplatesEdit = ({ ...props }) => {
  const { data: identity } = useGetIdentity();
  const redirect = useCustomListRedirect();
  const notify = useNotify();

  // Transform override
  const onTransform = async (data: any, { previousData }: any) => {
    if (!data.customExpeditor) {
      data.expeditor = "";
    }
    if (!data.showAlert) {
      data.alert_message = "";
      data.alert_delay = 0;
    }
    delete data.customExpeditor;
    if (["Client", "Indirect"].includes(identity?.status)) {
      data.group_id = identity?.company;
    }
    data.oldCrmGroupId = previousData?.crmGroupId;
    return data;
  };

  const onSuccess = async (data: any) => {
    notify(`Modèle modifié avec succès`, { type: "success" });
    redirect("templates");
  };

  return (
    <Edit
      {...props}
      transform={onTransform}
      mutationMode="optimistic"
      mutationOptions={{ onSuccess }}
      hasShow={false}
      actions={<></>}
    >
      <PageTitle
        text={(record: any) => `Modèle ${record.name}`}
        type="edit"
        resource="templates"
        breadcrumbListLabel="Liste des modèles"
        createButton={<CloneButton icon={<CopyIcon size={18} />} variant="contained" sx={{ px: 2, py: 1 }} />}
      />
      <SimpleForm validate={validateTemplateEdit} resolver={zodResolver(formSchema)} sx={{ position: "relative" }}>
        {["Collaborateur", "Revendeur"].includes(identity?.status) && (
          <ReferenceInput
            required
            source="group_id"
            reference="companies"
            filter={identity?.status === "Revendeur" ? { parentCompany: identity?.company } : {}}
            sort={{ field: "company", order: "ASC" }}
            perPage={1000}
          >
            <AutocompleteInput
              label="Organisation"
              optionText="company"
              filterToQuery={(search: any) => ({ company: search })}
            />
          </ReferenceInput>
        )}
        <FormInput label="Nom du modèle" name="name" required />
        <FormInput
          checkboxLabel="Visible par tout les utilisateurs"
          name="group_access"
          defaultValue={false}
          type="checkbox"
        />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.group_access === false && (
              <ReferenceInput
                required
                source="crmGroupId"
                reference="groups"
                filter={{
                  company: ["Collaborateur", "Revendeur"].includes(identity?.status)
                    ? formData.group_id
                    : identity?.company,
                }}
                sort={{ field: "name", order: "ASC" }}
                perPage={1000}
              >
                <AutocompleteInput
                  label="Groupe d'accès au modèle"
                  optionText="name"
                  filterToQuery={(search: any) => ({ name: search })}
                  helperText="Seuls les utilisateurs du groupe pourront accéder au modèle"
                />
              </ReferenceInput>
            )
          }
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <FormInput
              checkboxLabel="Personnaliser l'émetteur"
              name="customExpeditor"
              defaultValue={!!formData.expeditor}
              type="checkbox"
            />
          )}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.customExpeditor && (
              <FormInput label="Émetteur" name="expeditor" defaultValue={null} helperText="11 caractères au maximum" />
            )
          }
        </FormDataConsumer>

        <SmsTextInput source="confirmation_message" defaultValue={null} label="Message de confirmation" />

        <SmsTextInput source="reminder_message" label="Message de rappel" />
        {/* <SelectInput
          defaultValue={1440}
          validate={required()}
          source="reminder_delay"
          label="Délai avant rappel"
          choices={DELAYS}
        /> */}
        <FormInput
          label="Délai avant rappel"
          name="reminder_delay"
          type="select"
          defaultValue={1440}
          values={DELAYS.map((delay: any) => {
            return { label: delay.name, id: delay.id };
          })}
          select
          autoComplete="off"
          required
          placeholder="choisissez..."
        />

        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <FormInput
              label="Envoyer un message d'alerte"
              name="showAlert"
              defaultValue={!!formData.alert_message}
              type="checkbox"
            />
          )}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.showAlert && <SmsTextInput source="alert_message" label="Message d'alerte" />
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.showAlert && (
              <FormInput
                defaultValue={1440}
                label="Délai avant alerte"
                name="alert_delay"
                type="select"
                values={DELAYS.map((delay: any) => {
                  return { label: delay.name, id: delay.id };
                })}
                select
                autoComplete="off"
                required
                placeholder="choisissez..."
              />
            )
          }
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

export default TemplatesEdit;
