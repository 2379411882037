import { Container, Grid, Box, Typography, useTheme } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import React from "react";
import { fbFirestore } from "../../services/firebase/firebase.services";
import { collection, Firestore, getDocs, orderBy, query, where } from "firebase/firestore";
import { useGetIdentity } from "react-admin";
import PageTitle from "../../components/navigation/PageTitle";

const ProductCard = ({ product }: any) => {
  let navigate = useNavigate();
  const theme = useTheme();

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        border: "1px solid lightgrey",
        borderRadius: "10px",
        padding: 4,
        transition: "all 0.2s",
        cursor: "pointer",
        position: "relative",
        ":hover": { transform: "scale(1.05)" },
      }}
      onClick={() => {
        navigate(`/shop/${product.id}`);
      }}
    >
      <Typography variant="h6" component="h1" fontWeight={"bold"} sx={{ textAlign: "center", mb: "1rem" }}>
        {product.name}
      </Typography>

      <Box
        sx={{
          position: "relative",
          width: 1,
          height: 0,
          paddingBottom: "100%",
          marginBottom: 3,
        }}
      >
        <img
          src={product.pictures[0].src}
          alt={product.name}
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            borderRadius: "10px",
          }}
        />
      </Box>

      <div
        style={{
          padding: "10px",
          backgroundColor: theme.palette.mode === "light" ? "lightgrey" : "inherit",
          width: "fit-content",
          height: "45px",
          borderRadius: "5px",
          alignSelf: "center",
        }}
      >
        <Typography variant="subtitle1" component="h1" sx={{ textAlign: "center" }}>
          {product.type}
        </Typography>
      </div>

      <Typography variant="body1" component="h1" sx={{ textAlign: "center", mt: "1rem", mb: "1.5rem" }}>
        {product.description}
      </Typography>
    </Box>
  );
};

const ShopPage: FC = () => {
  const [products, setProducts] = useState([] as any[]);
  const theme = useTheme();
  const { isLoading, identity } = useGetIdentity();

  useEffect(() => {
    const asyncCall = async () => {
      const q = query(collection(fbFirestore, "products"), orderBy("order"));
      let productsSnap = await getDocs(q);
      let products = productsSnap.docs.map((p) => p.data());
      products = products
        .map((product: any) => {
          const availableTarifs = product.tarifs.filter((tarif: any) => {
            return (
              tarif.countries.includes(identity?.companyCountry) &&
              tarif.companyStatus.includes(identity?.status) &&
              tarif.companyTypes.includes(identity?.type) &&
              (!tarif?.companyIds?.length || tarif.companyIds.includes(identity?.company)) &&
              !(tarif?.blackListedCompanyIds || []).includes(identity?.company)
            );
          });
          return availableTarifs.length > 0
            ? {
                ...product,
                tarifs: availableTarifs,
              }
            : null;
        })
        .filter((product: any) => !!product);
      setProducts(products);
    };

    asyncCall();
  }, [identity?.status, identity?.companyCountry]);

  // JSX to be rendered
  return isLoading ? null : (
    <Container maxWidth={false}>
      <PageTitle text="Boutique en ligne" backButton={false} divider />
      {identity?.status === "Indirect" ? (
        <div>
          <Typography variant="h6" sx={{ mt: "30px", mb: "30px" }}>
            Merci de contacter votre organisme revendeur pour pouvoir obtenir de nouvelles licences ou des crédits SMS
            supplémentaires.
          </Typography>
          <Typography color="common.title" sx={{ mt: "30px", mb: "30px" }}>
            <b>Informations contact revendeur : </b>
            <br />
            {identity?.parentCompanyData?.company}
            <br />
            {identity?.parentCompanyData?.phone}
            <br />
            {identity?.parentCompanyData?.email}
            <br />
          </Typography>
        </div>
      ) : (
        <>
          <Typography variant="h6" sx={{ mt: "30px", mb: "30px" }}>
            Vous trouverez ici tout nos produits et services proposés.
          </Typography>

          <Box
            display="grid"
            gridAutoRows="1fr"
            sx={{ gridTemplateColumns: ["1fr", "repeat(2, 1fr)", "repeat(4, 1fr)"] }}
            gap={3}
          >
            {products
              .filter((p) => p.pictures.length)
              .map((p) => (
                <ProductCard key={p.id} product={p} style={{ height: "100%" }} />
              ))}
          </Box>
        </>
      )}
    </Container>
  );
};

export default ShopPage;
