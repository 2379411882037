import { Box, Typography, ToggleButton, ToggleButtonGroup, CircularProgress, Divider, useTheme } from "@mui/material";
import { useTheme as useRaTheme } from "react-admin";
import LoadingButton from "@mui/lab/LoadingButton";
import { FC, useState, useEffect, useContext } from "react";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { object, string, TypeOf, boolean } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import FormInput from "../../components/form/FormInput";
import styled from "@emotion/styled";
import React from "react";
import { useLocalStorage } from "usehooks-ts";
import { fbAuthProvider } from "../../services/firebase/firebase.providers";
import {
  browserLocalPersistence,
  browserSessionPersistence,
  getAuth,
  isSignInWithEmailLink,
  sendSignInLinkToEmail,
  setPersistence,
} from "firebase/auth";
import api from "../../services/api";
import { HistoryContext } from "../../services/historyProvider";
import SignContainer from "../../components/others/SignContainer";
import { fbAuth } from "../../services/firebase/firebase.services";

const PrimaryLinkItem = styled(Link)(({ theme }) => ({
  textDecoration: "underline",
  color: theme.palette.layout.link,
}));
const LinkItem = styled(Link)(({ theme }) => ({
  textDecoration: "underline",
  color: theme.palette.common.title,
}));

// Login Schema with Zod
const loginSchema = object({
  email: string().min(1, "Adresse mail requise").email("Adresse mail invalide"),
  password: string().optional(),
  signinType: string(),
  rememberMe: boolean(),
}).refine((schema) => (schema.signinType === "password" ? schema.password.length > 0 : true), {
  message: "Mot de passe requis",
  path: ["password"],
});

// Infer the Schema to get the TS Type
type ILogin = TypeOf<typeof loginSchema>;

const LoginPage: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [emailForSignIn, setEmailForSignIn] = useLocalStorage("emailForSignIn", "" as string);
  const [signinType, setSigninType] = useState("password");
  const [isEmailLinkPresent, setIsEmailLinkPresent] = useState(false);
  const [isEmailLinkChecked, setIsEmailLinkChecked] = useState(false);

  const navigate = useNavigate();

  //? was here to redirect to requested page before login, we decided to remove the feature and always redirect to dashboard, but keep it just in case
  // useEffect(() => {
  //   //history from historyContext can be not already updated
  //   if (history.length && !fromUrl && history[0] === window.location.pathname) {
  //     const matches: any = history
  //       .map((path: string) => {
  //         if (path && (path.includes("login") || path.includes("sign-up") || path.includes("forgotten-password"))) {
  //           return null;
  //         } else {
  //           return path;
  //         }
  //       })
  //       .filter((match: string) => match !== null);
  //     setFromUrl(matches.length ? matches[0] : "none");
  //   }
  // }, [setFromUrl, fromUrl, history]);

  // Get query parameter value
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const fromRegister = query.get("fromRegister");

  // Default Values
  const defaultValues: ILogin = {
    email: "",
    password: "",
    signinType: "password",
    rememberMe: false,
  };

  // The object returned from useForm Hook
  const methods = useForm<ILogin>({
    resolver: zodResolver(loginSchema),
    defaultValues,
  });

  // Submit Handler
  const onSubmitHandler: SubmitHandler<ILogin> = async (values: ILogin) => {
    setError("");
    // Login via firebase provider
    try {
      if (values.signinType === "password") {
        await fbAuthProvider.login({
          username: values.email,
          password: values.password,
          rememberMe: values.rememberMe,
        });
        let urlToRedirect: string;
        if (signinType === "password") {
          // urlToRedirect = fromUrl && fromUrl !== "none" ? fromUrl : "/";
          urlToRedirect = "/";
        } else {
          urlToRedirect = "/login?fromRegister=true";
        }
        navigate(urlToRedirect);
      } else {
        const auth = fbAuth;
        auth.useDeviceLanguage();
        let { data: userExists } = await api.post("/utils/checkUserExists", { email: values.email });
        if (userExists) {
          await sendSignInLinkToEmail(auth, values.email, {
            url: window.location.href.split("?")[0],
            handleCodeInApp: true,
          });
          setEmailForSignIn(values.email);
          navigate("/login?fromRegister=true");
        } else {
          setError("Identifiants incorrects");
        }
      }
    } catch (err) {
      setError("Identifiants incorrects");
    }
    setIsLoading(false);
  };

  const handleSigninTypeChange = (event: React.MouseEvent<HTMLElement>, newSigninType: string) => {
    if (newSigninType !== null) {
      setError("");
      methods.setValue("signinType", newSigninType);
      setSigninType(newSigninType);
    }
  };

  useEffect(() => {
    const auth = fbAuth;
    if (!isEmailLinkChecked && isSignInWithEmailLink(auth, window.location.href)) {
      setIsEmailLinkPresent(true);
    }
    setIsEmailLinkChecked(true);
  }, [isEmailLinkChecked]);

  useEffect(() => {
    if (isEmailLinkPresent) {
      let email = emailForSignIn || "";
      //! could be necessary if authentication with phone
      // if (!email) {
      //   email = window.prompt('Merci de confirmer votre adresse email.') as string;
      // }
      fbAuthProvider.login({ username: email }).then((res) => navigate("/"));
    }
  }, [emailForSignIn, setEmailForSignIn, navigate, isEmailLinkPresent]);

  // JSX to be rendered
  return (
    <SignContainer
      cardWidth={[0.92, 500]}
      BottomAction={() => (
        <Typography variant="regular_md" sx={{ mb: "1rem" }}>
          Pas de compte ?{" "}
          <PrimaryLinkItem to="/sign-up">
            <Typography variant="bold_md" ml={2} sx={{ textDecoration: "underline" }}>
              Je m'inscris.
            </Typography>
          </PrimaryLinkItem>
        </Typography>
      )}
      type="login"
    >
      <FormProvider {...methods}>
        {isEmailLinkPresent ? (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            sx={{ width: [0.5, 1] }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={methods.handleSubmit(onSubmitHandler)}
            sx={{
              width: 1,
              "& .MuiFormControl-root": { width: 1, mx: 1, my: 1 },
              "& .MuiAutocomplete-root": { width: 1, mx: 1, my: 1, "& .MuiFormControl-root": { mx: 0, my: 0 } },
              "& .MuiInputBase-root": { width: "auto", m: 0 },
              "& .MuiButtonBase-root": { py: 0.5 },
              "& .MuiToggleButton-root": { borderRadius: 2 },
              "& .MuiFormControlLabel-root": { width: 1, mt: 1 },
            }}
          >
            {!fromRegister && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "start",
                    pb: 0,
                    width: 1,
                  }}
                >
                  <Typography component="h1" variant="bold_xl" sx={{ textAlign: "left", width: 1 }}>
                    Connectez-vous à votre compte
                  </Typography>
                  <Divider sx={{ mb: 2, mt: 3, width: 1 }} />
                </Box>
              </>
            )}

            {fromRegister && (
              <Typography
                variant="subtitle1"
                component="h1"
                sx={{ textAlign: "left", my: 5, color: "success.main", fontWeight: "bold" }}
              >
                {"Un lien de connexion vous a été envoyé par mail. Vous pouvez fermer cet onglet."}
              </Typography>
            )}

            {!fromRegister && (
              <>
                <ToggleButtonGroup
                  value={signinType}
                  exclusive
                  color="primary"
                  onChange={handleSigninTypeChange}
                  aria-label="Platform"
                  sx={{
                    mt: [1, 2],
                    mb: 2,
                    width: 1,
                    justifyContent: "center",
                    borderRadius: 4,
                  }}
                >
                  <ToggleButton
                    value="password"
                    sx={{
                      width: 0.5,
                      borderColor: "button.primary.outlined.stroke",
                      color: "button.primary.outlined.content",
                      "&.Mui-selected, &.Mui-selected:hover": {
                        color: "button.primary.contained.content",
                        bgcolor: "button.primary.contained.background",
                      },
                    }}
                  >
                    <Typography variant="medium_lg">Classique</Typography>
                  </ToggleButton>
                  <ToggleButton
                    value="emailLink"
                    sx={{
                      width: 0.5,
                      borderColor: "button.primary.outlined.stroke",
                      color: "button.primary.outlined.content",
                      "&.Mui-selected, &.Mui-selected:hover": {
                        color: "button.primary.contained.content",
                        bgcolor: "button.primary.contained.background",
                      },
                    }}
                  >
                    <Typography variant="medium_lg">Lien magique</Typography>
                  </ToggleButton>
                </ToggleButtonGroup>
                {/* <FormInput label={"none"} type="input" name="signinType" required style={{ display: "none" }} /> */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    alignItems: "center",
                    height: [220],
                    width: 1,
                  }}
                >
                  <FormInput
                    label="Email"
                    name="email"
                    type="email"
                    InputLabelProps={{ required: false }}
                    defaultValue=""
                    required
                    disabled={isLoading}
                    helperText={
                      signinType === "emailLink" ? "Vous allez recevoir un lien unique pour vous connecter" : ""
                    }
                  />
                  {signinType === "password" && (
                    <>
                      <FormInput
                        label="Mot de passe"
                        name="password"
                        type="password"
                        defaultValue=""
                        required
                        InputLabelProps={{ required: false }}
                        disabled={isLoading}
                      />
                      {/* <FormInput
                        checkboxLabel="Se souvenir de moi"
                        name="rememberMe"
                        defaultValue={false}
                        type="checkbox"
                        disabled={isLoading}
                      /> */}
                    </>
                  )}
                  {/* {signinType === "emailLink" && (
                    <Typography sx={{ fontSize: "0.7rem", mb: "1rem", color: "#9f9d9d" }}>
                      Vous allez recevoir un lien unique pour vous connecter
                    </Typography>
                  )} */}
                  <Typography variant="medium_md" sx={{ width: 1, textAlign: "left", mb: 2, ml: 3, color: "#CD5C5C" }}>
                    {error}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    width: 1,
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {signinType === "password" ? (
                    <LinkItem to="/forgotten-password">
                      <Typography variant="bold_md">Mot de passe oublié ?</Typography>
                    </LinkItem>
                  ) : (
                    <div></div>
                  )}

                  <LoadingButton
                    loading={methods.formState.isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                    sx={{
                      px: [2, "auto"],
                    }}
                  >
                    <Typography variant="medium_lg">Connexion</Typography>
                  </LoadingButton>
                </Box>
              </>
            )}
          </Box>
        )}
      </FormProvider>
    </SignContainer>
  );
};

export default LoginPage;
