import { useCallback, useContext, useEffect, useState } from "react";
import { useRedirect } from "react-admin";
import { HistoryContext } from "../../services/historyProvider";

function useCustomListRedirect() {
  const history: string[] = useContext(HistoryContext);
  const redirect = useRedirect();
  const [company, setCompany] = useState("");

  useEffect(() => {
    if (history.length && !company && history[0] === window.location.pathname) {
      const match = history[1].match(/(?:companies\/)(.*)/);
      setCompany(match ? match[1] : "");
    }
  }, [history, company]);

  return useCallback(
    (resource: string = "", fromCompany = "") => {
      if (fromCompany || company) {
        redirect(`/companies/${company}/${resource}`);
      } else {
        redirect("list", resource);
      }
      return;
    },
    [company, redirect]
  );
}

export default useCustomListRedirect;
