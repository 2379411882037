import { Grid, Typography, Button, Box } from "@mui/material";
import React, { useState, useCallback, useContext, useEffect } from "react";
import {
  SaveButton,
  Toolbar,
  FormDataConsumer,
  TabbedForm,
  ReferenceInput,
  useGetIdentity,
  useUpdate,
  useNotify,
  useRecordContext,
  useDelete,
  Confirm,
  Button as RaButton,
  TabbedFormTabs,
  useDataProvider,
  DeleteWithConfirmButton,
} from "react-admin";
import { Edit, FormTab } from "@react-admin/ra-rbac";
import { SERVICES, TITLES, USERS_ROLES } from "../../services/common-utils/constants";
import useCustomListRedirect from "../../services/hooks/useCustomRedirect";
import api from "../../services/apiWithAuth";
import PageTitle from "../../components/navigation/PageTitle";
import FormInput from "../../components/form/FormInput";
import { object, string, literal, union, TypeOf, boolean, number } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { AutocompleteInput } from "../../components/form/AutocompleteInput";
import { collection, getDocs, query, where } from "firebase/firestore";
import { fbFirestore } from "../../services/firebase/firebase.services";

const passwordValidation = new RegExp(/^(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&.])[A-Za-z\d@$!%*?&.]{8,}$/);

const formSchema = object({
  title: string().nullish(),
  firstName: string().min(1, "Prénom requis"),
  lastName: string().min(1, "Nom de famille requis"),
  email: string().min(1, "Adresse mail requise").email("Adresse mail invalide"),
  crmGroupId: string().nullish(),
  service: string().nullish(),
  role: string().nullish(),
  position: string().nullish(),
  note: string().nullish(),
  password: string()
    .min(1, "Mot de passe requis")
    .min(8, "Minimum 8 caractères")
    .regex(passwordValidation, {
      message: "Mot de passe invalide",
    })
    .nullish(),
  authorized: boolean().nullish(),
  proPhone: string().min(4, "Téléphone requis").nullish(),
  company: string().min(1, "Organisation requise").nullish(),
});

const UsersToolBar = ({ ...props }) => {
  const record = useRecordContext();
  return (
    <Toolbar>
      <SaveButton label="Sauvegarder" sx={{ gap: 2 }} />
      {props.identity?.status === "Collaborateur" ||
        (props.identity?.status !== "Collaborateur" && record.id !== props.identity.email && (
          <DeleteWithConfirmButton
            confirmTitle={`Suppression de l'utilisateur ${record.firstName} ${record.lastName}`}
            confirmContent="Etes-vous sur de vouloir supprimer ce compte utilisateur ? Si ce dernier possède une licence elle ne sera plus utilisable."
            confirmColor="warning"
          />
        ))}
    </Toolbar>
  );
};

const UserTitle = () => {
  const record = useRecordContext();
  const { data: identity } = useGetIdentity();
  return !record || !identity ? null : record.id === identity?.id ? (
    <PageTitle text={"Édition de mon profil"} type="list" resource="users" breadcrumbListLabel={"Mon profil"} />
  ) : (
    <PageTitle
      text={(record: any) => `${record.firstName} ${record.lastName}`}
      type="edit"
      resource="users"
      breadcrumbListLabel="Liste des utilisateurs"
    />
  );
};

const Form = ({ ...props }) => {
  const { data: identity } = useGetIdentity();
  const redirect = useCustomListRedirect();
  const notify = useNotify();
  const record = useRecordContext();
  const dataProvider = useDataProvider();

  const [update] = useUpdate();
  const save = useCallback(
    async (values: any) => {
      values.email = values.email.trim().toLowerCase();
      const errors = {} as any;
      try {
        const password = values.password;
        const crmGroupId = values.crmGroupId;
        delete values.crmGroupId;
        delete values.password;
        delete values.updateWithPassword;
        await update("users", { id: record.id, data: values, previousData: record }, { returnPromise: true });
        if (crmGroupId !== record.crmGroupId) {
          const q = query(collection(fbFirestore, "groups"), where("users", "array-contains", record.id));
          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            const oldGroup = querySnapshot.docs[0].data();
            await update("groups", {
              id: oldGroup.id,
              data: { users: (oldGroup.users || []).filter((user: any) => user !== record.id) },
              previousData: oldGroup,
            });
          }
            const newGroupRes = await dataProvider.getOne("groups", { id: crmGroupId });
          await update("groups", {
            id: crmGroupId,
            data: { users: (newGroupRes.data.users || []).concat([record.id]) },
            previousData: newGroupRes.data,
          });
        }
        if (password) {
          if (password.length < 8) {
            errors.password = "Nombre de caractères insuffisant (8 min)";
            return errors;
          }
          try {
            await api.post("/utils/updateAuthUser", { email: values.email, password });
          } catch (err) {
            errors.password = "Erreur lors de la mise à jour du mot de passe.";
            return errors;
          }
        }
        notify("Utilisateur mis à jour avec succès", {
          type: "success",
          messageArgs: { smart_count: 1 },
        });
        redirect("users");
      } catch (error) {}
    },
    [update, notify, redirect, record]
  );

  if (!identity || !record) return null;
  return (
    <TabbedForm
      tabs={<TabbedFormTabs indicatorColor="primary" />}
      toolbar={<UsersToolBar identity={identity} />}
      onSubmit={save}
      resolver={zodResolver(formSchema)}
    >
      {/* General form */}
      <FormTab label="Géneral" name="general">
        <Box sx={{ display: "grid", gridTemplateColumns: ["1fr", "1fr", "1fr 1fr"], gap: 2 }}>
          <FormInput
            label="Civilité"
            source="title"
            type="select"
            defaultValue={null}
            values={TITLES.map((s) => ({ id: s, label: s }))}
            select
            placeholder="choisissez..."
          />
          <FormInput source="firstName" defaultValue={null} label="Prénom" />
          <FormInput source="lastName" defaultValue={null} label="Nom de famille" />
          <FormDataConsumer>
            {({ formData, ...rest }) => (
              <FormInput
                defaultValue={null}
                source="email"
                label="Adresse email"
                helperText="Cet e-mail est utilisée pour se connecter"
                disabled={formData.id === identity?.id && ["Administrateur"].includes(identity?.role)}
              />
            )}
          </FormDataConsumer>
          <ReferenceInput
            required
            source="company"
            reference="companies"
            filter={identity?.status === "Collaborateur" ? {} : { parentCompany: identity?.company }}
            sort={{ field: "company", order: "ASC" }}
            perPage={1000}
          >
            <AutocompleteInput
              label="Organisation"
              optionText="company"
              filterToQuery={(search: any) => ({ company: search })}
            />
          </ReferenceInput>
          <FormDataConsumer>
            {({ formData, ...rest }) => (
              <ReferenceInput
                required
                source="crmGroupId"
                reference="groups"
                sort={{ field: "name", order: "ASC" }}
                filter={{ company: formData.company }}
                perPage={1000}
              >
                <AutocompleteInput
                  label="Groupe"
                  optionText="name"
                  filterToQuery={(search: any) => ({ name: search })}
                />
              </ReferenceInput>
            )}
          </FormDataConsumer>
          <FormInput
            label="Service"
            type="select"
            name="service"
            values={SERVICES}
            select
            autoComplete="off"
            placeholder="choisissez..."
          />
          <FormInput defaultValue={null} source="position" label="Fonction" />
          <FormInput defaultValue={null} source="proPhone" label="Numéro de téléphone PRO" type="phone" />
          <FormInput source="note" defaultValue={null} label="Note" />
        </Box>
      </FormTab>

      {["Super Admin", "Administrateur"].includes(identity?.role) && (
        <FormTab label="Rôle et sécurité" name="security">
          <Box sx={{ display: "grid", gridTemplateColumns: ["1fr", "1fr", "1fr 1fr"], gap: 2 }}>
            {record.id !== identity?.id && (
              <FormInput
                label="Rôle"
                source="role"
                type="select"
                defaultValue={null}
                values={USERS_ROLES.map((s) => ({ id: s, label: s }))}
                select
                placeholder="choisissez..."
                required
              />
            )}
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.role === "Administrateur" &&
                record.id !== identity?.id && (
                  // TODO: find a way to set defaultvalue on checkbox avoiding the bug
                  <FormInput checkboxLabel="Autoriser l'accès au CRM" source="authorized" type="checkbox" />
                )
              }
            </FormDataConsumer>
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.role === "Administrateur" && (
                  <FormInput checkboxLabel="Modifier mot de passe" source="updateWithPassword" type="checkbox" />
                )
              }
            </FormDataConsumer>
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.role === "Administrateur" &&
                formData.updateWithPassword && (
                  <FormInput
                    label="Mot de passe"
                    name="password"
                    defaultValue=""
                    autoComplete="new-password"
                    type="password"
                    required
                    helperText="Doit contenir au minimum 1 minuscule, 1 chiffre et un caractère spécial"
                    placeholder="*************"
                  />
                )
              }
            </FormDataConsumer>
          </Box>
        </FormTab>
      )}
    </TabbedForm>
  );
};

const UsersEdit = ({ ...props }) => {
  const onTransform = async (data: any) => {
    data.email = data.email.trim().toLowerCase();
    if (data.role !== "Administrateur") {
      data.authorized = false;
    }
    return data;
  };

  return (
    <Edit {...props} transform={onTransform} hasShow={false}>
      <UserTitle />
      <Form {...props} />
    </Edit>
  );
};

export default UsersEdit;
