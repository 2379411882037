import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(LinearProgress,  {
  shouldForwardProp: (prop) => {
    return prop !== "width";
  },
})(({ theme, width }) => ({
  height: 10,
  borderRadius: 5,
  width,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "primary.main",
  },
}));

export default function LinearProgressBar({currentStep, stepsNumber, width}: {currentStep: number, stepsNumber: number, width: string[]}) {
  return (
    <Box sx={{ width: 1 }}>
      <BorderLinearProgress variant="determinate" value={Math.round(100 * currentStep / stepsNumber)} width={width}/>
    </Box>
  );
}