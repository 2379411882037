import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import { Box, Typography, Button, Grid, Divider, useMediaQuery } from "@mui/material";
import FormInput from "../../../components/form/FormInput";
import { COUNTRIES } from "../../../services/common-utils/constants";

const CheckoutForm = ({ handleStep, handleData, customerInfos, checkoutStep, setCustomerInfos, identity }: any) => {
  const isMobile = !!useMediaQuery((theme: any) => theme.breakpoints.only("xs"));
  const methods = useForm({
    defaultValues: {
      ...customerInfos,
      country: COUNTRIES.includes(identity.company) ? identity.company : "France",
    },
  });

  const onSubmit = async (data: any) => {
    delete data.country;
    setCustomerInfos(data);
    handleStep(1);
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      sx={{
        width: "auto",
        mr: [0, 4],
        "& .MuiInputBase-root": { width: 1 },
        "& .MuiFormControl-root": { margin: 0.5, width: 1 },
      }}
    >
      <FormProvider {...methods}>
        <Box component="form" autoComplete="off" id="customer-infos-form" onSubmit={methods.handleSubmit(onSubmit)}>
          <Box
            display="flex"
            sx={{ flexDirection: "column" }}
            alignItems="start"
            justifyContent="space-around"
            width="100%"
          >
            <Typography variant="bold_md" color="common.title" sx={{ mb: 2 }}>
              Coordonnées de contact
            </Typography>
            {isMobile ? (
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1}}>
                <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                  <FormInput name="firstName" label="Prénom" required filledSecondary />
                  <FormInput name="lastName" label="Nom" required filledSecondary />
                </Box>
                <FormInput name="email" label="Email" required filledSecondary />
                <FormInput
                  type="phone"
                  name="contactPhone"
                  label="Téléphone"
                  defaultValue={customerInfos.contactPhone}
                  filledSecondary
                />
              </Box>
            ) : (
              <Box sx={{ display: "grid", gridTemplateColumns: ["1fr", "1fr 1fr"], width: 1 }} gap={2}>
                <FormInput name="firstName" label="Prénom" required filledSecondary />
                <FormInput name="lastName" label="Nom" required filledSecondary />
                <FormInput name="email" label="Email" required filledSecondary />
                <FormInput
                  type="phone"
                  name="contactPhone"
                  label="Téléphone"
                  defaultValue={customerInfos.contactPhone}
                  filledSecondary
                />
              </Box>
            )}
            {isMobile && <Divider sx={{ width: 1, mt: 2 }} />}
            <Typography variant="bold_md" color="common.title" sx={{ my: 2 }}>
              Adresse de facturation
            </Typography>
            {isMobile ? (
              <>
                <FormInput
                  label="Adresse"
                  name="billingAddress"
                  required
                  disabled={methods.formState.isSubmitting}
                  filledSecondary
                />
                <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                  <FormInput
                    label={"Code postal"}
                    name="billingZipCode"
                    required
                    disabled={methods.formState.isSubmitting}
                    filledSecondary
                  />
                  <FormInput
                    label="Ville"
                    name="billingCity"
                    required
                    disabled={methods.formState.isSubmitting}
                    filledSecondary
                  />
                </Box>
                <FormInput label={"Pays"} name="country" required disabled filledSecondary />
              </>
            ) : (
              <>
                <Grid container spacing={{ xs: 1, md: 2 }} width={1}>
                  <Grid item xs={6} md={8}>
                    <FormInput
                      label="Adresse"
                      name="billingAddress"
                      required
                      disabled={methods.formState.isSubmitting}
                      filledSecondary
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormInput
                      label={"Code postal"}
                      name="billingZipCode"
                      required
                      disabled={methods.formState.isSubmitting}
                      filledSecondary
                    />
                  </Grid>
                </Grid>
                <Box display="flex" sx={{ flexDirection: ["column", "row"], gap: [1, 1] }} width={1}>
                  <FormInput
                    label="Ville"
                    name="billingCity"
                    required
                    disabled={methods.formState.isSubmitting}
                    filledSecondary
                  />
                  <FormInput label={"Pays"} name="country" required disabled filledSecondary />
                </Box>
              </>
            )}
          </Box>
        </Box>
      </FormProvider>
    </Box>
  );
};

export default CheckoutForm;
