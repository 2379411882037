import { Box, Typography, Card, Divider, Button, Grid } from "@mui/material";
import { ArrowLeftRight, CheckCircle, HelpCircle, PenLine, XCircle } from "lucide-react";
import { useEffect, useState } from "react";
import React from "react";
import LinearProgressBar from "../../components/others/LinearProgressBar";
import { useUpdate, useNotify, useGetOne } from "react-admin";
import api from "../../services/apiWithAuth";
import { useForm, SubmitHandler, FormProvider, useWatch, useFormContext } from "react-hook-form";
import { object, string, literal, union, TypeOf, boolean } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import FormInput from "../../components/form/FormInput";
import LoadingButton from "@mui/lab/LoadingButton";

// Select values
const COUNTRIES = ["France", "Belgique", "Suisse", "Canada"];

const billingDetailsSchema = object({
  firstName: string().min(1, "Prénom requis"),
  lastName: string().min(1, "Nom de famille requis"),
  company: string().min(1, "Nom entité requis"),
  email: string().min(1, "Adresse mail requise").email("Adresse mail invalide"),
  address: string().min(1, "Adresse requise"),
  zipCode: string().min(1, "Code postal requis"),
  city: string().min(1, "Ville requise"),
  country: string().min(1, "Pays requis"),
  withVat: boolean(),
  vat: string(),
}).refine((schema) => (schema.withVat ? schema.vat.length > 0 : true), {
  message: "Numéro de TVA intra-communautaire requis",
  path: ["vat"],
});

// Infer the Schema to get the TS Type
type IBillingDetails = TypeOf<typeof billingDetailsSchema>;

const BillingDetailsForm = ({ identity, company }: any) => {
  const notify = useNotify();
  const [update] = useUpdate();
  const { firstName, lastName, email, vat, address, zipCode, city } = company.billingDetails;
  // Default Values
  const defaultValues: IBillingDetails = {
    firstName,
    lastName,
    email,
    company: identity.companyName,
    address,
    zipCode,
    city,
    country: COUNTRIES.includes(identity?.country || "") ? identity.country : "France", // for dom tom
    withVat: !!vat,
    vat,
  };

  // The object returned from useForm Hook
  const methods = useForm<IBillingDetails>({
    resolver: zodResolver(billingDetailsSchema),
    defaultValues,
  });

  const [withVat] = useWatch({
    control: methods.control,
    name: ["withVat"],
  });

  const onSubmitHandler: SubmitHandler<IBillingDetails> = async (values: IBillingDetails) => {
    // BillingDetails via firebase provider
    try {
      const billingDetails: any = { ...values };
      delete billingDetails.company;
      delete billingDetails.country;
      delete billingDetails.withVat;
      await update(
        "companies",
        { id: identity?.company, data: { billingDetails }, previousData: company },
        { returnPromise: true }
      );
      notify(`Enregistrement des données effectué avec succés`, {
        type: "success",
        messageArgs: { smart_count: 1 },
      });
    } catch (err) {
      notify(`Erreur lors de l'enregistrement des nouvelles données de facturation`, {
        type: "error",
        messageArgs: { smart_count: 1 },
        autoHideDuration: 3000,
      });
      return;
    }
  };
  return (
    <FormProvider {...methods}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={async (evt) => {
          evt.preventDefault();
          methods.handleSubmit(onSubmitHandler)(evt);
        }}
        sx={{
          width: 1,
          "& .MuiFormControl-root": { width: 1, my: [0.5, 0.5, 0.5, 0.5, 1] },
          "& .MuiAutocomplete-root": { width: 1, "& .MuiFormControl-root": { mx: 0, my: 0 } },
          "& .MuiInputBase-root": { width: 1, my: 0 },
          "& .MuiButtonBase-root": { py: 0.5 },
          "& .MuiToggleButton-root": { borderRadius: 2 },
          "& .MuiFormControlLabel-root": { width: 1 },
        }}
      >
        <Box display="flex" sx={{ flexDirection: ["column", "row"], gap: [1, 5] }} width={1}>
          {/* Enterprise registration */}
          <FormInput label="Nom" name="lastName" required disabled={methods.formState.isSubmitting} />
          <FormInput label="Prénom" name="firstName" required disabled={methods.formState.isSubmitting} />
        </Box>
        <Box sx={{ width: 1 }}>
          {/* Enterprise registration */}
          <FormInput label="Email de facturation" name="email" required disabled={methods.formState.isSubmitting} />
          <FormInput label="Entreprise" name="company" required disabled />
        </Box>
        <Grid container spacing={{ xs: 1, md: 2 }} width={1}>
          <Grid item xs={12} md={8}>
            <FormInput label="Adresse" name="address" required disabled={methods.formState.isSubmitting} />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormInput label={"Code postal"} name="zipCode" required disabled={methods.formState.isSubmitting} />
          </Grid>
        </Grid>
        <Box display="flex" sx={{ flexDirection: ["column", "row"], gap: [1, 5] }} width={1}>
          <FormInput label="Ville" name="city" required disabled={methods.formState.isSubmitting} />
          <FormInput label={"Pays"} name="country" required disabled />
        </Box>
        <Box display="flex" width={1} sx={{ flexDirection: ["column", "row"], gap: [1, 5] }}>
          <FormInput
            label="TVA"
            checkboxLabel="J'ai un numéro de tva"
            name="withVat"
            type="checkbox"
            disabled={methods.formState.isSubmitting}
          />
          {withVat && (
            <FormInput label={"Numéro de TVA"} name="vat" required disabled={methods.formState.isSubmitting} />
          )}
        </Box>
        <LoadingButton
          loading={methods.formState.isSubmitting}
          type="submit"
          variant="contained"
          size="large"
          sx={{
            marginInline: "auto",
            width: 1,
            mt: 2,
          }}
        >
          <Typography variant="medium_lg">Sauvegarder</Typography>
        </LoadingButton>
      </Box>
    </FormProvider>
  );
};

const BillingDetails = ({ identity }) => {
  const { data: company } = useGetOne("companies", { id: identity?.company });
  return !company ? null : (
    <Box sx={{ width: 1, display: "flex", maxWidth: 1200, gap: 2, flexDirection: ["column", "row"] }}>
      <Box sx={{ flex: 1, maxWidth: 1000, display: "flex", flexDirection: "column", gap: 2 }}>
        <Typography variant="bold_lg" color="common.title" mb={2} sx={{ display: ["none", "inline"] }}>
          Informations de facturation
        </Typography>
        <Typography variant="regular_md" color="common.title">
          Vos informations de facturation peuvent être différentes des informations indiquées sur votre profil. Elles
          apparaîtront sur vos factures.
        </Typography>
      </Box>
      <Box sx={{ flex: 1, maxWidth: 1000, display: "flex", flexDirection: "column", gap: 2 }}>
        {identity && <BillingDetailsForm identity={identity} company={company} />}
      </Box>
    </Box>
  );
};

export default BillingDetails;
