import { collection, getDocs, query } from "firebase/firestore";
import React, { useState, useEffect } from "react";
import { fbFirestore } from "../../services/firebase/firebase.services";
import { alpha, IconButton, useTheme } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ChatIcon from "@mui/icons-material/Chat";
import EventIcon from "@mui/icons-material/Event";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import loadChat from "../../services/loadChat";
import { HelpCircle } from "lucide-react";
import zIndex from "@mui/material/styles/zIndex";
declare const window: any;

const HelpPanel = ({ identity }: any) => {
  const franceFlag = require(`../../assets/france.svg`).default as string;
  const [helpMenu, setHelpMenu] = useState({} as any);
  const [menuItems, setMenuItems] = useState([] as any);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const theme = useTheme();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const asyncCall = async () => {
      const q = query(collection(fbFirestore, "admin"));
      let adminSnap = await getDocs(q);
      let helpMenuData = adminSnap.docs[0].data().helpMenu;
      if (helpMenuData?.chatActivated) {
        window.BrevoConversationsSetup = {
          zIndex: 0,
          colors: {
            buttonText: theme.palette.primary /* chat button text/icon color */,
            buttonBg: theme.palette.primary /* chat button background color */,
            visitorBubbleBg: alpha(theme.palette.primary.main, 0.1) /* visitor’s message bubble color */,
            agentBubbleBg: alpha(theme.palette.primary.main, 0.1) /* agent’s message bubble color */,
          },
        };
        loadChat();
      }
      setHelpMenu(helpMenuData);
      const items = [
        {
          text: "Consulter le centre d’assistance",
          onClick: () => {
            if (helpMenuData.helpCenterUrl) {
              window.open(helpMenuData.helpCenterUrl, "_blank");
            }
          },
          startIcon: <HelpOutlineIcon sx={{ color: "common.text" }} />,
        },
      ];
      if (helpMenuData.chatActivated) {
        items.push({
          text: "Discuter avec un conseiller",
          onClick: () => {
            if (helpMenuData?.chatActivated) {
              window.BrevoConversations.setZIndex(9999);
              window.BrevoConversations.openChat(true);
              window.BrevoConversations.updateIntegrationData({
                email: identity?.email,
                firstName: identity?.firstName,
                lastName: identity?.lastName,
                ORGANISATION: identity?.companyName,
                status: identity?.status,
              });
              if (helpMenuData?.scenarioId) {
                window.BrevoConversations.startBotScenario(helpMenuData?.scenarioId);
              }
            }
          },
          startIcon: <ChatIcon sx={{ color: "common.text" }} />,
        });
      }
      items.push({
        text: "Programmer une démonstration",
        onClick: () => {
          if (helpMenuData.meetingUrl) window.open(helpMenuData.meetingUrl, "_blank");
        },
        startIcon: <EventIcon sx={{ color: "common.text" }} />,
      });
      setMenuItems(items);
    };

    if (identity) asyncCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identity]);

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <IconButton
          onClick={handleClick}
          sx={{ mx: 0.5, width: 35, height: 35, p: 0 }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <HelpCircle size={24} />
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="help-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        disableScrollLock={true}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 20,
              width: 10,
              height: 10,
              bgcolor: "secondary.main",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        MenuListProps={{ sx: { pt: 0, pb: 1 } }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            p: 2,
            backgroundColor: "button.secondary.contained.background",
            color: "button.secondary.contained.content",
            mb: 2,
          }}
        >
          <Typography variant="h5" fontWeight="bold">
            {helpMenu.title}
          </Typography>
          <Typography>{helpMenu.subtitle}</Typography>
        </Box>

        {menuItems.map((menuItem: any) => (
          <MenuItem key={menuItem.text} onClick={menuItem.onClick} sx={{ px: 1, py: 0.5, color: "common.text" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                bgcolor: "neutral.light",
                width: 1,
                px: 1,
                py: 0.5,
                borderRadius: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {menuItem.startIcon}
                <Typography sx={{ ml: 1 }}>{menuItem.text}</Typography>
              </Box>
              <ChevronRightIcon sx={{ color: "common.text", ml: 2 }} />
            </Box>
          </MenuItem>
        ))}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: 1,
            mt: 2,
            p: 1,
            mx: "auto",
            bgcolor: "secondary.lighter",
            color: "secondary.darker",
            borderRadius: 2,
            width: "fit-content",
          }}
        >
          <LocalPhoneIcon sx={{ color: "secondary.dark" }} />
          <Typography color="secondary.dark" fontWeight="bold">
            {helpMenu.phone}
          </Typography>
          <img src={franceFlag} alt={"drapeau france"} width="24" height="24" />
          <Typography color="secondary.dark" fontWeight="bold">
            Gratuit
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 2,
            mt: 1,
            mb: 3,
            width: 0.8,
            mx: "auto",
            textAlign: "center",
          }}
        >
          <Typography color="common.text" fontWeight="bold" fontSize={12}>
            {helpMenu.disponibility}
          </Typography>
        </Box>
      </Menu>
    </React.Fragment>
  );
};

export default HelpPanel;
