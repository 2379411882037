export const isEmailValid = (email: string) => /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);

export const sanitizePhoneNumber = (phoneNumber: string) => {
  let updatedPhoneNumber = phoneNumber;
  if (phoneNumber.length === 10 && phoneNumber.charAt(0) === "0") {
    updatedPhoneNumber = "+33" + phoneNumber.substring(1);
  }
  if (phoneNumber.length === 11 && phoneNumber.startsWith("33")) {
    updatedPhoneNumber = "+33" + phoneNumber.substring(2);
  }
  return updatedPhoneNumber;
};
