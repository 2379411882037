import { Grid, Typography, InputAdornment } from "@mui/material";
import React, { useState, useEffect } from "react";
import {
  Create,
  NumberInput,
  SaveButton,
  Toolbar,
  DeleteButton,
  FormDataConsumer,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  Labeled,
  TextField,
  ArrayInput,
  SimpleFormIterator,
} from "react-admin";
import { SimpleForm } from "@react-admin/ra-rbac";
import {
  DISCOUNT_APPLICATION_TYPES,
  DISCOUNT_TYPES,
  DISCOUNT_SCOPES,
  TYPES,
  COMPANIES_STATUSES,
} from "../../services/common-utils/constants";
import { useFormContext } from "react-hook-form";
import PageTitle from "../../components/navigation/PageTitle";
import FormInput from "../../components/form/FormInput";
import { zodResolver } from "@hookform/resolvers/zod";
import { AutocompleteInput } from "../../components/form/AutocompleteInput";
import { schema, Discount } from "./IDiscount";

const ScopeInput = ({ type, ...props }: any) => {
  const { setValue } = useFormContext();

  useEffect(() => {
    if (type === "Produit offert") {
      setValue("scope", "Produits spécifiques");
    }
  }, [type, setValue]);

  return type === "Produit offert" ? (
    <Labeled label="Champ d'application">
      <TextField source="scope" mb={"20px"} />
    </Labeled>
  ) : (
    <FormInput
      type="select"
      required
      defaultValue="Panier"
      source="scope"
      label="Champ d'application"
      values={DISCOUNT_SCOPES.map((s) => ({ id: s, label: s }))}
      {...props}
    />
  );
};

const DiscountsToolBar = ({ ...props }) => (
  <Toolbar>
    <SaveButton label="Sauvegarder" />
  </Toolbar>
);

const DiscountCreate = ({ ...props }) => {
  const [type, setType] = useState({} as any);

  const onTransform = (data: any) => {
    return data;
  };

  const onTypeChange = (e: any) => {
    setType(e.target.value);
  };

  return (
    <Create {...props} transform={onTransform} hasShow={false}>
      <PageTitle
        text={"Création d'une remise"}
        type="create"
        resource="discounts"
        breadcrumbListLabel={"Liste des remises"}
        breadcrumbCreateLabel={"Nouvelle remise"}
      />
      <SimpleForm toolbar={<DiscountsToolBar />} resolver={zodResolver(schema)}>
        <Grid container direction={"column"}>
          <FormInput required defaultValue={null} source="name" label="Nom de la remise" />
          <FormInput type="date" required defaultValue={null} source="startDate" label="Date de début" />
          <FormInput type="checkbox" defaultValue={false} source="expire" checkboxLabel="Expiration" />
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.expire && (
                <FormInput type="date" required defaultValue={null} source="endDate" label="Date de fin" />
              )
            }
          </FormDataConsumer>

          <FormInput
            type="select"
            required
            defaultValue="Automatique"
            source="applicationType"
            label="Type de remise"
            values={DISCOUNT_APPLICATION_TYPES.map((s) => ({ id: s, label: s }))}
          />
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.applicationType === "Code promo" && (
                <FormInput required defaultValue={null} source="promoCode" label="Code promo" />
              )
            }
          </FormDataConsumer>
          <FormInput type="checkbox" source="onlyOnceByCompany" checkboxLabel="Utilisable une seule fois par client" />
          <FormInput
            type="checkbox"
            source="onlyDuringFreetry"
            checkboxLabel="Utilisable uniquement pendant la période d'éssai"
          />
          <FormInput
            type="select"
            required
            defaultValue="Pourcentage"
            source="type"
            label="Type de réduction"
            values={DISCOUNT_TYPES.map((s) => ({ id: s, label: s }))}
            onChange={onTypeChange}
          />
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.type === "Pourcentage" && (
                <FormInput
                  type="number"
                  defaultValue={0}
                  min={0}
                  max={100}
                  required
                  source="rate"
                  label="Pourcentage"
                  {...rest}
                  InputProps={{ endAdornment: <InputAdornment position="start">%</InputAdornment> }}
                />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.type === "Fidélité" && (
                <ArrayInput
                  required
                  source="rates"
                  sx={{ "& .MuiFormLabel-asterisk": { color: "error.main" } }}
                  label={
                    <Typography
                      variant="bold_lg"
                      sx={{
                        height: 24,
                        position: "relative",
                        overflow: "visible",
                        transform: "none",
                      }}
                      color="common.title"
                    >
                      Remises fidélité
                    </Typography>
                  }
                >
                  <SimpleFormIterator
                    sx={{
                      width: "100%",
                      "& .RaSimpleFormIterator-form": {
                        backgroundColor: "rgba(0,0,0,0.04)",
                        padding: "10px",
                        marginTop: "10px",
                        borderRadius: "2px",
                        border: "solid 1px",
                        mb: 2,
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        gap: 2,
                        width: "100%",
                      },
                    }}
                    disableClear
                  >
                    <FormDataConsumer>
                      {({ formData, getSource, ...rest }) => (
                        <>
                          <FormInput
                            label="Ancienneté"
                            name={getSource?.("years") || ""}
                            required
                            type="number"
                            InputProps={{
                              endAdornment: <InputAdornment position="start">années</InputAdornment>,
                            }}
                          />
                          <FormInput
                            label="Pourcentage remise"
                            name={getSource?.("rate") || ""}
                            required
                            type="number"
                            InputProps={{
                              endAdornment: <InputAdornment position="start">%</InputAdornment>,
                            }}
                          />
                        </>
                      )}
                    </FormDataConsumer>
                  </SimpleFormIterator>
                </ArrayInput>
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.type === "Montant" && (
                <FormInput
                  type="number"
                  defaultValue={0}
                  required
                  source="amount"
                  label="Montant"
                  {...rest}
                  InputProps={{ endAdornment: <InputAdornment position="start">€ HT</InputAdornment> }}
                />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.type === "Produit offert" && (
                <FormInput
                  type="number"
                  defaultValue={1}
                  required
                  source="freeProductQuantity"
                  label="Quantité offerte"
                  {...rest}
                />
              )
            }
          </FormDataConsumer>
          <ScopeInput type={type} />
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.scope === "Produits spécifiques" && (
                <ReferenceArrayInput source="products" reference="products">
                  <AutocompleteInput multiple label="Produits concernées" optionText="name" />
                </ReferenceArrayInput>
              )
            }
          </FormDataConsumer>
          <FormInput
            type="checkbox"
            defaultValue={false}
            source="scopedByCompanyType"
            checkboxLabel="Restreindre selon nature du client"
          />
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.scopedByCompanyType && (
                <FormInput
                  type="multiselect"
                  required
                  defaultValue={[]}
                  source="companyTypes"
                  label="Nature du client"
                  values={TYPES.map((s) => ({ id: s, label: s }))}
                />
              )
            }
          </FormDataConsumer>
          <FormInput
            type="checkbox"
            defaultValue={false}
            source="scopedByCompanyStatus"
            checkboxLabel="Restreindre selon statut du client"
          />
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.scopedByCompanyStatus && (
                <FormInput
                  type="multiselect"
                  required
                  defaultValue={[]}
                  source="companyStatus"
                  label="Type"
                  values={COMPANIES_STATUSES.map((s) => ({ id: s, label: s }))}
                />
              )
            }
          </FormDataConsumer>
          <FormInput
            type="checkbox"
            defaultValue={false}
            source="scopedByCompanyIds"
            checkboxLabel="Restreindre à un ou des clients spécifiques"
          />
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.scopedByCompanyIds && (
                <ReferenceArrayInput source="companyIds" reference="companies">
                  <AutocompleteInput
                    multiple
                    label="Clients éligibles"
                    optionText="company"
                    filterToQuery={(search: any) => ({ company: search })}
                    defaultValue={[]}
                  />
                </ReferenceArrayInput>
              )
            }
          </FormDataConsumer>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export default DiscountCreate;
