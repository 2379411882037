import React, { useContext, useState, useEffect, useCallback } from "react";
import { useNotify, useGetIdentity, FormDataConsumer, useCreate, useRefresh } from "react-admin";
import useCustomListRedirect from "../../services/hooks/useCustomRedirect";
import { Box } from "@mui/material";
import { object, string } from "zod";
import FormInput from "../../components/form/FormInput";
import CreateInDialog from "../../components/react-admin/CreateInDialog";
import { USERS_ROLES } from "../../services/common-utils/constants";

const formSchema = object({
  firstName: string({
    invalid_type_error: "Prénom requis",
  }).min(1, "Prénom requis"),
  lastName: string({
    invalid_type_error: "Nom requis",
  }).min(1, "Nom requis"),
  role: string().min(1, "Rôle requis"),
  password: string().nullish(),
  email: string({
    invalid_type_error: "Adresse mail requise",
  })
    .min(1, "Adresse mail requise")
    .email("Adresse mail invalide"),
  company: string().nullish(),
});

const UsersCreate = ({ ...props }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const { isLoading, data: identity } = useGetIdentity();
  const [key, setKey] = useState(0);

  const [create] = useCreate();
  const save = useCallback(
    async (values: any) => {
      const errors = {} as any;
      values.email = values.email.trim().toLowerCase();
      values.licenseId = null;
      values.authorized = values?.role === "Administrateur";
      if (["Client", "Indirect"].includes(identity?.status)) {
        values.company = identity?.company;
      }
      if (["Revendeur"].includes(identity?.status)) {
        values.company = values?.company || identity?.company;
      }
      try {
        await create("users", { data: values }, { returnPromise: true });
        notify("Utilisateur créé avec succès", {
          type: "success",
          messageArgs: { smart_count: 1 },
        });
        setKey((key) => key + 1);
        refresh();
      } catch (error) {
        notify("Erreur lors de la création de l'utilisateur", {
          type: "error",
          messageArgs: { smart_count: 1 },
        });
        setKey((key) => key + 1);
        refresh();
      }
    },
    [create, identity?.company, identity?.status, notify]
  );

  // Success override
  const onSuccess = async (data: any) => {
    notify(`Utilisateur créé avec succès`, { type: "success" });
  };

  return isLoading ? (
    <></>
  ) : (
    <CreateInDialog
      key={key}
      {...props}
      label="Nouvel utilisateur"
      title="Ajouter un utilisateur"
      formSchema={formSchema}
      // transform={onTransform}
      mutationOptions={{ onSuccess }}
      fullWidthFields
      formProps={{ onSubmit: save }}
    >
      <Box sx={{ display: "grid", gridTemplateColumns: ["1fr", "1fr", "1fr 1fr"], gap: 2 }}>
        <FormInput label="Prénom" name="firstName" required />
        <FormInput label="Nom" name="lastName" required />
        <FormInput label="Adresse email" name="email" type="email" />
        <FormInput
          label="Rôle"
          source="role"
          type="select"
          values={USERS_ROLES.map((s) => ({ id: s, label: s }))}
          placeholder="choisissez..."
          required
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return formData.role === "Administrateur" ? (
              <FormInput checkboxLabel="Spécifier mot de passe" source="specifyPassword" type="checkbox" />
            ) : null;
          }}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.role === "Administrateur" && formData.specifyPassword ? (
              <FormInput
                label="Mot de passe"
                name="password"
                defaultValue=""
                autoComplete="new-password"
                type="password"
                required
                helperText="Doit contenir au minimum 1 minuscule, 1 chiffre et un caractère spécial"
                placeholder="*************"
              />
            ) : null
          }
        </FormDataConsumer>
      </Box>
    </CreateInDialog>
  );
};

export default UsersCreate;
