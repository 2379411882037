import React, { useCallback, useEffect, useState } from "react";
import { useListContext, useUnselectAll, SimpleForm, useRefresh, useNotify, useCreate } from "react-admin";
import { CreateInDialogButton } from "@react-admin/ra-form-layout";
import FormInput from "../../components/form/FormInput";
import { Typography } from "@mui/material";
import { PlusIcon } from "lucide-react";
import { DialogIcon } from "../../components/react-admin/DialogIcon";

const CreateContactList = () => {
  const notify = useNotify();
  const { selectedIds, data } = useListContext();
  const refresh = useRefresh();
  const unselectAll = useUnselectAll("contacts");
  //using key for the react-admin dialog component is a hack to close the dialog after submitting
  //without interfering with the isOpen property.
  const [key, setKey] = useState(0);
  const [company, setCompany] = useState(null);

  useEffect(() => {
    if (selectedIds.length) {
      const selectedContactsCompany = selectedIds
        .map((id: any) => {
          const contact = data.find((contact) => id === contact.id);
          return contact?.group_id || null;
        })
        .filter((contact) => contact !== null);
      const company = selectedContactsCompany[0];
      setCompany(selectedContactsCompany.every((companyId: string) => companyId === company) ? company : null);
    }
  }, [data, selectedIds]);

  const [create] = useCreate();
  const save = useCallback(
    async (values: any) => {
      try {
        await create("contactLists", { data: values }, { returnPromise: true });
        notify("Liste de contacts créé avec succès", {
          type: "success",
          messageArgs: { smart_count: 1 },
        });
        unselectAll();
      } catch (error) {
        notify("Erreur lors de la création de la liste de contact", {
          type: "error",
          messageArgs: { smart_count: 1 },
        });
      }
      setKey((key) => key + 1);
      refresh();
    },
    [create, notify, refresh, unselectAll]
  );

  return !company ? null : (
    <CreateInDialogButton
      title={"Nouvelle liste de contacts"}
      resource="contactLists"
      record={{ group_id: company, contacts: selectedIds }}
      key={key}
      label={<Typography variant="medium_sm" ml={0.5}>Former une liste de contacts</Typography>}
      buttonType="bulkAction"
      icon={<DialogIcon icon={<PlusIcon size={18}/>} text="Former une liste de contacts"/>}
    >
      <SimpleForm onSubmit={save}>
        <FormInput label="Nom de la liste de contact" source="name" />
      </SimpleForm>
    </CreateInDialogButton>
  );
};

export default CreateContactList;
