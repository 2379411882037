import {
  arrayUnion,
  collection,
  doc,
  getCountFromServer,
  limit,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import React, { useState, useEffect } from "react";
import { fbFirestore } from "../../services/firebase/firebase.services";
import { Badge, Collapse, Divider, IconButton, Tooltip, useMediaQuery, Button } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { useRefresh } from "react-admin";
import { Bell, CircleCheck } from "lucide-react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

const NotificationItem = ({ notification, setAnchorEl, identity }) => {
  const { displayTime, text, read, link, id, description } = notification;
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const isMobile = !!useMediaQuery((theme: any) => theme.breakpoints.only("xs"));

  const redirect = async (id, read, link: string) => {
    setAnchorEl(null);
    await markAsView(id);
    navigate(link);
  };

  const markAsView = async (id) => {
    if (!read) {
      await updateDoc(doc(fbFirestore, "notifications", id), {
        seenBy: arrayUnion(identity.id),
      });
    }
  };

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <MenuItem
      onClick={() => {
        if (link) {
          redirect(id, read, link);
        }
      }}
      sx={{
        whiteSpace: "unset",
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        width: [1, 600],
        mt: 0.5,
        px: [1, 2],
        cursor: link ? "pointer" : "default",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: ["column", "row"],
          alignItems: ["start", "center"],
          color: read ? "grey" : "red",
          position: "relative",
          width: 1,
        }}
      >
        <Typography variant="bold_sm" color={read ? "common.title" : "error.main"}>
          {displayTime}
        </Typography>
        <Typography
          variant={isMobile ? "regular_xs" : "regular_sm"}
          color={read ? "common.text" : "error.main"}
          ml={isMobile ? 0 : 2}
        >
          {text}
        </Typography>
        <Box sx={{ position: "absolute", right: 0, display: "flex", alignItems: "center", gap: 0.5 }}>
          {!read && (
            <Tooltip title="Marquer comme vue">
              <IconButton
                onClick={(event) => {
                  markAsView(id);
                  event?.stopPropagation();
                }}
                sx={{ width: 24, height: 24, p: 0 }}
              >
                <CircleCheck size={20} />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Voir plus">
            <IconButton
              onClick={(event) => {
                handleClick();
                event?.stopPropagation();
              }}
              sx={{ width: 24, height: 24, p: 0 }}
            >
              {open ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      {description && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Typography variant="regular_xs">{description}</Typography>
        </Collapse>
      )}
    </MenuItem>
  );
};

const Notifications = ({ identity }: any) => {
  const [notifications, setNotifications] = useState([] as any);
  const [unreadNotificationsNumber, setUnreadNotificationsNumber] = useState(0);
  const refresh = useRefresh();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    // if (identity?.showOrderNotifications) {
    const docRef = collection(fbFirestore, "notifications");
    let q: any;
    if (identity?.status !== "Collaborateur") {
      q = query(
        docRef,
        where("companyId", "==", identity?.company),
        where("recipient", "==", "customer"),
        where("methods", "array-contains", "dashboard"),
        orderBy("createdate", "desc"),
        limit(100)
      );
    } else {
      q = query(
        docRef,
        where("recipient", "==", "admin"),
        where("methods", "array-contains", "dashboard"),
        orderBy("createdate", "desc"),
        limit(100)
      );
    }
    const unsubscribeNotifications = onSnapshot(q, (snap: any) => {
      let newNotifications = notifications;
      snap.docs.forEach((doc: any) => {
        const notification = doc.data();
        newNotifications = newNotifications.filter((notif: any) => {
          return notif.id !== doc.id;
        });
        newNotifications.push({
          id: doc.id,
          text: notification.text,
          description: notification.description,
          link: notification.link,
          read: (notification?.seenBy || []).includes(identity?.id),
          time: notification.createdate.seconds,
          displayTime:
            new Date(notification.createdate.seconds * 1000)
              .toISOString()
              .split("T")[0]
              .split("-")
              .reverse()
              .join("/")
              .slice(0, 5) +
            " à " +
            new Date(notification.createdate.seconds * 1000).toTimeString().slice(0, 5).replace(":", "h"),
        });
      });
      setNotifications(newNotifications);
    });

    return () => unsubscribeNotifications();
    // } else {
    //   return;
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center", mr: 2 }}>
        {/* <Typography sx={{ minWidth: 100 }}>Contact</Typography>
        <Typography sx={{ minWidth: 100 }}>Profile</Typography> */}
        <IconButton
          onClick={handleClick}
          sx={{ mx: 0.5 }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <Badge
            badgeContent={notifications.filter((notif: any) => !notif.read).length}
            color="error"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <Bell size={24} />
          </Badge>
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            p: [0, 1],
            mt: 1.5,
            width: [1, "auto"],
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: ["6rem", 14],
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Typography variant="bold_lg" sx={{ ml: 2 }}>
          Notifications
        </Typography>
        <Divider sx={{ width: 1, my: 1 }} />
        {notifications.length ? (
          notifications
            .sort((a: any, b: any) => b.time - a.time)
            .filter((notif: any, index: number) => index < 10)
            .map((notification: any, index: number) => {
              return (
                <NotificationItem
                  key={index}
                  notification={notification}
                  setAnchorEl={setAnchorEl}
                  identity={identity}
                />
              );
            })
        ) : (
          <MenuItem>Aucune notification</MenuItem>
        )}
        {notifications.length >= 10 && (
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: 1 }}>
            <Button
              variant="outlined"
              onClick={() => {
                navigate("/notifications");
              }}
              sx={{ mt: 2, width: 1, mx: 2 }}
            >
              Voir toutes les notifications
            </Button>
          </Box>
        )}
      </Menu>
    </React.Fragment>
  );
};

export default Notifications;
