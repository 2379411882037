import React, { useCallback } from "react";
import {
  DateField,
  FileField,
  FileInput,
  FunctionField,
  ReferenceManyField,
  useGetIdentity,
  Datagrid as RaDatagrid,
  useNotify,
  useRecordContext,
  useUpdate,
  useRefresh,
  useRedirect,
  TextField,
} from "react-admin";
import { Typography } from "@mui/material";
import { EditableDatagrid, RowForm, useRowContext } from "@react-admin/ra-editable-datagrid";
import { useFormContext } from "react-hook-form";
import api from "../../services/apiWithAuth";
import ListChip from "./ListChip";

const Datagrid = ({ children }: any) => {
  return <RaDatagrid bulkActionButtons={false}>{children}</RaDatagrid>;
};

export const PaymentDatagrid = () => {
  return (
    <Datagrid>
      <TextField source="invoiceNumber" label="N°Facture" />
      <DateField source="paymentDate" label="Date de paiement" showTime />
      {/* <FunctionField
        label="Début periode facturée"
        render={(record: any) =>
          !record.periodStart ? "" : new Date(record.periodStart * 1000).toLocaleDateString("fr")
        }
      />
      <FunctionField
        label="Fin période facturée"
        render={(record: any) => (!record.periodEnd ? "" : new Date(record.periodEnd * 1000).toLocaleDateString("fr"))}
      /> */}
      <FunctionField label="Montant TTC" render={(record: any) => Number(record.amount).toFixed(2) + " €"} />
      <FunctionField
        label="Type"
        render={({ type }: any) => {
          return <ListChip label={"Payé"} color={"secondary"} />;
        }}
      />
      <FileField label="Téléchargement" source="pdfInvoice.src" title="pdfInvoice.title" target="_blank" />
    </Datagrid>
  );
};

const PaymentsField = ({ target }: any) => {
  return (
    <ReferenceManyField
      fullWidth
      label="Paiements"
      reference="payments"
      target={target}
      sort={{ field: "paymentDate", order: "DESC" }}
    >
      <PaymentDatagrid />
    </ReferenceManyField>
  );
};

export default PaymentsField;
