import React, { Children, Fragment, useCallback } from "react";
import { CreateButton, TextField, TopToolbar, ReferenceField, FunctionField, DateField, Filter } from "react-admin";
import { List } from "@react-admin/ra-rbac";
import { CustomDatagrid } from "../../components/react-admin/CustomDatagrid";
import CountryField from "../../components/fields/CountryField";
import { OPPORTUNITIES_STATUSES } from "../../services/common-utils/constants";
import PageTitle from "../../components/navigation/PageTitle";
import FormInput from "../../components/form/FormInput";
import { ListActions } from "../../components/react-admin/ListActions";
import { useTheme } from "@mui/material";
import ListChip from "../../components/fields/ListChip";
import { ThumbsDown, ThumbsUp } from "lucide-react";

const OpportunitiesList = ({ ...props }) => {
  const theme = useTheme();
  return (
    <>
      <PageTitle
        text="Opportunités"
        createButton={<CreateButton label="Nouvelle opportunité" resource="opportunities" />}
        type="list"
        breadcrumbListLabel="Liste des opportunités"
      />
      <List
        perPage={5}
        {...props}
        actions={<ListActions />}
        filters={
          <Filter {...props}>
            <FormInput placeholder="Rechercher par nom" name="company" type="search" alwaysOn />
            <FormInput
              placeholder="Source"
              type="select"
              source="origin"
              values={["Parrainage", "Inscription via formulaire", "Admin"].map((s) => ({
                id: s === "Admin" ? "admin" : s,
                label: s,
              }))}
              label="Source"
              hideLabel
              filledSecondary
            />
            <FormInput
              placeholder="État"
              type="select"
              source="status"
              values={OPPORTUNITIES_STATUSES.map((s) => ({ id: s, label: s }))}
              label="État"
              hideLabel
              filledSecondary
            />
          </Filter>
        }
        exporter={false}
        empty={false}
        sort={{ field: "createdate", order: "DESC" }}
      >
        <CustomDatagrid>
          <DateField label="Date création" source="createdate" showTime/>
          <TextField source="company" label="Nom" />
          <CountryField source="country" label="Pays" />
          <TextField label="Contact principal" source="mainContact" />
          <TextField label="Téléphone" source="phone" />
          <TextField label="Source" source="origin" />
          <FunctionField
            label="État"
            render={(record: any) => {
              const statusColor =
                record.status === "Lien parrainage envoyé"
                  ? "info"
                  : record.status === "Essai gratuit"
                  ? "info"
                  : record.status === "Attente engagement"
                  ? "warning"
                  : record.status === "Gagné"
                  ? "secondary"
                  : record.status === "Perdu"
                  ? "error"
                  : "default";
              return (
                <ListChip
                  label={
                    record.status === "Gagné" ? (
                      <>
                        <ThumbsUp size={18} />
                        {record.status}
                      </>
                    ) : record.status === "Perdu" ? (
                      <>
                        <ThumbsDown size={18} />
                        {record.status}
                      </>
                    ) : (
                      record.status
                    )
                  }
                  color={statusColor}
                />
              );
            }}
          />
        </CustomDatagrid>
      </List>
    </>
  );
};

export default OpportunitiesList;
