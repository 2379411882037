import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ImportButton } from "react-admin-import-csv";
import { Box, IconButton, useMediaQuery, useTheme } from "@mui/material";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import PublishIcon from "@mui/icons-material/Publish";
import { ReactSpreadsheetImport } from "react-spreadsheet-import";
import { useState } from "react";
import { FileDownIcon, ImportIcon, TablePropertiesIcon, Upload } from "lucide-react";
import { translations } from "./translations";

export default function ImportButtons({ fields, xlsxTemplate, csvTemplate, rowHook, onSubmit }: any) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const theme = useTheme();
  const open = Boolean(anchorEl);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isMobile = !!useMediaQuery((theme: any) => theme.breakpoints.only("xs"));
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
      <Button
        id="positioned-button"
        aria-controls={open ? "positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        startIcon={<TablePropertiesIcon size={18} />}
        onClick={handleClick}
        sx={{ ml: [0, 2], minWidth: "unset" }}
      >
        {isMobile ? "" : "Modèle"}
      </Button>
      <Box sx={{ ml: [0, 2], color: "primary.main" }}>
        <Button startIcon={<FileDownIcon size={18} />} onClick={() => setIsModalOpen(true)} sx={{ minWidth: "unset" }}>
          {isMobile ? "" : "Importer"}
        </Button>
      </Box>
      <Menu
        id="positioned-menu"
        aria-labelledby="positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={handleClose}>
          <Button
            onClick={() => {
              window.open(xlsxTemplate);
            }}
          >
            Modèle Excel
          </Button>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Button
            onClick={() => {
              window.open(csvTemplate);
            }}
          >
            Modèle csv
          </Button>
        </MenuItem>
      </Menu>
      <ReactSpreadsheetImport
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={onSubmit}
        fields={fields}
        translations={translations}
        rowHook={rowHook}
        isNavigationEnabled
        autoMapSelectValues
        customTheme={{
          colors: {
            background: "white",
            rsi: { ...theme.palette.brand },
          },
          components: {
            Modal: {
              variants: {
                rsi: {
                  body: {
                    paddingX: "1rem",
                  },
                  dialog: {
                    minH: "calc(var(--chakra-vh) - 3rem)",
                    maxW: "calc(var(--chakra-vw) - 2rem)",
                  },
                },
              },
            },
          },
        }}
      />
    </Box>
  );
}
