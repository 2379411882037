import React, { useState, useEffect } from "react";
import { SidebarToggleButton, useGetIdentity, HideOnScroll, useRedirect } from "react-admin";
import {
  Box,
  Typography,
  useMediaQuery,
  Theme,
  useTheme,
  Skeleton,
  AppBar,
  Toolbar,
  useScrollTrigger,
  Button,
} from "@mui/material";
import "./Topbar.css";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";
import Notifications from "./Notifications";
import HelpPanel from "./HelpPanel";
import LinearProgressBar from "../others/LinearProgressBar";
import FirstStepsDialog from "../../pages/dashboard/FirstStepsDialog";
import api from "../../services/apiWithAuth";
import MyAccount from "./MyAccount";

const CompletedStepsLabel = ({ isMobile = false }: any) => {
  return (
    <Typography variant="bold_sm" sx={{ color: "common.title" }}>
      5/6 {!isMobile ? "terminés" : ""}
    </Typography>
  );
};

const FirstStepsHeader = ({ identity }: any) => {
  const [completedSteps, setCompletedSteps] = useState([]);
  const theme = useTheme();
  const isMobile = !!useMediaQuery((theme: any) => theme.breakpoints.only("xs"));

  useEffect(() => {
    if (!identity?.firstStepsCompleted && completedSteps.length === 0) {
      const asyncCall = async () => {
        const getCompletedStepsRes = await api.get("/utils/getCompletedSteps/" + identity?.company);
        setCompletedSteps(getCompletedStepsRes.data);
      };
      asyncCall();
    }
  }, [completedSteps, identity?.company, identity?.firstStepsCompleted]);

  return (
    <Box
      display="flex"
      flexDirection="row"
      sx={{
        width: 1,
        padding: 0,
        alignItems: "center",
        justifyContent: ["space-between", "center"],
        my: 0,
        px: 2,
        height: 56,
        backgroundColor: theme.palette.layout.topBarBackground,
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        sx={{
          display: "flex",
          alignItems: ["start", "center"],
          justifyContent: "center",
          flexDirection: ["column", "row"],
          gap: [0.5, 2],
          px: [0, 2],
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: ["space-between", "flex-end"],
            flexDirection: "row",
            width: 1,
          }}
        >
          <Typography variant="bold_sm" sx={{ color: "common.title" }}>
            Premiers pas
          </Typography>
          {isMobile && <CompletedStepsLabel completedSteps={completedSteps} isMobile />}
        </Box>

        <Box display="flex" flexDirection="row" alignItems="center">
          <LinearProgressBar currentStep={5} stepsNumber={6} width={[isMobile ? "180px" : "216px"]} />
        </Box>
      </Box>
      {!isMobile && <CompletedStepsLabel completedSteps={completedSteps} />}
      <FirstStepsDialog />
    </Box>
  );
};

const TopBar = (props: any) => {
  const redirect = useRedirect();
  const theme = useTheme();
  const { identity } = useGetIdentity();
  const [cart, setCart] = useLocalStorage("cart", [] as any[]);
  const navigate = useNavigate();
  const logo = require("../../assets/logo.svg").default as string;
  const isMobile = !!useMediaQuery<Theme>((theme) => theme.breakpoints.only("xs"));
  const trigger = useScrollTrigger();
  return (
    <AppBar
      sx={{
        bgcolor: theme.palette.layout.topBarBackground,
        boxShadow:
          "0px 2px 4px -1px rgba(0,0,0,0.05),0px 4px 5px 0px rgba(0,0,0,0.04),0px 1px 10px 0px rgba(0,0,0,0.03)",
      }}
    >
      <Toolbar
        {...props}
        sx={{
          background: theme.palette.layout.topBarBackground,
          borderBottom: "1px solid",
          borderColor: "layout.topBarDivider",
          boxShadow: 0,
          height: 72,
          px: 2,
          "& .MuiSvgIcon-root": {
            color: theme.palette.button.neutral.contained.content,
            fontSize: [20, 35],
          },
          "& .sidebarButton": {
            backgroundColor: theme.palette.button.neutral.contained.background,
            borderRadius: 2,
            mr: 1,
          },
        }}
        elevation={1}
        title={null}
      >
        {isMobile && <SidebarToggleButton className="sidebarButton" />}
        <Box sx={{ height: 32, cursor: "pointer", ml: 1 }} onClick={() => navigate("/")}>
          <img
            src={logo}
            alt="digisoft"
            className="digisoft-logo"
            style={{ width: "auto", height: "100%", margin: 0 }}
          />
        </Box>
        <Box component="span" sx={{ flex: 1 }} />
        {identity?.status !== "Collaborateur" && (
          <Box sx={{ display: "flex", flexDirection: "row", gap: 2, height: 35, alignItems: "center" }}>
            {identity?.freeTry?.daysTillFreeTryEnd > 0 &&
              identity?.freeTry?.licenseConverted === false &&
              !isMobile && (
                <>
                  <Typography variant="medium_sm" color="common.title">
                    Essai gratuit -{" "}
                    {identity?.freeTry.daysTillFreeTryEnd === 1
                      ? "1 jour restant"
                      : `${identity?.freeTry.daysTillFreeTryEnd} jours restants`}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      navigate("/myOffer");
                    }}
                  >
                    <Typography variant="medium_sm">Acheter maintenant</Typography>
                  </Button>
                </>
              )}

            <HelpPanel identity={identity} />
          </Box>
        )}
        <Notifications identity={identity} />
        <MyAccount />
      </Toolbar>
      {identity?.status !== "Collaborateur" && !identity?.firstStepsCompleted && !trigger && (
        <FirstStepsHeader identity={identity} />
      )}
    </AppBar>
  );
};

export default TopBar;
