import { Grid, Typography, InputAdornment } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import {
  Create,
  required,
  AutocompleteInput,
  NumberInput,
  DateInput,
  ReferenceInput,
  SimpleForm,
  useGetList,
  useNotify,
  useRedirect,
  BooleanInput,
  SelectInput,
  TextInput,
  useRefresh,
  FormDataConsumer,
  useDataProvider,
} from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";
import api from "../../services/apiWithAuth";
import { MANUAL_PAYMENT_MODES } from "../../services/common-utils/constants";
import priceCalculator from "../../services/common-utils/priceCalculator";
import { HistoryContext } from "../../services/historyProvider";
import useCustomListRedirect from "../../services/hooks/useCustomRedirect";
import PaymentMethodInput from "../../components/fields/PaymentMethodInput";
import PageTitle from "../../components/navigation/PageTitle";

const TarifInput = ({ product, ...props }: any) => {
  const [tarifs, setTarifs] = useState([]);

  useEffect(() => {
    if (Object.keys(product).length) {
      const parsedTarifs = product.tarifs.map((tarif: any) => {
        return {
          id: tarif.id,
          name:
            tarif.paymentModel === "Forfait"
              ? `${tarif.packageSize} ${product?.type}`
              : "" + (tarif.billingPeriod || "") + ` (${Number(tarif.price).toFixed(2)}€ HT)`,
        };
      });
      setTarifs(parsedTarifs);
    }
  }, [product]);

  return <SelectInput disabled={Object.keys(tarifs).length === 0} validate={required()} choices={tarifs} {...props} />;
};

const PriceInput = ({ totals, ...props }: any) => {
  const { setValue } = useFormContext();

  useEffect(() => {
    if (Object.keys(totals).length) {
      setValue("billingPrice", totals.totalHt);
    }
  }, [totals, setValue]);

  return <NumberInput {...props} format={(value) => (value ? value.toFixed(2) : "0.00")} />;
};

const PeriodInput = ({ product, tarif, ...props }: any) => {
  const { setValue } = useFormContext();

  useEffect(() => {
    if (Object.keys(product).length && Object.keys(tarif).length) {
      setValue("billingPeriod", tarif.billingPeriod);
    }
  }, [product, tarif, setValue]);

  return <TextInput {...props} />;
};

const SubscriptionsCreate = ({ ...props }) => {
  const notify = useNotify();
  const redirect = useCustomListRedirect();
  const [products, setProducts] = useState([] as any);
  const [product, setProduct] = useState({} as any);
  const [company, setCompany] = useState("");
  const [companyCountry, setCompanyCountry] = useState("France");
  const [productId, setProductId] = useState("");
  const [tarif, setTarif] = useState({} as any);
  const [quantity, setQuantity] = useState(1);
  const [discount, setDiscount] = useState({} as any);
  const [availableDiscounts, setAvailableDiscounts] = useState([] as any);
  const [cart, setCart] = useState([] as any);
  const [totals, setTotals] = useState({} as any);
  const { data: productsList } = useGetList("products");
  const history: string[] = useContext(HistoryContext);
  const [fromCompany, setFromCompany] = useState("");
  const refresh = useRefresh();
  const dataProvider = useDataProvider();

  const genRef = (prefix = "") => {
    return (
      prefix + new Date().toISOString().slice(0, 19).replaceAll("-", "").replace("T", "-").replaceAll(":", "") + "-1"
    );
  };

  useEffect(() => {
    if (productsList) {
      // check if product have some recurring pricing
      const recurringProducts = productsList.filter(
        (product: any) =>
          !!product.tarifs.find((tarif: any) => product.isRecurring) &&
          ["Licence", "Crédits SMS", "API"].includes(product.type)
      );
      setProducts(recurringProducts);
    }
  }, [productsList]);

  useEffect(() => {
    if (history.length && !fromCompany && history[0] === window.location.pathname) {
      const match = history[1].match(/(?:companies\/)(.*)/);
      const companyId = match ? match[1] : "none";
      setFromCompany(companyId);
      setCompany(companyId);
    }
  }, [history, fromCompany]);

  // Transform override
  const onTransform = async (data: any) => {
    const item = cart[0];
    const tarif = item.product.tarifs.find((tarif: any) => item.tarifId === tarif.id);
    const subscriptionRef = genRef("A");
    if (item.product.type === "Crédits SMS") {
      data.quantity *= tarif.packageSize;
    }
    data.cancelled = false;
    data.type = item.product.type;
    data.totals.totalHt = parseFloat(data.totals.totalHt.toFixed(2));
    data.totals.totalTtc = parseFloat(totals.totalTtc.toFixed(2));
    data.id = subscriptionRef;
    if (data.autoRenewal && data.paymentMethod.id) {
      const {
        data: { stripe_id, newPaymentMethod, resetPeriodStart, resetPeriodEnd },
      } = await api.post("/utils/createStripeSubscription", {
        subscription: data,
        paymentMethodId: data.paymentMethod.id,
        resetMode: true,
      });
      data.periodStart = resetPeriodStart;
      data.periodEnd = resetPeriodEnd;
      data.createdManuallyInAuto = true;
      data.paymentMethod = newPaymentMethod;
      data.stripe_id = stripe_id;
      data.paymentStatus = "Paiement en cours";
    }
    return data;
  };

  // Success override
  const onSuccess = (data: any) => {
    notify(`Abonnement créé avec succès`, { type: "success" });
    redirect("subscriptions");
    refresh();
  };

  const onProductChange = (id: string) => {
    setProductId(id);
  };

  const onTarifChange = (e: any) => {
    setTarif(product.tarifs.find(({ id }: any) => id === e.target.value));
  };

  const onQuantityChange = (e: any) => {
    setQuantity(e.target.value);
  };

  const onDiscountChange = (e: any) => {
    setDiscount(availableDiscounts.find(({ id }: any) => id === e.target.value));
  };

  useEffect(() => {
    if (productId) {
      const product: any = productsList?.find((product: any) => product.id === productId);
      setProduct(product);
    }
  }, [productId, productsList]);

  useEffect(() => {
    if (Object.keys(product).length && Object.keys(tarif).length && quantity && company) {
      const tarifId = product.tarifs.findIndex((productTarif: any) => productTarif.id === tarif.id);
      if (tarifId >= 0) {
        setCart([{ product, tarifId, quantity }]);
        const asyncCall = async () => {
          const { data: discounts } = await api.post("/utils/getAvailableDiscounts", {
            companyId: company,
          });
          setAvailableDiscounts(discounts);
        };
        asyncCall();
      }
    }
  }, [product, quantity, tarif, company]);

  useEffect(() => {
    if (company && company !== "none") {
      const asyncCall = async () => {
        const {
          data: { country },
        } = await dataProvider.getOne("companies", { id: company });
        setCompanyCountry(country);
      };
      asyncCall();
    }
  }, [company, dataProvider]);

  useEffect(() => {
    if (Object.keys(product).length && Object.keys(tarif).length && quantity) {
      const totals = priceCalculator(cart, discount, companyCountry);
      setTotals(totals);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantity, tarif, discount, setTotals, cart, companyCountry]);

  return !fromCompany ? (
    <></>
  ) : (
    <Create {...props} transform={onTransform} mutationOptions={{ onSuccess }}>
      <PageTitle
        text={"Création d'un abonnement"}
        type="create"
        resource="subscriptions"
        breadcrumbListLabel={"Liste des abonnements"}
        breadcrumbCreateLabel={"Nouvel abonnement"}
      />
      <Typography variant="bold_sm" color="warning.main">
        Désactivé momentanément le temps de trouver comment être raccord avec les nouveautés relatives au fonctionnement
        des abonnements
      </Typography>
      {/* <SimpleForm defaultValues={() => (fromCompany !== "none" ? { company_id: fromCompany } : {})}>
        <Typography variant="h6" mb={"20px"}>
          Informations générales
        </Typography>
        <ReferenceInput
          required
          source="company_id"
          reference="companies"
          sort={{ field: "company", order: "ASC" }}
          perPage={1000}
        >
          <AutocompleteInput
            label="Organisation"
            optionText="company"
            filterToQuery={(search: any) => ({ company: search })}
            onChange={(value: any) => setCompany(value)}
          />
        </ReferenceInput>
        <DateInput
          required
          source="subscriptionDate"
          label="Date de souscription"
          defaultValue={new Date().toISOString().split("T")[0]}
        />

        <div style={{ borderBottom: "1px solid lightgray", width: "100%", marginBottom: "28px", marginTop: "16px" }} />

        <Typography variant="h6" mb={"20px"}>
          Produit
        </Typography>
        <AutocompleteInput
          defaultValue={null}
          label="Produit"
          source="product_id"
          choices={products}
          filterToQuery={(search: any) => ({ name: search })}
          onChange={onProductChange}
        />
        <TarifInput
          defaultValue={null}
          label="Tarification produit"
          source="tarif"
          product={product}
          onChange={onTarifChange}
        />
        <NumberInput
          required
          defaultValue={1}
          source="quantity"
          label="Quantité"
          step={1}
          onChange={onQuantityChange}
        />
        <SelectInput
          defaultValue={null}
          label="Remise"
          source="discount"
          onChange={onDiscountChange}
          disabled={Object.keys(availableDiscounts).length === 0}
          choices={availableDiscounts}
        />

        <div style={{ borderBottom: "1px solid lightgray", width: "300px", marginBottom: "28px", marginTop: "16px" }} />

        <Typography variant="h6" mb={"20px"}>
          Facturation
        </Typography>
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <BooleanInput
              defaultValue={false}
              disabled={formData.subscriptionDate !== new Date().toISOString().split("T")[0]}
              source="autoRenewal"
              label="Facturation automatique"
            />
          )}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.autoRenewal &&
            formData.subscriptionDate === new Date().toISOString().split("T")[0] && (
              <ReferenceInput
                label="Mode de paiement"
                source="paymentMethod.id"
                reference="paymentMethods"
                filter={{ group_id: formData.company_id }}
                perPage={1000}
              >
                <PaymentMethodInput
                  style={{ marginBottom: "20px" }}
                  helperText="Pour utiliser un nouveau moyen de paiement, le créer au préalable dans votre organisation"
                />
              </ReferenceInput>
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            !formData.autoRenewal && (
              <SelectInput
                source="manualPaymentMode"
                defaultValue={null}
                label="Mode de paiement manuel"
                choices={MANUAL_PAYMENT_MODES.map((s) => ({ id: s, name: s }))}
              />
            )
          }
        </FormDataConsumer>
        <PeriodInput
          required
          disabled
          source="billingPeriod"
          label="Cycle de facturation"
          product={product}
          tarif={tarif}
        />
      </SimpleForm> */}
    </Create>
  );
};

export default SubscriptionsCreate;
