import React, { useCallback, useEffect, useState } from "react";
import { useRefresh, useNotify, Button } from "react-admin";
import { Box, Typography, useTheme } from "@mui/material";
import { Mail } from "lucide-react";
import CreateInDialog from "../../components/react-admin/CreateInDialog";
import { object, string } from "zod";
import FormInput from "../../components/form/FormInput";
import api from "../../services/apiWithAuth";
import { COUNTRIES } from "../../services/common-utils/constants";
import { sanitizePhoneNumber } from "../../services/common-utils/functions";
import { DialogIcon } from "../../components/react-admin/DialogIcon";

const formSchema = object({
  companyName: string().min(1, "Merci de renseigner le nom de l'organisation à parrainer"),
  companyCountry: string().min(1, "Merci de renseigner un pays"),
  email: string().min(1, "Merci de renseigner une adresse email"),
  phone: string().min(1, "Merci de renseigner un numéro de téléphone"),
  contactFirstName: string().min(1, "Merci de renseigner le prénom de votre contact"),
  contactLastName: string().min(1, "Merci de renseigner le nom de votre contact"),
});

const AffiliationButton = ({ record, companyCredits, clicked, closeMenu }: any) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const theme = useTheme();
  //using key for the react-admin dialog component is a hack to close the dialog after submitting
  //without interfering with the isOpen property.
  const [key, setKey] = useState(0);

  const save = useCallback(
    async (values: any) => {
      values.phone = sanitizePhoneNumber(values.phone);
      try {
        await api.post("/utils/createAffiliationOpportunity", values);
      } catch (error) {
        notify("Erreur lors de l'envoi du lien d'affiliation", {
          type: "error",
          messageArgs: { smart_count: 1 },
        });
      }
      setKey((key) => key + 1);
      refresh();
    },
    [notify, refresh]
  );

  useEffect(() => {
    if (clicked) {
      document?.getElementById("affiliateButton")?.click();
    }
  }, [clicked]);

  return (
    <CreateInDialog
      formSchema={formSchema}
      title={"Parrainer"}
      resource="sms"
      key={key}
      confirmLabel="Envoyer lien d'affiliation au filleul"
      formProps={{ onSubmit: save }}
      fullWidthFields
      buttonType="custom"
      icon={<DialogIcon icon={null} text="Ajouter" variant="bold_sm" />}
      ButtonProps={{
        variant: "contained",
        color: "primary",
        id: "affiliateButton",
        sx: {
          fontSize: "0.9rem",
          width: 1,
          mt: 2,
          px: 2,
          py: 0.75,
          lineHeight: "1.75 !important",
          height: ["2.3rem", "auto"],
          borderRadius: 2,
          backgroundColor: theme.palette.button.primary.contained.background,
          color: theme.palette.button.primary.contained.content,
          "&:hover": {
            backgroundColor: theme.palette.button.primary.contained.background + " !important",
          },
        },
      }}
      onClose={() => {
        closeMenu();
      }}
    >
      <Typography variant="medium_sm" color="common.text" sx={{ mb: 2 }}>
        Parrainez une entreprise et recevez chacun un pack de 100 SMS gratuits !Lorsque l'entreprise que vous parrainez
        confirme sa première commande sur son espace client, vous et votre filleul recevrez tous les deux 100 crédits
        SMS offerts. Remplissez simplement le formulaire ci-dessous pour profiter de cette offre exclusive !
      </Typography>
      <Box sx={{ display: "grid", gridTemplateColumns: ["1fr", "1fr", "1fr 1fr"], gap: 2 }}>
        <FormInput label="Nom de l'organisation" name="companyName" defaultValue={null} />
        <FormInput label="Pays du siège social" type="select" name="companyCountry" values={COUNTRIES} required />
        <FormInput label="Email" name="email" defaultValue={null} />
        <FormInput label="Téléphone" name="phone" type="phone" />
        <FormInput label="Prénom du contact" name="contactFirstName" defaultValue={null} />
        <FormInput label="Nom du contact" name="contactLastName" defaultValue={null} />
      </Box>
    </CreateInDialog>
  );
};

export default AffiliationButton;
